import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import ApplicationPermissionPropTypes from 'propTypes/application/ApplicationPermissionPropTypes';
import { translate } from '@apex/react-toolkit/lib';
import { useDeleteApplicationRolePermissionMutation } from 'api/applicationRoleSlice';

const RemovePermissionModal = ({ show, handleClose, roleId, permission }) => {
  const [deleteApplicationRolePermission, { isLoading }] = useDeleteApplicationRolePermissionMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('removePermission')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmRemovePermission', { permissionName: permission.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await deleteApplicationRolePermission({ roleId, permissionId: permission.id });
            handleClose();
          }}
        >
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemovePermissionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  roleId: PropTypes.string.isRequired,
  permission: ApplicationPermissionPropTypes.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RemovePermissionModal;

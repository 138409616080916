import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationPaginatedTable from 'common/application/ApplicationPaginatedTable';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationSearchFields from 'common/application/ApplicationSearchFields';
import useSearchApplications from 'hooks/useSearchApplications';
import useCheckUserPermission from 'hooks/useCheckUserPermission';

const Search = () => {
  const { isSuperUser } = useCheckUserPermission();
  const navigate = useNavigate();
  const {
    result: queryResult,
    setSearchObj,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchApplications();

  const { error, isFetching, data: paginatedData } = queryResult;

  if (error) return (<Error coverPage />);
  if (!paginatedData) return (<Spinner size="10x" coverViewport />);

  const { data, meta } = paginatedData;

  return (
    <Container>
      <Row className="mt-5">
        <Col md={4}>
          <h2>{translate('applications')} <Badge pill variant="secondary">{meta.total}</Badge></h2>
        </Col>
        <Col md={{ span: 2, offset: 6 }} className="text-end">
          {isSuperUser &&
            (
              <Button onClick={() => navigate('/applications/new')}>
                <FontAwesomeIcon icon="plus" /> {translate('addApplication')}
              </Button>
            )
          }
        </Col>

      </Row>
      <Row className="my-2">
        <Col>
          <ApplicationSearchFields
            disabled={queryResult.isFetching}
            setSearchObj={setSearchObj}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {
            (isFetching) ? (<Spinner size="5x" />)
              : (
                <ApplicationPaginatedTable
                  sortBy={sortBy}
                  setSortBy={(updatedSortKey) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortDirection={sortDirection}
                  applications={data}
                  pageCount={meta.last_page}
                  currentPage={meta.current_page}
                  onPageChange={setPage}
                />
              )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default Search;

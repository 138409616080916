import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import useToast from 'hooks/useToast';
import { usePromoteApplicationMicroserviceDeploymentMutation } from 'api/applicationMicroserviceSlice';

const ConfirmPromotionModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  deploymentId: string | null;
  imageTag: string | null;
}> = ({ show, handleClose, deploymentId, imageTag }) => {
  const [promoteDeployment, { isLoading }] = usePromoteApplicationMicroserviceDeploymentMutation();
  const toast = useToast();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('promote')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmPromoteDeployment', { imageTag: imageTag || translate('microservice') })}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="primary"
          onClick={async () => {
            try {
              await promoteDeployment({ deploymentId });

              toast({
                bg: 'success',
                title: translate('success'),
                message: ` ${translate('promotionStartedSuccessfully')}`,
              });
            } catch (e) {
              toast({
                bg: 'danger',
                title: translate('error'),
                message: translate('anUnexpectedErrorOccurred'),
                autohide: false,
              });
            }

            handleClose();
          }}
        >
          {translate('promote')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPromotionModal;

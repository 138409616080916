import { FC } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { IApplicationMicroserviceScheduledJob } from 'types/application/microservice/IScheduledJobMicroservice';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';
import AccordionToggleLink from 'common/AccordionToggleLink';
import ComputeDetailsForm from 'common/computeBoundMicroservices/ComputeDetailsForm';
import { useGetPolicyablePoliciesQuery } from 'api/iamPolicySlice';
import IamPolicyableAdder from 'common/infrastructure/iamPolicies/IamPolicyableAdder';

const GlobalSettings: FC<{
  microservice: IApplicationMicroserviceScheduledJob;
  isDeveloperAuthorized: boolean;
  isSuperUserAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized, isSuperUserAuthorized }) => {
  const { data: currentPolicies } = useGetPolicyablePoliciesQuery({
    policyableId: microservice.application_microserviceable_id,
    policyableType: microservice.application_microserviceable_type,
  });

  return (
    <Accordion alwaysOpen className="mb-3">
      {
        isDeveloperAuthorized && (
          <>
            <Card bg="dark">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('computeDetails')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="computeDetails"
                      inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="computeDetails">
              <ComputeDetailsForm computeDetails={microservice.microserviceable.compute_details} applicationMicroserviceId={microservice.id} isDeveloperAuthorized={isDeveloperAuthorized} />
            </Accordion.Collapse>
          </>
        )
      }
      {
        isSuperUserAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start">
                    {translate('iamPolicies')}
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {currentPolicies?.length} {translate('iamPolicies')}
                    </span>
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="iam-policy-adder"
                      inactiveText={isSuperUserAuthorized ? translate('edit') : translate('view')}
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="iam-policy-adder">
              <IamPolicyableAdder
                policyableType={microservice.application_microserviceable_type}
                policyableId={microservice.application_microserviceable_id}
              />
            </Accordion.Collapse>
          </>
        )
      }
      { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }
    </Accordion>
  )
};

export default GlobalSettings;

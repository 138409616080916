import React from 'react';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import MaintainerPropTypes from 'propTypes/maintainer/MaintainerPropTypes';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';

const maintainerRoles = [
  'viewer',
  'developer',
  'administrator',
];

const MaintainerTable = ({ maintainers, sortBy, setSortBy, sortDirection, onSelectMaintainerRole, actionColumn, canMutateMaintainers }) => {
  const columns = [
    {
      title: translate('firstName'),
      dataIndex: 'first_name',
      sortDirection: sortBy === 'first_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('first_name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/users/${id}`}>{first}</Link>),
    },
    {
      title: translate('lastName'),
      dataIndex: 'last_name',
      sortDirection: sortBy === 'last_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('last_name'),
        className: 'clickable',
      }),
    },
    {
      title: translate('email'),
      dataIndex: 'email',
      sortDirection: sortBy === 'email' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('email'),
        className: 'clickable',
      }),
    },
    {
      title: translate('type'),
      dataIndex: 'maintainer_role',
      sortDirection: sortBy === 'maintainer_role' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('maintainer_role'),
        className: 'clickable',
      }),
      render: (_, maintainer) => canMutateMaintainers && (
        <DropdownButton
          disabled={!canMutateMaintainers}
          size="sm"
          variant="secondary"
          title={capitalize(maintainer.maintainer_role)}
          onSelect={(eventKey) => onSelectMaintainerRole(maintainer.id, eventKey)}
          menuVariant="dark"
        >
          {
            maintainerRoles.map(item => (
              <Dropdown.Item
                key={item}
                eventKey={item}
                active={item === maintainer.maintainer_role}
                as="button"
              >
                {capitalize(item)}
              </Dropdown.Item>
            ))
          }
        </DropdownButton>
      ),
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={maintainers}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

MaintainerTable.propTypes = {
  ...SortPropTypes,
  maintainers: PropTypes.arrayOf(MaintainerPropTypes).isRequired,
  onSelectMaintainerRole: PropTypes.func.isRequired,
  actionColumn: PropTypes.object,
  canMutateMaintainers: PropTypes.bool,
};

MaintainerTable.defaultProps = {
  actionColumn: null,
  canMutateMaintainers: false,
};

export default MaintainerTable;

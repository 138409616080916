import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addScheduledJobMicroservice: builder.mutation({
      query: data => ({ url: 'scheduled-job-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    editScheduledJobMicroservice: builder.mutation({
      query: data => ({ url: `scheduled-job-microservices/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    addScheduledExecution: builder.mutation({
      query: ({ scheduledJobId, data }) => ({ url: `scheduled-job-microservices/${scheduledJobId}/add-scheduled-execution`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'ScheduledExecutions' }],
    }),
    getScheduledExecutions: builder.query({
      query: ({ queryParameters, scheduledJobId }) => {
        return { url: `scheduled-job-microservices/${scheduledJobId}/scheduled-executions`, method: 'get', queryParameters };
      },
      providesTags: ['ScheduledExecutions'],
    }),
  }),
});

export const {
  useAddScheduledJobMicroserviceMutation,
  useEditScheduledJobMicroserviceMutation,
  useLazyGetScheduledExecutionsQuery,
  useAddScheduledExecutionMutation,
} = extendedApiSlice;

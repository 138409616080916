import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import GroupSearchFields from 'common/group/GroupSearchFields';
import PaginationControl from 'common/PaginationControl';
import AddGroupTable from 'common/group/AddGroupTable';
import { useAddApplicationGroupsMutation } from 'api/applicationSlice';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import useSearchGroups from 'hooks/useSearchGroups';
import AddedGroupList from 'common/group/AddedGroupList';
import IGroup from 'types/group/IGroup';

const AddGroupsModal: React.FC<{
  applicationId: string;
  show: boolean;
  handleClose: () => void;
  currentGroups: IGroup[];
}> = ({ applicationId, show, handleClose, currentGroups }) => {
  const [selectedGroupHash, setSelectedGroupHash] = useState<{ [key: string]: IGroup }>({});
  const [addApplicationGroups, { isLoading: addGroupsLoading, error: addGroupsError }] = useAddApplicationGroupsMutation();
  const {
    result: queryResult,
    setSearchObj,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchGroups({ relationships: ['organization'] });

  useEffect(() => {
    setSelectedGroupHash({});
  }, [show]);

  const isLoading = queryResult.isLoading || addGroupsLoading;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w add-relation"
    >
      <Modal.Header className="border-0">
        <h1>{translate('addGroupsToApplication')}</h1>
      </Modal.Header>
      <Modal.Body>
        {
          (queryResult.error || addGroupsError) && (<Error />)
        }
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <GroupSearchFields
                  disabled={queryResult.isFetching}
                  setSearchObj={setSearchObj}
                  take={take}
                  setTake={setTake}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <AddGroupTable
                  setSortBy={(updatedSortKey) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  stagedGroupHash={selectedGroupHash}
                  currentGroups={currentGroups}
                  groups={queryResult?.data?.data || []}
                  loading={isLoading}
                  addSelectedGroup={group => {
                    const updatedSelectedGroupHash = { ...selectedGroupHash };
                    updatedSelectedGroupHash[group.id] = group;
                    setSelectedGroupHash(updatedSelectedGroupHash);
                  }}
                />
                <PaginationControl
                  pagesToShow={3}
                  pageCount={queryResult?.data?.meta?.last_page || 1}
                  currentPage={queryResult?.data?.meta?.current_page || 0}
                  onPageChange={setPage}
                />
              </Col>
              <Col md={6}>
                <AddedGroupList
                  addedGroups={Object.values(selectedGroupHash)}
                  dismissGroup={group => {
                    const updatedSelectedGroupHash = { ...selectedGroupHash };
                    delete updatedSelectedGroupHash[group.id];
                    setSelectedGroupHash(updatedSelectedGroupHash);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const response = await addApplicationGroups({
              applicationId,
              data: Object.values(selectedGroupHash).map(({ id }) => id),
            });

            if ('error' in response === false) {
              handleClose();
            }
          }}
        >
          {translate('addGroups')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddGroupsModal;

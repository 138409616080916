import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Deployment } from 'kubernetes-types/apps/v1';
import { Col, Row } from 'react-bootstrap';

const ReplicasRow: React.FC<{ deployment: Deployment }> = ({ deployment }) => {
  return (
    <>
      <Row>
        <h4>{translate('replicas')}</h4>
      </Row>
      <Row className="mb-2">
        <Col><h6>{translate('desired')}</h6></Col>
        <Col><h6>{translate('ready')}</h6></Col>
        <Col><h6>{translate('available')}</h6></Col>
      </Row>
      <Row>
        <Col>{deployment.status?.replicas}</Col>
        <Col>{deployment.status?.readyReplicas}</Col>
        <Col>{deployment.status?.availableReplicas}</Col>
      </Row>
    </>
  );
};

export default ReplicasRow;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import MaintainerPropTypes from 'propTypes/maintainer/MaintainerPropTypes';
import { useRemoveApplicationMaintainerMutation } from 'api/applicationSlice';

const RemoveMaintainerModal = ({ show, handleClose, maintainer, applicationId, applicationName }) => {
  const [removeMaintainer, { isLoading }] = useRemoveApplicationMaintainerMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('removeMaintainer')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {
            translate('confirmRemoveMaintainer', {
              firstName: maintainer.first_name,
              lastName: maintainer.last_name,
              application: applicationName,
            })
          }
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await removeMaintainer({ applicationId, maintainerId: maintainer.id });
            handleClose();
          }}
        >
          {translate('remove')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveMaintainerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  maintainer: MaintainerPropTypes.isRequired,
  applicationId: PropTypes.string.isRequired,
  applicationName: PropTypes.string.isRequired,
};

export default RemoveMaintainerModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import ApplicationPermissionPropTypes from 'propTypes/application/ApplicationPermissionPropTypes';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';

const PermissionsTable = ({ permissions, sortBy, setSortBy, sortDirection, applicationId, queuePermissionForDelete, canMutatePermissions }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/applications/${applicationId}/permissions/${id}`}>{first}</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_, permission) => {
        return canMutatePermissions && (
          <Button
            variant="danger"
            size="sm"
            className="text-nowrap"
            onClick={() => queuePermissionForDelete(permission)}
          >
            <FontAwesomeIcon icon="trash-can" fixedWidth /> {translate('delete')}
          </Button>
        );
      },
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={permissions}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

PermissionsTable.defaultProps = {
  canMutatePermissions: false,
};

PermissionsTable.propTypes = {
  ...SortPropTypes,
  permissions: PropTypes.arrayOf(ApplicationPermissionPropTypes).isRequired,
  applicationId: PropTypes.string.isRequired,
  queuePermissionForDelete: PropTypes.func.isRequired,
  canMutatePermissions: PropTypes.bool,
};

export default PermissionsTable;

import React from 'react';
import { Badge, Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import ApplicationMicroserviceConfiguration from 'common/applicationMicroservice/ApplicationMicroserviceConfiguration';
import ApplicationMicroserviceHealthCheckStatus from 'common/applicationMicroservice/ApplicationMicroserviceHealthCheckStatus';
import { ApplicationMicroserviceApiPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useHealthcheck from 'hooks/useHealthcheck';
import styles from './HasuraDetail.module.css';

const HasuraDetail = ({ microservice }) => {
  // const { microserviceable: hasuraMicroservice } = microservice;
  const isHealthy = useHealthcheck(microservice.id, 'prod');

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
                {(isHealthy !== null) && (
                  <span className={`ms-3 align-middle ${styles.healthCheckStatusContainer}`}>
                    <ApplicationMicroserviceHealthCheckStatus isHealthy={isHealthy} />
                  </span>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('prodDeployedVersion')}:</strong> <span>{microservice.prod_tag || <Badge bg="warning" text="dark">{translate('notDeployed')}</Badge>}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <ApplicationMicroserviceConfiguration microservice={microservice} />
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

HasuraDetail.propTypes = {
  microservice: ApplicationMicroserviceApiPropTypes.isRequired,
};

export default HasuraDetail;

import { apiSlice } from 'api/apiSlice';
import IApiMicroservice from 'types/application/microservice/IApiMicroservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addApiMicroservice: builder.mutation({
      query: data => ({ url: 'api-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    deleteApiMicroservice: builder.mutation<null, IApiMicroservice>({
      query: (data: IApiMicroservice) => ({
        url: `api-microservices/${data.id}`, method: 'delete',
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useAddApiMicroserviceMutation,
  useDeleteApiMicroserviceMutation,
} = extendedApiSlice;

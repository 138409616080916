import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import INodeGroup from 'types/infrastructure/INodeGroup';
import { useNavigate } from 'react-router-dom';
import { useDeleteNodeGroupMutation } from 'api/nodeGroupSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteNodeGroupModal: FC<{
  show: boolean
  handleClose: () => void
  nodeGroup: INodeGroup
}> = ({ show, handleClose, nodeGroup }) => {
  const navigate = useNavigate();
  const [deleteNodeGroup, { isLoading }] = useDeleteNodeGroupMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteNodeGroup')}</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          {translate('confirmDeleteNodeGroup', { name: nodeGroup.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" className="me-1" />
          {translate('cancel')}
        </Button>
        <Button
          className="d-inline text-nowrap"
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await deleteNodeGroup(nodeGroup);
            navigate('/infrastructure#nodeGroups');
          }}
        >
          {
            isLoading ? (
              <FontAwesomeIcon icon="spinner" className="me-1" spin />
            ) : (
              <FontAwesomeIcon icon="trash-alt" className="me-1" />
            )
          }
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteNodeGroupModal;

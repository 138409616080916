import { useState, useEffect } from 'react';
import { useLazyListOrganizationsQuery } from 'api/organizationSlice';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import IOrganization from 'types/organization/IOrganization';
import PaginatedResult from 'types/PaginatedResult';
import RtkQueryResult from 'types/RtkQueryResult';

export type SortBy = 'name' | 'description' | 'domain';
export type OrganizationRelationships = 'applications' | 'teams' | 'groups';

export type UseSearchOrganizationsSearchParameters = {
  name?: string;
  description?: string;
  domain?: string;
  any?: string;
  relationships?: OrganizationRelationships[]
};

const useSearchOrganizations: (args?: {
  initialSearchParameters?: UseSearchOrganizationsSearchParameters;
}) => {
  setTake: (take: number) => void;
  take: number;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  setSearchParameters: (searchParameters: UseSearchOrganizationsSearchParameters) => void;
  searchParameters: UseSearchOrganizationsSearchParameters;
  page: number
  setPage: (page: number) => void
  result: RtkQueryResult<PaginatedResult<IOrganization>>
} = (args?: { initialSearchParameters?: UseSearchOrganizationsSearchParameters}) => {
  const initialSearchParameters = args?.initialSearchParameters || {};
  const [take, setTake] = useState<number>(10);
  const [sortBy, setSortBy] = useState<SortBy>('name');
  const [page, setPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [searchParameters, setSearchParameters] = useState<UseSearchOrganizationsSearchParameters>(initialSearchParameters);
  const [getOrganizations, result] = useLazyListOrganizationsQuery();

  const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchOrganizationsSearchParameters> = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
    ...searchParameters,
  };

  useEffect(() => {
    getOrganizations(queryParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrganizations, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getOrganizations(queryParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters, take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    setSearchParameters,
    searchParameters,
    page,
    setPage,
    result,
  };
};

export default useSearchOrganizations;

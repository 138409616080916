import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const defaultToast = {
  // bootstrap style
  bg: 'info',
  // dismiss delay in ms
  delay: 5000,
  // automatically dismiss toast
  autohide: true,
  // header text
  title: undefined,
  // body text
  message: undefined,
};

const slice = createSlice({
  name: 'toast',
  initialState: {
    toasts: {},
  },
  reducers: {
    addToast: (state, { payload: toast }) => {
      const id = uuidv4();
      state.toasts[id] = {
        id,
        ...defaultToast,
        ...toast,
      };
    },
    removeToast: (state, { payload: toastId }) => {
      delete state.toasts[toastId];
    },
  },
});

export const { removeToast, addToast } = slice.actions;
export const getToasts = (state) => state.toast.toasts;
export default slice.reducer;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import useToast from 'hooks/useToast';

const ConfirmationModal: React.FC<{
  show: boolean;
  onConfirm: () => void;
  handleClose: () => void;
  title: string;
  confirmationText: string;
  disabled: boolean;
  successMessage?: string;
}> = ({
  confirmationText,
  disabled,
  handleClose,
  onConfirm,
  show,
  successMessage,
  title,
}) => {
    const toast = useToast();

    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0">
          <h2>{title}</h2>
        </Modal.Header>
        <Modal.Body className="confirm">
          <p>
            {confirmationText}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 py-3 justify-content-start">
          <Button
            className="me-1"
            disabled={disabled}
            variant="secondary"
            onClick={handleClose}
          >
            {translate('cancel')}
          </Button>
          <Button
            disabled={disabled}
            variant="primary"
            onClick={async () => {
              try {
                await onConfirm();

                if (successMessage) {
                  toast({
                    bg: 'success',
                    title: translate('success'),
                    message: successMessage,
                  });
                }
              } catch (e) {
                toast({
                  bg: 'danger',
                  title: translate('error'),
                  message: translate('anUnexpectedErrorOccurred'),
                  autohide: false,
                });
              }

              handleClose();
            }}
          >
            {translate('confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

export default ConfirmationModal;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import useDebounce from 'hooks/useDebounce';

const SearchFilter = ({ size, options, onUpdate, disabled }) => {
  const [searchVal, setSearchVal] = useState('');
  const [selectedOption, setSelectedOption] = useState(options[0].option);
  const [firstDebounceOccurred, setFirstDebounceOccurred] = useState(false);
  const debouncedValue = useDebounce(searchVal, 500);

  useEffect(() => {
    // this check is needed because debouncedValue fired the effect hook on first render
    if (!firstDebounceOccurred) setFirstDebounceOccurred(true);

    if (firstDebounceOccurred) {
      onUpdate({
        option: selectedOption,
        value: debouncedValue,
        [selectedOption]: debouncedValue,
      });
    }
  }, [debouncedValue, selectedOption]); // eslint-disable-line

  return (
    <Row>
      <Col md={6}>
        <Form.Select
          size={size}
          onChange={e => setSelectedOption(e.target.value)}
          disabled={disabled}
        >
          {options.map(({ option, display }) => (
            <option key={option} value={option}>
              {display}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col md={6}>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            required
            size={size}
            name="searchVal"
            type="text"
            placeholder="Search"
            value={searchVal}
            onChange={e => setSearchVal(e.target.value)}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};

SearchFilter.defaultProps = {
  disabled: false,
  size: 'sm',
  onUpdate: () => null,
};

SearchFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    option: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })).isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'etc']),
};

export default SearchFilter;

import React, { useState } from 'react';
import { Alert, Card, Container, Row, Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import NewApi from 'App/AppRoutes/Applications/Application/Microservices/New/NewApi';
import NewRelationalDatabase from 'App/AppRoutes/Applications/Application/Microservices/New/NewRelationalDatabase';
import NewHasura from 'App/AppRoutes/Applications/Application/Microservices/New/NewHasura';
import NewLambda from 'App/AppRoutes/Applications/Application/Microservices/New/NewLambda';
import NewRedis from 'App/AppRoutes/Applications/Application/Microservices/New/NewRedis';
import NewS3 from 'App/AppRoutes/Applications/Application/Microservices/New/NewS3';
import NewScheduledJob from 'App/AppRoutes/Applications/Application/Microservices/New/NewScheduledJob';
import NewWorker from 'App/AppRoutes/Applications/Application/Microservices/New/NewWorker';
import Unauthorized from 'common/Unauthorized';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import NewFilesystem from './NewFilesystem';

const New = () => {
  const { applicationId } = useParams();
  const [selectedType, setSelectedType] = useState('');
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);

  if (!isDeveloperAuthorized) return <Unauthorized />;

  return (
    <Container>
      <Row className="mt-4">
        <Col md={{ span: 6, offset: 3 }}>
          <Row>
            <Col>
              <h2>{translate('addMicroservice')}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="bg-dark">
                <Card.Body>
                  <Form.Group>
                    <Form.Label>{translate('microserviceType')}</Form.Label>
                    <Form.Select
                      value={selectedType}
                      onChange={e => setSelectedType(e.target.value)}
                    >
                      <option value="" disabled>{translate('selectType')}</option>
                      <option value="api">{translate('api')}</option>
                      <option value="database">{translate('relationalDatabase')}</option>
                      <option value="filesystem">{translate('mountableFilesystem')}</option>
                      <option value="redis">{translate('redis')}</option>
                      <option value="lambda">{translate('lambda')}</option>
                      <option value="s3">{translate('s3')}</option>
                      <option value="scheduled-job">{translate('scheduledJob')}</option>
                      <option value="worker">{translate('worker')}</option>
                    </Form.Select>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            {(
              // eslint-disable-next-line func-names
              function () {
                if (selectedType === 'api') return (<NewApi />);
                if (selectedType === 'database') return (<NewRelationalDatabase />);
                if (selectedType === 'redis') return (<NewRedis />);
                if (selectedType === 'hasura') return (<NewHasura />);
                if (selectedType === 'lambda') return (<NewLambda />);
                if (selectedType === 's3') return (<NewS3 />);
                if (selectedType === 'scheduled-job') return (<NewScheduledJob />);
                if (selectedType === 'worker') return (<NewWorker />);
                if (selectedType === 'filesystem') return (<NewFilesystem />);
                return (
                  <Alert>
                    {translate('selectMicroserviceType')}
                  </Alert>
                );
              }())}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default New;

import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    deleteScheduledJobExecution: builder.mutation({
      query: id => ({ url: `scheduled-job-executions/${id}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'ScheduledExecutions' }],
    }),
  }),
});

export const {
  useDeleteScheduledJobExecutionMutation,
} = extendedApiSlice;

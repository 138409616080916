import { FC, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useListApexHelmChartVersionsQuery } from 'api/apexHelmChartVersionSlice';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { formatDate } from 'helpers/datetime';
import { sortBy } from 'lodash';
import RunningOutOfTimeSpan from 'common/datetime/RunningOutOfTimeSpan';

const MicroserviceChartSelectionForm: FC<{
  currentVersion: IApexHelmChartVersion;
  isDeveloperAuthorized: boolean;
  onSubmit: (version: IApexHelmChartVersion) => void;
  disabled?: boolean;
}> = ({ currentVersion, isDeveloperAuthorized, onSubmit, disabled }) => {
  const [selectedVersion, setSelectedVersion] = useState<IApexHelmChartVersion>(currentVersion);
  const { data: chartData, isLoading: helmChartsLoading } = useListApexHelmChartVersionsQuery({ chart_name: currentVersion.chart_name });
  const availableVersions = chartData?.data || [];

  const isDisabled = helmChartsLoading || disabled;

  return (
    <Form noValidate onSubmit={(e) => {
      e.preventDefault();
      onSubmit(selectedVersion);
    }}>
      <Row>
        <Col md={8}>
          <Typeahead
            id="version-selector"
            // We always want to show the whole list
            filterBy={() => true}
            disabled={isDisabled}
            labelKey="version"
            options={sortBy(availableVersions, 'version')}
            placeholder={translate('version')}
            selected={selectedVersion ? [selectedVersion as Option] : []}
            onChange={(selected) => {
              if (selected.length > 0) {
                setSelectedVersion(selected[0] as IApexHelmChartVersion);
              }
            }}
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            renderMenuItemChildren={(chartVersion: IApexHelmChartVersion) => {
              const isCurrentVersion = chartVersion.id === currentVersion.id;

              const today = new Date();
              const deprecationDate = new Date(chartVersion.deprecation_date);
              const eolDate = new Date(chartVersion.eol_date);

              const isDeprecated = chartVersion.deprecation_date && today > deprecationDate;
              const isDead = chartVersion.eol_date && today > eolDate;

              let versionClassName = '';
              let versionDescriptor = '';

              if (isDeprecated) {
                versionClassName = 'text-warning';
                versionDescriptor = translate('deprecated');
              }

              if (isDead) {
                versionClassName = 'text-danger';
                versionDescriptor = translate('noLongerSupported');
              }

              return (
                <>
                  <strong>
                    <span className={versionClassName}>{chartVersion.version} {versionDescriptor}</span> {isCurrentVersion && (<span className="text-success">Current</span>)}
                  </strong>
                  <div>
                    <small>
                      {translate('deprecationDate')}: {chartVersion.deprecation_date ? <RunningOutOfTimeSpan
                        warnDaysBefore={30}
                        dateString={chartVersion.deprecation_date}
                        renderDateString={formatDate}
                      /> : <span className="text-success">{translate('notSet')}</span>
                      }
                    </small>
                    <br />
                    <small>
                      {translate('eolDate')}: {chartVersion.eol_date ? <RunningOutOfTimeSpan
                        warnDaysBefore={30}
                        dateString={chartVersion.eol_date}
                        renderDateString={formatDate}
                      /> : <span className="text-success">{translate('notSet')}</span>
                      }
                    </small>
                  </div>
                </>
              );
            }}
          />
        </Col>
        <Col md={4}>
          {
            isDeveloperAuthorized && (
              <>
                <Button
                  variant="secondary"
                  disabled={isDisabled}
                  onClick={() => setSelectedVersion(currentVersion)}
                >
                  <FontAwesomeIcon icon="ban" />
                  <span className="ms-2">{translate('cancel')}</span>
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="ms-2"
                  disabled={isDisabled || (currentVersion.id === selectedVersion?.id)}
                >
                  {/* @ts-expect-error this is a valid icon */}
                  <FontAwesomeIcon icon="floppy-disk" />
                  <span className="ms-2">{translate('save')}</span>
                </Button>
              </>
            )
          }
        </Col>
      </Row>
    </Form>
  );
};

export default MicroserviceChartSelectionForm;


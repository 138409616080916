import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useGetFilesystemsQuery } from 'api/applicationMicroserviceSlice';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { IApplicationMicroserviceComputeBound } from 'types/application/microservice/IApplicationMicroservice';
import MountedFilesystemCard from './MountedFilesystemCard';
import FilesystemMounter from './FilesystemMounter';
import IFilesystemMicroservice from 'types/application/microservice/IFilesystemMicroservice';
import { useParams } from 'react-router-dom';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useUnmountFilesystemMutation } from 'api/filesystemMicroserviceSlice';
import useToast from 'hooks/useToast';

const FilesystemsTab: React.FC<{
  microservice: IApplicationMicroserviceComputeBound
}> = ({ microservice }) => {
  const [fsQueuedForRemoval, setFsQueuedForRemoval] = useState<IFilesystemMicroservice | null>(null);
  const [unmount, { isLoading: unmountLoading }] = useUnmountFilesystemMutation();
  const { data, isLoading, error } = useGetFilesystemsQuery(microservice.id);
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);
  const toast = useToast();

  const disabled = isLoading || unmountLoading || !isDeveloperAuthorized;

  if (isLoading) return (<Spinner />);
  if (error || !data) return (<Error error={error} coverPage />);


  return (
    <Container>
      <ConfirmationModal
        confirmationText={
          fsQueuedForRemoval ?
            translate('areYouSureYouWantToUnmount', {
              filesystemName: fsQueuedForRemoval.application_microservice?.name,
              microserviceName: microservice.name,
            })
            : ''
        }
        disabled={disabled}
        handleClose={() => setFsQueuedForRemoval(null)}
        onConfirm={async () => {
          const filesystem = fsQueuedForRemoval as IFilesystemMicroservice;
          const result = await unmount({ filesystemId: filesystem.id, applicationMicroserviceId: microservice.id })

          if ('error' in result) {
            toast({
              bg: 'danger',
              title: translate('error'),
              message: result.error.message,
              autohide: true,
            });
          }
        }}
        show={fsQueuedForRemoval !== null}
        successMessage={translate('filesystemQueuedForUnmount')}
        title={translate('confirmUnmount')}
      />
      {isDeveloperAuthorized && (
        <Row>
          <Col>
            <Card bg="dark">
              <Card.Header>
                <Card.Title>
                  <strong className="text-info">{translate('addFilesytstem')}</strong>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {/** @TODO filter out already mounted systems */}
                <FilesystemMounter
                  microservice={microservice}
                  applicationId={applicationId as string}
                  currentlyMountedFilesystems={data}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {data.length > 0 ? data.map((fs: IFilesystemMicroservice) => (
        <div key={fs.id}>
          <hr />
          <Row>
            <Col>
              <MountedFilesystemCard
                filesystem={fs}
                isDeveloperAuthorized={isDeveloperAuthorized}
                microservice={microservice}
                queueFilesystemForRemval={setFsQueuedForRemoval}
              />
            </Col>
          </Row>
        </div>
      )) : (
        <>
          <hr />
          <Alert variant="info">
            {translate('noFilesystemsMountedYet')}
          </Alert>
        </>
      )}
    </Container>
  );
};

export default FilesystemsTab;

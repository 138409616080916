import { Badge, Button, Col, Row } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import NodeGroupSearchFields from 'common/infrastructure/nodeGroups/NodeGroupSearchFields';
import NodeGroupPaginatedTable from 'common/infrastructure/nodeGroups/NodeGroupPaginatedTable';
import useSearchNodeGroups, { NodeGroupSortBy } from 'hooks/useSearchNodeGroups';

const SearchNodeGroups = () => {
  const navigate = useNavigate();
  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchNodeGroups();

  const { error, isLoading, data: paginatedData } = queryResult;

  if (!paginatedData) return (<Spinner animation='border' />);
  if (error) return (<Error coverPage />);

  const { data, meta } = paginatedData;

  return (
    <>
      <Row className="mt-2">
        <Col md={4}>
          <h2>
            {translate('nodeGroups')}
            <Badge pill className="ms-3">
              {meta.total}
            </Badge>
          </h2>
        </Col>
        <Col md={{ span: 4, offset: 4 }} className="text-end">
          <Button
            onClick={() => navigate('/infrastructure/node-groups/new')}
            className="text-nowrap"
          >
            <FontAwesomeIcon icon="plus" /> {translate('createNodeGroup')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <NodeGroupSearchFields
            disabled={isLoading}
            setSearchParameters={setSearchParameters}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <NodeGroupPaginatedTable
            nodeGroups={data}
            sortBy={sortBy}
            setSortBy={(updatedSortKey: NodeGroupSortBy) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortDirection={sortDirection}
            pageCount={meta.last_page}
            currentPage={meta.current_page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default SearchNodeGroups;

import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useAddScheduledJobMicroserviceMutation } from 'api/scheduledJobMicroserviceSlice';
import { Alert } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ScheduledJobForm from 'common/applicationMicroservice/scheduledJob/ScheduledJobForm';
import INameDecriptionFields from 'types/INameDecriptionFields';

const NewScheduledJob = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [addSchedJobMs, { isLoading, error: mutationError }] = useAddScheduledJobMicroserviceMutation();
  const [apiErrors, setApiErrors] = useState(undefined);


  const handleSubmit = async (formData: INameDecriptionFields) => {
    const result = await addSchedJobMs({ ...formData, application_id: applicationId });

    if ('error' in result) {
      setApiErrors(result.error.errors);
    } else if ('data' in result) {
      navigate(`/applications/${applicationId}/microservices/${result.data.application_microservice.id}`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/microservices/`);
  };

  return (
    <>
      {(mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>)}
      <ScheduledJobForm
        disabled={isLoading}
        initialValues={{
          name: '',
          description: '',
        }}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitText={translate('create')}
        apiErrors={apiErrors}
      />
    </>
  );
};

export default NewScheduledJob;

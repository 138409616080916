import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import useToast from 'hooks/useToast';
import ApexHelmChartVersionForm from 'common/infrastructure/apexHelmChartVersions/ApexHelmChartVersionForm';
import { useCreateApexHelmChartVersionMutation } from 'api/apexHelmChartVersionSlice';
import { IApexHelmChartVersionApiErrors } from 'types/infrastructure/IApexHelmChartVersion';

const NewApexHelmChartVersion: React.FC<{
  show: boolean;
  handleClose: () => void;
}> = ({
  handleClose,
  show,
}) => {
    const [createApexHelmChartVersion, { isLoading }] = useCreateApexHelmChartVersionMutation();
    const [apiErrors, setApiErrors] = useState<IApexHelmChartVersionApiErrors | undefined>(undefined);

    const toast = useToast();
    const disabled = isLoading;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0">
          <h2>{translate('createApexHelmChartVersion')}</h2>
        </Modal.Header>
        <Modal.Body className="confirm">
          <ApexHelmChartVersionForm
            onSubmit={async (values) => {
              const result = await createApexHelmChartVersion(values);

              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else if ('data' in result) {
                toast({
                  bg: 'success',
                  title: translate('success'),
                  message: translate('createdSuccessfully'),
                  autohide: true,
                });

                handleClose();
              }
            }}
            onCancel={handleClose}
            apiErrors={apiErrors}
            disabled={disabled}
          />
        </Modal.Body>
      </Modal>
    );
  };

export default NewApexHelmChartVersion;

import { apiSlice } from 'api/apiSlice';
import IQueryParameters from 'types/IQueryParameters';
import IUser from 'types/IUser';
import IApplication from 'types/application/IApplication';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addUser: builder.mutation({
      query: data => ({ url: 'users', method: 'post', data }),
      invalidatesTags: () => [{ type: 'Users' }],
    }),

    getUser: builder.query<IUser, { id: string, queryParameters?: IQueryParameters }>({
      query: ({ id, queryParameters }) => ({ url: `users/${id}`, method: 'get', queryParameters }),
      // providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),

    editUser: builder.mutation({
      query: data => ({ url: `users/${data.id}`, method: 'put', data }),
      invalidatesTags: () => ['Users'],
    }),

    listUsers: builder.query({
      query: queryParameters => {
        return { url: 'users', method: 'get', queryParameters };
      },
      providesTags: ['Users'],
    }),

    deleteUser: builder.mutation({
      query: data => ({ url: `users/${data.id}`, method: 'delete', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
    }),

    getApplicationAccess: builder.query<IApplication[], string>({
      query: id => ({ url: `users/${id}/application-access`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'UserApplicationAccess', id }],
    }),
  }),
});

export const {
  useAddUserMutation,
  useGetUserQuery,
  useListUsersQuery,
  useLazyListUsersQuery,
  useDeleteUserMutation,
  useEditUserMutation,
  useGetApplicationAccessQuery,
} = extendedApiSlice;

import { Card, Col, Row, Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IPermission from 'types/application/IPermission';

const OrganizationPermissionsCard: React.FC<{
  permissions: IPermission[]
}> = ({
  permissions
}) => {
    return (
      <Card bg="dark">
        <Card.Header className="ps-4 pe-0">
          <Row className="w-100">
            <Col>
              <h4>{translate('permissions')}</h4>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table variant="dark" striped>
            <tbody>
              {
                permissions?.map((permission) => (
                  <tr className="align-middle" key={permission.id}>
                    <td>
                      {permission.name}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

export default OrganizationPermissionsCard;

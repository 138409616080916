import React, { useState, useContext, useEffect } from 'react';
import { Accordion, AccordionContext, useAccordionButton, Button, Card, Col, ListGroup, Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useParams } from 'react-router-dom';
import Unauthorized from 'common/Unauthorized';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import { Badge } from 'react-bootstrap';
import { useEditMicroserviceS3AccessMutation } from 'api/applicationMicroserviceSlice';
import { useGetConsumesIamPolicyMicroservicesQuery } from 'api/applicationMicroserviceSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToast from 'hooks/useToast';

const AccordionToggleLink = ({ eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isActive = activeEventKey && activeEventKey.includes(eventKey);

  return (
    <Button
      variant="link"
      onClick={decoratedOnClick}
      className="p-0 align-baseline"
    >
      {isActive ? translate('close') : translate('edit')}
    </Button>
  );
};

const S3AccessManagement = ({ microserviceId, isDeveloperAuthorized }) => {
  if (!isDeveloperAuthorized) {
    return <Unauthorized />;
  }

  const { applicationId } = useParams();
  const [isAdding, setIsAdding] = useState(false);
  const [selected, setSelected] = useState([]);
  const [editMicroserviceS3Access] = useEditMicroserviceS3AccessMutation();
  const { data: consumesMicroServices, isLoading } = useGetConsumesIamPolicyMicroservicesQuery({ microserviceId });
  const toast = useToast();
  // Tracks saving button state
  const [isSaving, setIsSaving] = useState(false);
  // State to store copy of consumesMicroServices query results
  const [microServices, setMicroServices] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const {
    result,
  } = useSearchApplicationMicroservices({
    applicationId,
    initialSearchObj: {
      exclude_type: ['s3', 'redis', 'database', 'auth']
    },
  });

  useEffect(() => {
    if (consumesMicroServices) {
      setMicroServices(consumesMicroServices);
    }
  }, [consumesMicroServices]);

  const handleSave = async () => {
    setIsSaving(true);

    let selectedCopy = JSON.parse(JSON.stringify(selected));

    // copy of microservicesState
    let microServicesCopy = JSON.parse(JSON.stringify(microServices));
    let mergedCopies = [
      ...selectedCopy,
      ...microServicesCopy
    ];

    const microServiceIds = mergedCopies.map(item => item.application_microserviceable_id);

    try {
      await editMicroserviceS3Access({
        microserviceId,
        iamPolicyableMicroserviceIds: microServiceIds
      })

      toast({
        bg: 'success',
        title: translate('updated'),
        message: translate('savedSuccessfully'),
        autohide: true,
      });
    } catch (error) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    } finally {
      setSelected([]);
      setIsSaving(false);
      setIsAdding(false);
      setHasChanges(false);
    }
  };

  const handleCancel = () => {
    setMicroServices(consumesMicroServices || []);
    setSelected([]);
    setIsAdding(false);
    setHasChanges(false);
  };

  const handleRemove = (serviceIdx) => {
    setHasChanges(true);
    const microServicesCopy = JSON.parse(JSON.stringify(microServices));
    microServicesCopy.splice(serviceIdx, 1);
    setMicroServices(microServicesCopy);
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card className="bg-dark">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Col className="text-start">
            {translate('accessManagement')}
          </Col>
          <Col className="text-end">
            <AccordionToggleLink eventKey="0" />
          </Col>
        </Card.Header>
        <Accordion.Collapse eventKey="0" className="bg-dark">
          <Card.Body>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
                <>
                  <ListGroup className="mb-3">
                    {microServices.map((service, serviceIdx) => (
                      <ListGroup.Item key={service.id} className="d-flex justify-content-between align-items-center bg-dark text-white">
                        <div>
                          <h5 className="d-inline">{service.name}</h5>
                          <Badge bg="secondary" className="ms-2">{service.application_microserviceable_type.toUpperCase()}</Badge>
                        </div>
                        <Button variant="danger" size="sm" onClick={() => handleRemove(serviceIdx)}>
                          <FontAwesomeIcon icon="trash-alt" />
                          <span className="ms-2">{translate('remove')}</span>
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  {isAdding ? (
                    <Form>
                      <AsyncTypeahead
                        multiple
                        id="async-microservice-search"
                        isLoading={result.isLoading}
                        options={result.data?.data.map((service) => ({
                          ...service,
                          id: service.id,
                          name: service.name,
                          type: service.application_microserviceable_type,
                        })) || []}
                        labelKey="name"
                        placeholder={translate('selectMicroservice')}
                        onChange={(selectedItems) => {
                          setSelected(selectedItems);
                          if (selectedItems.length > 0) {
                            setHasChanges(true);
                          } else {
                            setHasChanges(false);
                          }
                        }}
                        selected={selected}
                        filterBy={(option) => {
                          return !selected.find(microService => microService.id === option.id) && !consumesMicroServices.find(microService => microService.id === option.id);
                        }}
                        renderMenuItemChildren={(option) => {
                          return (
                            <div>
                              {option.name}
                              <div>
                                <small>
                                  Type: {option.type}
                                </small>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </Form>
                  ) : (
                      <Button variant="primary" onClick={() => setIsAdding(true)}>
                        <FontAwesomeIcon icon='plus' />
                        <span className="ms-2">{translate('addMicroservice')}</span>
                      </Button>
                    )}
                  <div className="mt-2">
                    <Button variant="secondary" onClick={handleCancel} disabled={!hasChanges && !isAdding}>
                      <FontAwesomeIcon icon="ban" />
                      <span className="ms-2">{translate('cancel')}</span>
                    </Button>
                    <Button variant="primary" onClick={() => handleSave(selected)} className="ms-2" disabled={!hasChanges || isSaving}>
                      {isSaving ? (
                        <FontAwesomeIcon icon="spinner" spin />
                      ) : (
                          <FontAwesomeIcon icon="floppy-disk" />
                        )}
                      <span className="ms-2">{translate('save')}</span>
                    </Button>
                  </div>
                </>
              )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default S3AccessManagement;

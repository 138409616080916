import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SearchFilter from 'common/SearchFilter';
import { translate } from '@apex/react-toolkit/lib';
import { UseSearchUsersSearchParameters } from 'hooks/useSearchUsers';

const UserSearchFields: React.FC<{
  disabled?: boolean;
  searchParameters: UseSearchUsersSearchParameters;
  setSearchParameters: (searchParameters: UseSearchUsersSearchParameters) => void;
  take: number;
  setTake: (take: number) => void;
  includeApproved?: boolean;
}> = ({
  disabled = false,
  searchParameters,
  setSearchParameters,
  take,
  setTake,
  includeApproved = true
}) => {
    return (
      <Row>
        <Col md={6} className="text-start">
          <Form.Label>{translate('searchBy')}</Form.Label>
          <SearchFilter
            disabled={disabled}
            onUpdate={option => {
              const updatedParameters: UseSearchUsersSearchParameters = {
                [option.option]: option.value,
                approved: searchParameters?.approved || 'any',
              };
              setSearchParameters(updatedParameters);
            }}
            options={[
              {
                option: 'any',
                display: translate('any'),
              },
              {
                option: 'email',
                display: translate('email'),
              },
              {
                option: 'first_name',
                display: translate('firstName'),
              },
              {
                option: 'last_name',
                display: translate('lastName'),
              },
            ]}
          />
        </Col>
        <Col md={2} className="text-start">
          {
            includeApproved && (
              <>
                <Form.Label>{translate('approved')}</Form.Label>
                <Form.Select
                  size="sm"
                  disabled={disabled}
                  onChange={e => setSearchParameters({
                    ...searchParameters,
                    approved: e.target.value as '0' | '1' | 'any',
                  })}
                >
                  <option value="any">{translate('any')}</option>
                  <option value="1">{translate('approved')}</option>
                  <option value="0">{translate('unapproved')}</option>
                </Form.Select>
              </>
            )
          }
        </Col>
        <Col md={{ span: 3, offset: 1 }} className="text-end">
          <Form.Group>
            <Form.Label>{translate('resultsPerPage')}</Form.Label>
            <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    );
  };

export default UserSearchFields;

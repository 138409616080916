import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import Card from 'common/Card';

const TOPICS = [
  {
    title: translate('documentation'),
    url: 'https://documentation.app.apex-mp.com',
  },
  {
    title: translate('apiDocumentation'),
    url: 'https://documentation.app.apex-mp.com/api',
  },
  {
    title: translate('styleGuide'),
    url: 'https://documentation.app.apex-mp.com/styleguide',
  },
];

export default () => (
  <Card
    title={(
      <>
        {translate('resources')}

        <a href="https://documentation.app.apex-mp.com" target="_blank" style={{ fontSize: 14 }}>
          {translate('allResources')}
          <FontAwesomeIcon icon="arrow-right" className="ms-1" />
        </a>
      </>
    )}
    body={(
      <Table variant="dark">
        <tbody>
          {TOPICS.map(({ url, title }) => <tr><td><a href={url} target="_blank">{title}</a></td></tr>)}
        </tbody>
      </Table>
    )}
  />
);


import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { ImageTile } from '@apex/react-toolkit/components';
import IApplication from 'types/application/IApplication';
import { useStoreApplicationIconImgMutation } from 'api/applicationSlice';

const ImageUploaderModal: React.FC<{
  application: IApplication;
  show: boolean,
  handleClose: () => void;
}> = ({
  application,
  show,
  handleClose,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [url, setUrl] = useState<string | ArrayBuffer | null>(null);
    const [storeIconImg] = useStoreApplicationIconImgMutation();

    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="confirm">
          <Row>
            <Col>
              <Form.Group controlId="formFileSm" className="mb-3">
                <Form.Control
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files = e.target.files as FileList;
                    const file = files?.[0];
                    const reader = new FileReader();
                    reader.addEventListener('load', () => setUrl(reader.result || ''));
                    reader.readAsDataURL(file);
                    setFile(file);
                  }}
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  name="image"
                  size="sm"
                  className="mb-2"
                />
              </Form.Group>
            </Col>
          </Row>
          {url && (
            <Row>
              <Col className="d-flex justify-content-center">
                <ImageTile
                  title={application.name}
                  backgroundColor={application.background_color}
                  src={url}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-2 mb-2">
            <Col className="d-flex justify-content-center">
              <Button variant="secondary" onClick={handleClose}>{translate('cancel')}</Button>
              <Button
                className="ms-1"
                variant="primary"
                disabled={!url}
                onClick={async () => {
                  const formData = new FormData();
                  formData.append('icon_img', file as string | Blob);

                  if (file !== null) {
                    await storeIconImg({
                      applicationId: application.id,
                      data: formData,
                    });

                    setFile(null);
                    setUrl(null);
                    handleClose();
                  }
                }}
              >
                {translate('save')}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal >
    );
  };

export default ImageUploaderModal;

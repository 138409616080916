import React, { useState } from 'react';
import { Accordion, Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { camelCase } from 'lodash';
import CopyableLineItem from 'common/copyableLineItem';
import { IApplicationMicroserviceRedis, IRedisInstanceMetadata } from 'types/application/microservice/IRedisMicroservice';
import Environment from 'types/Environment';
import AccordionToggleLink from 'common/AccordionToggleLink';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useUpdateRedisPassowrdMutation } from 'api/redisMicroserviceSlice';

const RedisConnectionDetails: React.FC<{
  microservice: IApplicationMicroserviceRedis;
  environment: Environment;
  instance: IRedisInstanceMetadata;
  isDeveloperAuthorized: boolean;
}> = ({ microservice, environment, instance, isDeveloperAuthorized }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateRedisPassowrdMutation, { isLoading }] = useUpdateRedisPassowrdMutation();
  const disabled = isLoading;

  return (
    <div className="mb-4">
      <Card bg="dark">
        <Card.Header>
          <Row>
            <Col md="3" className="text-start">
              {`${translate('environment')} ${translate('databaseConnectionDetails')}`}
            </Col>
            <Col>
              <span className="text-muted me-4">Redis: {instance ? instance.status : translate('unavailable')}</span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey={environment}
                inactiveText="View"
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey={environment}>
        <Card bg="dark">
          <Card.Body>
            {
              !instance ? (
                <Alert variant="danger">
                  {translate('redisNotProvisionedForEnvironment')}
                </Alert>
              ) : (
                <>
                  {
                    [
                      'username',
                      'password',
                      'primaryEndpoint',
                      'primaryPort',
                      'readerEndpoint',
                      'readerPort',
                    ]
                      .map((field: string) => {
                        return (
                          <CopyableLineItem
                            key={field}
                            field={translate(camelCase(field))}
                            value={instance[field as keyof IRedisInstanceMetadata]?.toString() || `${field} ${translate('notAvailableYet')}`}
                            buttonVariant="link"
                            buttonSize="sm"
                            secureField={field === 'password'}
                            isReady={!!instance[field as keyof IRedisInstanceMetadata]}
                          />
                        );
                      })
                  }
                  {
                    isDeveloperAuthorized && (
                      <>
                        <Row className="mt-4">
                          <Col>
                            <Button size="sm" onClick={() => setShowConfirmationModal(true)}>
                              {translate('updatePassword')}
                            </Button>
                          </Col>
                        </Row>
                        <ConfirmationModal
                          show={showConfirmationModal}
                          onConfirm={async () => updateRedisPassowrdMutation({ redisMsId: microservice.application_microserviceable_id, environment })}
                          handleClose={() => setShowConfirmationModal(false)}
                          disabled={disabled}
                          title={translate('updateRedisPassword')}
                          confirmationText={translate('confirmUpdatePassword')}
                          successMessage={translate('passwordChangeQueuedSuccessfully')}
                        />
                      </>
                    )
                  }
                </>
              )
            }
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </div>
  );
};

export default RedisConnectionDetails;

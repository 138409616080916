import { Col, Container, Row } from 'react-bootstrap';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import OrganizationGroupForm from 'common/organizations/OrganizationGroupForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateOrganizationGroupMutation } from 'api/organizationSlice';
import IGroup, { IGroupApiErrors, IGroupValidationErrors } from 'types/group/IGroup';
import { useState } from 'react';

const CreateOrganizationGroup: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const { orgId: organizationId } = useParams();
  const [apiErrors, setApiErrors] = useState<IGroupValidationErrors | null>(null);
  const [createAnother, setCreateAnother] = useState<boolean>(false);

  if (!organizationId) return <Error message={translate('organizationIdRouteError')} />;

  const [createOrganizationGroup, { isLoading }] = useCreateOrganizationGroupMutation();

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('createGroup')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <OrganizationGroupForm
            onCancel={() => navigate(`/organizations/${organizationId}#groups`)}
            onSubmit={async (data) => {
              const result: { data: IGroup } | { error: IGroupApiErrors } = await createOrganizationGroup({ organizationId, data });

              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else {
                if (!createAnother) {
                  navigate(`/organizations/${organizationId}#groups`);
                }
              }
            }}
            apiErrors={apiErrors}
            disabled={isLoading}
            organizationId={organizationId}
            createAnother={createAnother}
            setCreateAnother={setCreateAnother}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateOrganizationGroup;

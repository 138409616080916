import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';

const ApexHelmChartVersionExplainTooltip: React.FC = () => {
  return (
    <OverlayTrigger
    trigger={['hover', 'focus']}
    placement="right"
    overlay={(
      <Popover>
        <Popover.Body>
          {translate('microserviceChartVersionExplanation')}
        </Popover.Body>
      </Popover>
    )}
  >
    <Badge pill bg="secondary" className="me-2">
      <FontAwesomeIcon icon="info" />
    </Badge>
  </OverlayTrigger>
  );
};

export default ApexHelmChartVersionExplainTooltip;

import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormFeedback from 'common/FormFeedback';

const HasuraForm = ({ initialValues, onCancel, onSubmit, disabled, apiErrors, submitText }) => {
  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
    database_connection_string: yup
      .string()
      .trim()
      // Matches format: postgres://user:password@host:port/database (where port is 4 digits)
      .matches(/^postgres:\/\/\S*?:\S*?@\S*?:\d{4}\/\S*?$/, translate('invalidFormat'))
      .required(translate('hasuraDatabaseConnectionStringRequired')),
    admin_secret: yup.string().required(translate('hasuraAdminSecretRequired')),
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder={translate('name')}
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder={translate('description')}
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDatabaseConnectionString">
              <Form.Label>
                <span className="me-1">{translate('databaseConnectionString')}</span>
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      {translate('hasuraDatabaseConnectionStringExplanation')}
                    </Tooltip>
                  )}
                >
                  <Badge pill bg="secondary">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                required
                name="database_connection_string"
                type="text"
                placeholder={translate('databaseConnectionStringPlaceholder')}
                value={values.database_connection_string}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.database_connection_string && !errors.database_connection_string}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="database_connection_string"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAdminSecret">
              <Form.Label>
                <span className="me-1">{translate('adminSecret')}</span>
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      {translate('hasuraAdminSecretExplanation')}
                    </Tooltip>
                  )}
                >
                  <Badge pill bg="secondary">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                required
                name="admin_secret"
                type="text"
                value={values.admin_secret}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.admin_secret && !errors.admin_secret}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="admin_secret"
              />
            </Form.Group>
            {onCancel && (
              <Button
                variant="secondary"
                type="button"
                disabled={disabled}
                onClick={onCancel}
                className="me-2"
              >
                {translate('cancel')}
              </Button>
            )}
            {onSubmit && (
              <Button
                variant="primary"
                type="submit"
                disabled={disabled}
              >
                {submitText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

HasuraForm.defaultProps = {
  initialValues: {
    name: '',
    description: '',
    database_connection_string: '',
    admin_secret: '',
  },
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  disabled: false,
  submitText: translate('save'),
};

HasuraForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    database_connection_string: PropTypes.string,
    admin_secret: PropTypes.string,
  }),
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
    database_connection_string: PropTypes.arrayOf(PropTypes.string),
    admin_secret: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
};

export default HasuraForm;

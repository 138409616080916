import React from 'react';

import { translate } from '@apex/react-toolkit/lib';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import OrganizationFormWithPhoto from 'common/organizations/OrganizationFormWithPhoto';
import { useAddOrganizationMutation } from 'api/organizationSlice';
import IOrganization, { IOrganizationApiErrors } from 'types/organization/IOrganization';

const New = () => {
  const [addOrganization, { isLoading, error }] = useAddOrganizationMutation();
  const navigate = useNavigate();

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate('createOrganization')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col>
          <OrganizationFormWithPhoto
            onSubmit={async values => {
              const result: { data: IOrganization} | { error: IOrganizationApiErrors } = await addOrganization(values);

              if (!error && 'data' in result) {
                navigate(`/organizations/${result.data.id}`);
              }
            }}
            disabled={isLoading}
            apiErrors={error?.errors}
            onCancel={() => navigate('/organizations')}
          />
        </Col>
      </Row>
    </Container >
  );
}

export default New;

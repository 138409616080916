import { FC } from 'react';
import PaginationControl from 'common/PaginationControl';
import ApexHelmChartVersionTable from 'common/infrastructure/apexHelmChartVersions/ApexHelmChartVersionTable';
import { ApexHelmChartVersionsSortBy } from 'hooks/useSearchApexHelmChartVersions';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';
import { InteractiveTableColumn } from 'common/InteractiveTable';

const ApexHelmChartVersionPaginatedTable: FC<{
  apexHelmChartVersions: IApexHelmChartVersion[]
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  sortBy: ApexHelmChartVersionsSortBy
  setSortBy: (value: ApexHelmChartVersionsSortBy) => void
  sortDirection: 'asc' | 'desc'
  onChartNameClicked?: (apexHelmChartVersion: IApexHelmChartVersion) => void;
  actionColumn?: InteractiveTableColumn<IApexHelmChartVersion>;
}> = ({ apexHelmChartVersions, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onChartNameClicked, actionColumn }) => {
  return (
    <>
      <ApexHelmChartVersionTable
        apexHelmChartVersions={apexHelmChartVersions}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        onChartNameClicked={onChartNameClicked}
        actionColumn={actionColumn}
      />
      <PaginationControl
        pagesToShow={3}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default ApexHelmChartVersionPaginatedTable;

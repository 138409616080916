import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';

import FormFeedback from 'common/FormFeedback';
import INameDecriptionFields, { INameDecriptionApiErrors } from 'types/INameDecriptionFields';

const FilesystemForm: React.FC<{
  initialValues: INameDecriptionFields
  onSubmit?: (values: INameDecriptionFields) => void
  onCancel?: () => void
  disabled: boolean
  apiErrors?: INameDecriptionApiErrors | null
  submitText?: string
}> = ({ initialValues, onCancel, onSubmit, disabled, apiErrors, submitText = translate('save') }) => {
  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          if (onSubmit) onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder={translate('name')}
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder={translate('description')}
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>
            {onCancel && (
              <Button
                variant="secondary"
                type="button"
                disabled={disabled}
                onClick={onCancel}
                className="me-2"
              >
                {translate('cancel')}
              </Button>
            )}
            {onSubmit && (
              <Button
                variant="primary"
                type="submit"
                disabled={disabled}
              >
                {submitText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FilesystemForm;

import { FC } from 'react';
import PaginationControl from 'common/PaginationControl';
import RoleConfigTable from 'common/infrastructure/roleConfigs/RoleConfigTable';
import { RoleConfigSortBy } from 'hooks/useSearchKubernetesRoleConfigs';
import IRoleConfig from 'types/infrastructure/IRoleConfig';

const RoleConfigPaginatedTable: FC<{
  roleConfigs: IRoleConfig[]
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  sortBy: RoleConfigSortBy
  setSortBy: (value: RoleConfigSortBy) => void
  sortDirection: 'asc' | 'desc'
}> = ({ roleConfigs, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection }) => {
  return (
    <>
      <RoleConfigTable
        roleConfigs={roleConfigs}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
      />
      <PaginationControl
        pagesToShow={3}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default RoleConfigPaginatedTable;

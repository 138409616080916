import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteRoleConfigMutation } from 'api/roleConfigsSlice';
import IRoleConfig from 'types/infrastructure/IRoleConfig';

const DeleteKubernetesRoleConfigModal: FC<{
  show: boolean
  handleClose: () => void
  roleConfig: IRoleConfig
}> = ({ show, handleClose, roleConfig }) => {
  const navigate = useNavigate();
  const [deleteRoleConfig, { isLoading }] = useDeleteRoleConfigMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteRoleConfig')}</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          {translate('confirmDeleteRoleConfig', { name: roleConfig.name })}
        </p>
        <p>
          {translate('rulesWillNotBeDeleted')}
        </p>
        <p>
          <strong>{translate('thisOperationCannotBeUndone')}</strong>
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" className="me-1" />
          {translate('cancel')}
        </Button>
        <Button
          className="d-inline text-nowrap"
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await deleteRoleConfig(roleConfig);
            navigate('/infrastructure#kubernetes-roles');
          }}
        >
          {
            isLoading ? (
              <FontAwesomeIcon icon="spinner" className="me-1" spin />
            ) : (
              <FontAwesomeIcon icon="trash-alt" className="me-1" />
            )
          }
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteKubernetesRoleConfigModal;

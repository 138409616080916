import React from 'react';
import { kebabCase, uniq } from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import { translate } from '@apex/react-toolkit/lib';
import * as freeSolidIconsModule from '@fortawesome/free-solid-svg-icons';
import * as proSolidIconsModule from '@fortawesome/pro-solid-svg-icons';
import { Option } from 'react-bootstrap-typeahead/types/types';

const FontAwesomeIconSelector: React.FC<{
  selectedIcon?: string;
  onIconSelected: (selected: string | undefined) => void;
  disabled?: boolean;
  isInvalid?: boolean;
}> = ({
  selectedIcon,
  onIconSelected,
  disabled = false,
  isInvalid = false,
}) => {
    const icons = uniq([
      ...Object.keys(freeSolidIconsModule).map(iconKey => kebabCase(iconKey).replace(/.+?-/, '').replace(/dice-d-/, 'dice-d')),
      ...Object.keys(proSolidIconsModule).map(iconKey => kebabCase(iconKey).replace(/.+?-/, '').replace(/dice-d-/, 'dice-d')),
    ]);

    return (
      <Typeahead
        id="icon-selector"
        disabled={disabled}
        isInvalid={isInvalid}
        labelKey="icon"
        options={icons}
        placeholder={translate('icon')}
        selected={selectedIcon ? [selectedIcon as Option] : []}
        onChange={(selected) => {
          if (selected.length > 0) {
            onIconSelected(selected.length ? selected[0] as string : undefined);
          } else {
            onIconSelected(undefined);
          }
        }}
      />
    );
  };

export default FontAwesomeIconSelector;



import React, { useState } from 'react';

import { translate } from '@apex/react-toolkit/lib';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import IFilesystemMicroservice, { FilesystemMountErrors } from 'types/application/microservice/IFilesystemMicroservice';
import FilesystemMountForm from '../FilesystemMountForm';
import { useMountFilesystemMutation } from 'api/filesystemMicroserviceSlice';
import { ComputeBoundMicroservice, ComputeBoundMicroserviceTypeStrings, IApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import AccordionToggleLink from 'common/AccordionToggleLink';

const MountedFilesystemCard: React.FC<{
  filesystem: IFilesystemMicroservice;
  isDeveloperAuthorized: boolean;
  microservice: IApplicationMicroservice<ComputeBoundMicroserviceTypeStrings, ComputeBoundMicroservice>;
  queueFilesystemForRemval?: (filesystem: IFilesystemMicroservice) => void;
}> = ({
  filesystem,
  isDeveloperAuthorized,
  microservice,
  queueFilesystemForRemval,
}) => {
    const [mountErrors, setMountErrors] = useState<FilesystemMountErrors | undefined>(undefined);
    const [mountFilesystem, { isLoading: mountIsLoading }] = useMountFilesystemMutation();

    const disabled = !isDeveloperAuthorized || mountIsLoading;

    return (
      <Accordion alwaysOpen>
        <Card bg="dark">
          <Card.Header>
            <Row>
              <Col md={6}>
                <Card.Title>
                  {filesystem.application_microservice && filesystem.application_microservice.name}
                </Card.Title>
              </Col>
              <Col>
                <span className="text-muted">
                  {filesystem.mount_path}
                </span>
              </Col>
              <Col className="text-end">
                <AccordionToggleLink
                  eventKey="0"
                  inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                  activeText={translate('close')}
                />
              </Col>
            </Row>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row>
                <Col>
                  <FilesystemMountForm
                    disabled={disabled}
                    filesystem={filesystem}
                    submitBtnText={translate('update')}
                    cancelText={translate('unmount')}
                    onCancel={queueFilesystemForRemval ? () => queueFilesystemForRemval(filesystem) : undefined}
                    apiErrors={mountErrors}
                    onSubmit={async (formInput) => {
                      const result: { data: IFilesystemMicroservice } | { error: FilesystemMountErrors }
                        = await mountFilesystem({
                          filesystemId: filesystem.id,
                          applicationMicroserviceId: microservice.id,
                          data: formInput,
                        });

                      if ('error' in result) {
                        setMountErrors(result.error);
                      }
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

export default MountedFilesystemCard;

import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Tile } from "@apex/react-toolkit/components";
import { translate } from "@apex/react-toolkit/lib";
import useCheckUserPermission from "hooks/useCheckUserPermission";

const Home = () => {
  const { isSuperUser } = useCheckUserPermission();

  return (
    <Container>
      <Row className="mt-4">
        <Col md={3}>
          <Tile
            as={Link}
            to="/users"
            icon="user"
            title="Users"
            variant="dark"
          />
        </Col>
        <Col md={3}>
          <Tile
            as={Link}
            to="/groups"
            icon="user-group"
            title="Groups"
            variant="dark"
          />
        </Col>
        <Col md={3}>
          <Tile
            as={Link}
            to="/applications"
            icon="browser"
            title="Applications"
            variant="dark"
          />
        </Col>
        <Col md={3}>
          <Tile
            as={Link}
            to="/organizations"
            icon="sitemap"
            title={translate("organizations")}
            variant="dark"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        {isSuperUser && (
          <Col md={3}>
            <Tile
              as={Link}
              to="/infrastructure"
              icon="cloud-binary"
              title={translate("apexInfrastructure")}
              variant="dark"
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Home;

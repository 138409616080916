import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import PaginationControl from 'common/PaginationControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupMembersTable from 'App/AppRoutes/Groups/Detail/DetailBody/GroupMembersTable';
import useSearchGroupMembers from 'hooks/useSearchGroupMembers';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import UserSearchFields from 'common/user/UserSearchFields';
import AddGroupMemberModal from 'App/AppRoutes/Groups/Detail/DetailBody/AddGroupMemberModal';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';
import { useGetGroupMembersQuery, useUpdateGroupMemberRoleMutation } from 'api/groupSlice';
import RemoveGroupMemberModal from './RemoveGroupMemberModal';

const DetailBody = ({ group }) => {
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [memberQueuedForRemoval, setMemberQueuedForRemoval] = useState(null);
  const [canAddMembers, setCanAddMembers] = useState(false);
  const [updateGroupMemberRole] = useUpdateGroupMemberRoleMutation();
  const { groupId } = useParams();
  const { data: allMembers, error: allMembersError, isFetching: fetchingAllMembers } = useGetGroupMembersQuery({ groupId });

  useEffect(() => {
    setCanAddMembers(allMembers && !allMembersError && !fetchingAllMembers);
  }, [allMembers, allMembersError, fetchingAllMembers]);

  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
    page,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchGroupMembers({ groupId });

  const {
    data: paginatedData,
    error,
    isLoading,
    isFetching,
  } = queryResult;

  if (error || allMembersError) return (<Error coverPage />);
  if (isLoading || !paginatedData) return (<Spinner size="10x" coverViewport />);

  const { meta, data: groupMembers } = paginatedData;

  return (
    <>
      {
        canAddMembers && (
          <AddGroupMemberModal
            show={showAddMembersModal}
            groupId={groupId}
            handleClose={() => setShowAddMembersModal(false)}
            currentMembers={allMembers.data}
          />
        )
      }
      {
        memberQueuedForRemoval && (
          <RemoveGroupMemberModal
            show={memberQueuedForRemoval !== null}
            handleClose={() => setMemberQueuedForRemoval(null)}
            member={memberQueuedForRemoval}
            group={group}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            {translate('members')} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          <Button
            onClick={() => setShowAddMembersModal(true)}
            disabled={!canAddMembers}
          >
            <FontAwesomeIcon icon="plus" /> {translate('addMembers')}
          </Button>
        </Col>
      </Row>
      <UserSearchFields
        disabled={isFetching}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        take={take}
        setTake={setTake}
      />
      <Row className="mt-2">
        <Col>
          <GroupMembersTable
            groupId={groupId}
            groupMembers={groupMembers}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            setSelectedMember={setMemberQueuedForRemoval}
            disabled={isFetching}
            onSelectMemberRole={(userId, role) => {
              updateGroupMemberRole({ groupId, userId, data: { role } });
            }}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

DetailBody.propTypes = {
  group: GroupPropTypes.isRequired,
};

export default DetailBody;

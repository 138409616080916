import { apiSlice } from 'api/apiSlice';
import IGroup from 'types/group/IGroup';
import IQueryParameters from 'types/IQueryParameters';
import PaginatedResult from 'types/PaginatedResult';
import ITeam, { ITeamFormValues } from 'types/team/ITeam';
import IUser from 'types/IUser';
import { PagniateSortSearchQueryParameters } from 'types/ListSearchTypes';
import { UseSearchUsersSearchParameters } from 'hooks/useSearchUsers';
import { SortBy } from 'hooks/useSearchTeamMembers';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addTeam: builder.mutation<ITeam, ITeamFormValues>({
      query: (data: ITeamFormValues) => ({
        url: 'teams', method: 'post', data,
      }),
      invalidatesTags: () => [{ type: 'Teams' }],
    }),

    getTeam: builder.query<ITeam, string>({
      query: (id: string) => ({ url: `teams/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'Teams', id }],
    }),

    editTeam: builder.mutation<ITeam, { team: ITeam, data: ITeamFormValues }>({
      query: ({ team, data}) => ({ url: `teams/${team.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { team }) => [{ type: 'Teams', id: team.id }],
    }),

    deleteTeam: builder.mutation<null, ITeam>({
      query: (data: ITeam) => ({ url: `teams/${data.id}`, method: 'delete' }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Teams', id }],
    }),

    listTeams: builder.query<PaginatedResult<ITeam>, IQueryParameters>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'teams', method: 'get', queryParameters };
      },
      providesTags: ['Teams'],
    }),
    getTeamGroups: builder.query<PaginatedResult<IGroup>, { queryParameters?: IQueryParameters, teamId: string }>({
      query: ({ queryParameters, teamId }) => {
        return { url: `teams/${teamId}/groups`, method: 'get', queryParameters };
      },
    }),
    listMembers: builder.query<PaginatedResult<IUser>, { queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchUsersSearchParameters>, teamId: string }>({
      query: ({ queryParameters, teamId }: { queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchUsersSearchParameters>, teamId: string }) => {
        return { url: `teams/${teamId}/members`, method: 'get', queryParameters };
      },
      providesTags: ['Teams'],
    }),
  }),
});

export const {
  useAddTeamMutation,
  useGetTeamQuery,
  useLazyListTeamsQuery,
  useGetTeamGroupsQuery,
  useLazyGetTeamGroupsQuery,
  useLazyListMembersQuery,
  useEditTeamMutation,
  useDeleteTeamMutation,
} = extendedApiSlice;

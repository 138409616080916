import { apiSlice } from 'api/apiSlice';
import { ApexHelmChartVersionFormValues } from 'common/infrastructure/apexHelmChartVersions/ApexHelmChartVersionForm';
import IQueryParameters from 'types/IQueryParameters';
import PaginatedResult from 'types/PaginatedResult';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createApexHelmChartVersion: builder.mutation<IApexHelmChartVersion, ApexHelmChartVersionFormValues>({
      query: (data: IApexHelmChartVersion) => ({ url: 'apex-helm-chart-versions', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApexHelmChartVersions' }],
    }),

    listApexHelmChartVersions: builder.query<PaginatedResult<IApexHelmChartVersion>, IQueryParameters | undefined>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'apex-helm-chart-versions', method: 'get', queryParameters };
      },
      providesTags: ['ApexHelmChartVersions'],
    }),

    editApexHelmChartVersion: builder.mutation<IApexHelmChartVersion, ApexHelmChartVersionFormValues>({
      query: data => ({ url: `apex-helm-chart-versions/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApexHelmChartVersions' }],
    }),

    deleteApexHelmChartVersion: builder.mutation<null, IApexHelmChartVersion>({
      query: (data: IApexHelmChartVersion) => ({ url: `apex-helm-chart-versions/${data.id}`, method: 'delete', data }),
      invalidatesTags: () => [{ type: 'ApexHelmChartVersions' }],
    }),

    getApexHelmChartNames: builder.query<string[], undefined>({
      query: () => {
        return { url: 'apex-helm-chart-names', method: 'get' };
      },
    }),
  }),
});

export const {
  useListApexHelmChartVersionsQuery,
  useLazyListApexHelmChartVersionsQuery,
  useCreateApexHelmChartVersionMutation,
  useEditApexHelmChartVersionMutation,
  useDeleteApexHelmChartVersionMutation,
  useGetApexHelmChartNamesQuery,
} = extendedApiSlice;

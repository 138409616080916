import { apiSlice } from 'api/apiSlice';
import IGroup, { IGroupMember, INewGroupValues } from 'types/group/IGroup';
import IQueryParameters from 'types/IQueryParameters';
import { GroupMemberRole } from 'types/group/IGroup';
import PaginatedResult from 'types/PaginatedResult';
import IApplicationRole from 'types/application/IApplicationRole';
import IApplication from 'types/application/IApplication';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addGroup: builder.mutation<IGroup, INewGroupValues>({
      query: (data: INewGroupValues) => ({ url: 'groups', method: 'post', data }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),

    getGroup: builder.query<IGroup, string>({
      query: (id: string) => ({ url: `groups/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'Groups', id }],
    }),

    listGroups: builder.query<PaginatedResult<IGroup>, IQueryParameters>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'groups', method: 'get', queryParameters };
      },
      providesTags: ['Groups'],
    }),

    editGroup: builder.mutation<IGroup, IGroup>({
      query: data => ({ url: `groups/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Groups', id }],
    }),

    deleteGroup: builder.mutation<null, IGroup>({
      query: (data: IGroup) => ({ url: `groups/${data.id}`, method: 'delete', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Groups', id }],
    }),

    getGroupMembers: builder.query<PaginatedResult<IGroupMember>, { groupId: string; queryParameters: IQueryParameters }>({
      query: ({ queryParameters, groupId }) => {
        return { url: `groups/${groupId}/members`, method: 'get', queryParameters };
      },
    }),

    addGroupMembers: builder.mutation<IGroupMember[], { groupId: string; data: string[] }>({
      query: ({ groupId, data }) => ({ url: `groups/${groupId}/members`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),

    removeGroupMember: builder.mutation<IGroupMember[], { groupId: string; userId: string }>({
      query: ({ groupId, userId }) => ({ url: `groups/${groupId}/members/${userId}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),

    updateGroupMemberRole: builder.mutation<IGroupMember[], { groupId: string; userId: string; data: { role: GroupMemberRole } }>({
      query: ({ groupId, userId, data }) => ({ url: `groups/${groupId}/members/${userId}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'Groups' }],
    }),

    getGroupRoles: builder.query<PaginatedResult<IApplicationRole>, { groupId: string; queryParameters: IQueryParameters }>({
      query: ({ queryParameters, groupId }) => {
        return { url: `groups/${groupId}/roles`, method: 'get', queryParameters };
      },
    }),

    getGroupApplications: builder.query<IApplication[], string>({
      query: (groupId: string) => {
        return { url: `groups/${groupId}/applications`, method: 'get' };
      },
    }),
  }),
});

export const {
  useAddGroupMutation,
  useGetGroupQuery,
  useLazyListGroupsQuery,
  useLazyGetGroupMembersQuery,
  useDeleteGroupMutation,
  useEditGroupMutation,
  useGetGroupMembersQuery,
  useAddGroupMembersMutation,
  useRemoveGroupMemberMutation,
  useUpdateGroupMemberRoleMutation,
  useLazyGetGroupRolesQuery,
  useGetGroupApplicationsQuery,
} = extendedApiSlice;

import { Col, Container, Row } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import NodeGroupForm, { NodeGroupFormValues } from 'common/infrastructure/nodeGroups/NodeGroupForm';
import INodeGroup, { INodeGroupApiErrors } from 'types/infrastructure/INodeGroup';
import { useNavigate } from 'react-router-dom';
import { useAddNodeGroupMutation } from 'api/nodeGroupSlice';
import { useState } from 'react';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';

const New = () => {
  const navigate = useNavigate();
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();
  const [addNodeGroup, { isLoading }] = useAddNodeGroupMutation();
  const [apiErrors, setApiErrors] = useState<null | Pick<INodeGroupApiErrors, 'errors'>>(null);

  if (!isSuperUser) return <Unauthorized />;
  if (permissionLoading) return (<Spinner size="10x" coverViewport />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('createNodeGroup')}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <NodeGroupForm
            disabled={isLoading}
            onCancel={() => navigate('..')}
            onSubmit={async (formData: NodeGroupFormValues) => {
              const result: { data: INodeGroup } | { error: INodeGroupApiErrors } = await addNodeGroup(formData);

              if ('error' in result) {
                // @ts-expect-error I do this in other places, not sure why it's wrong here
                setApiErrors(result.error.errors);
              } else {
                navigate(`../node-groups/${result.data.id}`);
              }
            }}
            apiErrors={apiErrors}
            submitText={translate('create')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default New;

import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ icon, color, className, tooltip }) => {
  const button = (
    <Button variant="link" className="p-0">
      <FontAwesomeIcon icon={icon} color={color} className={className} />
    </Button>
  );

  if (!tooltip) return button;

  return (
    <OverlayTrigger
      placement="auto"
      overlay={(
        <Tooltip className="pt-0">
          {tooltip}
        </Tooltip>
      )}
    >
      {button}
    </OverlayTrigger>
  );
};

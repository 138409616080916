import React, { useEffect, useRef, useState } from 'react';

import { ITeamApiErrors } from "types/team/ITeam";
import { translate } from "@apex/react-toolkit/lib";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, Row } from "react-bootstrap";
import { Error, Spinner } from "@apex/react-toolkit/components";
import FormFeedback from "common/FormFeedback";
import { useListSeriesQuery } from "api/seriesSlice";
import { Option } from 'react-bootstrap-typeahead/types/types';
import IUser from 'types/IUser';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import MenuItem from 'common/typeahead/MenuItem';
import useSearchOrganizationMembers from 'hooks/useSearchOrganizationMembers';

export interface InitialValues {
  name: string;
  series_id: string;
  car_number: string;
  users?: string[];
}

const defaultInitialValues: InitialValues = {
  name: "",
  series_id: "",
  car_number: "",
};

const TeamForm: React.FC<{
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => void;
  disabled?: boolean;
  onCancel: () => void;
  organizationId: string;
  apiErrors: ITeamApiErrors;
  submitText?: string;
  currentUsers?: IUser[]
}> = ({
  initialValues = defaultInitialValues,
  disabled = false,
  onCancel,
  onSubmit,
  apiErrors,
  submitText = translate("create"),
  currentUsers,
  organizationId,
}) => {
    const { data, isLoading, error } = useListSeriesQuery();
    const schema = yup.object({
      name: yup.string().required(translate("nameRequired")),
      car_number: yup
        .string()
        .matches(/^\d{1,3}$/, { message: translate('carNumber3DigitsOrLess') })
        .required(translate("carNumberRequired")),
      series_id: yup.string().required(translate("seriesIdRequired")),
    });

    const [selectedUsers, setSelectedUsers] = useState<Option[] | never[]>(currentUsers || []);
    const {
      setSearchParameters: setSearchMembersParameters,
      result: membersResult,
    } = useSearchOrganizationMembers({ organizationId });

    const usersTypeahead = useRef(null);
    useEffect(() => {
      if (currentUsers) {
        setSelectedUsers(currentUsers);
      }
    }, [JSON.stringify(currentUsers)]);

    if (isLoading) return <Spinner />;
    if (error || !data) return <Error coverPage />;

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit({
            ...formInput,
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            users: selectedUsers.map((user: IUser) => user.id),
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>{translate("name")}</Form.Label>
                <Form.Control
                  required
                  name="name"
                  type="text"
                  placeholder={translate("name")}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.name && !errors.name}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCarNumber">
                <Form.Label>{translate("carNumber")}</Form.Label>
                <Form.Control
                  required
                  name="car_number"
                  type="text"
                  placeholder={translate("carNumber")}
                  value={values.car_number}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.car_number && !errors.car_number}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="car_number"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSeries">
                <Form.Label>{translate("series")}</Form.Label>
                <Form.Select
                  required
                  name="series_id"
                  value={values.series_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                >
                  <option value="" disabled>
                    {translate("series")}
                  </option>
                  {data.data.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="series_id"
                />
              </Form.Group>
              <Form.Group controlId="formUsers" className="mb-3">
                <Form.Label>{translate('users')}</Form.Label>
                <AsyncTypeahead
                  multiple
                  disabled={disabled}
                  ref={usersTypeahead}
                  paginate={false}
                  placeholder={translate('searchUsers')}
                  id="async-users-typeahead"
                  delay={300}
                  isLoading={membersResult.isLoading || membersResult.isFetching}
                  onSearch={(query: string) => setSearchMembersParameters({ any: query })}
                  options={membersResult.data?.data || []}
                  selected={selectedUsers}
                  onChange={setSelectedUsers}
                  renderMenuItemChildren={(option, { text }) => (
                    // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                    <MenuItem label={`${option.first_name} ${option.last_name}`} smallText={option.email} searchText={text} />
                  )}
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  labelKey={option => `${option.first_name} ${option.last_name}`}
                  filterBy={(option) => {
                    // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                    return !selectedUsers.find((user: IUser) => user.id === option.id)
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  className="me-2"
                  onClick={onCancel}
                >
                  {translate("cancel")}
                </Button>
                <Button type="submit" variant="primary" disabled={disabled}>
                  {submitText}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default TeamForm;

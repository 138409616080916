import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import styles from './ApplicationRoleListItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IApplicationRole from 'types/application/IApplicationRole';
import { useNavigate } from 'react-router-dom';

const ApplicationRoleListItem: React.FC<{
  appRole: IApplicationRole,
  setSelectedRole: (role: IApplicationRole) => void,
  isSelected: boolean,
  queueRoleForRemoval: (role: IApplicationRole) => void,
}> = ({ appRole, setSelectedRole, isSelected, queueRoleForRemoval }) => {
  const navigate = useNavigate();

  return (
    <tr className={`align-middle ${isSelected ? styles.selectedSecondary : ''}`} onClick={() => setSelectedRole(appRole)}>
      <td>
        {appRole.name}
      </td>
      <td className="pe-0 me-0 text-end">
        <OverlayTrigger
          trigger="click"
          placement="right"
          rootClose
          overlay={(
            <Popover>
              <Popover.Body>
                <Button
                  onClick={() => navigate(`applications/${appRole.application_id}/roles/${appRole.id}/edit`)}
                  variant="secondary"
                  size="sm"
                  className="text-nowrap me-2"
                >
                  <FontAwesomeIcon
                    icon="pencil"
                  />
                  <span className="ms-2">{translate('editRole')}</span>
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  className="text-nowrap float-end"
                  onClick={() => queueRoleForRemoval(appRole)}
                >
                  <FontAwesomeIcon
                    icon="trash-alt"
                  />
                  <span className="ms-2">{translate('deleteRole')}</span>
                </Button>
              </Popover.Body>
            </Popover>
          )}
        >
          <Button
            variant="link"
            className="text-white"
          >
            <FontAwesomeIcon icon="ellipsis-v" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default ApplicationRoleListItem;

import { apiSlice } from 'api/apiSlice';
import { IOrganizationGroupFormFields } from 'types/group/IGroup';
import IQueryParameters from 'types/IQueryParameters';
import IUser from 'types/IUser';
import PaginatedResult from 'types/PaginatedResult';
import IApplication from 'types/application/IApplication';
import IApplicationRole, { IApplicationRoleFormFields } from 'types/application/IApplicationRole';
import IGroup from 'types/group/IGroup';
import IOrganization, { IOrganizationMember, OrganizationRole } from 'types/organization/IOrganization';
import ITeam from 'types/team/ITeam';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addOrganization: builder.mutation<IOrganization, FormData>({
      query: (data: FormData) => ({
        url: 'organizations', method: 'post', formData: data, headers: {},
      }),
      invalidatesTags: () => [{ type: 'Organizations' }],
    }),

    getOrgnanization: builder.query<IOrganization, string>({
      query: (id: string) => ({ url: `organizations/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'Organizations', id }],
    }),

    listOrganizations: builder.query<PaginatedResult<IOrganization>, IQueryParameters>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'organizations', method: 'get', queryParameters };
      },
      providesTags: ['Organizations'],
    }),

    getOrganizationMembers: builder.query<PaginatedResult<IOrganizationMember>, { queryParameters: IQueryParameters, organizationId: string }>({
      query: ({ queryParameters, organizationId }: { queryParameters: IQueryParameters, organizationId: string }) => {
        return { url: `organizations/${organizationId}/members`, method: 'get', queryParameters };
      },
      providesTags: ['Users'],
    }),

    listOrganizationTeams: builder.query<PaginatedResult<ITeam>, { queryParameters: IQueryParameters, organizationId: string }>({
      query: ({ queryParameters, organizationId }: { queryParameters: IQueryParameters, organizationId: string }) => {
        return { url: `organizations/${organizationId}/teams`, method: 'get', queryParameters };
      },
      providesTags: ['Teams'],
    }),

    addOrganizationMembers: builder.mutation<IOrganization, { organizationId: string, data: string[] }>({
      query: ({ organizationId, data }) =>
        ({ url: `organizations/${organizationId}/members`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'Users' }],
    }),

    removeOrganizationMember: builder.mutation<null, { organizationId: string, userId: string }>({
      query: ({ organizationId, userId }) =>
        ({ url: `organizations/${organizationId}/members/${userId}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'Users' }],
    }),

    updateOrganizationMemberRole: builder.mutation<IUser, { organizationId: string, userId: string, data: { role: OrganizationRole } }>({
      query: ({ organizationId, userId, data }) =>
        ({ url: `organizations/${organizationId}/members/${userId}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'Users' }],
    }),

    listOrganizationApplications: builder.query<IApplication[], string>({
      query: (organizationId: string) => {
        return { url: `organizations/${organizationId}/applications`, method: 'get' };
      },
      providesTags: ['OrganizationApplications'],
    }),

    getOrganizationGroups: builder.query<PaginatedResult<IGroup>, { queryParameters: IQueryParameters, organizationId: string }>({
      query: ({ queryParameters, organizationId }: { queryParameters: IQueryParameters, organizationId: string }) => {
        return { url: `organizations/${organizationId}/groups`, method: 'get', queryParameters };
      },
      providesTags: ['OrganizationGroups', 'Groups'],
    }),

    getOrganizationGroup: builder.query<IGroup, { groupId: string, organizationId: string }>({
      query: ({ groupId, organizationId }: { groupId: string, organizationId: string }) => ({ url: `organizations/${organizationId}/groups/${groupId}`, method: 'get' }),
      providesTags: (result, error, groupId) => [{ type: 'Groups', groupId }],
    }),

    editOrganizationGroup: builder.mutation<IGroup, { data: IOrganizationGroupFormFields, group: IGroup }>({
      query: ({ data, group }) => ({ url: `organizations/${group.organization_id}/groups/${group.id}`, method: 'put', data }),
      invalidatesTags: () => ['OrganizationGroups', 'OrganizationApplications', 'Groups'],
    }),

    createOrganizationApplicationRole: builder.mutation<IApplicationRole, { organizationId: string, applicationId: string, data: IApplicationRoleFormFields }>({
      query: ({ organizationId, applicationId, data }) =>
        ({ url: `organizations/${organizationId}/applications/${applicationId}/roles`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'OrganizationApplications' }],
    }),

    editOrganizationApplicationRole: builder.mutation<IApplicationRole, { data: IApplicationRoleFormFields, role: IApplicationRole }>({
      query: ({ data, role }) =>
        ({ url: `organizations/${role.organization_id}/application-roles/${role.id}`, method: 'put', data }),
      invalidatesTags: () => ['OrganizationApplications', 'ApplicationRoles'],
    }),

    deleteOrganizationApplicationRole: builder.mutation<null, { organizationId: string, applicationRole: IApplicationRole }>({
      query: ({ organizationId, applicationRole }) =>
        ({ url: `organizations/${organizationId}/application-roles/${applicationRole.id}`, method: 'delete' }),
      invalidatesTags: () => ['OrganizationApplications', 'ApplicationRoles'],
    }),

    getOrganizationApplicationRole: builder.query<IApplicationRole, { organizationId: string, roleId: string }>({
      query: ({ organizationId, roleId }: { organizationId: string, roleId: string }) => {
        return { url: `organizations/${organizationId}/application-roles/${roleId}`, method: 'get' };
      },
      providesTags: ['OrganizationApplications', 'ApplicationRoles'],
    }),

    createOrganizationGroup: builder.mutation<IGroup, { organizationId: string, data: IOrganizationGroupFormFields }>({
      query: ({ organizationId, data }) =>
        ({ url: `organizations/${organizationId}/groups`, method: 'post', data }),
      invalidatesTags: () => ['OrganizationGroups', 'OrganizationApplications'],
    }),

    deleteOrganizationGroup: builder.mutation<null, { organizationId: string, group: IGroup }>({
      query: ({ organizationId, group }) =>
        ({ url: `organizations/${organizationId}/groups/${group.id}`, method: 'delete' }),
      invalidatesTags: () => ['OrganizationGroups', 'OrganizationApplications'],
    }),
  }),
});

export const {
  useAddOrganizationMutation,
  useCreateOrganizationApplicationRoleMutation,
  useCreateOrganizationGroupMutation,
  useGetOrgnanizationQuery,
  useGetOrganizationMembersQuery,
  useGetOrganizationGroupQuery,
  useGetOrganizationGroupsQuery,
  useLazyListOrganizationsQuery,
  useLazyListOrganizationTeamsQuery,
  useLazyGetOrganizationMembersQuery,
  useLazyGetOrganizationGroupsQuery,
  useAddOrganizationMembersMutation,
  useUpdateOrganizationMemberRoleMutation,
  useRemoveOrganizationMemberMutation,
  useListOrganizationApplicationsQuery,
  useEditOrganizationGroupMutation,
  useDeleteOrganizationApplicationRoleMutation,
  useDeleteOrganizationGroupMutation,
  useGetOrganizationApplicationRoleQuery,
  useEditOrganizationApplicationRoleMutation,
} = extendedApiSlice;

import { FC } from 'react';
import PaginationControl from 'common/PaginationControl';
import IamPolicyTable from 'common/infrastructure/iamPolicies/IamPolicyTable';
import { IamPolicySortBy } from 'hooks/useSearchIamPolicies';
import IIamPolicy from 'types/infrastructure/IIamPolicy';
import { InteractiveTableColumn } from 'common/InteractiveTable';

const IamPolicyPaginatedTable: FC<{
  iamPolicies: IIamPolicy[]
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  sortBy: IamPolicySortBy
  setSortBy: (value: IamPolicySortBy) => void
  sortDirection: 'asc' | 'desc'
  onPolicyNameClicked?: (apexHelmChartVersion: IIamPolicy) => void;
  actionColumn?: InteractiveTableColumn<IIamPolicy>;
}> = ({ iamPolicies, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onPolicyNameClicked, actionColumn }) => {
  return (
    <>
      <IamPolicyTable
        iamPolicies={iamPolicies}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        onPolicyNameClicked={onPolicyNameClicked}
        actionColumn={actionColumn}
      />
      <PaginationControl
        pagesToShow={3}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default IamPolicyPaginatedTable;

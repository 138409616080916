import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { UseSearchApexHelmChartVersionsSearchParameters } from 'hooks/useSearchApexHelmChartVersions';
import { useGetApexHelmChartNamesQuery } from 'api/apexHelmChartVersionSlice';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';

const ApexHelmChartVersionSearchFields: FC<{
  disabled: boolean;
  setSearchParameters: (searchParameters: UseSearchApexHelmChartVersionsSearchParameters) => void
  take: number;
  setTake: (value: number) => void;
}> = ({ disabled, setSearchParameters, take, setTake }) => {

  const { data: chartNames, isLoading, error } = useGetApexHelmChartNamesQuery(undefined);

  if (isLoading) return (<Spinner />);
  if (error) return (<Error error={error} />);

  return (
    <Row>
      <Col md={6} className="text-start">
        <Form.Label>{translate('chartName')}</Form.Label>

        <Form.Select
          size="sm"
          defaultValue=""
          onChange={e => setSearchParameters({ chart_name: e.target.value })}
          disabled={disabled}
        >
          <option value="">{translate('all')}</option>
          {chartNames && chartNames.map((chartName) => (
            <option key={chartName} value={chartName}>
              {chartName}
            </option>
          ))}
        </Form.Select>
      </Col>
      <Col md={{ span: 3, offset: 3 }} className="text-end">
        <Form.Group>
          <Form.Label>{translate("resultsPerPage")}</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={take}
            onChange={(e) => setTake(+e.target.value)}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ApexHelmChartVersionSearchFields;

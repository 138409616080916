import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationRoleListItem from 'App/AppRoutes/Organizations/Detail/ApplicationsTab/ApplicationListItem/OrganizationRoleDetailPanel/ApplicationRoleListItem';
import OrganizationPermissionsCard from 'App/AppRoutes/Organizations/Detail/ApplicationsTab/ApplicationListItem/OrganizationRoleDetailPanel/OrganizationPermissionsCard';
import OrganizationRoleGroupsCard from 'App/AppRoutes/Organizations/Detail/ApplicationsTab/ApplicationListItem/OrganizationRoleDetailPanel/OrganizationRoleGroupsCard';
import OrganizationRoleUsersCard from 'App/AppRoutes/Organizations/Detail/ApplicationsTab/ApplicationListItem/OrganizationRoleDetailPanel/OrganizationRoleUsersCard';
import DeleteRoleModal from 'common/applicationRole/DeleteRoleModal';
import IApplicationRole from 'types/application/IApplicationRole';
import { useDeleteOrganizationApplicationRoleMutation } from 'api/organizationSlice';
import useToast from 'hooks/useToast';

const OrganizationRoleDetailPanel: React.FC<{
  appRoles: IApplicationRole[]
  selectedRole: IApplicationRole | null
  setSelectedRole: (role: IApplicationRole | null) => void
  applicationId: string
  organizationId: string
}> = ({ appRoles, selectedRole, setSelectedRole, applicationId, organizationId }) => {
  const navigate = useNavigate();
  const [roleQueuedForRemoval, setRoleQueuedForRemoval] = useState<IApplicationRole | null>(null);
  const [deleteOrgAppRole, result] = useDeleteOrganizationApplicationRoleMutation();
  const toast = useToast();

  if (result.error) {
    toast({
      bg: 'danger',
      title: translate('error'),
      message: translate('anUnexpectedErrorOccurred'),
      autohide: false,
    });
  }

  useEffect(() => {
    if (selectedRole) {
      if (!appRoles.find((role: IApplicationRole) => role.id === selectedRole.id)) {
        setSelectedRole(null);
      }
    }
  }, [appRoles]);

  return appRoles && (
    <Col md={9} className="d-flex bg-dark p-2">
      {roleQueuedForRemoval && (
        <DeleteRoleModal
          show
          applicationRole={roleQueuedForRemoval}
          handleClose={() => setRoleQueuedForRemoval(null)}
          handleDelete={async (applicationRole: IApplicationRole) => {
            await deleteOrgAppRole({ organizationId, applicationRole });
          }}
        />
      )}
      <Col md={5} className="">
        <Card bg="dark" className="border-0">
          <Card.Header className="px-2">
            <Row>
              <Col>
                <h4>{translate('roles')}</h4>
              </Col>
              <Col className="text-end">
                <Button variant="primary" className="text-nowrap" onClick={() => navigate(`applications/${applicationId}/roles/new`)}>
                  <FontAwesomeIcon
                    icon="plus"
                  />
                  <span className="ms-2">{translate('createRole')}</span>
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <Table variant="dark" className="mb-0">
              <tbody>
                {
                  appRoles.map((appRole) => (
                    <ApplicationRoleListItem
                      key={appRole.id}
                      appRole={appRole}
                      isSelected={selectedRole === appRole}
                      setSelectedRole={setSelectedRole}
                      queueRoleForRemoval={setRoleQueuedForRemoval}
                    />
                  ))
                }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {selectedRole && (
        <Col className="p-2 bg-dark-secondary">
          <OrganizationPermissionsCard
            permissions={selectedRole.permissions || []}
          />
          <OrganizationRoleGroupsCard
            groups={selectedRole.groups || []}
          />
          <OrganizationRoleUsersCard
            users={selectedRole.users || []}
          />
        </Col>
      )}
    </Col>
  );
};

export default OrganizationRoleDetailPanel;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getToasts, removeToast } from 'localState/toastSlice';
import { Toast, ToastContainer } from 'react-bootstrap';

const Toasts = () => {
  const toasts = useSelector(getToasts);
  const dispatch = useDispatch();

  return (
    <ToastContainer
      className="p-3 mt-5"
      style={
        {
          zIndex: 1,
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          textAlign: 'Center',
        }}
    >
      {
        Object.keys(toasts).map(toastId => {
          const toast = toasts[toastId];
          return (
            <Toast
              onClose={() => dispatch(removeToast(toast.id))}
              key={toast.id}
              bg={toast.bg}
              delay={toast.delay}
              autohide={!(toast.autohide === false)}
            >
              {toast.title && (
                <Toast.Header className="d-flex justify-content-between">
                  <strong>
                    {toast.title}
                  </strong>
                </Toast.Header>
              )}
              {toast.message && (<Toast.Body>{toast.message}</Toast.Body>)}
            </Toast>
          );
        })
      }
    </ToastContainer>
  );
};

export default Toasts;

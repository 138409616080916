import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SearchFilter from 'common/SearchFilter';
import { translate } from '@apex/react-toolkit/lib';
import { SortBy } from 'hooks/useSearchOrganizations';

const OrganizationSearchFields: React.FC<{
  disabled?: boolean
  take: number
  setTake: (take: number) => void
  setSearchObj: (searchObj: { option: SortBy; value: string; } | null) => void
}> = ({ disabled = false, setSearchObj, take, setTake }) => {
  return (
    <Row>
      <Col md={6} className="text-start">
        <Form.Label>{translate('searchBy')}</Form.Label>
        <SearchFilter
          disabled={disabled}
          onUpdate={setSearchObj}
          options={[
                        {
              option: 'any',
              display: translate('any'),
            },
            {
              option: 'name',
              display: translate('name'),
            },
            {
              option: 'description',
              display: translate('description'),
            },
            {
              option: 'domain',
              display: translate('domain'),
            },
          ]}
        />
      </Col>
      <Col md={{ span: 3, offset: 3 }} className="text-end">
        <Form.Group>
          <Form.Label>{translate('resultsPerPage')}</Form.Label>
          <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default OrganizationSearchFields;

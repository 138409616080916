import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useRegenerateApplicationCredentialsMutation } from 'api/applicationSlice';
import ClientCredentialsModal from 'App/AppRoutes/Applications/Application/Detail/OauthCredentialsModal';
import RemoveApplicationModal from 'App/AppRoutes/Applications/Application/Detail/RemoveApplicationModal';
import ApplicationPropTypes from 'propTypes/application/ApplicationPropTypes';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

const ActionsDropdown = ({ application }) => {
  const [regenerateApplicationCredentials, { isLoading }] = useRegenerateApplicationCredentialsMutation();
  const [newOauthCredentials, setNewOauthCredentials] = useState(null);
  const [credentialsError, setCredentialsError] = useState(null);
  const [oauthModalOpen, setOauthModalOpen] = useState(false);
  const [applicationQueuedForRemoval, setApplicationQueuedForRemoval] = useState(null);
  const { error: appAuthError, isSuperUser } = useIsApplicationMaintainer(application.id);

  const regenerateClientCredentials = async () => {
    const { data: creds, error: apiError } = await regenerateApplicationCredentials(application.id);

    if (apiError) {
      setCredentialsError(apiError);
    } else {
      setNewOauthCredentials(creds);
      setOauthModalOpen(true);
    }
  };

  if (credentialsError || appAuthError) return (<Error coverPage />);

  return (
    <>
      {
        oauthModalOpen && newOauthCredentials && !isLoading
        && (
          <ClientCredentialsModal
            show={newOauthCredentials && oauthModalOpen}
            handleClose={() => setOauthModalOpen(false)}
            credentials={newOauthCredentials}
          />
        )
      }
      {
        applicationQueuedForRemoval && (
          <RemoveApplicationModal
            show={applicationQueuedForRemoval !== null}
            handleClose={() => setApplicationQueuedForRemoval(null)}
            application={applicationQueuedForRemoval}
          />
        )
      }

      <Dropdown className="d-inline-block">
        <Dropdown.Toggle variant="secondary">
          {translate('actions')}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark">
          <Dropdown.Item disabled={!isSuperUser} as={Link} to="edit">{translate('editApplicationDetails')}</Dropdown.Item>
          <Dropdown.Item disabled={!isSuperUser} onClick={regenerateClientCredentials}>{translate('regenerateClientCredentials')}</Dropdown.Item>
          <Dropdown.Item
            disabled={!isSuperUser}
            className="text-danger"
            onClick={() => setApplicationQueuedForRemoval(application)}
          >
            {translate('deleteApplication')}
          </Dropdown.Item>
          <Dropdown.Divider />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

ActionsDropdown.propTypes = {
  application: ApplicationPropTypes,
};

export default ActionsDropdown;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetApplicationRoleGroupsQuery, useGetApplicationRoleQuery } from 'api/applicationRoleSlice';
import RemoveGroupModal from 'App/AppRoutes/Applications/Application/Roles/Detail/Groups/RemoveGroupModal';
import AddGroupsModal from 'App/AppRoutes/Applications/Application/Roles/Detail/Groups/AddGroupsModal';
import PaginationControl from 'common/PaginationControl';
import ApplicationRoleSearchFields from 'common/applicationRole/ApplicationRoleSearchFields';
import useSearchApplicationRoleGroups from 'hooks/useSearchApplicationRoleGroups';
import GroupTable from 'common/group/GroupTable';

const Groups = () => {
  const { applicationId, roleId } = useParams();
  const [groupQueuedForRemoval, setGroupQueuedForRemoval] = useState(null);
  const [showAddGroupsModal, setShowAddGroupsModal] = useState(false);
  const [canAddGroups, setCanAddGroups] = useState(false);
  const { data: currentGroups, isFetching: currentGroupsFetching, error: currentGroupsError } = useGetApplicationRoleGroupsQuery({ roleId });

  const {
    data: applicationRole,
    isFetching: isLoadingRole,
    error: appRoleError,
  } = useGetApplicationRoleQuery(roleId);

  const {
    result: queryResult,
    setSearchObj,
    take,
    setTake,
    setPage,
    page,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchApplicationRoleGroups({ roleId });

  useEffect(() => {
    setCanAddGroups(currentGroups && !currentGroupsFetching && !currentGroupsError);
  }, [currentGroups, currentGroupsFetching, currentGroupsError]);

  if ((queryResult.error && appRoleError) || currentGroupsError) return (<Error coverPage />);
  if (queryResult.isLoading || isLoadingRole) return (<Spinner size="10x" coverViewport />);
  const { data } = queryResult;
  const meta = data?.meta || {};
  const groups = data?.data || [];

  return (
    <>
      {
        groupQueuedForRemoval && (
          <RemoveGroupModal
            show={groupQueuedForRemoval !== null}
            handleClose={() => setGroupQueuedForRemoval(null)}
            roleId={roleId}
            group={groupQueuedForRemoval}
            applicationRole={applicationRole}
          />
        )
      }
      {
        canAddGroups && (
          <AddGroupsModal
            applicationId={applicationId}
            roleId={roleId}
            show={showAddGroupsModal}
            handleClose={() => setShowAddGroupsModal(false)}
            currentGroups={currentGroups.data}
          />
        )
      }
      <Row className="mt-4">
        <Col md={10}>
          <h1>{applicationRole.name} {translate('groups')} <Badge pill>{meta?.total || 0}</Badge></h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="primary"
            onClick={() => setShowAddGroupsModal(true)}
            disabled={!canAddGroups}
          >
            <FontAwesomeIcon icon="plus" /> {translate('addGroups')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicationRoleSearchFields
            roleId={roleId}
            disabled={queryResult.isFetching}
            setSearchObj={setSearchObj}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <GroupTable
            groups={groups}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            applicationId={applicationId}
            actionColumn={{
              dataIndex: 'id',
              render: (_, group) => {
                return (
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setGroupQueuedForRemoval(group)}
                  >
                    <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                  </Button>
                );
              },
            }}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta?.last_page || 0}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default Groups;

import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import PaginationControl from 'common/PaginationControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import useSearchApplicationOrganizations from 'hooks/useSearchApplicationOrganizations';
import { SortBy } from 'hooks/useSearchOrganizations';
import IOrganization from 'types/organization/IOrganization';
import IApplication from 'types/application/IApplication';
import OrganizationTable from 'common/organizations/OrganizationTable';
import OrganizationSearchFields from 'common/organizations/OrganizationSearchFields';
import AddOrganizationModal from './AddOrganizationModal';
import RemoveOrganizationModal from './RemoveOrganizationModal';

const OrganizationsBody: React.FC<{
  application: IApplication;
}> = ({ application }) => {
  const {
    isAdministratorAuthorized,
    isSuperUser,
    isFetching: appAuthIsFetching,
    isLoading: appAuthIsLoading
  } = useIsApplicationMaintainer(application.id);

  const [showAddOrgsModal, setShowAddOrgsModal] = useState<boolean>(false);
  const [orgQueuedForRemoval, setOrgQueuedForRemoval] = useState<IOrganization | null>(null);

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    page,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchApplicationOrganizations({ applicationId: application.id });

  const {
    data: paginatedData,
    error,
    isLoading,
    isFetching,
  } = queryResult;


  if (isLoading || appAuthIsLoading) return (<Spinner size="10x" coverViewport />);
  if (error || !paginatedData) return (<Error coverPage />);

  const disabled = isFetching || appAuthIsFetching;

  return (
    <>
      {
        (isAdministratorAuthorized || isSuperUser) && (
          <AddOrganizationModal
            show={showAddOrgsModal}
            application={application}
            handleClose={() => setShowAddOrgsModal(false)}
          />
        )
      }
      {
        orgQueuedForRemoval && (
          <RemoveOrganizationModal
            show={orgQueuedForRemoval !== null}
            handleClose={() => setOrgQueuedForRemoval(null)}
            applicationId={application.id}
            organization={orgQueuedForRemoval}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            Organizations <Badge pill>{paginatedData?.meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {
            isAdministratorAuthorized && (
              <Button
                onClick={() => setShowAddOrgsModal(true)}
                disabled={disabled}
              >
                <FontAwesomeIcon icon="plus" /> {translate('addOrganizations')}
              </Button>
            )
          }
        </Col>
      </Row>
      <OrganizationSearchFields
        disabled={disabled}
        setSearchObj={(searchObj) => {
          if (searchObj) setSearchParameters({ [searchObj.option]: searchObj.value });
        }}
        take={take}
        setTake={setTake}
      />
      <Row>
        <Col>
          <OrganizationTable
            organizations={paginatedData?.data}
            setSortBy={(updatedSortKey: string) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey as SortBy);
              }
            }}
            sortBy={sortBy}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            actionColumn={{
              dataIndex: 'id',
              title: '',
              render: (_, org) => {
                if (!isAdministratorAuthorized) return null;
                return (
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setOrgQueuedForRemoval(org)}
                  >
                    <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                  </Button>
                );
              },
            }}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={paginatedData?.meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default OrganizationsBody;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationRoleForm from 'common/applicationRole/ApplicationRoleForm';
import { useAddApplicationRoleMutation } from 'api/applicationRoleSlice';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import Unauthorized from 'common/Unauthorized';

const New = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [addApplicationRole, { isLoading, error: mutationError }] = useAddApplicationRoleMutation();
  const { isAdministratorAuthorized, isLoading: appAuthIsLoading } = useIsApplicationMaintainer(applicationId);
  const [apiErrors, setApiErrors] = useState(null);

  const handleSubmit = async (formData) => {
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await addApplicationRole({ ...formData, application_id: applicationId });

    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/applications/${applicationId}/roles/${data.id}`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/roles/`);
  };

  if(!isAdministratorAuthorized) return (<Unauthorized />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate('addApplicationRole')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          { (mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>) }
          <ApplicationRoleForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            disabled={isLoading || appAuthIsLoading}
            apiErrors={apiErrors}
            submitText={translate('create')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default New;

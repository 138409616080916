import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Container, Row, Col } from 'react-bootstrap';

const PageNotFound = () => (
  <Container className="mt-4">
    <Row>
      <Col><h1>{translate('pageNotFound')}</h1></Col>
    </Row>
  </Container>
);

export default PageNotFound;

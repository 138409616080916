import { Badge, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import MicroserviceChartSelectionForm from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard/MicroserviceChartSelectionForm';
import { IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';
import { useUpdateMsHelmChartVersionMutation } from 'api/applicationMicroserviceSlice';
import useToast from 'hooks/useToast';
import Environment from 'types/Environment';

const MicroserviceChartVersionSelectorCard: React.FC<{
  microservice: IBaseApplicationMicroservice;
  isDeveloperAuthorized: boolean;
  environments?: [Environment];
}> = ({
  microservice,
  isDeveloperAuthorized,
  environments = ['dev', 'staging', 'prod'],
}) => {
    const toast = useToast();
    const [updateVersion, result] = useUpdateMsHelmChartVersionMutation();

    return (
      <Card bg="dark">
        <Card.Body>
          {environments.map((env: string) => {
            const today = new Date();
            // @ts-expect-error TypeScript ain't smart nuff for this one
            const chartVersion = microservice[`${env}_chart_version`] as IApexHelmChartVersion;
            const deprecationDate = new Date(chartVersion.deprecation_date);
            const eolDate = new Date(chartVersion.eol_date);

            const isDeprecated = chartVersion.deprecation_date && today > deprecationDate;
            const isDead = chartVersion.eol_date && today > eolDate;

            let toolTip: React.ReactNode = '';
            if (isDeprecated) {
              toolTip = (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      {translate('deprecatedExplanation')}
                    </Tooltip>
                  )
                  }
                >
                  <Badge pill bg="warning" className="me-2">
                    {/** @ts-expect-error stupid icon */}
                    <FontAwesomeIcon icon="triangle-exclamation" />
                  </Badge>
                </OverlayTrigger>
              )
            }

            if (isDead) {
              toolTip = (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip>
                      {translate('eolExplanation')}
                    </Tooltip>
                  )
                  }
                >
                  <Badge pill bg="danger" className="me-2">
                    {/** @ts-expect-error stupid icon */}
                    <FontAwesomeIcon icon="circle-exclamation" />
                  </Badge>
                </OverlayTrigger>
              )
            }

            return (
              <Row className="mt-4">
                <Col md={1}>
                  <span>
                    {translate(env)}
                  </span>
                </Col>
                <Col md={1}>
                  <span>
                    {toolTip}
                  </span>
                </Col>
                <Col md={10}>
                  <MicroserviceChartSelectionForm
                    disabled={result.isLoading}
                    currentVersion={chartVersion}
                    isDeveloperAuthorized={isDeveloperAuthorized}
                    onSubmit={async (version: IApexHelmChartVersion) => {
                      const result = await updateVersion({
                        microserviceId: microservice.id, data: {
                          version_id: version.id,
                          environment: env as Environment,
                        }
                      });

                      if ('error' in result) {
                        toast({
                          title: translate('error'),
                          message: translate('anUnexpectedErrorOccurred'),
                          bg: 'danger',
                        });
                      } else if ('data' in result) {
                        toast({
                          title: translate('success'),
                          message: translate('updatedSuccessfully'),
                          bg: 'success',
                        });
                      }
                    }}
                  />
                </Col>
              </Row>
            );
          })}
        </Card.Body>
      </Card >
    );
  }

export default MicroserviceChartVersionSelectorCard;

import { BaseSyntheticEvent, useRef, useState, useEffect } from 'react';
import { groupBy, uniq } from 'lodash';
import * as yup from 'yup';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { Formik } from 'formik';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import FormFeedback from 'common/FormFeedback';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import useSearchOrganizationMembers from 'hooks/useSearchOrganizationMembers';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSearchOrganizationTeams from 'hooks/useSearchTeams';
import { IGroupFormFields, IGroupValidationErrors, IOrganizationGroupFormFields } from 'types/group/IGroup';
import IUser from 'types/IUser';
import ITeam from 'types/team/ITeam';
import MenuItem from 'common/typeahead/MenuItem';
import { useListOrganizationApplicationsQuery } from 'api/organizationSlice';
import IApplication from 'types/application/IApplication';
import IApplicationRole from 'types/application/IApplicationRole';
import useToast from 'hooks/useToast';

const OrganizationGroupForm: React.FC<{
  onSubmit: (formData: IOrganizationGroupFormFields) => void
  onCancel: () => void
  initialValues?: IGroupFormFields
  apiErrors: IGroupValidationErrors | null
  disabled: boolean
  organizationId: string
  newGroup?: boolean
  createAnother?: boolean
  setCreateAnother?: (createAnother: boolean) => void
  currentUsers?: IUser[]
  currentTeams?: ITeam[]
  currentRoles?: IApplicationRole[]
}> = ({
  onSubmit,
  onCancel,
  initialValues = { name: '', description: '' },
  apiErrors,
  disabled,
  organizationId,
  newGroup = true,
  createAnother,
  setCreateAnother,
  currentUsers = [],
  currentTeams = [],
  currentRoles = [],
}) => {
    const schema = yup.object({
      name: yup.string().required(translate('nameRequired')),
      description: yup.string(),
    });

    const toast = useToast();
    const teamsTypeahead = useRef(null);
    const usersTypeahead = useRef(null);
    const [appSectionOpen, setAppSectionOpen] = useState<boolean>(true);
    const [selectedApplications, setSelectedApplications] = useState<IApplication[] | never[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<Option[] | never[]>(currentUsers || []);
    const [selectedTeams, setSelectedTeams] = useState<Option[] | never[]>(currentTeams || []);
    const [selectedRoles, setSelectedRoles] = useState({});

    const {
      setSearchParameters: setSearchMembersParameters,
      result: membersResult,
    } = useSearchOrganizationMembers({ organizationId });

    const {
      setSearchParameters: setSearchTeamsParameters,
      result: teamsResult,
    } = useSearchOrganizationTeams({ organizationId, defaultTake: 150 });

    const { data: applications, isLoading, error } = useListOrganizationApplicationsQuery(organizationId);

    useEffect(() => {
      if (currentRoles && applications) {
        const appIds = uniq(currentRoles.map((role: IApplicationRole) => role.application_id));
        const apps = applications.filter((app: IApplication) => appIds.includes(app.id));
        const intialSelectedRoles = groupBy(currentRoles, 'application_id');

        setSelectedApplications(apps);
        setSelectedRoles(intialSelectedRoles);
      }
      // the stringify is necessary because React will recreate the arrays causing an infinite render loop
    }, [JSON.stringify(currentRoles), JSON.stringify(applications)]);

    useEffect(() => {
      if (currentUsers) {
        setSelectedUsers(currentUsers);
      }
    }, [JSON.stringify(currentUsers)]);


    if (error) return <Error message={translate('errorLoadingOrganizationApplications')} />;

    const setSelectedRoleByApplicationHash = (option: Option, appId: string) => {
      const copy = JSON.parse(JSON.stringify(selectedRoles));
      const updated = {
        ...copy,
        [appId]: option,
      };
      setSelectedRoles(updated);
    }

    const deselectApp = (appId: string) => {
      const copy = JSON.parse(JSON.stringify(selectedRoles));
      delete copy[appId];
      setSelectedRoles(copy);
    }

    return (
      <Formik
        enableReinitialize={!newGroup}
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (formInput, { resetForm }) => {
          await onSubmit({
            ...formInput,
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            teams: selectedTeams.map(team => team.id),
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            users: selectedUsers.map(user => user.id),
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            application_roles: Object.values(selectedRoles).flat().map(role => role.id),
            applications: selectedApplications.map(app => app.id),
          });

          if (createAnother) {
            setSelectedApplications([]);
            setSelectedRoles({});
            setSelectedUsers([]);
            setSelectedTeams([]);
            // @ts-expect-error importing the typeahead type still causes a type error despite the docs
            teamsTypeahead.current?.clear();
            // @ts-expect-error importing the typeahead type still causes a type error despite the docs
            usersTypeahead.current?.clear();

            toast({
              bg: 'success',
              title: translate('success'),
              message: translate('createdGroup', { groupName: formInput.name }),
              autohide: true,
            });

            resetForm();
          }
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder="Name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                value={values.description || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>

            <Row className="mt-5 mb-3">
              <Col className="d-flex">
                <FontAwesomeIcon style={{ width: '2rem' }} icon={appSectionOpen ? "chevron-down" : "chevron-right"} size="lg" className="my-auto" onClick={() => setAppSectionOpen(!appSectionOpen)} />
                <div className="d-block">
                  <h3 className="my-auto">{translate('applicationsAndRoles')}</h3>
                </div>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="right"
                  rootClose
                  overlay={(
                    <Popover>
                      <Popover.Body>
                        {translate('organizationApplicationAndRoles')}
                      </Popover.Body>
                    </Popover>
                  )}
                >
                  <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row>
              {
                appSectionOpen && (
                  <Col>
                    <Card className="border-0 bg-dark-secondary">
                      <Card.Header className="px-2">
                        <Row>
                          <Col>
                            <h6>{translate('applications')}</h6>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="py-0">
                        {
                          !isLoading && applications ? (
                            applications.map((app) => (
                              // @ts-expect-error dumb
                              <div key={app.id} className={`my-2 px-3 ${selectedApplications.includes(app) ? 'bg-dark' : ''}`}>
                                <FormCheck
                                  disabled={disabled}
                                  className="d-block align-middle py-2"
                                  type="checkbox"
                                  label={app.name}
                                  id="add-app-role-checkbox"
                                  // @ts-expect-error why is the expected parameter of type never?
                                  checked={selectedApplications.includes(app)}
                                  onChange={(e: BaseSyntheticEvent) => {
                                    if (e.target.checked) {
                                      setSelectedApplications([...selectedApplications, app]);
                                      setSelectedRoleByApplicationHash([] as Option, app.id);
                                    } else {
                                      const updated = selectedApplications.filter((a) => a !== app);
                                      setSelectedApplications(updated);
                                      deselectApp(app.id);
                                    }
                                  }}
                                />
                                {
                                  // @ts-expect-error dumb
                                  selectedApplications.includes(app) && (
                                    <Card bg="dark" className="border-0 ms-2">
                                      <Card.Header>{translate('roles')}</Card.Header>
                                      <Card.Body className="py-0">
                                        {
                                          app.roles && app.roles.length > 0 ? (
                                            <Typeahead
                                              disabled={disabled}
                                              key={`app-${app.id}-role-typeahead`}
                                              multiple
                                              paginate={false}
                                              placeholder={translate('searchApplicationRoles', { appName: app.name })}
                                              id={`${app.name}-org-role-typeahead`}
                                              options={app.roles}
                                              // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                                              selected={selectedRoles[app.id]}
                                              onChange={(option) => setSelectedRoleByApplicationHash(option, app.id)}
                                              renderMenuItemChildren={(option, { text }) => (
                                                // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                                                <MenuItem label={option.name} smallText={option.description} searchText={text} />
                                              )}
                                              labelKey={(option) => {
                                                // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                                                return option.name;
                                              }}
                                              filterBy={((option) => {
                                                // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                                                // @TODO -- here is the problem
                                                return option.application_id === app.id && !selectedRoles[app.id]?.find(r => r.id === option.id)
                                              })}
                                              className="mb-3"
                                            />
                                          ) : (
                                            <h6>{translate('orgDoesNotHaveAppRoles')}</h6>
                                          )
                                        }
                                      </Card.Body>
                                    </Card>
                                  )
                                }
                              </div>
                            ))
                          ) : (
                            <Spinner />
                          )
                        }
                      </Card.Body>
                    </Card>
                  </Col>
                )
              }
            </Row>

            <Row className="mt-3 mb-3">
              <Col className="d-flex">
                <h3 className="my-auto">{translate('userAccess')}</h3>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="right"
                  rootClose
                  overlay={(
                    <Popover>
                      <Popover.Body>
                        {translate('organizationUserAccess')}
                      </Popover.Body>
                    </Popover>
                  )}
                >
                  <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Col>
            </Row>

            <Form.Group controlId="formTeams" className="mb-3">
              <Form.Label>{translate('teams')}</Form.Label>
              <AsyncTypeahead
                multiple
                disabled={disabled}
                ref={teamsTypeahead}
                paginate={false}
                placeholder={translate('searchTeams')}
                id="async-teams-typeahead"
                delay={300}
                isLoading={teamsResult.isLoading || teamsResult.isFetching}
                onSearch={(query: string) => setSearchTeamsParameters({ any: query })}
                options={teamsResult.data?.data || []}
                selected={selectedTeams}
                onChange={setSelectedTeams}
                renderMenuItemChildren={(option, { text }) => (
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  <MenuItem label={option.name} smallText={`Series: ${option.series.name}, Car Number: ${option.car_number}`} searchText={text} />
                )}
                // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                labelKey={(option) => option.name}
                filterBy={(option) => {
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  return !selectedUsers.find(u => u.id === option.id) && !currentTeams.find(u => u.id === option.id)
                }}
              />
            </Form.Group>

            <Form.Group controlId="formUsers" className="mb-3">
              <Form.Label>{translate('users')}</Form.Label>
              <AsyncTypeahead
                multiple
                disabled={disabled}
                ref={usersTypeahead}
                paginate={false}
                placeholder={translate('searchUsers')}
                id="async-users-typeahead"
                delay={300}
                isLoading={membersResult.isLoading || membersResult.isFetching}
                onSearch={(query: string) => setSearchMembersParameters({ any: query })}
                options={membersResult.data?.data || []}
                selected={selectedUsers}
                onChange={setSelectedUsers}
                renderMenuItemChildren={(option, { text }) => (
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  <MenuItem label={`${option.first_name} ${option.last_name}`} smallText={option.email} searchText={text} />
                )}
                // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                labelKey={option => `${option.first_name} ${option.last_name}`}
                filterBy={(option) => {
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  return !selectedUsers.find(u => u.id === option.id)
                }}
              />
            </Form.Group>

            <Form.Group className="mt-4">
              {onCancel && (
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  onClick={onCancel}
                >
                  {translate('cancel')}
                </Button>
              )}
              <Button
                type="submit"
                variant="primary"
                className="mx-2"
                disabled={disabled}
              >
                {translate('submit')}
              </Button>
              {(newGroup && setCreateAnother) && (
                <FormCheck
                  className="d-inline-block align-middle"
                  type='checkbox'
                  label={translate('createAnotherGroup')}
                  id="create-another-group-checkbox"
                  onClick={() => setCreateAnother(!createAnother)}
                />
              )}
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  };

export default OrganizationGroupForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetApplicationRoleQuery } from 'api/applicationRoleSlice';
import RemovePermissionModal from 'App/AppRoutes/Applications/Application/Roles/Detail/Permissions/RemovePermissionModal';
import AddPermissionModal from 'App/AppRoutes/Applications/Application/Roles/Detail/Permissions/AddPermissionModal';
import PermissionsTable from 'App/AppRoutes/Applications/Application/Roles/Detail/Permissions/PermissionTable';
import PaginationControl from 'common/PaginationControl';
import ApplicationRolePermissionSearchFields from 'common/applicationRole/ApplicationRolePermissionSearchFields';
import useSearchApplicationRolePermissions from 'hooks/useSearchApplicationRolePermissions';
import { useGetApplicationPermissionsQuery } from 'api/applicationSlice';
import { useGetApplicationRolePermissionsQuery } from 'api/applicationRoleSlice'

const Permissions = ({ applicationId, roleId }) => {
  const [showAddPermissionModal, setShowAddPermissionModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [canAddPermissions, setCanAddPermissions] = useState(false);
  const { data: allPermissions, isFetching: allPermissionsFetching, error: allPermissionsError } = useGetApplicationPermissionsQuery({ applicationId });
  const { data: currentPermissions, isFetching: currentPermissionsFetching, error: currentPermissionsError } = useGetApplicationRolePermissionsQuery({ roleId });

  const {
    data: applicationRole,
    isFetching: isLoadingRole,
    error: appRoleError,
  } = useGetApplicationRoleQuery(roleId);

  const {
    result: queryResult,
    setSearchObj,
    take,
    setTake,
    setPage,
    page,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchApplicationRolePermissions({ roleId });

  useEffect(() => {
    setCanAddPermissions(!allPermissionsFetching && allPermissions);
  }, [allPermissions, allPermissionsFetching]);

  if ((queryResult.error && appRoleError) || allPermissionsError || currentPermissionsError) return (<Error coverPage />);
  if (queryResult.isLoading || isLoadingRole || currentPermissionsFetching) return (<Spinner size="10x" coverViewport />);
  const { data } = queryResult;
  const meta = data?.meta || {};
  const permissions = data?.data || [];

  return (
    <>
      {
        selectedPermission && (
          <RemovePermissionModal
            show={selectedPermission !== null}
            handleClose={() => setSelectedPermission(null)}
            roleId={roleId}
            permission={selectedPermission}
          />
        )
      }
      {
        canAddPermissions && (
          <AddPermissionModal
            roleId={roleId}
            show={showAddPermissionModal}
            handleClose={() => setShowAddPermissionModal(false)}
            currentPermissions={currentPermissions.data}
          />
        )
      }
      <Row className="mt-4">
        <Col md={10}>
          <h1>{applicationRole.name} {translate('permissions')} <Badge pill>{meta?.total || 0}</Badge></h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="primary"
            onClick={() => setShowAddPermissionModal(true)}
            disabled={!canAddPermissions}
          >
            <FontAwesomeIcon icon="plus" /> {translate('addPermissions')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicationRolePermissionSearchFields
            roleId={roleId}
            disabled={queryResult.isFetching}
            setSearchObj={setSearchObj}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <PermissionsTable
            permissions={permissions}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            applicationId={applicationId}
            setSelectedPermission={setSelectedPermission}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta?.last_page || 0}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

Permissions.propTypes = {
  applicationId: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
};

export default Permissions;

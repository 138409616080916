import React from 'react';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetMicroservicePipelinesQuery } from 'api/applicationMicroserviceSlice';
import { IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import PiplineAccordion from 'common/pipeline/PiplineAccordion';
import IPipeline from 'types/IPipeline';

const MicroservicePipelineMonitor: React.FC<{ microservice: IBaseApplicationMicroservice }> = ({ microservice }) => {
  const { data, isLoading, error } = useGetMicroservicePipelinesQuery({ microserviceId: microservice.id });

  if (isLoading) return (<Spinner />);
  if (error || !data) return (<Error error={error} />);

  return (
    <>
      {data.data.map((pipeline: IPipeline) => <PiplineAccordion pipeline={pipeline} key={pipeline.id} />)}
    </>
  );
};

export default MicroservicePipelineMonitor;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldArray } from 'formik';
import {
  Button,
  Form,
  InputGroup,
  Card,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Badge,
} from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import useSearchApplicationMicroserviceEnvironmentVariables from 'hooks/useSearchApplicationMicroserviceEnvironmentVariables';
import { useEditApplicationMicroserviceEnvironmentVariablesMutation } from 'api/applicationMicroserviceSlice';
import useToast from 'hooks/useToast';
import { useParams } from 'react-router-dom';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

const schema = yup.object().shape({
  environmentVariables: yup.array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .matches(/^[A-Z0-9_]*$/, translate('environmentVariableNameFormat'))
          .required(translate('nameRequired')),
        value: yup
          .string()
          .matches(/^\S*$/, translate('noWhitespace'))
          .required(translate('valueRequired')),
      })
        .test('unique-environment-name-combo', { name: translate('uniqueEnvironmentNameCombo') }, (value, context) => {
          // Will always be at least 1 because the given value will match itself in the Formik FieldArray
          const dupeCount = context.parent.reduce((acc, el) => {
            if (el.environment === value.environment && el.name === value.name) acc++;
            return acc;
          }, 0);

          const isUnique = dupeCount === 1;
          return isUnique;
        }),
    ),
});

const sortByEnvironment = (data) => {
  return _.chain(data)
    .sortBy('name')
    .groupBy('name')
    .map((envarsGroupedByName) => ([
      _.find(envarsGroupedByName, { environment: 'dev' }),
      _.find(envarsGroupedByName, { environment: 'staging' }),
      _.find(envarsGroupedByName, { environment: 'prod' }),
    ]))
    .flatten()
    .compact()
    .value();
};

const ApplicationMicroserviceEnvironmentVariables = ({ microserviceId, lockedEnv }) => {
  const { applicationId } = useParams();
  const {
    result: queryResult,
  } = useSearchApplicationMicroserviceEnvironmentVariables({ microserviceId });
  const { isDeveloperAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);
  const toast = useToast();
  const [editEnvironmentVariables, editResult] = useEditApplicationMicroserviceEnvironmentVariablesMutation();

  if (queryResult.isLoading || !queryResult.data || appAuthIsFetching) {
    return (
      <Spinner />
    );
  }

  let editableEnvironmentVariables = queryResult.data;
  let hiddenEnvironmentVariables = [];

  if (lockedEnv !== null) {
    editableEnvironmentVariables = queryResult.data.filter(({ environment }) => environment === lockedEnv);
    hiddenEnvironmentVariables = queryResult.data.filter(({ environment }) => environment !== lockedEnv);
  }


  const handleSubmit = async (formData) => {

    try {
      await editEnvironmentVariables({
        microserviceId,
        environmentVariables: [
          ...formData.environmentVariables,
          ...hiddenEnvironmentVariables,
        ],
      });

      toast({
        bg: 'success',
        title: translate('updated'),
        message: ` ${translate('environmentVariablesSavedSuccessfully')}`,
      });
    } catch (e) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    }
  };

  return (
    <Card bg="dark">
      <Card.Body>
        <Row className="mb-1">
          <Col xl={4}><h6>{translate('name')}</h6></Col>
          <Col xl={4}>
            <h6 className="d-inline-block me-2">{translate('value')}</h6>
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip>
                  {translate('environmentVariableEncryptedExplanation')}
                </Tooltip>
              )}
            >
              <Badge pill bg="secondary">
                <FontAwesomeIcon icon="info" />
              </Badge>
            </OverlayTrigger>
          </Col>
          <Col xl={2}><h6>{translate('environment')}</h6></Col>
          {
            isDeveloperAuthorized && (
              <>
                <Col xl={1}><h6>{translate('hide')}</h6></Col>
                <Col xl={1}><h6>{translate('delete')}</h6></Col>
              </>
            )
          }
        </Row>

        <Formik
          validationSchema={schema}
          initialValues={{ environmentVariables: sortByEnvironment(editableEnvironmentVariables) }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, isValid, handleReset, dirty }) => (
            <FormikForm>
              <FieldArray name="environmentVariables">
                {({ push, remove }) => (
                  <div>
                    {values.environmentVariables.length > 0
                      && values.environmentVariables.map((environmentVariable, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index}>
                          <Col xl={4}>
                            <Field name={`environmentVariables.${index}.name`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      {...field}
                                      onKeyUp={field.onBlur}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                      <ErrorMessage name={`environmentVariables.${index}.name`} />
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xl={4}>
                            <Field name={`environmentVariables.${index}.value`}>
                              {({ form, field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      {...field}
                                      onKeyUp={field.onBlur}
                                      type={(form.values.environmentVariables[index].hide) ? 'password' : 'text'}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                      <ErrorMessage name={`environmentVariables.${index}.value`} />
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xs={2}>
                            <Field name={`environmentVariables.${index}.environment`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Select
                                      disabled={editResult.isLoading || !isDeveloperAuthorized || lockedEnv}
                                      {...field}
                                    >
                                      <option value="dev">{translate('dev')}</option>
                                      <option value="staging">{translate('staging')}</option>
                                      <option value="prod">{translate('prod')}</option>
                                    </Form.Select>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          {
                            isDeveloperAuthorized && (
                              <>
                                <Col xs={1}>
                                  <Field name={`environmentVariables.${index}.hide`}>
                                    {({ field }) => (
                                      <Form.Group className="mb-3">
                                        <InputGroup>
                                          <Form.Check
                                            {...field}
                                            checked={field.value}
                                            disabled={editResult.isLoading}
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    )}
                                  </Field>
                                </Col>

                                <Col xs={1}>
                                  <Button
                                    disabled={editResult.isLoading}
                                    variant="danger"
                                    onClick={() => remove(index)}
                                  >
                                    <FontAwesomeIcon icon="times" />
                                  </Button>
                                </Col>
                              </>
                            )
                          }
                        </Row>
                      ))}
                    {
                      isDeveloperAuthorized && (
                        <Button
                          variant="primary"
                          onClick={() => push({ name: '', value: '', hide: false, environment: lockedEnv || 'dev' })}
                        >
                          {translate('addEnvironmentVariable')}
                        </Button>
                      )
                    }
                  </div>
                )}
              </FieldArray>
              {
                isDeveloperAuthorized && (
                  <Row className="mt-5">
                    <Col>
                      <Button
                        className="me-2"
                        variant="secondary"
                        type="cancel"
                        disabled={editResult.isLoading || !dirty}
                        onClick={handleReset}
                      >
                        {translate('cancel')}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValid || editResult.isLoading || !dirty}
                      >
                        {(editResult.isLoading) ? translate('saving') : translate('save')}
                      </Button>
                    </Col>
                  </Row>
                )
              }
            </FormikForm>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

ApplicationMicroserviceEnvironmentVariables.defaultProps = {
  lockedEnv: null,
};

ApplicationMicroserviceEnvironmentVariables.propTypes = {
  microserviceId: PropTypes.string.isRequired,
  lockedEnv: PropTypes.oneOf(['dev', 'staging', 'prod']),
};

export default ApplicationMicroserviceEnvironmentVariables;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import useInterval from 'hooks/useInterval';
import { REFETCH_DELAY_IN_SECONDS } from 'config';
import DeploymentSearchFields from 'common/deployment/DeploymentSearchFields';
import useSearchNodeGroupDeployments, { SortBy } from 'hooks/useSearchNodeGroupDeployments';
import DeploymentPaginatedTable from 'common/deployment/DeploymentPaginatedTable';

const NodeGroupDeploymentSearchTable: React.FC<{
  nodeGroupId: string;
  canPromote?: boolean;
}> = ({ nodeGroupId, canPromote = false }) => {
  const {
    result: queryResult,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStatus,
    setSearchStatus,
    setSearchTargetEnvironment,
    searchTargetEnvironment,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    refetch,
  } = useSearchNodeGroupDeployments({ nodeGroupId });

  useInterval(() => {
    refetch();
  }, REFETCH_DELAY_IN_SECONDS * 1000);

  if (queryResult.isLoading || !queryResult.data) {
    return (
      <Spinner />
    );
  }

  const { data, meta } = queryResult.data;

  return (
    <Card className="bg-dark">
      <Card.Header>
        <Row>
          <Col>
            <h3>Deployments</h3>
          </Col>
          <Col className="text-end">
            <small className="text-muted">Results update every {REFETCH_DELAY_IN_SECONDS} seconds</small>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <DeploymentSearchFields
              searchTargetEnvironment={searchTargetEnvironment}
              setSearchTargetEnvironment={setSearchTargetEnvironment}
              searchStatus={searchStatus}
              setSearchStatus={setSearchStatus}
              toDate={toDate}
              setToDate={setToDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
              take={take}
              setTake={setTake}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DeploymentPaginatedTable
              setSortBy={(updatedSortKey) => {
                if (sortBy === updatedSortKey) {
                  setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                } else {
                  setSortBy(updatedSortKey as SortBy);
                }
              }}
              sortBy={sortBy}
              sortDirection={sortDirection}
              deployments={data}
              pageCount={meta.last_page}
              currentPage={meta.current_page}
              onPageChange={setPage}
              canPromote={canPromote}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NodeGroupDeploymentSearchTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import ApplicationPropTypes from 'propTypes/application/ApplicationPropTypes';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';

const ApplicationTable = ({ applications, sortBy, setSortBy, sortDirection }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/applications/${id}`}>{ first }</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={applications}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

ApplicationTable.propTypes = {
  ...SortPropTypes,
  applications: PropTypes.arrayOf(ApplicationPropTypes).isRequired,
};

export default ApplicationTable;

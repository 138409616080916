import { translate } from '@apex/react-toolkit/lib';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import useSearchIamPolicies from 'hooks/useSearchIamPolicies';
import { useState } from 'react';
import { useAddPolicyableMutation, useDetachPolicyableMutation, useGetPolicyablePoliciesQuery } from 'api/iamPolicySlice';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import IIamPolicy from 'types/infrastructure/IIamPolicy';
import MenuItem from 'common/typeahead/MenuItem';
import Environment from 'types/Environment';
import IamPolicyTable from 'common/infrastructure/iamPolicies/IamPolicyTable';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IamPolicyableAdder: React.FC<{
  policyableType: string;
  policyableId: string;
}> = ({
  policyableType,
  policyableId,
}) => {
    const {
      result: queryResult,
      setSearchParameters,
      sortBy,
      setSortBy,
      sortDirection,
    } = useSearchIamPolicies();
    const navigate = useNavigate();
    const { isLoading, data: paginatedData } = queryResult;
    const { data: currentPolicies } = useGetPolicyablePoliciesQuery({
      policyableId,
      policyableType,
    });

    const [addPolicyable, { isLoading: addIsLoading }] = useAddPolicyableMutation();
    const [detachPolicyable, { isLoading: detachIsLoading}] = useDetachPolicyableMutation();
    const [selectedPolicy, setSelectedPolicy] = useState<IIamPolicy | null>(null);
    const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | ''>('');

    const disabled = isLoading || addIsLoading || detachIsLoading;

    return (
      <Card bg="dark">
        <Card.Body>
          <Row>
            <Col md={5}>
              <AsyncTypeahead
                paginate={false}
                filterBy={() => true}
                id="policy-adder"
                labelKey="policy_name"
                isLoading={isLoading}
                minLength={2}
                selected={selectedPolicy ? [selectedPolicy] : []}
                // @ts-expect-error more typeahead type failures
                onChange={(selected: IIamPolicy[]) => {
                  setSelectedPolicy(selected[0]);
                }}
                onSearch={(query: string) => {
                  setSearchParameters({ policy_name: query })
                }}
                options={paginatedData?.data || []}
                placeholder={translate('policyName')}
                // @ts-expect-error yeah yeah yeahFo
                renderMenuItemChildren={(option: policy_name, { text }) => (
                  <MenuItem
                    label={option.policy_name}
                    smallText={option.arn}
                    searchText={text}
                  />
                )}
              />
            </Col>
            <Col md={5}>
              <Form.Select
                onChange={e => setSelectedEnvironment(e.target.value as Environment)}
                disabled={disabled}
                value={selectedEnvironment}
              >
                <option value="" disabled>{translate('environment')}</option>
                <option value="dev">dev</option>
                <option value="staging">staging</option>
                <option value="prod">prod</option>
              </Form.Select>
            </Col>
            <Col md={2}>
              <Button
                disabled={disabled || !selectedPolicy || !selectedEnvironment}
                onClick={() => {
                  if (selectedPolicy) {
                    addPolicyable({
                      policyId: selectedPolicy.id,
                      iam_policyable_id: policyableId,
                      iam_policyable_type: policyableType,
                      environment: selectedEnvironment as Environment,
                    })
                  }
                }}
              >
                {translate('attach')}
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <IamPolicyTable
                withEnvPivot={true}
                iamPolicies={currentPolicies || []}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                onPolicyNameClicked={(policy: IIamPolicy) => navigate(`infrastructure/iam-policies/${policy.id}`)}
                actionColumn={{
                  dataIndex: 'id',
                  title: '',
                  render: (_, policy) => {
                    return (
                      <Button
                        variant="danger"
                        size="sm"
                        className="text-nowrap"
                        onClick={() => detachPolicyable({
                          policyId: policy.id,
                          iam_policyable_id: policyableId,
                          iam_policyable_type: policyableType,
                          environment: policy?.pivot?.environment as Environment
                        })}
                      >
                        <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                      </Button>
                    );
                  },
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

export default IamPolicyableAdder;

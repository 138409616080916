import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import OrganizationGroupForm from 'common/organizations/OrganizationGroupForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditOrganizationGroupMutation, useGetOrganizationGroupQuery } from 'api/organizationSlice';
import IGroup, { IGroupApiErrors, IGroupValidationErrors, IOrganizationGroupFormFields } from 'types/group/IGroup';
import useSearchGroupRoles from 'hooks/useSearchGroupRoles';
import useSearchGroupMembers from 'hooks/useSearchGroupMembers';

const EditOrganizationGroup: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const { orgId: organizationId, groupId } = useParams();
  const { data: group, isLoading: groupIsLoading } = useGetOrganizationGroupQuery({ groupId: groupId as string, organizationId: organizationId as string });
  const [apiErrors, setApiErrors] = useState<IGroupValidationErrors | null>(null);
  const { result: rolesResult } = useSearchGroupRoles({ groupId: groupId as string, defaultTake: 25 });
  const { result: membersResult } = useSearchGroupMembers({ groupId: groupId as string, defaultTake: 25 });
  const [editOrgGroup, editOrgGroupResult] = useEditOrganizationGroupMutation();

  const isLoading = rolesResult.isLoading || groupIsLoading || membersResult.isLoading;

  if (isLoading) return (<Spinner />);
  if (!organizationId) return <Error message={translate('organizationIdRouteError')} />;
  if (rolesResult.error || membersResult.error || !group) return <Error />;

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('editGroup')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <OrganizationGroupForm
            initialValues={group}
            onCancel={() => navigate(`/organizations/${organizationId}#groups`)}
            currentRoles={rolesResult?.data?.data || []}
            currentUsers={membersResult?.data?.data || []}
            currentTeams={group?.teams || []}
            onSubmit={async (data: IOrganizationGroupFormFields) => {
              const result: { data: IGroup } | { error: IGroupApiErrors } = await editOrgGroup({ group, data });
              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else {
                navigate(`/organizations/${organizationId}/groups/${groupId}`);
              }
            }}
            apiErrors={apiErrors}
            disabled={editOrgGroupResult.isLoading || membersResult.isFetching || rolesResult.isFetching}
            organizationId={organizationId}
            newGroup={false}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EditOrganizationGroup;

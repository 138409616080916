import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import Card from 'common/Card';

const TOPICS = [
  {
    title: translate('howToDeployANewMicroservice'),
    url: 'https://documentation.app.apex-mp.com/guides/deploy-a-new-microservice',
  },
  {
    title: translate('howToBuildADockerImage'),
    url: 'https://documentation.app.apex-mp.com/guides/build-a-docker-image',
  },
  {
    title: translate('howToUseTheSdk'),
    url: 'https://documentation.app.apex-mp.com/sdk',
  },
  {
    title: translate('howToDevelopLocallyWithTheMockPlatformServer'),
    url: 'https://documentation.app.apex-mp.com/local-development#working-with-the-mock-platform-server',
  },
];

export default () => {
  return (
    <Card
      title={(
        <>
          {translate('guidesAndTroubleshooting')}

          <a href="https://documentation.app.apex-mp.com/category/guides" target="_blank" style={{ fontSize: 14 }}>
            {translate('allGuides')}
            <FontAwesomeIcon icon="arrow-right" className="ms-1" />
          </a>
        </>
      )}
      body={(
        <Table variant="dark">
          <tbody>
            {TOPICS.map(({ url, title }) => <tr><td><a href={url} target="_blank">{title}</a></td></tr>)}
          </tbody>
        </Table>
      )}
    />
  );
};

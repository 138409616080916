import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from 'react-bootstrap';

const TabsWithNavigationHash = ({ defaultActiveKey, children, ...props }) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const [activeKey, setActiveKey] = React.useState(hash.replace('#', '') || defaultActiveKey);

  // Hash is not set, so set it to the default tab
  React.useEffect(() => {
    if (!hash) navigate(`${pathname}#${defaultActiveKey}`, { replace: true });
  }, [defaultActiveKey, hash, navigate, pathname]);

  // Tab was changed, so update the route hash
  React.useEffect(() => {
    navigate(`${pathname}#${activeKey}`, { replace: true });
  }, [navigate, pathname, activeKey]);

  return (
    <Tabs
      {...props}
      activeKey={activeKey}
      onSelect={(k) => setActiveKey(k)}
    >
      {children}
    </Tabs>
  );
};

TabsWithNavigationHash.propTypes = {
  defaultActiveKey: PropTypes.string.isRequired,
};

export default TabsWithNavigationHash;

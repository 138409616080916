import React from 'react';
import { Deployment } from 'kubernetes-types/apps/v1';
import { Accordion, Card } from 'react-bootstrap';
import DeploymentStatusCardHeader from './DeploymentStatusCardHeader';
import ReplicasRow from './ReplicasRow';
import MetricsRow from './MetricsRow';
import { HorizontalPodAutoscaler } from 'kubernetes-types/autoscaling/v2';

const DeploymentStatusCard: React.FC<{
  deployment: Deployment;
  hpa: HorizontalPodAutoscaler;
}> = ({ deployment, hpa }) => {
  return (
    <Accordion className="mb-3" defaultActiveKey="0">
      <Card bg="dark">
        <DeploymentStatusCardHeader deployment={deployment} />
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <ReplicasRow deployment={deployment} />
            <hr />
            <MetricsRow deployment={deployment} hpa={hpa} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default DeploymentStatusCard;

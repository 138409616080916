import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import UserSearchFields from 'common/user/UserSearchFields';
import PaginationControl from 'common/PaginationControl';
import AddGroupMemberTable from 'App/AppRoutes/Groups/Detail/DetailBody/AddGroupMemberModal/AddGroupMemberTable';
import AddedMemberList from 'App/AppRoutes/Groups/Detail/DetailBody/AddGroupMemberModal/AddedMemberList';
import { useAddGroupMembersMutation } from 'api/groupSlice';
import UserPropTypes from 'propTypes/user/UserPropTypes';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import useSearchUsers from 'hooks/useSearchUsers';

const AddGroupMemberModal = ({ show, handleClose, currentMembers }) => {
  const { groupId } = useParams();
  const [selectedUserHash, setSelectedUserHash] = useState({});
  const [addGroupMembers, { isLoading, error }] = useAddGroupMembersMutation();
  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
  } = useSearchUsers();

  useEffect(() => {
    setSelectedUserHash({});
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w add-relation"
    >
      <Modal.Header className="border-0">
        <h1>{translate('addMembersToGroup')}</h1>
      </Modal.Header>
      <Modal.Body>
        {
          error && (<Error />)
        }
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <UserSearchFields
                  disabled={queryResult.isFetching}
                  searchParameters={searchParameters}
                  setSearchParameters={setSearchParameters}
                  take={take}
                  setTake={setTake}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <AddGroupMemberTable
                  stagedMemberHash={selectedUserHash}
                  currentMembers={currentMembers}
                  users={queryResult?.data?.data || []}
                  loading={isLoading}
                  addSelectedUser={user => {
                    const updatedSelectedUserHash = { ...selectedUserHash };
                    if (updatedSelectedUserHash[user.id]) delete updatedSelectedUserHash[user.id];
                    else updatedSelectedUserHash[user.id] = user;
                    setSelectedUserHash(updatedSelectedUserHash);
                  }}
                />
                <PaginationControl
                  pagesToShow={3}
                  pageCount={queryResult?.data?.meta?.last_page || 1}
                  currentPage={queryResult?.data?.meta?.current_page || 0}
                  onPageChange={setPage}
                />
              </Col>
              <Col md={6}>
                <AddedMemberList
                  addedMembers={Object.values(selectedUserHash)}
                  dimissUser={user => {
                    const updatedSelectedUserHash = { ...selectedUserHash };
                    delete updatedSelectedUserHash[user.id];
                    setSelectedUserHash(updatedSelectedUserHash);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const response = await addGroupMembers({
              groupId,
              data: Object.values(selectedUserHash).map(({ id }) => id),
            });

            if (!response.error) {
              handleClose();
            }
          }}
        >
          {translate('addMembers')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddGroupMemberModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentMembers: PropTypes.arrayOf(UserPropTypes).isRequired,
};

export default AddGroupMemberModal;

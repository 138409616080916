import React from "react";
import { useParams } from "react-router-dom";
import { translate } from "@apex/react-toolkit/lib";
import { Container, Row, Col, Tab } from "react-bootstrap";
import TabsWithNavigationHash from "common/TabsWithNavigationHash";
import { Spinner, Error } from "@apex/react-toolkit/components";
import { useGetOrgnanizationQuery } from "api/organizationSlice";
import MembersTab from "./MembersTab";
import useGetCurrentUserOrganizationRole from "hooks/useGetCurrentUserOrganizationRole";
import useCheckUserPermission from "hooks/useCheckUserPermission";
import TeamsTab from "./TeamsTab";
import ApplicationsTab from "./ApplicationsTab";
import GroupsTab from 'App/AppRoutes/Organizations/Detail/GroupsTab';

const Detail = () => {
  const { orgId } = useParams();
  const {
    data: organization,
    isLoading,
    error,
  } = useGetOrgnanizationQuery(orgId as string);
  const { isAdmin } = useGetCurrentUserOrganizationRole({
    organizationId: orgId as string,
  });
  const { isSuperUser } = useCheckUserPermission();

  if (isLoading) return <Spinner size="10x" coverViewport />;
  if (error || !organization) return <Error coverPage />;

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <img height="70rem" src={`/api/organizations/${orgId}/logo`} />
          <h1 className="ms-2 d-inline-block">{organization?.name}</h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TabsWithNavigationHash
            defaultActiveKey="members"
            transition={false}
            className="mb-3"
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey="members" title={translate("members")}>
              <MembersTab
                isSuperUser={isSuperUser}
                isAdmin={isAdmin}
                organization={organization}
              />
            </Tab>
            <Tab eventKey="groups" title={translate("groups")}>
              <GroupsTab organization={organization} />
            </Tab>
            <Tab eventKey="teams" title={translate("teams")}>
              <TeamsTab isSuperUser={isSuperUser} isAdmin={isAdmin} />
            </Tab>
            <Tab eventKey="applications" title={translate("applications")}>
              <ApplicationsTab />
            </Tab>
            <Tab eventKey="settings" title={translate("settings")}></Tab>
          </TabsWithNavigationHash>
        </Col>
      </Row>
    </Container>
  );
};

export default Detail;

import { Card, Col, Row, Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IUser from 'types/IUser';

const OrganizationRoleUsersCard: React.FC<{
  users: IUser[]
}> = ({
  users,
}) => {
    return (
      <Card bg="dark" className="mt-2">
        <Card.Header className="ps-4 pe-0">
          <Row className="w-100">
            <Col>
              <h4>{translate('users')}</h4>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table variant="dark" striped>
            <tbody>
              {
                users?.map((user) => (
                  <tr className="align-middle" key={user.id}>
                    <td>
                      {user.first_name} {user.last_name}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

export default OrganizationRoleUsersCard;

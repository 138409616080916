import { DateTime } from 'luxon';

export const formatDate = (dateString: string) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_SHORT);
};

export const formatTime = (dateString: string) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE);
};

export const formatDateTime = (dateString: string) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_SHORT);
};

export const formControlIsoToDateIn = (isoString: string) => {
  return DateTime.fromISO(isoString).toFormat('yyyy-MM-dd');
}

export const formControlDateToIsoOut = (formcontrolVal: string) => {
  const formControlSet = DateTime.fromFormat(formcontrolVal, 'yyyy-MM-dd').toUTC().toISO();
  // if this returns null, it's because we are using the value provided by API which already
  // in ISO format (i.e. hasn't been manipulated by the form) so we send the original value back
  if (formControlSet !== null) return formControlSet;

 return formcontrolVal;
}

/**
 * Take an ISO String with zulu time and get a local time formatted specifically for datetime-local formcontrols
 *
 * @param string isoString
 * @returns string
 */
export const formControlIsoToLocalIn = (isoString: string) => {
  return DateTime.fromISO(isoString).toFormat('yyyy-MM-dd__HH:mm').replace('__', 'T').replace(/\.[0-9Z]+$/, '');
};

/**
 * Take a local datetime form control value, and convert it to ISO string with zulu time
 *
 * @param string formcontrolVal
 * @returns string
 */
export const formControlLocalToIsoOut = (formcontrolVal: string) => {
  return DateTime.fromFormat(formcontrolVal.replace('T', '__'), 'yyyy-MM-dd__HH:mm').toUTC().toISO();
};

export const getAgeInGoFormat = (timeStr: string) => {
  // @ts-expect-error - sort this out later if ya want
  let timeDifference = new Date() - new Date(timeStr);

  const daysDiff = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  timeDifference -= daysDiff * (1000 * 60 * 60 * 24);

  const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
  timeDifference -= hoursDiff * (1000 * 60 * 60);

  const minutesDiff = Math.floor(timeDifference / (1000 * 60));
  timeDifference -= minutesDiff * (1000 * 60);

  const secondsDiff = Math.floor(timeDifference / 1000);

  // Prepare the output string, only show 2 figures.
  let output = '';
  if (daysDiff !== 0) {
    output += `${daysDiff}d`;
  }
  if (hoursDiff !== 0) {
    output += `${hoursDiff}h`;
  }
  if (!output.includes('d') && minutesDiff !== 0) {
    output += `${minutesDiff}m`;
  }
  if (!output.includes('h') && secondsDiff !== 0) {
    output += `${secondsDiff}s`;
  }

  return output;
}

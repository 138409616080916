import { apiSlice } from 'api/apiSlice';
import { IamPolicyFormValues } from 'common/infrastructure/iamPolicies/IamPolicyForm';
import Environment from 'types/Environment';
import IQueryParameters from 'types/IQueryParameters';
import PaginatedResult from 'types/PaginatedResult';
import IIamPolicy, { AwsIamPolicy } from 'types/infrastructure/IIamPolicy';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getIamPolicy: builder.query<IIamPolicy, string>({
      query: (iamPolicyId: string) => {
        return { url: `iam-policies/${iamPolicyId}`, method: 'get' };
      },
      providesTags: ['IamPolicies'],
    }),

    createIamPolicy: builder.mutation<IIamPolicy, IamPolicyFormValues>({
      query: (data: IIamPolicy) => ({ url: 'iam-policies', method: 'post', data }),
      invalidatesTags: () => [{ type: 'IamPolicies' }],
    }),

    listIamPolicies: builder.query<PaginatedResult<IIamPolicy>, IQueryParameters | undefined>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'iam-policies', method: 'get', queryParameters };
      },
      providesTags: ['IamPolicies'],
    }),

    editIamPolicy: builder.mutation<IIamPolicy, IamPolicyFormValues>({
      query: data => ({ url: `iam-policies/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'IamPolicies' }],
    }),

    deleteIamPolicy: builder.mutation<null, IIamPolicy>({
      query: (data: IIamPolicy) => ({ url: `iam-policies/${data.id}`, method: 'delete', data }),
      invalidatesTags: () => [{ type: 'IamPolicies' }],
    }),

    searchIamPolicies: builder.query<AwsIamPolicy[], IQueryParameters | undefined>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'search-iam-policies', method: 'get', queryParameters };
      },
      providesTags: ['IamPolicies'],
    }),

    importPolicy: builder.mutation<IIamPolicy, { policyArn: string }>({
      query: (data) => ({ url: 'iam-policies/import', method: 'post', data }),
      invalidatesTags: () => [{ type: 'IamPolicies' }],
    }),

    getPolicyablePolicies: builder.query<IIamPolicy[], { policyableType: string; policyableId: string; }>({
      query: ({ policyableType, policyableId }) => {
        return { url: `iam-policies/policyable-policies/${policyableType}/${policyableId}`, method: 'get' };
      },
      providesTags: ['IamPolicies'],
    }),

    addPolicyable: builder.mutation<IIamPolicy, { policyId: string; iam_policyable_type: string; iam_policyable_id: string; environment: Environment }>({
      query: data => ({ url: `iam-policies/${data.policyId}/add-policyable`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'IamPolicies' }],
    }),

    detachPolicyable: builder.mutation<IIamPolicy, { policyId: string; iam_policyable_type: string; iam_policyable_id: string; environment: Environment }>({
      query: data => ({ url: `iam-policies/${data.policyId}/detach-policyable`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'IamPolicies' }],
    }),
  }),
});

export const {
  useGetIamPolicyQuery,
  useListIamPoliciesQuery,
  useLazyListIamPoliciesQuery,
  useCreateIamPolicyMutation,
  useEditIamPolicyMutation,
  useDeleteIamPolicyMutation,
  useLazySearchIamPoliciesQuery,
  useImportPolicyMutation,
  useGetPolicyablePoliciesQuery,
  useAddPolicyableMutation,
  useDetachPolicyableMutation,
} = extendedApiSlice;

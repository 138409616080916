import { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import CopyButton from 'common/copyButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, Col, FormControl, Row } from 'react-bootstrap';

type CopyableLineItemProps = {
  field: string;
  value: string;
  buttonVariant?: string;
  buttonSize?: ButtonProps['size'];
  secureField: boolean;
  isReady: boolean;
};

// TODO: APEX-682 test this component further and integrate into toolkit
const CopyableLineItem = ({ field, value, buttonVariant = 'link', buttonSize = 'sm', secureField, isReady }: CopyableLineItemProps) => {
  const [fieldHidden, setFieldHidden] = useState(true);

  return (
    <Row className="mb-2">
      <Col md="2">
        <strong>{field}:</strong>
      </Col>
      <Col md="6">
        <FormControl
          readOnly
          type={secureField && fieldHidden ? 'password' : 'text'}
          style={{ backgroundColor: 'rgb(52, 55, 62)' }}
          className={`border-0 text-nowrap overflow-hidden ${isReady ? 'text-light' : 'text-warning'}`}
          value={value}
        />
      </Col>
      <Col md="2">
        <CopyButton variant={buttonVariant} size={buttonSize} value={value} field={field} />
        {
          secureField && (
            <Button variant={buttonVariant} size={buttonSize} onClick={() => setFieldHidden(!fieldHidden)}>
              {
                fieldHidden ? (
                  <>
                    <FontAwesomeIcon className="me-1" icon="eye" />
                    {translate('show')}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon className="me-1" icon="eye-slash" />
                    {translate('hide')}
                  </>
                )
              }
            </Button>
          )
        }
      </Col>
    </Row>
  );
};

export default CopyableLineItem;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import ApplicationPropTypes from 'propTypes/application/ApplicationPropTypes';
import { translate } from '@apex/react-toolkit/lib';
import { useDeleteApplicationMutation } from 'api/applicationSlice';

const RemoveApplicationModal = ({ show, handleClose, application }) => {
  const navigate = useNavigate();
  const [deleteApplication, { isLoading }] = useDeleteApplicationMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteApplication')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmDeleteApplication', { applicationName: application.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await deleteApplication(application);
            navigate('/applications');
          }}
        >
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveApplicationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  application: ApplicationPropTypes.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RemoveApplicationModal;

import apiSlice from "api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listMicroserviceJobTypes: builder.query<string[], void>({
      query: () => {
        return { url: 'microservice-jobs/types', method: 'get' };
      },
    }),
  }),
});

export const {
  useListMicroserviceJobTypesQuery,
} = extendedApiSlice;

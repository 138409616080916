import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormFeedback from 'common/FormFeedback';

const ApiForm = ({ initialValues, onCancel, onSubmit, disabled, apiErrors, submitText }) => {
  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
    // begins with a letter; allows letters, hyphens, and numbers; ends with letter or number
    route: yup
      .string()
      .trim()
      // Matches: api, api/v1
      // Doesn't match: /api, api/, api//v1, api/v1/ /api/v1, /api/v1/
      .matches(/^(?!.*[/]{2})[a-z][a-z0-9-/]*[a-z0-9]$/, translate('invalidFormat'))
      .required(translate('routeRequired')),
    http_port: yup.number().required(translate('httpPortRequired')),
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder={translate('name')}
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder={translate('description')}
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRoute">
              <Form.Label>
                <span className="me-1">{translate('route')}</span>
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      {translate('apiRouteRuleExplanation')}
                    </Tooltip>
                  )}
                >
                  <Badge pill bg="secondary">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>/</InputGroup.Text>
                <Form.Control
                  required
                  name="route"
                  type="text"
                  placeholder={translate('route')}
                  value={values.route}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.route && !errors.route}
                />
              </InputGroup>
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="route"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formHttpPort">
              <Form.Label>
                <span className="me-1">{translate('httpPort')}</span>
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip>
                      {translate('httpPortExplanation')}
                    </Tooltip>
                  )}
                >
                  <Badge pill bg="secondary">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                required
                name="http_port"
                type="number"
                placeholder={translate('httpPort')}
                value={values.http_port}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.http_port && !errors.http_port}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="http_port"
              />
            </Form.Group>
            {onCancel && (
              <Button
                variant="secondary"
                type="button"
                disabled={disabled}
                onClick={onCancel}
                className="me-2"
              >
                {translate('cancel')}
              </Button>
            )}
            {onSubmit && (
              <Button
                variant="primary"
                type="submit"
                disabled={disabled}
              >
                {submitText}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

ApiForm.defaultProps = {
  initialValues: {
    name: '',
    description: '',
    route: '',
    http_port: 80,
  },
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  disabled: false,
  submitText: translate('save'),
};

ApiForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    route: PropTypes.string,
    http_port: PropTypes.number,
  }),
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
    route: PropTypes.arrayOf(PropTypes.string),
    http_port: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
};

export default ApiForm;

import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const applicationMicroserviceJobProperties = {
  ...BaseModelPropTypes,
};

export default PropTypes.shape({
  ...applicationMicroserviceJobProperties,
});

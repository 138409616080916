import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';
import RedisInstanceMetadataPropTypes from 'propTypes/application/microservice/RedisInstanceMetadataPropTypes';

export const redisMicroserviceProperties = {
  ...BaseModelPropTypes,
  dev_instance: RedisInstanceMetadataPropTypes,
  staging_instance: RedisInstanceMetadataPropTypes,
  prod_instance: RedisInstanceMetadataPropTypes,
};

export default PropTypes.shape({
  ...redisMicroserviceProperties,
});

import React from 'react';
import { sortBy } from 'lodash';
import { translate } from '@apex/react-toolkit/lib';
import { Col, Accordion, Badge } from 'react-bootstrap';
import IPipeline from 'types/IPipeline';
import { formatDateTime } from 'helpers/datetime';
import StageAccordion from './StageAccordion';
import IStage from 'types/IStage';

const PiplineAccordion: React.FC<{ pipeline: IPipeline }> = ({ pipeline }) => {
  let statusClass = 'warning'
  if (pipeline.status === 'COMPLETE') statusClass = 'success';
  if (pipeline.status === 'FAILED') statusClass = 'danger';

  return (
    <Accordion key={pipeline.id} flush>
      <Accordion.Header>
        <Col md={1}>
          {translate('pipeline')}
        </Col>
        <Col md={3}>
          <strong className="text-info">{pipeline.type}</strong>
        </Col>
        <Col md={1}>
        {translate('stages')}: <Badge pill>{pipeline.stages.length}</Badge>&nbsp;
        </Col>
        <Col md={{ span: 3, offset: 1 }}>
        {translate('created')}: {formatDateTime(pipeline.created_at)}
        </Col>
        <Col md={3}>
          <strong className={`text-${statusClass}`}>
            {pipeline.status}
          </strong>
        </Col>
      </Accordion.Header>
      <Accordion.Body>
        {sortBy(pipeline.stages, 'index').map((stage: IStage) => <StageAccordion stage={stage} key={stage.id}/>)}
      </Accordion.Body>
    </Accordion>
  );
};

export default PiplineAccordion;

import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import {
  Tile,
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetApplicationRoleQuery } from 'api/applicationRoleSlice';

const Cards: React.FC<{
  applicationId: string;
  roleId: string;
}> = ({ applicationId, roleId }) => {
  const navigate = useNavigate();
  const { data: applicationRole, isLoading, error } = useGetApplicationRoleQuery(roleId);

  if (isLoading) return (<Spinner size="10x" coverViewport />);
  if (error || !applicationRole) return (<Error coverPage />);

  return (
    <Container>
      <Row className="mt-4">
        <Col md={10}>
          <h1>{applicationRole.name}</h1>
          <h3>
            <span className="me-2">{translate('organization')}: </span>
            <span>
              {applicationRole.organization ? applicationRole.organization.name : translate('n/a')}
            </span>
          </h3>
        </Col>
        <Col md={{ span: 1, offset: 1 }} className="text-end">
          <Button onClick={() => navigate(`/applications/${applicationId}/roles/${roleId}/edit`)}>{translate('edit')}</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{applicationRole.description}</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={3}>
          <Tile
            as={Link}
            to="./permissions"
            icon="user-unlock"
            title="Permissions"
            variant="dark"
          />
        </Col>
        <Col md={3}>
          <Tile
            as={Link}
            to="./groups"
            icon="user-group"
            title="Groups"
            variant="dark"
          />
        </Col>
      </Row>
    </Container>
  );
};

Cards.propTypes = {
  applicationId: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
};

export default Cards;

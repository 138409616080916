import { useSelector } from 'react-redux';
import { useGetOrganizationMembersQuery } from 'api/organizationSlice';
import { selectCurrentUser } from 'localState/authSlice';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';
import { IOrganizationMember } from 'types/organization/IOrganization';
import useCheckUserPermission from './useCheckUserPermission';

export type UseSearchOrganizationMembersResult = {
  isAdmin?: boolean;
  role?: string;
  isLoading: boolean;
  isFetching: boolean;
  result: RtkQueryResult<PaginatedResult<IOrganizationMember>>
};

const useGetCurrentUserOrganizationRole: (args: { organizationId: string }) => UseSearchOrganizationMembersResult
  = ({ organizationId }) => {
    const { isSuperUser } = useCheckUserPermission();
    const currentUser = useSelector(selectCurrentUser);
    const queryResult = useGetOrganizationMembersQuery({
      organizationId,
      queryParameters: {
        email: currentUser?.email,
        limit: 1,
      }
    });

    const { data, isLoading, isFetching } = queryResult;
    const role = data?.data?.[0]?.organization_role;
    let isAdmin;

    if (isSuperUser) {
      isAdmin = true;
    } else if (role) {
      isAdmin = role === 'admin';
    }

    const result: UseSearchOrganizationMembersResult = {
      isAdmin,
      role,
      isLoading,
      isFetching,
      result: queryResult,
    }

    return result;
  };

export default useGetCurrentUserOrganizationRole;

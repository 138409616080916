import { apiSlice } from 'api/apiSlice';
import IUiMicroservice, { UiMicroserviceHealthcheckPathData } from 'types/application/microservice/IUiMicroservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    deleteUiMicroservice: builder.mutation<null, IUiMicroservice>({
      query: (data: IUiMicroservice) => ({
        url: `ui-microservices/${data.id}`, method: 'delete',
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

    updateHealthcheckPath: builder.mutation<IUiMicroservice, UiMicroserviceHealthcheckPathData>({
      query: (data: UiMicroserviceHealthcheckPathData) => ({
        url: `ui-microservices/${data.id}/healthcheck-path`, method: 'put', data
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useDeleteUiMicroserviceMutation,
  useUpdateHealthcheckPathMutation,
} = extendedApiSlice;

import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const applicationActivityProperties = {
  ...BaseModelPropTypes,
};

export default PropTypes.shape({
  ...applicationActivityProperties,
});

import { apiSlice } from 'api/apiSlice';
import { ComputeBoundMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import IFilesystemMicroservice, { INewFilesystemValues } from 'types/application/microservice/IFilesystemMicroservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addFilesystemMicroservice: builder.mutation<IFilesystemMicroservice, INewFilesystemValues>({
      query: (data: INewFilesystemValues) => ({ url: 'filesystem-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    getFilesystemMicroservice: builder.query<IFilesystemMicroservice, string>({
      query: (id: string) => ({ url: `filesystem-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),
    mountFilesystem: builder.mutation<IFilesystemMicroservice, { filesystemId: string; applicationMicroserviceId: string; data: { mount_path: string } }>({
      query: ({ filesystemId, applicationMicroserviceId, data }) => ({
        url: `filesystem-microservices/${filesystemId}/mount/${applicationMicroserviceId}`,
        method: 'put',
        data,
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    unmountFilesystem: builder.mutation<IFilesystemMicroservice, { filesystemId: string; applicationMicroserviceId: string; }>({
      query: ({ filesystemId, applicationMicroserviceId }) => ({
        url: `filesystem-microservices/${filesystemId}/unmount/${applicationMicroserviceId}`,
        method: 'put',
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    getMountedMicroservices: builder.query<Array<ComputeBoundMicroservice>, string>({
      query: (microserviceId) => {
        return { url: `filesystem-microservices/${microserviceId}/mounted-microservices`, method: 'get' };
      },
    }),
  }),
});

export const {
  useAddFilesystemMicroserviceMutation,
  useGetFilesystemMicroserviceQuery,
  useMountFilesystemMutation,
  useUnmountFilesystemMutation,
  useGetMountedMicroservicesQuery,
} = extendedApiSlice;

import React, { useState } from 'react';

import OrganizationForm from 'common/organizations/OrganizationForm';
import { Alert, Button, Card, Col, Image, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import UploadButton from 'common/UploadButton';
import { IOrganizationApiErrors } from 'types/organization/IOrganization';
import { InitialValues } from 'common/organizations/OrganizationForm';

const OrganizationFormWithPhoto: React.FC<{
  initialValues?: InitialValues,
  onSubmit: (values: FormData) => void
  disabled?: boolean
  onCancel: () => void
  apiErrors: IOrganizationApiErrors
  submitText?: string
}> = ({
  initialValues,
  disabled = false,
  onCancel,
  onSubmit,
  apiErrors,
  submitText = translate('create'),
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [url, setUrl] = useState<string | ArrayBuffer | null>('');

    return (
      <Row>
        <Col md={4}>
          <OrganizationForm
            initialValues={initialValues}
            disabled={disabled}
            onCancel={onCancel}
            onSubmit={(formValues) => {
              const formData = new FormData();
              formData.append('upload', file as string | Blob);
              formData.append('name', formValues.name);
              formData.append('description', formValues.description || '');
              formData.append('domain', formValues.domain);

              if (file !== null) {
                onSubmit(formData);
              }
            }}
            apiErrors={apiErrors}
            submitText={submitText}
          />
        </Col>
        <Col md={{ span: 5, offset: 1 }} className="text-center">
          <Card bg="dark">
            <Card.Body>
              <Row>
                <Col>
                  {
                    typeof url === 'string' && url !== '' ? (
                      <Image
                        src={url}
                        width="200rem"
                      />
                    ) :
                      <Alert variant='danger'>
                        {translate('logoImageRequired')}
                      </Alert>
                  }
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <UploadButton
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e.target.files as FileList;
                      const file = files?.[0];
                      const reader = new FileReader();
                      reader.addEventListener('load', () => setUrl(reader.result || ''));
                      reader.readAsDataURL(file);
                      setFile(file);
                    }}
                    accept=".png, .jpg, .jpeg"
                    btnText={translate('uploadLogo')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="danger" onClick={() => {
                    setFile(null);
                    setUrl(null);
                  }}>
                    {translate('removeLogo')}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row >
    )
  }

export default OrganizationFormWithPhoto;

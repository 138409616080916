import React from 'react';
import { Badge, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';

import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from 'common/FormFeedback';
import { IWorkerMicroserviceFormErrors, IWorkerMicroserviceFormValues } from 'types/application/microservice/IWorkerMicroservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Admonition } from 'common/admonition';

const WorkerForm: React.FC<{
  initialValues?: IWorkerMicroserviceFormValues
  onSubmit?: (values: IWorkerMicroserviceFormValues) => void
  onCancel?: () => void
  disabled: boolean
  apiErrors?: IWorkerMicroserviceFormErrors['errors']
  submitText?: string
  nodeGroupTypes: string[]
}> = (
  {
    initialValues = {
      name: '',
      description: '',
      instance_type: 'General Purpose',
    },
    onCancel,
    onSubmit,
    disabled,
    apiErrors,
    submitText = translate('submit'),
    nodeGroupTypes,
  }) => {
    const schema = yup.object({
      name: yup.string().required(translate('nameRequired')),
      description: yup.string().nullable(),
      network_port: yup.number().integer().positive().min(1).max(65535).nullable(),
      instance_type: yup.string().nullable(),
    });

    return (
      <>
        <Admonition type="info" message={translate('workerMicroserviceExplanation')} />
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(formInput) => {
            if (onSubmit) onSubmit(formInput);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>{translate('name')}</Form.Label>
                <Form.Control
                  required
                  name="name"
                  type="text"
                  placeholder={translate('name')}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.name && !errors.name}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>{translate('description')}</Form.Label>
                <Form.Control
                  required
                  name="description"
                  type="text"
                  placeholder={translate('description')}
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.description && !errors.description}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="description"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formNetworkPort">
                <Form.Label>
                  {translate('networkPort')}
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="right"
                    rootClose
                    overlay={(
                      <Popover>
                        <Popover.Body>
                          {translate('networkPortExplanation')}
                        </Popover.Body>
                      </Popover>
                    )}
                  >
                    <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                      <FontAwesomeIcon icon="info" />
                    </Badge>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  required
                  name="network_port"
                  type="number"
                  min={1}
                  placeholder={translate('networkPort')}
                  value={values.network_port}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.network_port && !errors.network_port}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="network_port"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formInstanceType">
                <Form.Label>
                  {translate('instanceType')}
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="right"
                    rootClose
                    overlay={(
                      <Popover>
                        <Popover.Body>
                          {translate('instanceTypeExplanation')}
                        </Popover.Body>
                      </Popover>
                    )}
                  >
                    <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                      <FontAwesomeIcon icon="info" />
                    </Badge>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  name="instanceType"
                  value={values.instance_type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.instance_type && !errors.instance_type}
                >
                  <option key="noSelection" value="General Purpose" disabled>
                    General Purpose
                  </option>
                  {
                    nodeGroupTypes.map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))
                  }
                </Form.Select>
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="instance_type"
                />
              </Form.Group>
              {onCancel && (
                <Button
                  variant="secondary"
                  type="button"
                  disabled={disabled}
                  onClick={onCancel}
                  className="me-2"
                >
                  {translate('cancel')}
                </Button>
              )}
              {onSubmit && (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </>
    );
  };

export default WorkerForm;

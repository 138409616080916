import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import GroupTable from 'common/group/GroupTable';
import IGroup from 'types/group/IGroup';
import { SortBy } from 'hooks/useSearchGroups';
import { SortDirection } from 'types/ListSearchTypes';

const AddGroupTable: React.FC<{
  groups: IGroup[];
  addSelectedGroup: (group: IGroup) => void;
  currentGroups: IGroup[];
  loading: boolean;
  stagedGroupHash: {
    [key: string]: IGroup;
  };
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
}> = (props) => {
  const { groups, addSelectedGroup, currentGroups, loading, stagedGroupHash, setSortBy, sortBy, sortDirection } = props;

  const currentGroupHash = useMemo(() => {
    const groupHash: {
      [key: string]: IGroup;
    } = {};
    currentGroups.forEach(group => {
      groupHash[group.id] = group;
    });
    return groupHash;
  }, [currentGroups]);

  return (
    <GroupTable
      groups={groups}
      setSortBy={setSortBy}
      sortBy={sortBy}
      sortDirection={sortDirection}
      actionColumn={{
        title: '',
        dataIndex: 'id',
        onTd: () => ({ className: 'text-center' }),
        render: (_, group) => {
          if (currentGroupHash[group.id]) return (<span className="text-success">{translate('added')}</span>);
          if (stagedGroupHash[group.id]) return (<span className="text-warning">{translate('queued')}</span>);
          return (
            <Button className="btn-xs" onClick={() => addSelectedGroup(group)} disabled={loading}>
              <FontAwesomeIcon icon="plus" />
            </Button>
          );
        },
      }}
    />
  );
};

export default AddGroupTable;

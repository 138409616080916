import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationPermissionForm from 'common/applicationPermission/ApplicationPermissionForm';
import { useEditApplicationPermissionMutation, useGetApplicationPermissionQuery } from 'api/applicationPermissionSlice';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import Unauthorized from 'common/Unauthorized';

const Edit = () => {
  const navigate = useNavigate();
  const { applicationId, permissionId } = useParams();
  const { data: applicationPermission, isFetching, error: fetchingError } = useGetApplicationPermissionQuery(permissionId);
  const [editApplicationPermission, { isLoading, error: mutationError }] = useEditApplicationPermissionMutation();
  const { isAdministratorAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);
  const [apiErrors, setApiErrors] = useState(null);

  const handleSubmit = async (formData) => {
    const { error } = await editApplicationPermission({
      ...applicationPermission,
      ...formData,
    });
    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/applications/${applicationId}/permissions/${permissionId}`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/permissions/${permissionId}`);
  };

  if (fetchingError) {
    return (<Error />);
  }

  if (isFetching || appAuthIsFetching) {
    return (<Spinner />);
  }

  if(!isAdministratorAuthorized) return (<Unauthorized />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate('editPermission')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          {(mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>)}
          <ApplicationPermissionForm
            initialValues={{ ...applicationPermission }}
            isLoading={isLoading || isFetching}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            disabled={isLoading}
            apiErrors={apiErrors}
            submitText={translate('save')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Edit;

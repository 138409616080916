import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { formControlIsoToLocalIn, formControlLocalToIsoOut } from 'helpers/datetime';

const ApplicationActivitySearchFields = (props) => {
  const {
    disabled,
    fromDate,
    searchName,
    searchEnvironment,
    setFromDate,
    setSearchName,
    setSearchEnvironment,
    setToDate,
    take,
    toDate,
    setTake,
    names,
  } = props;

  return (
    <Row>
      <Col md={3}>
        <Form.Group>
          <Form.Label>From</Form.Label>
          <Form.Control
            onChange={e => setFromDate(
              formControlLocalToIsoOut(e.target.value),
            )}
            value={formControlIsoToLocalIn(fromDate)}
            type="datetime-local"
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>To</Form.Label>
          <Form.Control
            onChange={e => setToDate(
              formControlLocalToIsoOut(e.target.value),
            )}
            value={formControlIsoToLocalIn(toDate)}
            type="datetime-local"
          />
        </Form.Group>
      </Col>
      <Col md={2} className="text-start">
        <Form.Label>{translate('name')}</Form.Label>
        <Form.Select
          size="sm"
          onChange={e => setSearchName(e.target.value)}
          disabled={disabled}
          value={searchName}
        >
          <option value="">{translate('any')}</option>
          {names.map(name => <option value={name}>{name}</option>)}
        </Form.Select>
      </Col>
      <Col md={2} className="text-start">
        <Form.Label>{translate('environment')}</Form.Label>
        <Form.Select
          size="sm"
          onChange={e => setSearchEnvironment(e.target.value)}
          disabled={disabled}
          value={searchEnvironment}
        >
          <option value="">{translate('any')}</option>
          <option value="dev">{translate('dev')}</option>
          <option value="staging">{translate('staging')}</option>
          <option value="prod">{translate('prod')}</option>
        </Form.Select>
      </Col>
      <Col md={2}>
        <Form.Group>
          <Form.Label>{translate('resultsPerPage')}</Form.Label>
          <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

ApplicationActivitySearchFields.propTypes = {
  disabled: PropTypes.bool,
  toDate: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  searchEnvironment: PropTypes.string.isRequired,
  setSearchEnvironment: PropTypes.func.isRequired,
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  take: PropTypes.number.isRequired,
  setTake: PropTypes.func.isRequired,
};

ApplicationActivitySearchFields.defaultProps = {
  disabled: false,
};

export default ApplicationActivitySearchFields;

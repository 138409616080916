import React from 'react';

import { Formik, FormikValues } from 'formik';
import { Button, Col, Row, Form, Alert } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import cron from 'cron-validate';
import IScheduledJobCron, { IScheduledJobCronApiErrors } from 'types/application/microservice/IScheduledJobCron';

const CronForm: React.FC<{
  initialValues: IScheduledJobCron
  onSubmit: (values: IScheduledJobCron) => void
  disabled: boolean
  canActivate: boolean
  apiErrors: IScheduledJobCronApiErrors
}> = ({
  initialValues,
  onSubmit,
  disabled,
  canActivate,
  apiErrors,
}) => {
    return (
      <Formik
        validate={(values: FormikValues) => {
          // using the cron_minute property as a catchall for the errors provided by the validation library
          const errors: { cron_minute?: string[] } = {};
          const cronString =
            `${values.cron_minute} ${values.cron_hour} ${values.cron_dom} ${values.cron_dow} ${values.cron_month}`;

          const cronResult = cron(cronString);
          if (!cronResult.isValid()) {
            errors.cron_minute = cronResult.getError();
          }

          return errors;
        }}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col md={4}>
                <Form.Group>
                  <Form.Check
                    checked={values.cron_active}
                    type="switch"
                    name="cron_active"
                    label={translate('active')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled || !canActivate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={{ span: 2, offset: 1 }}>
                <Form.Group>
                  <Form.Label>{translate('minute')}</Form.Label>
                  <Form.Control
                    value={values.cron_minute}
                    type="text"
                    name="cron_minute"
                    placeholder={translate('minute')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{translate('hourUtc')}</Form.Label>
                  <Form.Control
                    value={values.cron_hour}
                    type="text"
                    name="cron_hour"
                    placeholder={translate('hourUtc')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{translate('day')}</Form.Label>
                  <Form.Control
                    value={values.cron_dom}
                    type="text"
                    name="cron_dom"
                    placeholder={translate('day')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{translate('month')}</Form.Label>
                  <Form.Control
                    value={values.cron_month}
                    type="text"
                    name="cron_month"
                    placeholder={translate('month')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{translate('dayOfTheWeek')}</Form.Label>
                  <Form.Control
                    value={values.cron_dow}
                    type="text"
                    name="cron_dow"
                    placeholder={translate('dayOfTheWeek')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            </Row>
            {
              (errors.cron_minute && errors.cron_minute.length > 0) && (
                <Col>
                  <Alert variant='danger' className="text-center">
                    {errors.cron_minute}
                  </Alert>
                </Col>
              )
            }
            {
              apiErrors && (
                <Col>
                  <Alert variant='danger' className="text-center">
                    {
                      Object.keys(apiErrors.errors)
                        .filter(key => key !== 'message')
                        .map(key => {
                          return apiErrors.errors[key as keyof IScheduledJobCronApiErrors["errors"]].join(',');
                        }
                        )
                    }
                  </Alert>
                </Col>
              )
            }
            <Row>
              <Col className="text-start">
                <a href="https://en.wikipedia.org/wiki/Cron" target="_blank">
                  Cron https://en.wikipedia.org/wiki/Cron
                </a>
              </Col>
              <Col className="text-end">
                <Button
                  variant="secondary"
                  type="button"
                  disabled={disabled}
                  className="me-1"
                >
                  {translate('cancel')}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    // disable if errors are present or if none of the values have changed
                    !!errors?.cron_minute?.length
                    || (
                      !Object.keys(values)
                        .reduce((hasChanged: boolean, currentProperty): boolean => {
                          return hasChanged
                            || (values[currentProperty as keyof IScheduledJobCron] !== initialValues[currentProperty as keyof IScheduledJobCron])
                        }, false)
                    )
                  }
                >
                  {translate('save')}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default CronForm;

import { addToast } from 'localState/toastSlice';
import { useDispatch } from 'react-redux';
import { Variant } from 'react-bootstrap/types';

export declare type ToastMessage = {
  bg: Variant;
  title: string;
  message: string;
  autohide?: boolean;
}

const useToast = () => {
  const dispatch = useDispatch();
  const toast = (msg: ToastMessage) => {
    dispatch(addToast(msg));
  };
  return toast;
};

export default useToast;

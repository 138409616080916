import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import UserPropTypes from 'propTypes/user/UserPropTypes';

const AddedMaintainerList = ({ addedMaintainers, dismissMaintainer }) => {
  return (
    <Card style={{ minHeight: '100%' }}>
      <Card.Body variant="secondary">
        {
          addedMaintainers.map(maintainer => (
            <Alert
              key={maintainer.id}
              dismissible
              className="alert-fit-content ms-3"
              onClose={() => dismissMaintainer(maintainer)}
            >
              {maintainer.first_name} {maintainer.last_name}
            </Alert>
          ))
        }
      </Card.Body>
    </Card>
  );
};

AddedMaintainerList.propTypes = {
  addedMaintainers: PropTypes.arrayOf(UserPropTypes).isRequired,
  dismissMaintainer: PropTypes.func.isRequired,
};

export default AddedMaintainerList;

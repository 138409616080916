import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';

import { translate } from '@apex/react-toolkit/lib';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormFeedback from 'common/FormFeedback';

import IFilesystemMicroservice, { FilesystemMountErrors } from 'types/application/microservice/IFilesystemMicroservice';
import InfoPopover from 'common/InfoPopover';

type FilesystemMountFormValues = {
  mount_path: string;
}

const FilesystemMountForm: React.FC<{
  filesystem: IFilesystemMicroservice;
  disabled: boolean;
  submitBtnText?: string;
  onSubmit: (values: FilesystemMountFormValues) => void;
  onCancel?: () => void;
  cancelText?: string;
  apiErrors?: FilesystemMountErrors;
}> = ({
  filesystem,
  disabled,
  onSubmit,
  submitBtnText = translate('mountFilesystem'),
  onCancel,
  cancelText = translate('cancel'),
  apiErrors,
}) => {

    const schema = yup.object({
      // @TODO APEX_1391 validate mount path after testing
      // in Dev
      mount_path: yup.string().required()
    });


    return (
      <Formik
        validationSchema={schema}
        initialValues={filesystem}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          isValid,
          errors,
          touched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col>
                <Form.Group>
                  <Form.Label>{translate('mountPath')} <InfoPopover infoText={translate('mountingWillTriggerRedeploy')} /></Form.Label>
                  <Form.Control
                    value={values.mount_path}
                    type="text"
                    name="mount_path"
                    placeholder={translate('mountPath')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    readOnly={disabled}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="mount_path"
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Button
                    type="submit"
                    variant="secondary"
                    disabled={disabled || !isValid}
                    className="me-2 ms-2"
                  >
                    {submitBtnText}
                  </Button>
                  {onCancel && (
                    <Button
                      type="button"
                      onClick={onCancel}
                      variant="danger"
                    >
                      {cancelText}
                    </Button>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default FilesystemMountForm;

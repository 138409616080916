import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import useToast from 'hooks/useToast';
import ApexHelmChartVersionForm from 'common/infrastructure/apexHelmChartVersions/ApexHelmChartVersionForm';
import { useEditApexHelmChartVersionMutation } from 'api/apexHelmChartVersionSlice';
import IApexHelmChartVersion, { IApexHelmChartVersionApiErrors } from 'types/infrastructure/IApexHelmChartVersion';

const EditApexHelmChartVersion: React.FC<{
  show: boolean;
  handleClose: () => void;
  apexHelmChartVersion: IApexHelmChartVersion;
}> = ({
  handleClose,
  show,
  apexHelmChartVersion,
}) => {
    const [editApexHelmChartVersion, { isLoading }] = useEditApexHelmChartVersionMutation();
    const [apiErrors, setApiErrors] = useState<IApexHelmChartVersionApiErrors | undefined>(undefined);

    const toast = useToast();
    const disabled = isLoading;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-0">
          <h2>{translate('editApexHelmChartVersion')}</h2>
        </Modal.Header>
        <Modal.Body className="confirm">
          <ApexHelmChartVersionForm
            initialValues={apexHelmChartVersion}
            submitText={translate('update')}
            onSubmit={async (values) => {
              const result = await editApexHelmChartVersion(values);

              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else if ('data' in result) {
                toast({
                  bg: 'success',
                  title: translate('success'),
                  message: translate('updatedSuccessfully'),
                  autohide: true,
                });

                handleClose();
              }
            }}
            onCancel={handleClose}
            apiErrors={apiErrors}
            disabled={disabled}
          />
        </Modal.Body>
      </Modal>
    );
  };

export default EditApexHelmChartVersion;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FC, useEffect, useState } from 'react';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { IBaseApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { IContainerLiveViewData } from 'types/application/microservice/IContainerLiveViewData';
import { getAgeInGoFormat } from 'helpers/datetime';
import { ContainerStatus } from 'kubernetes-types/core/v1';
import { slugify } from 'helpers/slugify';

const ContainerLiveView: FC<{ microservice: IBaseApplicationMicroservice }> = ({ microservice }) => {
  const [liveViewOpen, setLiveViewOpen] = useState(false);
  const [pods, setPods] = useState<IContainerLiveViewData[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (microservice.application) {
      const sse = new EventSource(`${window.location.origin}/proxy/pods/${microservice.application?.namespace}/${slugify(microservice.name)}/stream`, { withCredentials: true });
      sse.onmessage = e => {
        setPods(JSON.parse(e.data));
        setLoading(false);
      }
      sse.onerror = () => {
        setError(true);
        setLoading(false);
        sse.close();
      }
      return () => {
        sse.close();
      };
    } else {
      setError(true);
      setLoading(false);
    }
  }, []);

  return (
    <Col>
      <strong>Live View</strong>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="right"
        rootClose
        overlay={(
          <Popover>
            <Popover.Body>
              {translate('containerLiveViewExplanation')}
            </Popover.Body>
          </Popover>
        )}
      >
        <
          span className="ms-2"><FontAwesomeIcon icon="info-circle" /></span>
      </OverlayTrigger>
      <FontAwesomeIcon
        icon={liveViewOpen ? 'chevron-up' : 'chevron-down'}
        className="ms-2"
        style={{ cursor: 'pointer' }}
        onClick={() => setLiveViewOpen(!liveViewOpen)}
      />
      {
        liveViewOpen && (
          <Card bg="dark" className="mt-2">
            <Card.Body>
              {
                loading && (<Spinner />)
              }
              {
                error && (
                  <Row>
                    <Col>
                      <p className="my-2 text-center text-danger">
                        <FontAwesomeIcon icon="engine-warning" />
                        <span className="ms-2">{translate('liveContainerDataError')}</span>
                      </p>
                    </Col>
                  </Row>
                )
              }
              {
                !loading && !error && pods && pods.length !== 0 && (
                  <Row className="mb-2">
                    <Col md={5}>Container</Col>
                    <Col>{translate('version')}</Col>
                    <Col>{translate('ready')}</Col>
                    <Col>{translate('status')}</Col>
                    <Col>{translate('restarts')}</Col>
                    <Col>{translate('age')}</Col>
                  </Row>
                )
              }
              {
                !loading && !error && pods && pods.length === 0 && (
                  <Row>
                    <Col>
                      <p className="my-2 text-center text-warning">{translate('noRunningContainersFound')}</p>
                      <p className="my-2 text-center text-warning">{translate('reachOutToSupport')}</p>
                    </Col>
                  </Row>

                )
              }
              {
                pods && pods.map((pod: IContainerLiveViewData) => {
                  if ('containerStatuses' in pod.status &&
                    (typeof pod.status.containerStatuses !== 'undefined')
                  ) {
                    return (
                      <Row>
                        <Col md={5}>{pod.metadata.name}</Col>
                        <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{pod.status.containerStatuses[0].image.split(':')[1]}</Col>
                        <Col>{pod.status.containerStatuses.reduce((acc: number, curr: ContainerStatus) => curr.ready ? acc += 1 : acc, 0)} / {pod.status.containerStatuses.length}</Col>
                        <Col>{pod.metadata.deletionTimestamp ? translate('terminating') : pod.status.phase}</Col>
                        <Col>{pod.status.containerStatuses[0].restartCount}</Col>
                        <Col>{getAgeInGoFormat(pod.status.startTime as string)}</Col>
                      </Row>
                    )
                  }
                })
              }
            </Card.Body>
          </Card>
        )
      }
    </Col>
  );
};

export default ContainerLiveView;

import { useState, useEffect } from 'react';
import { useLazyListGroupsQuery } from 'api/groupSlice';
import IQueryParameters from 'types/IQueryParameters';
import { SortDirection } from 'types/ListSearchTypes';
import PaginatedResult from 'types/PaginatedResult';
import IGroup from 'types/group/IGroup';
import RtkQueryResult from 'types/RtkQueryResult';

export type GroupRelationships = 'organization';
export type SortBy = 'name' | 'description';

const useSearchGroups: (args?: { relationships: [GroupRelationships] }) => {
  setTake: (take: number) => void;
  take: number;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  setSearchObj: (searchObj: IQueryParameters | null) => void;
  page: number;
  setPage: (page: number) => void;
  result: RtkQueryResult<PaginatedResult<IGroup>>;
} = (args) => {
  const relationships = args?.relationships || '';
  const [take, setTake] = useState<number>(10);
  const [sortBy, setSortBy] = useState<SortBy>('name');
  const [page, setPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [searchObj, setSearchObj] = useState<IQueryParameters | null>(null);
  const [getGroups, result] = useLazyListGroupsQuery();

  const queryParameters: IQueryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };
  const searchBy = searchObj?.option;
  const searchVal = searchObj?.value;

  if (typeof searchBy === 'string' && searchVal) queryParameters[searchBy] = searchVal;

  useEffect(() => {
    if (typeof searchBy === 'string' && searchVal) queryParameters[searchBy] = searchVal;

    getGroups(queryParameters);
  }, [getGroups, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getGroups({
      ...queryParameters,
      relationships,
    });
  }, [searchObj, take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    setSearchObj,
    page,
    setPage,
    result,
  };
};

export default useSearchGroups;

import React from 'react';
import PaginationControl from 'common/PaginationControl';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import IGroup from 'types/group/IGroup';
import { SortBy } from 'hooks/useSearchOrganizationGroups';
import { SortDirection } from 'types/ListSearchTypes';

const OrganizationGroupTable: React.FC<{
  groups: IGroup[];
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sortBy: string
  setSortBy: (sortBy: SortBy) => void
  sortDirection?: SortDirection
  setSortDirection?: (sortDirection: SortDirection) => void
}> = ({ groups, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first: string, { id }: IGroup) => (<Link to={`groups/${id}`}>{first}</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
  ];

  return (
    <>
      <InteractiveTable
        dataId="id"
        data={groups}
        columns={columns}
      />
      <PaginationControl
        pagesToShow={3}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default OrganizationGroupTable;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import { ApplicationMicroserviceS3PropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import Configuration from 'App/AppRoutes/Applications/Application/Microservices/Detail/S3Detail/S3AccessManagement/Configuration';
import S3AccessManagement from './S3AccessManagement';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import MicroservicePipelineMonitor from 'common/applicationMicroservice/MicroservicePipelineMonitor';

const S3Detail = ({ microservice }) => {
  const { applicationId } = useParams();
  const { isDeveloperAuthorized, isSuperUser } = useIsApplicationMaintainer(applicationId);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>{microservice.name}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('deployedVersions')}:</strong>
              <ul>
                <li>{translate('dev')}: <pre className="d-inline">{microservice.microserviceable.dev_location || translate('none')}</pre></li>
                <li>{translate('staging')}: <pre className="d-inline">{microservice.microserviceable.staging_location || translate('none')}</pre></li>
                <li>{translate('prod')}: <pre className="d-inline">{microservice.microserviceable.prod_location || translate('none')}</pre></li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <Configuration isDeveloperAuthorized={isDeveloperAuthorized} isSuperUserAuthorized={isSuperUser} microservice={microservice} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} canPromote={isDeveloperAuthorized} />
          </Tab>
          {
            isDeveloperAuthorized && (
              <Tab eventKey="accessManagement" title={translate('accessManagement')}>
                <S3AccessManagement microserviceId={microservice.id} isDeveloperAuthorized={isDeveloperAuthorized} />
              </Tab>
            )
          }
          {
            isSuperUser && (
              <Tab eventKey="pipelines" title={translate('pipelines')}>
                <MicroservicePipelineMonitor microservice={microservice} />
              </Tab>
            )
          }
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

S3Detail.propTypes = {
  microservice: ApplicationMicroserviceS3PropTypes.isRequired,
};

export default S3Detail;

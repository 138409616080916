import React from 'react';
import {
  Pagination,
} from 'react-bootstrap';

const PaginationControl: React.FC<{
  pagesToShow: number;
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}> = ({ pagesToShow, pageCount, currentPage, onPageChange }) => {
  const pageItems = [];

  let start = (currentPage - pagesToShow);
  if (start < 1) start = 1;
  let end = (currentPage + pagesToShow);
  if (end > pageCount) end = pageCount;

  if (currentPage >= pagesToShow) pageItems.push(<Pagination.Ellipsis key="pagination-first" disabled />);
  for (let i = start; i <= end; i++) {
    pageItems.push(
      <Pagination.Item
        key={`pagination-${i}`}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  }
  if (currentPage <= pageCount - pagesToShow) pageItems.push(<Pagination.Ellipsis key="pagination-last" disabled />);
  return (
    <Pagination size="sm">
      <Pagination.First
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
      />
      {pageItems}
      <Pagination.Last
        onClick={() => onPageChange(pageCount)}
        disabled={currentPage === pageCount}
      />
    </Pagination>
  );
};

export default PaginationControl;

import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import useToast from 'hooks/useToast';

// TODO: APEX-682 test this component further and integrate into toolkit
const CopyButton = ({ variant, size, field, value }) => {
  const toast = useToast();

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(value);
    toast({
      bg: 'success',
      title: translate('success'),
      message: translate('copiedSuccessfully', { field }),
    });
  };

  return (
    <Button
      className="text-decoration-none text-primary"
      variant={variant}
      size={size}
      onClick={() => copyToClipboard()}
    >
      <FontAwesomeIcon className="me-1" icon="copy" />
      {translate('copy')}
    </Button>
  );
};

CopyButton.defaultProps = {
  variant: 'link',
  size: 'sm',
};

CopyButton.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
};

export default CopyButton;

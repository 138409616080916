import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetApplicationQuery } from 'api/applicationSlice';
import MicroservicesBody from 'App/AppRoutes/Applications/Application/Microservices/MicroservicesBody';

const Microservices: React.FC = () => {
  const { applicationId } = useParams();
  const { data: application, isFetching, error } = useGetApplicationQuery(applicationId as string);

  if (isFetching) return (<Spinner size="10x" coverViewport />);
  if (error || !application ) return (<Error coverPage />);

  return (
    <Container>
      <Row className="mt-4">
        <Col md={10}>
          <h1>{application.name}</h1>
        </Col>
      </Row>
      <MicroservicesBody />
    </Container>
  );
};

export default Microservices;

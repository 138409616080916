import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { IApplicationMicroserviceDatabase, IDmsReplicationConfig } from 'types/application/microservice/IDatabaseMicroservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreateReplicationConfigMutation, useDeleteReplicationConfigMutation, useStartReplicationMutation, useStopReplicationMutation, } from 'api/dbMicroserviceSlice';
import useToast from 'hooks/useToast';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';

const ReplicatonActionDropdown: React.FC<{
  databaseMicroservice: IApplicationMicroserviceDatabase;
  replicationCofig: IDmsReplicationConfig | null;
  additionalDropdownItems?: React.ReactElement[];
}> = ({ databaseMicroservice, replicationCofig, additionalDropdownItems }) => {
  const toast = useToast();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [shoeConfirmStopModal, setShowConfirmStopModal] = useState<boolean>(false);
  const [createReplicationConfig] = useCreateReplicationConfigMutation();
  const [deleteReplicationConfig, { isLoading: deleteIsLoading }] = useDeleteReplicationConfigMutation();
  const [startReplication] = useStartReplicationMutation();
  const [stopReplication] = useStopReplicationMutation();

  return (
    <DropdownButton variant="secondary" title={translate('actions')} className="d-inline mx-2">
      {replicationCofig ? (
        <>
          <ConfirmationModal
            show={showConfirmDeleteModal}
            title={translate('confirmDeletion')}
            confirmationText={translate('confirmDeleteReplicationConfig')}
            disabled={deleteIsLoading}
            handleClose={() => setShowConfirmDeleteModal(false)}
            onConfirm={async () => await deleteReplicationConfig(databaseMicroservice.application_microserviceable_id)}
            successMessage={translate('replicationQueuedForDeletion')}
          />

          <ConfirmationModal
            show={shoeConfirmStopModal}
            title={translate('confirmStop')}
            confirmationText={translate('confirmStopReplication')}
            disabled={deleteIsLoading}
            handleClose={() => setShowConfirmStopModal(false)}
            onConfirm={async () => await stopReplication(databaseMicroservice.application_microserviceable_id)}
            successMessage={translate('replicationQueuedForStop')}
          />

          <Dropdown.Item onClick={() => setShowConfirmDeleteModal(true)}>
            <FontAwesomeIcon icon="trash" /> {translate('deleteReplicationConfig')}
          </Dropdown.Item>
          <Dropdown.Item onClick={async () => {
            try {
              await startReplication(databaseMicroservice.application_microserviceable_id);

              toast({
                bg: 'success',
                title: translate('success'),
                message: translate('replicationQueuedForStart'),
                autohide: true,
              });
            } catch (e) {
              toast({
                bg: 'danger',
                title: translate('error'),
                message: translate('anUnexpectedErrorOccurred'),
                autohide: false,
              });
            }

          }}>
            <FontAwesomeIcon icon="play-circle" /> {translate('startReplicationConfig')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowConfirmStopModal(true)}>
            <FontAwesomeIcon icon="stop-circle" /> {translate('stopReplicationConfig')}
          </Dropdown.Item>
        </>
      ) : (
        <>
          <Dropdown.Item onClick={async () => {
            try {
              await createReplicationConfig(databaseMicroservice.application_microserviceable_id);

              toast({
                bg: 'success',
                title: translate('success'),
                message: translate('replicationQueuedForCreation'),
                autohide: true,
              });

            } catch (e) {
              toast({
                bg: 'danger',
                title: translate('error'),
                message: translate('anUnexpectedErrorOccurred'),
                autohide: false,
              });
            }

          }}>
            <FontAwesomeIcon icon="plus-circle" /> {translate('createReplicationConfig')}
          </Dropdown.Item>
        </>
      )}
      {typeof additionalDropdownItems !== 'undefined' && additionalDropdownItems}
    </DropdownButton>
  );
};

export default ReplicatonActionDropdown;

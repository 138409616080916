import PropTypes from 'prop-types';

export default PropTypes.shape({
  password: PropTypes.string.isRequired,
  primaryEndpoint: PropTypes.string,
  primaryPort: PropTypes.number,
  readerEndpoint: PropTypes.string,
  readerPort: PropTypes.number,
  status: PropTypes.string.isRequired,
});

import { formatDate } from 'helpers/datetime';
import React from 'react';

const RunningOutOfTimeSpan: React.FC<{
  dateString: string;
  warnDaysBefore: number;
  renderDateString?: (dateString: string) => React.ReactNode
}> = ({
  dateString,
  warnDaysBefore,
  renderDateString = (dateString) => formatDate(dateString)
}) => {
    const today = new Date();
    const warnDaysFromNow = new Date();
    warnDaysFromNow.setDate(today.getDate() + warnDaysBefore);
    const endDate = new Date(dateString);

    let bgClass = 'text-success';
    if (warnDaysFromNow > endDate) bgClass = 'text-warning';
    if (today > endDate) bgClass = 'text-danger';

    return (
      <span className={bgClass}>
        {renderDateString(dateString)}
      </span>
    );
  };

export default RunningOutOfTimeSpan;

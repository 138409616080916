import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({

    addApplicationRole: builder.mutation({
      query: data => ({ url: 'application-roles', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationRoles' }],
    }),

    getApplicationRole: builder.query({
      query: id => ({ url: `application-roles/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationRoles', id }],
    }),

    editApplicationRole: builder.mutation({
      query: data => ({ url: `application-roles/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationRoles', id }],
    }),

    deleteApplicationRole: builder.mutation({
      query: data => ({ url: `application-roles/${data.id}`, method: 'delete', data }),
      invalidatesTags: () => ['ApplicationRoles', 'OrganizationApplications'],
    }),

    addApplicationRolePermission: builder.mutation({
      query: ({ roleId, data }) => ({ url: `application-roles/${roleId}/permissions`, method: 'post', data }),
      invalidatesTags: (result, error, roleId) => [{ type: 'ApplicationRoles', roleId }],
    }),

    getApplicationRolePermissions: builder.query({
      query: ({ roleId, queryParameters }) => ({ url: `application-roles/${roleId}/permissions`, method: 'get', queryParameters }),
      providesTags: (result, error, roleId) => [{ type: 'ApplicationRoles', roleId }],
    }),

    deleteApplicationRolePermission: builder.mutation({
      query: ({ roleId, permissionId }) => ({ url: `application-roles/${roleId}/permissions/${permissionId}`, method: 'delete' }),
      invalidatesTags: (result, error, roleId) => [{ type: 'ApplicationRoles', roleId }],
    }),

    addApplicationRoleGroups: builder.mutation({
      query: ({ roleId, data }) => ({ url: `application-roles/${roleId}/groups`, method: 'post', data }),
      invalidatesTags: (result, error, roleId) => [{ type: 'ApplicationRoles', roleId }],
    }),

    getApplicationRoleGroups: builder.query({
      query: ({ roleId, queryParameters }) => ({ url: `application-roles/${roleId}/groups`, method: 'get', queryParameters }),
      providesTags: (result, error, roleId) => [{ type: 'ApplicationRoles', roleId }],
    }),

    removeApplicationRoleGroup: builder.mutation({
      query: ({ roleId, groupId }) => ({ url: `application-roles/${roleId}/groups/${groupId}`, method: 'delete' }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationRoles', id }],
    }),
  }),
});

export const {
  useAddApplicationRoleMutation,
  useEditApplicationRoleMutation,
  useGetApplicationRoleQuery,
  useDeleteApplicationRoleMutation,
  useGetApplicationRolePermissionsQuery,
  useAddApplicationRolePermissionMutation,
  useGetApplicationRoleGroupsQuery,
  useLazyGetApplicationRoleGroupsQuery,
  useLazyGetApplicationRolePermissionsQuery,
  useDeleteApplicationRolePermissionMutation,
  useAddApplicationRoleGroupsMutation,
  useRemoveApplicationRoleGroupMutation,
} = extendedApiSlice;

import React from 'react';
import PaginationControl from 'common/PaginationControl';
import IOrganization from 'types/organization/IOrganization';
import OrganizationTable from 'common/organizations/OrganizationTable'
import { SortDirection } from 'types/ListSearchTypes';
import { SortBy } from 'hooks/useSearchOrganizations';

const OrganizationPaginatedTable: React.FC<{
  organizations: IOrganization[]
  sortBy?: string
  setSortBy?: (sortBy: SortBy) => void
  sortDirection?: SortDirection
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
}> = ({ organizations, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection }) => (
  <>
    <OrganizationTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      organizations={organizations}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

export default OrganizationPaginatedTable;

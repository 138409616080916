import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const TeamsTabRedirect: React.FC = () => {
  const { orgId } = useParams();

  return (
    <Navigate to={`/organizations/${orgId}#teams`} />
  );
}

export default TeamsTabRedirect;

import React from 'react';

import { translate } from "@apex/react-toolkit/lib";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form, Row } from "react-bootstrap";
import { Error, Spinner } from "@apex/react-toolkit/components";
import FormFeedback from "common/FormFeedback";
import { useGetApexHelmChartNamesQuery } from 'api/apexHelmChartVersionSlice';
import { IApexHelmChartVersionApiErrors } from 'types/infrastructure/IApexHelmChartVersion';
import { formControlDateToIsoOut, formControlIsoToDateIn } from 'helpers/datetime';

export interface InitialValues {
  id?: string,
  chart_name: string;
  version: string;
  eol_date?: string;
  deprecation_date?: string;
}

export type ApexHelmChartVersionFormValues = InitialValues;

const defaultInitialValues: InitialValues = {
  chart_name: '',
  version: '',
  eol_date: '',
  deprecation_date: '',
};

const ApexHelmChartVersionForm: React.FC<{
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => void;
  disabled?: boolean;
  onCancel: () => void;
  apiErrors?: IApexHelmChartVersionApiErrors;
  submitText?: string;
}> = ({
  initialValues = defaultInitialValues,
  disabled = false,
  onCancel,
  onSubmit,
  apiErrors,
  submitText = translate("create"),
}) => {
    const { data: chartNames, isLoading, error } = useGetApexHelmChartNamesQuery(undefined);

    const schema = yup.object({
      chart_name: yup.string().required(translate("valueRequired")),
      version: yup.string().required(translate("valueRequired")),
      eol_date: yup.date().nullable().default(null),
      deprecation_date: yup.date().nullable().default(null),
    });

    if (isLoading) return (<Spinner />);
    if (error) return (<Error error={error} />);

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit({
            ...formInput,
            eol_date: (!formInput.eol_date || formInput.eol_date === '') ? '' : formControlDateToIsoOut(formInput.eol_date) as string,
            deprecation_date: (!formInput.deprecation_date || formInput.deprecation_date === '') ? '' : formControlDateToIsoOut(formInput.deprecation_date) as string,
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group className="mb-3" controlId="formChartName">
                <Form.Label>{translate("chartName")}</Form.Label>
                <Form.Select
                  required
                  name="chart_name"
                  value={values.chart_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled || !!values.id}
                >
                  <option value="" disabled>
                    {translate("chartName")}
                  </option>
                  {chartNames?.map((chartName: string) => (
                    <option key={chartName} value={chartName}>
                      {chartName}
                    </option>
                  ))}
                </Form.Select>
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="chart_name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formVersion">
                <Form.Label>{translate("version")}</Form.Label>
                <Form.Control
                  required
                  name="version"
                  type="text"
                  placeholder={translate("version")}
                  value={values.version}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={!!values.id}
                  disabled={disabled || !!values.id}
                  isValid={touched.version && !errors.version}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="version"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDeprecationDate">
                <Form.Label>{translate("deprecationDate")}</Form.Label>
                <Form.Control
                  required
                  name="deprecation_date"
                  type="date"
                  value={values.deprecation_date ? formControlIsoToDateIn(values.deprecation_date) : ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.deprecation_date && !errors.deprecation_date}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="deprecation_date"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formEolDate">
                <Form.Label>{translate("eolDate")}</Form.Label>
                <Form.Control
                  required
                  name="eol_date"
                  type="date"
                  value={values.eol_date ? formControlIsoToDateIn(values.eol_date) : ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.eol_date && !errors.eol_date}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="eol_date"
                />
              </Form.Group>
              <Form.Group>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  className="me-2"
                  onClick={onCancel}
                >
                  {translate("cancel")}
                </Button>
                <Button type="submit" variant="primary" disabled={disabled}>
                  {submitText}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default ApexHelmChartVersionForm;

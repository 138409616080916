import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Error } from '@apex/react-toolkit/components';
import OrganizationApplicationRoleForm from 'common/organizations/OrganizationApplicationRoleForm';
import { useCreateOrganizationApplicationRoleMutation } from 'api/organizationSlice';
import { useState } from 'react';
import IApplicationRole, { IApplicationRoleApiErrors, IApplicationRoleValidationErrors } from 'types/application/IApplicationRole';

const CreateOrganizationApplicationRole: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const { applicationId, orgId } = useParams();
  const [apiErrors, setApiErrors] = useState<IApplicationRoleValidationErrors | null>(null);
  if (!applicationId || !orgId) return <Error message="Organization ID or Application ID not found." />;

  const [createOrganizationApplicationRole, { isLoading }] = useCreateOrganizationApplicationRoleMutation();

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('createRole')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <OrganizationApplicationRoleForm
            onSubmit={async (data) => {
              const result: { data: IApplicationRole } | { error: IApplicationRoleApiErrors } = await createOrganizationApplicationRole({ organizationId: orgId, applicationId, data });

              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else {
                navigate(`/organizations/${orgId}#applications`);
              }
            }}
            onCancel={() => navigate(`/organizations/${orgId}#applications`)}
            apiErrors={apiErrors}
            disabled={isLoading}
            organizationId={orgId}
            applicationId={applicationId}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateOrganizationApplicationRole;

import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import RoleConfigForm, { RoleConfigFormValues } from 'common/infrastructure/roleConfigs/RoleConfigForm';
import { useCreateRoleConfigMutationMutation } from 'api/roleConfigsSlice';
import IRoleConfig, { IRoleConfigApiErrors, IRoleConfigValidationErrors } from 'types/infrastructure/IRoleConfig';
import { useNavigate } from 'react-router-dom';

const NewKubernetesRoleConfig: FC = () => {
  const navigate = useNavigate();
  const [createRoleConfig, { isLoading }] = useCreateRoleConfigMutationMutation();
  const [apiErrors, setApiErrors] = useState<IRoleConfigValidationErrors | null>(null);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('createKubernetesRoleConfig')}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <RoleConfigForm
            disabled={isLoading}
            onCancel={() => null}
            onSubmit={async (formData: RoleConfigFormValues) => {
              const result: { data: IRoleConfig } | { error: IRoleConfigApiErrors } = await createRoleConfig(formData);

              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else {
                navigate(`../kubernetes-roles/${result.data.id}`);
              }
            }}
            apiErrors={apiErrors}
            submitText='Create'
            icon='save'
          />
        </Col>
      </Row>
    </Container>
  );
};

export default NewKubernetesRoleConfig;

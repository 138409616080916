import React, { useEffect, useState } from 'react';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Environment from 'types/Environment';
import { IApplicationMicroserviceComputeBound } from 'types/application/microservice/IApplicationMicroservice';
import { useLazyListK8sDeploymentsQuery } from 'api/k8s/deployments';
import { useLazyListK8sHpasQuery } from 'api/k8s/hpas';
import DeploymentStatusCard from './DeploymentStatusCard';

const ComputeBoundStatusView: React.FC<{ microservice: IApplicationMicroserviceComputeBound }> = ({ microservice }) => {
  const [env, setEnv] = useState<Environment>('dev');

  const [getK8sDeployments, { data: deploymentsData, isLoading: deploymentsIsLoading, isFetching: deploymentsIsFetching, error: deploymentError }] = useLazyListK8sDeploymentsQuery();
  const [getK8sHpas, { data: hpasData, isLoading: hpasIsLoading, isFetching: hpasIsFetching, error: hpasError }] = useLazyListK8sHpasQuery();

  useEffect(() => {
    getK8sDeployments({
      namespace: microservice.namespace, queryParameters: {
        microserviceableId: microservice.application_microserviceable_id,
        env,
      }
    });

    getK8sHpas({
      namespace: microservice.namespace, queryParameters: {
        microserviceableId: microservice.application_microserviceable_id,
        env,
      }
    });
  }, [env, microservice]);


  if (deploymentsIsLoading || !deploymentsData || hpasIsLoading || !hpasData) return (<Spinner />);
  if (deploymentError || hpasError) return (<Error />);

  const deployment = deploymentsData[0];
  const hpa = hpasData[0];

  let content = (<Error />);
  if (!deployment) content = (<Error error={{ message: translate('noDeploymentsFound') }} />);
  if (!hpa) content = (<Error error={{ message: translate('noHpasFound') }} />);
  if (deployment && hpa) content = (<DeploymentStatusCard deployment={deployment} hpa={hpa} />);

  return (
    <Container>
      <Row className="mb-3">
        <Col md={3}>
          <Form.Select
            onChange={e => setEnv(e.target.value as Environment)}
            disabled={deploymentsIsFetching || hpasIsFetching}
          >
            <option value="dev">{translate('dev')}</option>
            <option value="staging">{translate('staging')}</option>
            <option value="prod">{translate('prod')}</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default ComputeBoundStatusView;

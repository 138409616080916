import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';
import ApiMicroservicePropTypes from './ApiMicroservicePropTypes';
import AuthMicroservicePropTypes from './AuthMicroservicePropTypes';
import DatabaseMicroservicePropTypes from './DatabaseMicroservicePropTypes';
import LambdaMicroservicePropTypes from './LambdaMicroservicePropTypes';
import RedisMicroservicePropTypes from './RedisMicroservicePropTypes';
import S3MicroservicePropTypes from './S3MicroservicePropTypes';
import IScheduledJobMicroservice from 'types/application/microservice/IScheduledJobMicroservice';

export const applicationMicroserviceProperties = {
  ...BaseModelPropTypes,
  application_microserviceable_id: PropTypes.string.isRequired,
  application_microserviceable_type: PropTypes.oneOf([
    'api',
    'auth',
    'database',
    'lambda',
    'redis',
    's3',
    'scheduled_job',
    'ui',
    'worker',
  ]),
};

export const ApplicationMicroserviceApiPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: ApiMicroservicePropTypes,
});

export const ApplicationMicroserviceAuthPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: AuthMicroservicePropTypes,
});

export const ApplicationMicroserviceDatabasePropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: DatabaseMicroservicePropTypes,
});

export const ApplicationMicroserviceLambdaPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: LambdaMicroservicePropTypes,
});

export const ApplicationMicroserviceRedisPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: RedisMicroservicePropTypes,
});

export const ApplicationMicroserviceS3PropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: S3MicroservicePropTypes,
});

export const ApplicationMicroserviceConfigurationPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: ApiMicroservicePropTypes,
});

export const DatabaseMicroserviceConfigurationPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: DatabaseMicroservicePropTypes,
});

export const ApplicationMicroserviceScheduledJobPropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: PropTypes.shape({
    dev_tag: PropTypes.string,
    staging_tag: PropTypes.string,
    prod_tag: PropTypes.string,
    image_repository_uri: PropTypes.string,
    image_repository_url: PropTypes.string,
  }),
});

export interface IApplicationMicroserviceScheduledJobPropTypes {
  application_microserviceable_id: string;
  application_microserviceable_type: 'scheduled_job';
  name: string;
  description:string;
  environment_variables_count: number;
  microserviceable: IScheduledJobMicroservice
}

export const ApplicationMicroserviceEnvironmentVariablePropTypes = PropTypes.shape({
  ...applicationMicroserviceProperties,
  microserviceable: ApiMicroservicePropTypes,
});

export default PropTypes.shape({
  ...applicationMicroserviceProperties,
});

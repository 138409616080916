import ITeam from "types/team/ITeam";
import { Badge, Col, Row } from "react-bootstrap";
import { Spinner, Error } from "@apex/react-toolkit/components";
import { translate } from "@apex/react-toolkit/lib";
import useSearchTeamMembers from "hooks/useSearchTeamMembers";
import UserSearchFields from "common/user/UserSearchFields";
import UserPaginatedTable from "common/user/UserPaginatedTable";

const TeamMembersTab: React.FC<{
  team: ITeam;
}> = ({ team }) => {
  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchTeamMembers({ teamId: team.id });
  const { data: paginatedData, error, isLoading, isFetching } = queryResult;

  if (error || !team) return <Error coverPage />;
  if (isLoading || !paginatedData) return <Spinner size="10x" coverViewport />;

  const { meta } = paginatedData;
  const disabled = !!isFetching;

  return (
    <>
      <Row>
        <Col>
          <h2>
            {translate("members")} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
      </Row>
      <UserSearchFields
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        disabled={disabled}
        take={take}
        setTake={setTake}
        includeApproved={false}
      />
      <Row className="my-2">
        <Col>
          {isLoading ? (
            <Spinner size="5x" />
          ) : (
            <UserPaginatedTable
              sortBy={sortBy}
              setSortBy={(updatedSortKey) => {
                if (sortBy === updatedSortKey) {
                  setSortDirection(sortDirection === "desc" ? "asc" : "desc");
                } else {
                  setSortBy(updatedSortKey);
                }
              }}
              sortDirection={sortDirection}
              users={paginatedData?.data || []}
              pageCount={paginatedData?.meta?.last_page || 1}
              currentPage={paginatedData?.meta?.current_page || 0}
              onPageChange={setPage}
              isFetching={isFetching}
              approvalColumn={false}
              userUrl={`/organizations/${team.organization_id}/users`}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TeamMembersTab;

import { useState, useEffect } from 'react';
import { useLazyGetApplicationMicroservicesQuery } from 'api/applicationSlice';

const useSearchApplicationMicroservices = ({ applicationId, initialSearchObj = {} }) => {
  const [sortBy, setSortBy] = useState('created_at');
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchObj, setSearchObj] = useState(initialSearchObj);
  const [getApplicationMicroservices, result] = useLazyGetApplicationMicroservicesQuery();

  let queryParameters = {
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };

  if (Object.keys(searchObj).length > 0) {
    queryParameters = {
      ...queryParameters,
      ...searchObj,
    }
  }

  useEffect(() => {
    if (Object.keys(searchObj).length > 0) {
      queryParameters = {
        ...queryParameters,
        ...searchObj,
      }
    }

    getApplicationMicroservices({ applicationId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationMicroservices, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getApplicationMicroservices({ applicationId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObj]);

  return {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    setSearchObj,
    page,
    setPage,
    result,
    refetch: () => getApplicationMicroservices({ applicationId, queryParameters }),
  };
};

export default useSearchApplicationMicroservices;

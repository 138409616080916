import { useState, useEffect } from 'react';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import { useLazyListMembersQuery } from 'api/teamSlice';
import { UseSearchUsersSearchParameters } from 'hooks/useSearchUsers';

export type SortBy = 'first_name' | 'last_name' | 'email';

const useSearchTeamMembers = ({ teamId, defaultTake = 10 }: { teamId: string; defaultTake?: number }) => {
  const [take, setTake] = useState<number>(defaultTake);
  const [sortBy, setSortBy] = useState<SortBy>('first_name');
  const [page, setPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [searchParameters, setSearchParameters] = useState<UseSearchUsersSearchParameters>({});
  const [getMembers, result] = useLazyListMembersQuery();

  const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchUsersSearchParameters> = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
    ...searchParameters,
  };

  useEffect(() => {

    getMembers({ queryParameters, teamId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMembers, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getMembers({ queryParameters, teamId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters, take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    setSearchParameters,
    page,
    setPage,
    result,
    searchParameters,
  };
};

export default useSearchTeamMembers;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from 'common/FormFeedback';

const RelationalDatabaseForm = ({ initialValues, onCancel, onSubmit, disabled, apiErrors, submitText, supportedDatabases }) => {
  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
    engine: yup.string().required(translate('databaseEngineRequired')),
    engine_version: yup.string().required(translate('databaseEngineVersionRequired')),
    database_name: yup
      .string()
      .trim()
      .matches(/^[A-Za-z][0-9A-Za-z]+$/, translate('invalidFormat'))
      .required(translate('databaseNameRequired')),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        onSubmit(formInput);
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{translate('name')}</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder={translate('name')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
              isValid={touched.name && !errors.name}
              autoFocus
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{translate('description')}</Form.Label>
            <Form.Control
              required
              name="description"
              type="text"
              placeholder={translate('description')}
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
              isValid={touched.description && !errors.description}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="description"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{translate('databaseName')}</Form.Label>
            <Form.Control
              required
              name="database_name"
              type="text"
              placeholder={translate('databaseName')}
              value={values.database_name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled}
              isValid={touched.database_name && !errors.database_name}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="database_name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEngine">
            <Form.Label>{translate('engine')}</Form.Label>
            <Form.Select
              required
              name="engine"
              type="text"
              value={values.engine}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" disabled>{translate('selectEngine')}</option>
              {
                supportedDatabases
                && supportedDatabases.map(db => <option key={db.engine} value={db.engine}>{db.displayName}</option>)
              }
            </Form.Select>
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="engine"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEngineVersion">
            <Form.Label>{translate('engineVersion')}</Form.Label>
            <Form.Select
              required
              name="engine_version"
              type="text"
              value={values.engine_version}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" disabled>{translate('selectEngineVersion')}</option>
              {
                values.engine
                && supportedDatabases.find(db => db.engine === values.engine).versions
                  .map(version => <option key={version} value={version}>{version}</option>)
              }
            </Form.Select>
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="engine_version"
            />
          </Form.Group>
          {onCancel && (
            <Button
              variant="secondary"
              type="button"
              disabled={disabled}
              onClick={onCancel}
              className="me-2"
            >
              {translate('cancel')}
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="primary"
              type="submit"
              disabled={disabled}
            >
              {submitText}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

RelationalDatabaseForm.defaultProps = {
  initialValues: {
    name: '',
    description: '',
    engine: '',
    engine_version: '',
    database_name: '',
  },
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  disabled: false,
  submitText: translate('save'),
};

RelationalDatabaseForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    engine: PropTypes.string,
    engine_version: PropTypes.string,
    database_name: PropTypes.string,
  }),
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
    engine: PropTypes.arrayOf(PropTypes.string),
    engine_version: PropTypes.arrayOf(PropTypes.string),
    database_name: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  supportedDatabases: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RelationalDatabaseForm;

import React from 'react';
import PropTypes from 'prop-types';
import PaginationControl from 'common/PaginationControl';
import PaginationPropTypes from 'propTypes/PaginationPropTypes';
import GroupTable from 'common/group/GroupTable';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';

const GroupPaginatedTable = ({ groups, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onGroupSelected }) => (
  <>
    <GroupTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      groups={groups}
      onGroupSelected={onGroupSelected}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

GroupPaginatedTable.propTypes = {
  groups: PropTypes.arrayOf(GroupPropTypes),
  onGroupSelected: PropTypes.func,
  ...PaginationPropTypes,
};

GroupPaginatedTable.defaultProps = {
  groups: [],
  onGroupSelected: undefined,
};

export default GroupPaginatedTable;

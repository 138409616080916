import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({

    addApplicationPermission: builder.mutation({
      query: data => ({ url: 'application-permissions', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationPermissions' }],
    }),

    getApplicationPermission: builder.query({
      query: id => ({ url: `application-permissions/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationPermissions', id }],
    }),

    listApplicationPermissions: builder.query({
      query: queryParameters => {
        return { url: 'application-permissions', method: 'get', queryParameters };
      },
    }),

    getApplicationPermissionRoles: builder.query({
      query: ({ queryParameters, permissionId }) => {
        return { url: `application-permissions/${permissionId}/roles`, method: 'get', queryParameters };
      },
    }),

    editApplicationPermission: builder.mutation({
      query: data => ({ url: `application-permissions/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationPermissions', id }],
    }),

    deleteApplicationPermission: builder.mutation({
      query: data => ({ url: `application-permissions/${data.id}`, method: 'delete', data }),
      invalidatesTags: () => [{ type: 'ApplicationPermissions' }],
    }),
  }),
});

export const {
  useAddApplicationPermissionMutation,
  useLazyListApplicationPermissionsQuery,
  useGetApplicationPermissionQuery,
  useLazyGetApplicationPermissionRolesQuery,
  useEditApplicationPermissionMutation,
  useDeleteApplicationPermissionMutation,
} = extendedApiSlice;

import PaginationControl from 'common/PaginationControl';
import NodeGroupTable from 'common/infrastructure/nodeGroups/NodeGroupTable';
import { NodeGroupSortBy } from 'hooks/useSearchNodeGroups';
import { FC } from 'react';
import INodeGroup from 'types/infrastructure/INodeGroup';

const NodeGroupPaginatedTable: FC<{
  nodeGroups: INodeGroup[]
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
  sortBy: NodeGroupSortBy
  setSortBy: (value: NodeGroupSortBy) => void
  sortDirection: 'asc' | 'desc'
}> = ({ nodeGroups, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection }) => {
  return (
    <>
      <NodeGroupTable
        nodeGroups={nodeGroups}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
      />
      <PaginationControl
        pagesToShow={3}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default NodeGroupPaginatedTable;

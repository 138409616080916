import apiSlice from 'api/apiSlice';
import ISeries from 'types/series/ISeries';
import PaginatedResult from 'types/PaginatedResult';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listSeries: builder.query<PaginatedResult<ISeries>, void>({
      query: () => {
        return { url: 'series', method: 'get' };
      },
      providesTags: ['Series'],
    })
  })
});

export const {
  useListSeriesQuery,
} = extendedApiSlice;

import React from 'react';

import { ITrustedSourceApiErrors, ITrustedSourceFormFields } from "types/application/ITrustedSource";
import { translate } from "@apex/react-toolkit/lib";
import * as yup from "yup";
import { Formik } from "formik";
import { isIPv6, isIPv4 } from 'is-ip';
import isCidr from 'is-cidr';
import { Button, Col, Form, Row } from "react-bootstrap";
import FormFeedback from "common/FormFeedback";

const defaultInitialValues: ITrustedSourceFormFields = {
  description: '',
  trusted_ips: '',
  environment: 'dev',
};

const TrustedSourceForm: React.FC<{
  initialValues?: ITrustedSourceFormFields;
  onSubmit: (values: ITrustedSourceFormFields) => void;
  disabled?: boolean;
  apiErrors: ITrustedSourceApiErrors;
  submitText?: string;
}> = ({
  initialValues = defaultInitialValues,
  disabled = false,
  onSubmit,
  apiErrors,
  submitText = translate("create"),
}) => {
    const schema = yup.object({
      description: yup.string().required(translate('valueRequired')),
      trusted_ips: yup
        .string()
        .test('is-valid-ip-source', translate('validTrustedIpsMsg'), (value: string | undefined) => {
          if (typeof value === 'undefined') return false;
          return isIPv4(value) || isIPv6(value) || isCidr.v4(value) || isCidr.v6(value);
        })
    });

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          await onSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
          isValid,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>{translate("description")}</Form.Label>
                  <Form.Control
                    required
                    name="description"
                    type="text"
                    placeholder={translate("description")}
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                    isValid={touched.description && !errors.description}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="description"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formTrustedIps">
                  <Form.Label>{translate("trustedIps")}</Form.Label>
                  <Form.Control
                    required
                    name="trusted_ips"
                    type="text"
                    placeholder={translate("trustedIps")}
                    value={values.trusted_ips}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                    isValid={touched.trusted_ips && !errors.trusted_ips}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="trusted_ips"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group>
                <Button type="submit" variant="primary" disabled={disabled || !isValid}>
                  {submitText}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default TrustedSourceForm;

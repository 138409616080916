import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import Detail from 'App/AppRoutes/Organizations/Detail';
import Search from 'App/AppRoutes/Organizations/Search';
import New from 'App/AppRoutes/Organizations/New';
import NewTeam from 'App/AppRoutes/Organizations/Detail/Teams/NewTeam';
import EditTeam from 'App/AppRoutes/Organizations/Detail/Teams/EditTeam';
import TeamDetail from 'App/AppRoutes/Organizations/Detail/Teams/TeamDetail';
import CreateOrganizationApplicationRole from 'App/AppRoutes/Organizations/Detail/Applications/ApplicationRoles/CreateOrganizationApplicationRole';
import CreateOrganizationGroup from 'App/AppRoutes/Organizations/Detail/Groups/CreateOrganizationGroup';
import EditOrganizationGroup from 'App/AppRoutes/Organizations/Detail/Groups/EditOrganizationGroup';
import OrganizationGroupDetail from 'App/AppRoutes/Organizations/Detail/Groups/OrganizationGroupDetail';
import GroupsTabRedirect from 'App/AppRoutes/Organizations/Detail/GroupsTabRedirect';
import TeamsTabRedirect from 'App/AppRoutes/Organizations/Detail/TeamsTabRedirect';
import ApplicationsTabRedirect from 'App/AppRoutes/Organizations/Detail/ApplicationsTabRedirect';
import EditOrganizationApplicationRole from './Detail/Applications/ApplicationRoles/EditOrganizationApplicationRole';
import User from 'App/AppRoutes/Organizations/Detail/User';

const Organizations = () => {
  return (
    <Suspense fallback={<h1>{translate("loading")} . . .</h1>}>
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="new" element={<New />} />
        <Route path=":orgId" element={<Detail />} />
        <Route path=":orgId/applications/:applicationId/roles/new" element={<CreateOrganizationApplicationRole />} />
        <Route path=":orgId/applications/:applicationId/roles/:roleId/edit" element={<EditOrganizationApplicationRole />} />
        <Route path=":orgId/applications/*" element={<ApplicationsTabRedirect />} />
        <Route path=":orgId/groups/new" element={<CreateOrganizationGroup />} />
        <Route path=":orgId/groups/:groupId" element={<OrganizationGroupDetail />} />
        <Route path=":orgId/groups/:groupId/edit" element={<EditOrganizationGroup />} />
        <Route path=":orgId/groups" element={<GroupsTabRedirect />} />
        <Route path=":orgId/teams/new" element={<NewTeam />} />
        <Route path=":orgId/teams/:teamId" element={<TeamDetail />} />
        <Route path=":orgId/teams/:teamId/edit" element={<EditTeam />} />
        <Route path=":orgId/teams" element={<TeamsTabRedirect />} />
        <Route path=":orgId/users/:userId" element={<User />} />
      </Routes>
    </Suspense>
  );
};

export default Organizations;

import { FC } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import AccordionToggleLink from 'common/AccordionToggleLink';
import MicroserviceKubernetesRoleConfigForm from 'common/infrastructure/MicroserviceKubernetesRoleConfigForm';

const MicroserviceKubernetesRoleConfigAccordion: FC<{
  isSuperUserAuthorized: boolean
  microserviceId: string
}> = ({ isSuperUserAuthorized, microserviceId }) => {
  return (
    <>
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start text-white">
              {translate('kubernetesRoles')}
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="4"
                inactiveText={isSuperUserAuthorized ? translate('edit') : translate('view')}
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="4">
        <MicroserviceKubernetesRoleConfigForm
          isSuperUserAuthorized={isSuperUserAuthorized}
          microserviceId={microserviceId}
        />
      </Accordion.Collapse>
    </>
  );
};

export default MicroserviceKubernetesRoleConfigAccordion;

import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useAddRedisMicroserviceMutation } from 'api/redisMicroserviceSlice';
import { Alert } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import RedisForm from 'common/applicationMicroservice/redis/RedisForm';

const NewRedis = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [addRedisMicroservice, { isLoading, error: mutationError }] = useAddRedisMicroserviceMutation();
  const [apiErrors, setApiErrors] = useState(null);


  const handleSubmit = async (formData) => {
    const { error } = await addRedisMicroservice({ ...formData, application_id: applicationId });

    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/applications/${applicationId}/microservices`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/microservices/`);
  };

  return (
    <>
      {(mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>)}
      <RedisForm
        disabled={isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        apiErrors={apiErrors}
      />
    </>
  );
};

export default NewRedis;

export default {
  areYouSureYouWantToUnmount: 'Are you sure you want to unmount filesystem :filesystemName: from :microserviceName:',
  addFilesytstem: 'Add Filesystem',
  confirmUnmount: 'Confirm Unmount',
  filesystem: 'Filesystem',
  filesystemInfo: 'Filesystem Info',
  filesystemQueuedForUnmount: 'Filesystem queued for unmount',
  filesystems: 'Filesystems',
  mountableFilesystem: 'Mountable Filesystem',
  mountedMicroservices: 'Mounted Microservices',
  mountedOnMicroserviceDetailInfo: 'Microservices mount and unmount Filesystems from their own detail page by developer application maintainers. The File Manager microservice is automatically provisioned and managed the Apex Platform.',
  mountFilesystem: 'Mount Filesystem',
  mountingWillTriggerRedeploy: 'NOTE: Mounting/Unmounting will cause a redeployment of this microservice in any currently deployed environments',
  noFilesystemInstancesExistYet: 'We are currently spinning up your filesystem. Please check back in a few minutes.',
  noFilesystemsMountedYet: 'No Filesystems are currently mounted to this microservice.',
  noFilesystemsProvisionedYet: 'There are currently no Mountable Filesystems provisioned.',
  noMicroservicesMountedYet: 'This Filesystem is not yet mounted to any Microservices',
}

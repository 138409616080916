import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { useGetApplicationGroupsQuery, useLazyGetApplicationGroupsQuery } from 'api/applicationSlice';
import PaginationControl from 'common/PaginationControl';
import GroupTable from 'common/group/GroupTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddGroupsModal from 'App/AppRoutes/Applications/Application/Groups/GroupsBody/AddGroupsModal';
import RemoveGroupModal from 'App/AppRoutes/Applications/Application/Groups/GroupsBody/RemoveGroupModal';
import GroupSearchFields from 'common/group/GroupSearchFields';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import IQueryParameters from 'types/IQueryParameters';
import { SortDirection } from 'types/ListSearchTypes';
import IGroup from 'types/group/IGroup';
import { SortBy } from 'hooks/useSearchGroups';

const GroupsBody = () => {
  const [showAddGroupsModal, setShowAddGroupsModal] = useState<boolean>(false);
  const [groupQueuedForRemoval, setGroupQueuedForRemoval] = useState<IGroup | null>(null);
  const [canAddGroups, setCanAddGroups] = useState<boolean>(false);
  const { applicationId } = useParams();
  const [take, setTake] = useState<number>(10);
  const [sortBy, setSortBy] = useState<SortBy>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [searchObj, setSearchObj] = useState<IQueryParameters | null>(null);
  const [page, setPage] = useState(1);

  if (!applicationId) return (<Error coverPage />);

  const orgRelationship = { relationships: ['organization'] };
  const [getApplicationGroups, { data: paginatedData, isLoading, isFetching }] = useLazyGetApplicationGroupsQuery();
  const { data: allGroups, isFetching: allGroupsFetching, error: allGroupsError } = useGetApplicationGroupsQuery({ applicationId, ...orgRelationship });
  const { isAdministratorAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);

  const queryParameters: IQueryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
    ...orgRelationship,
  };
  const searchBy = searchObj?.option;
  const searchVal = searchObj?.value;

  if (typeof searchBy === 'string' && searchVal) queryParameters[searchBy] = searchVal;

  useEffect(() => {
    setCanAddGroups(!!(allGroups && !allGroupsError && !allGroupsFetching));
  }, [allGroups, allGroupsError, allGroupsFetching]);

  useEffect(() => {
    getApplicationGroups({ queryParameters, applicationId });
  }, [getApplicationGroups, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getApplicationGroups({
      applicationId,
      queryParameters: {
        ...queryParameters,
        page: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObj, take]);

  if (allGroupsError) return (<Error coverPage />);
  if (isLoading || appAuthIsFetching || !paginatedData || allGroupsFetching || !allGroups) return (<Spinner />);

  const { data: groups, meta } = paginatedData;

  return (
    <>
      {
        canAddGroups && (
          <AddGroupsModal
            show={showAddGroupsModal}
            applicationId={applicationId}
            handleClose={() => setShowAddGroupsModal(false)}
            currentGroups={allGroups.data}
          />
        )
      }
      {
        groupQueuedForRemoval && (
          <RemoveGroupModal
            show={groupQueuedForRemoval !== null}
            handleClose={() => setGroupQueuedForRemoval(null)}
            applicationId={applicationId}
            group={groupQueuedForRemoval}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            Groups <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {
            isAdministratorAuthorized && (
              <Button
                onClick={() => setShowAddGroupsModal(true)}
                disabled={!canAddGroups}
              >
                <FontAwesomeIcon icon="plus" /> {translate('addGroups')}
              </Button>
            )
          }
        </Col>
      </Row>
      <GroupSearchFields
        disabled={isFetching}
        setSearchObj={setSearchObj}
        take={take}
        setTake={setTake}
      />
      <Row>
        <Col>
          <GroupTable
            groups={groups}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            sortDirection={sortDirection}
            actionColumn={{
              dataIndex: 'id',
              title: translate('actions'),
              render: (_, group) => {
                return isAdministratorAuthorized && (
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setGroupQueuedForRemoval(group)}
                  >
                    <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                  </Button>
                );
              },
            }}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default GroupsBody;

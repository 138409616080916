import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import ClientCredentialsPropTypes from 'propTypes/application/ClientCredentialsPropTypes';

const ClientCredentialsModal = ({ show, handleClose, credentials }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('oauthCredentialsUpdated')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>{translate('updatedCredentialsMessage')}</p>
        <br />
        <p><b>{translate('clientId')}: </b>{credentials.client_id}</p>
        <p><b>{translate('clientSecret')}: </b>{credentials.client_secret}</p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          {translate('okay')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ClientCredentialsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  credentials: ClientCredentialsPropTypes.isRequired,
};

export default ClientCredentialsModal;

import PropTypes from 'prop-types';

export const ActionColumnPropTypesObj = {
  onHeaderClicked: PropTypes.func,
  title: PropTypes.string,
  dataIndex: PropTypes.string,
  render: PropTypes.func,
  onTd: PropTypes.func,
};

export default PropTypes.shape({
  ...ActionColumnPropTypesObj,
});

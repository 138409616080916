import { useState, useEffect } from 'react';
import { useLazyGetOrganizationGroupsQuery } from 'api/organizationSlice';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import IGroup from 'types/group/IGroup';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';

export type SortBy = 'name' | 'description';
export type UseSearchOrganizationGroupsSearchParameters = {
  name?: string
  description?: string
  any?: string
};

const useSearchOrganizationGroups:
  (args: { organizationId: string, defaultTake?: number }) => {
    setTake: (take: number) => void;
    take: number;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    sortDirection: SortDirection;
    setSortDirection: (sortDirection: SortDirection) => void;
    setSearchParameters: (searchParameters: UseSearchOrganizationGroupsSearchParameters) => void;
    searchParameters: UseSearchOrganizationGroupsSearchParameters;
    page: number
    setPage: (page: number) => void;
    result: RtkQueryResult<PaginatedResult<IGroup>>
  }
  = ({ organizationId, defaultTake = 10 }) => {
    const [take, setTake] = useState<number>(defaultTake);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [page, setPage] = useState(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [searchParameters, setSearchParameters] = useState<UseSearchOrganizationGroupsSearchParameters>({});
    const [getOrgGroups, result] = useLazyGetOrganizationGroupsQuery();

    const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchOrganizationGroupsSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      getOrgGroups({ organizationId, queryParameters });
    }, [getOrgGroups, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      getOrgGroups({ organizationId, queryParameters });
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchOrganizationGroups;

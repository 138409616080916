import { apiSlice } from 'api/apiSlice';
import IQueryParameters from 'types/IQueryParameters';
import { Deployment } from 'kubernetes-types/apps/v1';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listK8sDeployments: builder.query<Deployment[], { namespace: string, queryParameters?: IQueryParameters }>({
      query: ({ namespace, queryParameters }) => ({
        url: `deployments/${namespace}`,
        queryParameters,
        method: 'get',
        altBasePath: 'proxy'
      }),
    }),
  }),
});

export const {
  useLazyListK8sDeploymentsQuery,
} = extendedApiSlice;

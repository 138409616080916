import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Error, Spinner } from '@apex/react-toolkit/components';
import OrganizationRoleDetailPanel from './ApplicationListItem/OrganizationRoleDetailPanel';
import ApplicationListItem from './ApplicationListItem';
import { useListOrganizationApplicationsQuery } from 'api/organizationSlice';
import IApplication from 'types/application/IApplication';
import IApplicationRole from 'types/application/IApplicationRole';

const ApplicationsTab: React.FC<Record<string, never>> = () => {
  const { orgId: organizationId } = useParams();
  const [selected, setSelected] = useState<IApplication | null>(null);
  const [selectedRole, setSelectedRole] = useState<IApplicationRole | null>(null);

  if (!organizationId) return <Error message={translate('organizationIdNotValid')} />;

  const { data: applications, isLoading, error } = useListOrganizationApplicationsQuery(organizationId);

  useEffect(() => {
    if (applications && applications.length > 0) {
      setSelected(applications[0]);

      if (applications[0].roles && applications[0].roles.length > 0) {
        setSelectedRole(applications[0].roles[0]);
      }
    }
  }, [applications, setSelected, setSelectedRole]);

  if (isLoading) return <Spinner />;
  if (error || !applications) return <Error message={translate('errorLoadingOrganizationApplications')} />;

  // if request doesn't error but there are no applications associated with the org
  if (applications.length === 0 && !error) {
    return (
      <Row>
        <Col style={{ minHeight: "5rem" }}>
          <Card className="bg-dark h-100 text-center p-4">
            <h5 className="text-warning">{translate('noOrganizationApplicationsHeader')}</h5>
            <p>{translate('noOrganizationApplications')}</p>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2>
            {translate('applications')} <Badge pill>{applications.length}</Badge>
          </h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {
            applications.map((application: IApplication) => (
              <ApplicationListItem
                key={application.id}
                application={application}
                setSelected={(application) => { setSelected(application); setSelectedRole(null); }}
                isSelected={selected === application}
              />
            ))
          }
        </Col>
        {
          selected && (
            <OrganizationRoleDetailPanel
              appRoles={selected?.roles || []}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              applicationId={selected?.id || ''}
              organizationId={organizationId}
            />
          )
        }
      </Row>
    </Container>
  );
};

export default ApplicationsTab;

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import IconWithTooltip from 'common/IconWithTooltip';

const ApplicationActivityStatus = ({ status }) => {
  switch (status) {
    case 'CREATED': return <IconWithTooltip icon="circle-dashed" color="grey" tooltip={translate('created')} />;
    case 'IN_PROGRESS': return <IconWithTooltip icon="spinner" color="white" tooltip={translate('inProgress')} className="fa-spin" />;
    case 'COMPLETE': return <IconWithTooltip icon="check" color="green" tooltip={translate('complete')} />;
    case 'FAILED': return <IconWithTooltip icon="times" color="red" tooltip={translate('failed')} />;
    case 'ABORTED': return <IconWithTooltip icon="ban" color="grey" tooltip={translate('canceled')} />;
    default: return null;
  }
};

ApplicationActivityStatus.propTypes = {
  status: PropTypes.oneOf([
    'CREATED',
    'IN_PROGRESS',
    'COMPLETE',
    'FAILED',
    'ABORTED',
  ]).isRequired,
};

export default ApplicationActivityStatus;

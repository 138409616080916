import { useLazyListNodeGroupsQuery } from 'api/nodeGroupSlice';
import { useEffect, useState } from 'react';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import PaginatedResult from 'types/PaginatedResult';
import RtkQueryResult from 'types/RtkQueryResult';
import INodeGroup from 'types/infrastructure/INodeGroup';

export type NodeGroupSortBy = 'name' | 'environment';
export type UseSearchNodeGroupsSearchParameters = {
  name?: string
  environment?: string
  description?: string
  any?: string
}

const useSearchNodeGroups:
  (args?: { initialSearchParameters: UseSearchNodeGroupsSearchParameters }) => {
    setTake: (take: number) => void
    take: number
    sortBy: NodeGroupSortBy
    setSortBy: (sortBy: NodeGroupSortBy) => void
    sortDirection: SortDirection
    setSortDirection: (sortDirection: SortDirection) => void
    setSearchParameters: (searchParameters: UseSearchNodeGroupsSearchParameters) => void
    searchParameters: UseSearchNodeGroupsSearchParameters
    page: number
    setPage: (page: number) => void
    result: RtkQueryResult<PaginatedResult<INodeGroup>>
  } = (args) => {
    const initialSearchParameters = args?.initialSearchParameters || {};
    const [take, setTake] = useState<number>(10);
    const [sortBy, setSortBy] = useState<NodeGroupSortBy>('name');
    const [page, setPage] = useState<number>(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [searchParameters, setSearchParameters] = useState<UseSearchNodeGroupsSearchParameters>(initialSearchParameters);
    const [fetchNodeGroups, result] = useLazyListNodeGroupsQuery();

    const queryParameters: PagniateSortSearchQueryParameters<NodeGroupSortBy, UseSearchNodeGroupsSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      fetchNodeGroups(queryParameters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchNodeGroups, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      fetchNodeGroups(queryParameters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchNodeGroups;

import { apiSlice } from 'api/apiSlice';
import { RoleConfigFormValues } from 'common/infrastructure/roleConfigs/RoleConfigForm';
import IQueryParameters from 'types/IQueryParameters';
import PaginatedResult from 'types/PaginatedResult';
import IRoleConfig from 'types/infrastructure/IRoleConfig';
import { KubernetesAPIResource } from 'types/infrastructure/KubernetesResources';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getKubernetesApiGroups: builder.query<string[], void>({
      query: () => ({ url: `kubernetes-api-groups`, method: 'get' }),
    }),

    getResourcesByApiGroup: builder.query<KubernetesAPIResource[], IQueryParameters>({
      query: (queryParameters) => ({ url: `kubernetes-api-groups/resources`, method: 'get', queryParameters }),
    }),

    createRoleConfigMutation: builder.mutation<IRoleConfig, RoleConfigFormValues>({
      query: (data: IRoleConfig) => ({ url: 'kubernetes-role-configs', method: 'post', data }),
      invalidatesTags: () => [{ type: 'KubernetesRoleConfigs' }],
    }),

    listKubernetesRoleConfigs: builder.query<PaginatedResult<IRoleConfig>, IQueryParameters>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'kubernetes-role-configs', method: 'get', queryParameters };
      },
      providesTags: ['KubernetesRoleConfigs'],
    }),

    getKubernetesRoleConfig: builder.query<IRoleConfig, string>({
      query: (id: string) => ({ url: `kubernetes-role-configs/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'KubernetesRoleConfigs', id }],
    }),

    editKubernetesRoleConfig: builder.mutation<IRoleConfig, IRoleConfig | RoleConfigFormValues & { id: string }>({
      query: data => ({ url: `kubernetes-role-configs/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'KubernetesRoleConfigs', id }],
    }),

    deleteRoleConfig: builder.mutation<null, IRoleConfig>({
      query: (data: IRoleConfig) => ({ url: `kubernetes-role-configs/${data.id}`, method: 'delete', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'KubernetesRoleConfigs', id }],
    }),
  }),
});

export const {
  useGetKubernetesApiGroupsQuery,
  useLazyGetResourcesByApiGroupQuery,
  useCreateRoleConfigMutationMutation,
  useLazyListKubernetesRoleConfigsQuery,
  useGetKubernetesRoleConfigQuery,
  useDeleteRoleConfigMutation,
  useEditKubernetesRoleConfigMutation,
} = extendedApiSlice;

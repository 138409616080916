import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldArray } from 'formik';
import {
  Button,
  Form,
  InputGroup,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSearchApplicationMicroserviceEventNotifications from 'hooks/useSearchApplicationMicroserviceEventNotifications';
import { useEditApplicationMicroserviceEventNotificationsMutation } from 'api/applicationMicroserviceSlice';
import useToast from 'hooks/useToast';
import { useParams } from 'react-router-dom';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';

const EVENT_NOTIFICATION_TYPES = [
  // 's3:ObjectCreated:*', // To reduce complexity, require the user to explicitly select all conditions
  's3:ObjectCreated:Put',
  's3:ObjectCreated:Post',
  's3:ObjectCreated:Copy',
  's3:ObjectCreated:CompleteMultipartUpload',

  // 's3:ObjectRemoved:*', // To reduce complexity, require the user to explicitly select all conditions
  's3:ObjectRemoved:Delete',
  's3:ObjectRemoved:DeleteMarkerCreated',

  // 's3:ObjectRestore:*', // To reduce complexity, require the user to explicitly select all conditions
  's3:ObjectRestore:Post',
  's3:ObjectRestore:Completed',
  's3:ObjectRestore:Delete',

  's3:ObjectAcl:Put',

  // 's3:ObjectTagging:*', // To reduce complexity, require the user to explicitly select all conditions
  's3:ObjectTagging:Put',
  's3:ObjectTagging:Delete',

  's3:ReducedRedundancyLostObject',

  // 's3:Replication:*', // To reduce complexity, require the user to explicitly select all conditions
  's3:Replication:OperationMissedThreshold',
  's3:Replication:OperationReplicatedAfterThreshold',
  's3:Replication:OperationNotTracked',
  's3:Replication:OperationFailedReplication',

  's3:LifecycleTransition',

  // 's3:LifecycleExpiration:*', // To reduce complexity, require the user to explicitly select all conditions
  's3:LifecycleExpiration:Delete',
  's3:LifecycleExpiration:DeleteMarkerCreated',

  's3:IntelligentTiering',
];

const schema = yup.object().shape({
  eventNotifications: yup.array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .matches(/^.{1,255}$/, translate('eventNotificationNameFormat'))
          .required(translate('nameRequired')),
        resource_arn: yup
          .string()
          .required(),
        events: yup
          .array()
          .of(yup.string().oneOf(EVENT_NOTIFICATION_TYPES))
          .min(1, translate('atLeastOneEventRequired'))
          .required(),
      })
        .test('unique-environment-name-combo', { name: translate('uniqueEnvironmentNameCombo') }, (value, context) => {
          // Will always be at least 1 because the given value will match itself in the Formik FieldArray
          const dupeCount = context.parent.reduce((acc, el) => {
            if (el.environment === value.environment && el.name === value.name) acc++;
            return acc;
          }, 0);

          const isUnique = dupeCount === 1;
          return isUnique;
        }),
    ),
});

const sortByEnvironment = (data) => {
  return _.chain(data)
    .sortBy('name')
    .groupBy('name')
    .map((groupedByName) => ([
      _.find(groupedByName, { environment: 'dev' }),
      _.find(groupedByName, { environment: 'staging' }),
      _.find(groupedByName, { environment: 'prod' }),
    ]))
    .flatten()
    .compact()
    .value();
};

const ApplicationMicroserviceEventNotifications = ({ microserviceId }) => {
  const { applicationId } = useParams();
  const {
    result: queryResult,
  } = useSearchApplicationMicroserviceEventNotifications({ microserviceId });
  const { isDeveloperAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);
  const {
    result: applicationMicroservicesResult,
  } = useSearchApplicationMicroservices({
    applicationId,
    initialSearchObj: {
      include_type: ['lambda'],
    }
  });
  const toast = useToast();
  const [editEventNotifications, editResult] = useEditApplicationMicroserviceEventNotificationsMutation();

  if (queryResult.isLoading || !queryResult.data || appAuthIsFetching) {
    return (
      <Spinner />
    );
  }

  const handleSubmit = async (formData) => {
    try {
      await editEventNotifications({
        microserviceId,
        eventNotifications: formData.eventNotifications.map((eventNotification) => ({
          ...eventNotification,
          filter_prefix: eventNotification.filter_prefix || null,
          filter_suffix: eventNotification.filter_suffix || null,
        })),
      });

      toast({
        bg: 'success',
        title: translate('updated'),
        message: ` ${translate('eventNotificationsSavedSuccessfully')}`,
      });
    } catch (e) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    }
  };

  return (
    <Card bg="dark">
      <Card.Body>
        <Row className="mb-1">
          <Col xl={2}><h6>{translate('name')}</h6></Col>
          <Col xl={2}><h6>{translate('resource')}</h6></Col>
          <Col xl={4}><h6>{translate('events')}</h6></Col>
          <Col xl={1}><h6>{translate('filterPrefix')}</h6></Col>
          <Col xl={1}><h6>{translate('filterSuffix')}</h6></Col>
          <Col xl={1}><h6>{translate('environment')}</h6></Col>
          {
            isDeveloperAuthorized && (
              <Col xl={1}><h6>{translate('delete')}</h6></Col>
            )
          }
        </Row>

        <Formik
          validationSchema={schema}
          initialValues={{ eventNotifications: sortByEnvironment(queryResult.data) }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, isValid, handleReset, dirty, setFieldValue }) => (
            <FormikForm>
              <FieldArray name="eventNotifications">
                {({ push, remove }) => (
                  <div>
                    {values.eventNotifications.length > 0
                      && values.eventNotifications.map((eventNotification, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index}>
                          <Col xl={2}>
                            <Field name={`eventNotifications.${index}.name`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      {...field}
                                      onKeyUp={field.onBlur}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                      <ErrorMessage name={`eventNotifications.${index}.name`} />
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xl={2}>
                            <Field name={`eventNotifications.${index}.resource_arn`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      as="select"
                                      {...field}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                    >
                                      <option key="default" value="" disabled>{translate('selectResource')}</option>
                                      {
                                        applicationMicroservicesResult?.data?.data
                                          // Only show resources that are already deployed to the selected environment
                                          ?.filter(resource => resource.microserviceable[`${eventNotification.environment}_arn`])
                                          .map((resource) => (
                                            <option key={resource.id} value={resource.microserviceable[`${values.eventNotifications[index].environment}_arn`]}>{resource.name}</option>
                                          ))
                                      }
                                    </Form.Control>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xl={4}>
                            <Field name={`eventNotifications.${index}.events`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      as="select"
                                      {...field}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                      multiple
                                    >
                                      {EVENT_NOTIFICATION_TYPES.map((eventType) => (
                                        <option key={eventType} value={eventType}>{eventType}</option>
                                      ))}
                                    </Form.Control>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xl={1}>
                            <Field name={`eventNotifications.${index}.filter_prefix`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      {...field}
                                      onKeyUp={field.onBlur}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                      <ErrorMessage name={`eventNotifications.${index}.filter_prefix`} />
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xl={1}>
                            <Field name={`eventNotifications.${index}.filter_suffix`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      {...field}
                                      onKeyUp={field.onBlur}
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                    />
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                      <ErrorMessage name={`eventNotifications.${index}.filter_suffix`} />
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xs={1}>
                            <Field name={`eventNotifications.${index}.environment`}>
                              {({ field }) => (
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Select
                                      disabled={editResult.isLoading || !isDeveloperAuthorized}
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        // Clears the resource ARN when the environment is changed
                                        setFieldValue(`eventNotifications[${index}].resource_arn`, '');
                                      }}
                                    >
                                      <option value="dev">{translate('dev')}</option>
                                      <option value="staging">{translate('staging')}</option>
                                      <option value="prod">{translate('prod')}</option>
                                    </Form.Select>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            </Field>
                          </Col>

                          <Col xs={1}>
                            <Button
                              disabled={editResult.isLoading}
                              variant="danger"
                              onClick={() => remove(index)}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    {
                      isDeveloperAuthorized && (
                        <Button
                          variant="primary"
                          onClick={() => push({ name: '', resource_arn: '', events: [], filter_prefix: '', filter_suffix: '', environment: 'dev' })}
                        >
                          {translate('addEventNotification')}
                        </Button>
                      )
                    }
                  </div>
                )}
              </FieldArray>
              {
                isDeveloperAuthorized && (
                  <Row className="mt-5">
                    <Col>
                      <Button
                        className="me-2"
                        variant="secondary"
                        type="cancel"
                        disabled={editResult.isLoading || !dirty}
                        onClick={handleReset}
                      >
                        {translate('cancel')}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValid || editResult.isLoading || !dirty}
                      >
                        {(editResult.isLoading) ? translate('saving') : translate('save')}
                      </Button>
                    </Col>
                  </Row>
                )
              }
            </FormikForm>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

ApplicationMicroserviceEventNotifications.defaultProps = {};

ApplicationMicroserviceEventNotifications.propTypes = {
  microserviceId: PropTypes.string.isRequired,
};

export default ApplicationMicroserviceEventNotifications;

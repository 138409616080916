import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from 'common/FormFeedback';

const GroupForm = ({ initialValues, disabled, onCancel, onSubmit, apiErrors, submitText }) => {
  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        onSubmit(formInput);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>

          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{translate('name')}</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder="Name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.name && !errors.name}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="name"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDomain">
            <Form.Label>{translate('description')}</Form.Label>
            <Form.Control
              required
              name="description"
              type="text"
              placeholder="Description"
              value={values.description || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.description && !errors.description}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="description"
            />
          </Form.Group>
          {onCancel && (
            <Button
              variant="secondary"
              type="button"
              disabled={disabled}
              onClick={onCancel}
              className="me-2"
            >
              {translate('cancel')}
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="primary"
              type="submit"
              disabled={disabled}
            >
              {submitText}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

GroupForm.defaultProps = {
  initialValues: {
    name: '',
    description: '',
  },
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  submitText: translate('save'),
};

GroupForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
};

export default GroupForm;

import { apiSlice } from 'api/apiSlice';
import IScheduledJobCron from 'types/application/microservice/IScheduledJobCron';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    editCron: builder.mutation<IScheduledJobCron, IScheduledJobCron>({
      query: (data: IScheduledJobCron) => ({ url: `scheduled-job-crons/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useEditCronMutation,
} = extendedApiSlice;

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Col, Row } from "react-bootstrap"
import IApplication from "types/application/IApplication";

const ApplicationListItem: React.FC<{
  application: IApplication
  setSelected: (application: IApplication) => void,
  isSelected: boolean,
}> = ({ application, setSelected, isSelected }) => {
  return (
    <Row className={`py-3 ${isSelected ? 'bg-dark' : ''}`} onClick={() => setSelected(application)} role="button">
      <Col className="d-flex align-items-center">
        <Card
          className="border-0"
          style={{ backgroundColor: `${application.background_color}`, height: '3rem', width: '3rem' }}
        >
          <FontAwesomeIcon
            icon={application.icon}
            className="mx-auto my-auto"
            size="2x"
          />
        </Card>
        <h4 className="ms-4 my-auto">{application.name}</h4>
      </Col>
    </Row>
  )
};

export default ApplicationListItem;

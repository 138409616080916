import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Error, Spinner } from '@apex/react-toolkit/components';
import OrganizationApplicationRoleForm from 'common/organizations/OrganizationApplicationRoleForm';
import { useEditOrganizationApplicationRoleMutation, useGetOrganizationApplicationRoleQuery } from 'api/organizationSlice';
import { useState } from 'react';
import IApplicationRole, { IApplicationRoleApiErrors, IApplicationRoleValidationErrors } from 'types/application/IApplicationRole';

const EditOrganizationApplicationRole: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const { applicationId, orgId, roleId } = useParams();
  const [apiErrors, setApiErrors] = useState<IApplicationRoleValidationErrors | null>(null);

  const { data: appRole, isLoading: getRoleLoading, error: getRoleError } = useGetOrganizationApplicationRoleQuery({ organizationId: orgId as string, roleId: roleId as string })
  const [editRole, { isLoading }] = useEditOrganizationApplicationRoleMutation();

  if (getRoleLoading) return (<Spinner />);
  if (getRoleError || !appRole) return (<Error />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('editRole')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <OrganizationApplicationRoleForm
            onSubmit={async (data) => {
              const result: { data: IApplicationRole } | { error: IApplicationRoleApiErrors } = await editRole({ data, role: appRole });

              if ('error' in result) {
                setApiErrors(result.error.errors);
              } else {
                navigate(`/organizations/${orgId}#applications`);
              }
            }}
            onCancel={() => navigate(`/organizations/${orgId}#applications`)}
            apiErrors={apiErrors}
            disabled={isLoading}
            organizationId={orgId as string}
            initialValues={appRole}
            applicationId={applicationId as string}
            currentPermissions={appRole.permissions}
            currentGroups={appRole.groups}
            currentUsers={appRole.users}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EditOrganizationApplicationRole;

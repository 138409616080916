import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import IOrganization from 'types/organization/IOrganization';

const AddedOrganizationList: React.FC<{
  addedOrgs: IOrganization[];
  dismissOrg: (organization: IOrganization) => void;
}> = ({ addedOrgs, dismissOrg }) => {
  return (

    <Card style={{ minHeight: '100%' }} bg="secondary">
      <Card.Body>
        {
          addedOrgs.map(organization => (
            <Alert
              key={organization.id}
              dismissible
              className="alert-fit-content ms-3"
              onClose={() => dismissOrg(organization)}
            >
              {organization.name}
            </Alert>
          ))
        }
      </Card.Body>
    </Card>

  );
};

export default AddedOrganizationList;

import React, { useState } from 'react';
import { Badge, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IApplication from 'types/application/IApplication';
import { Tile } from '@apex/react-toolkit/components';
import AppDetailsForm from './AppDetailsForm';
import { FormikHelpers } from 'formik';
import { IApplicationApiErrors } from 'types/application/IApplication';

const AppDetailsFormWithTilePreview: React.FC<{
  disabled?: boolean;
  initialValues: IApplication;
  onSubmit: (values: IApplication, formikHelpers: FormikHelpers<IApplication>) => void;
  apiErrors: IApplicationApiErrors
}> = ({
  disabled,
  initialValues,
  onSubmit,
  apiErrors,
}) => {
    const [currentValues, setCurrentValues] = useState(initialValues);

    return (
      <Row>
        <Col md={6}>
          <AppDetailsForm
            onValuesUpdated={(values: IApplication) => {
              setCurrentValues(values);
            }}
            initialValues={initialValues}
            disabled={disabled}
            apiErrors={apiErrors}
            onSubmit={onSubmit}
          />
        </Col>
        <Col md={6} className="text-center">
          <Row className='mb-2'>
            <Col className="d-flex justify-content-center">
              <div style={{ width: '12.5rem' }}>
                <h4>
                  {translate('tilePreview')}
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {translate('defaultAppTileMessage')}
                      </Tooltip>
                    }
                  >
                    <Badge pill bg="secondary" className="ms-1" style={{ fontSize: '1rem' }}>
                      ?
                    </Badge>
                  </OverlayTrigger>
                </h4>
                <Row>
                  <Col>
                    <Tile
                      bodyClassName="home-tile-body"
                      title={initialValues.name}
                      icon={currentValues.icon}
                      backgroundcolor={currentValues.background_color}
                      textcolor={currentValues.text_color}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

export default AppDetailsFormWithTilePreview;

import { apiSlice } from 'api/apiSlice';
import IS3Microservice from 'types/application/microservice/IS3Microservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addS3Microservice: builder.mutation<IS3Microservice, IS3Microservice>({
      query: (data: IS3Microservice) => ({ url: 's3-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    deleteS3Microservice: builder.mutation<null, IS3Microservice>({
      query: (data: IS3Microservice)  => ({ url: `s3-microservices/${data.id}`, method: 'delete', }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useAddS3MicroserviceMutation,
  useDeleteS3MicroserviceMutation
} = extendedApiSlice;

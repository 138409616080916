import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  },
  reducers: {
    setToken: (state, { payload: token }) => {
      state.token = token;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setUser: (state, { payload: user }) => {
      state.user = user;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setToken, clearToken, setUser, clearUser } = slice.actions;
export const selectCurrentUser = (state) => state.auth.user;
export default slice.reducer;

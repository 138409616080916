import React from 'react';

import { translate } from '@apex/react-toolkit/lib';
import { Deployment } from 'kubernetes-types/apps/v1';
import { HorizontalPodAutoscaler, MetricSpec, MetricStatus } from 'kubernetes-types/autoscaling/v2';
import { Container } from 'kubernetes-types/core/v1';
import { Col, Row } from 'react-bootstrap';

const MetricsRow: React.FC<{
  deployment: Deployment;
  hpa: HorizontalPodAutoscaler;
}> = ({
  deployment,
  hpa,
}) => {
    const deploymentContainer: Container | undefined = deployment.spec?.template.spec?.containers[0];

    const hpaCurrentCpuMetric: MetricStatus | undefined = hpa.status?.currentMetrics?.find((metricStatus: MetricStatus) => metricStatus.resource?.name === 'cpu');
    const hpaSpecCpuMetric: MetricSpec | undefined = hpa.spec?.metrics?.find((metricSpec: MetricSpec) => metricSpec.resource?.name === 'cpu');
    const requestedMiliCoresString = deploymentContainer?.resources?.requests?.cpu.replace(/m/, '');
    const requestedMilliCoresInt = requestedMiliCoresString && parseInt(requestedMiliCoresString);
    const miliCoresLimitString = deploymentContainer?.resources?.limits?.cpu.replace(/m/, '');
    const miliCoresLimitInt = miliCoresLimitString && parseInt(miliCoresLimitString);
    const hpaCurrentCpuMillicoreAverageValueString = hpaCurrentCpuMetric?.resource?.current.averageValue?.replace(/m/, '');
    const hpaCurrentCpuMillicoreAverageValueInt = hpaCurrentCpuMillicoreAverageValueString && parseInt(hpaCurrentCpuMillicoreAverageValueString);

    const hpaCurrentMemoryMetric: MetricStatus | undefined = hpa.status?.currentMetrics?.find((metricStatus: MetricStatus) => metricStatus.resource?.name === 'memory');
    const hpaSpecMemoryMetric: MetricSpec | undefined = hpa.spec?.metrics?.find((metricSpec: MetricSpec) => metricSpec.resource?.name === 'memory');
    const requestedMemoryString = deploymentContainer?.resources?.requests?.memory.replace(/Mi/, '');
    const requestedMemoryInt = requestedMemoryString && parseInt(requestedMemoryString);
    const memoryLimitString = deploymentContainer?.resources?.limits?.memory.replace(/Mi/, '');
    const memoryLimitInt = memoryLimitString && parseInt(memoryLimitString);
    const hpaCurrentMemoryAverageValueString = hpaCurrentMemoryMetric?.resource?.current.averageValue;
    const hpaCurrentMemoryAverageValueInt = hpaCurrentMemoryAverageValueString && parseInt(hpaCurrentMemoryAverageValueString);

    return (
      <>
        <Row className="mt-4">
          <h4>{translate('metrics')}</h4>
        </Row>
        <Row className="mb-2">
          <Col></Col>
          <Col><h6>{translate('requested')}</h6></Col>
          <Col><h6>{translate('averageUtilization')}</h6></Col>
          <Col><h6>{translate('limits')}</h6></Col>
          <Col><h6>{translate('scalesAt')}</h6></Col>
        </Row>
        <Row>
          <Col>{translate('cpu')}</Col>
          {
            // @TODO omit env in containers in the proxy
            requestedMilliCoresInt && hpaCurrentCpuMillicoreAverageValueInt && hpaSpecCpuMetric && hpaCurrentCpuMetric ? (
              <>
                <Col>{requestedMilliCoresInt / 1000} {translate('cores')}</Col>
                <Col>{hpaCurrentCpuMillicoreAverageValueInt / 1000} {translate('cores')} ({hpaCurrentCpuMetric.resource?.current.averageUtilization}%)</Col>
                <Col>{typeof miliCoresLimitInt === 'number' && miliCoresLimitInt / 1000} {translate('cores')}</Col>
                <Col>{hpaSpecCpuMetric?.resource?.target.averageUtilization}% {translate('averageUtilization')}</Col>
              </>
            ) : (
              <span className="text-danger">{translate('replicaStatusUnavailble')}</span>
            )
          }
        </Row>
        <Row>
          <Col>{translate('memory')}</Col>
          {
            requestedMemoryInt && hpaSpecMemoryMetric && hpaCurrentMemoryAverageValueInt ? (
              <>
                <Col>{requestedMemoryInt}MB</Col>
                <Col>{Math.round(hpaCurrentMemoryAverageValueInt / 1000000)}MB ({hpaCurrentMemoryMetric?.resource?.current.averageUtilization}%)</Col>
                <Col>{memoryLimitInt}MB</Col>
                <Col>{hpaSpecMemoryMetric?.resource?.target.averageUtilization}% {translate('averageUtilization')}</Col>
              </>
            ) : (
              <span className="text-danger">{translate('replicaStatusUnavailble')}</span>
            )
          }
        </Row>
      </>
    );
  };
export default MetricsRow;

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetGroupQuery } from 'api/groupSlice';
import DetailBody from 'App/AppRoutes/Groups/Detail/DetailBody';

const Groups = () => {
  const { groupId } = useParams();
  const { data: group, isFetching, error } = useGetGroupQuery(groupId);


  if (error) return <Error error={error} coverPage />;
  if (isFetching) return (<Spinner size="10x" coverViewport />);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h1>{group.name}</h1>
        </Col>
        <Col className="text-end">
          <Button as={Link} to="./edit">{translate('edit')}</Button>
        </Col>
      </Row>
      <DetailBody group={group} />
    </Container>
  );
};

export default Groups;

import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const UpdatePreviewValues = ({ onFormValuesChange }) => {
  const { values: formikValues } = useFormikContext();

  useEffect(() => {
    onFormValuesChange(formikValues);
  }, [formikValues, onFormValuesChange]);

  return null;
};

export default UpdatePreviewValues;

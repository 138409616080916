import { FC, useState } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IamPolicyPaginatedTable from 'common/infrastructure/iamPolicies/IamPolicyPaginatedTable';
import IamPolicySearchFields from 'common/infrastructure/iamPolicies/IamPolicySearchFields';
import IIamPolicy from 'types/infrastructure/IIamPolicy';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useDeleteIamPolicyMutation } from 'api/iamPolicySlice';
import useSearchIamPolicies, { IamPolicySortBy } from 'hooks/useSearchIamPolicies';
import { useNavigate } from 'react-router-dom';
import useToast from 'hooks/useToast';

const SearchIamPolicies: FC = () => {
  const navigate = useNavigate();
  const [versionToDelete, setVersionToDelete] = useState<IIamPolicy | null>(null);
  const [deleteIamPolicy, { isLoading: deleteIsLoading }] = useDeleteIamPolicyMutation();
  const toast = useToast();

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchIamPolicies();
  const { error, isLoading, data: paginatedData } = queryResult;

  if (!paginatedData) return (<Spinner animation='border' />);
  if (error) return (<Error coverPage />);

  const { data, meta } = paginatedData;

  return (
    <>
      <ConfirmationModal
        show={!!versionToDelete}
        title={translate('confirmDeletion')}
        confirmationText={translate('confirmDeleteByName', { name: versionToDelete?.policy_name })}
        disabled={deleteIsLoading}
        handleClose={() => setVersionToDelete(null)}
        onConfirm={async () => {
          const result = await deleteIamPolicy(versionToDelete as IIamPolicy);

          if ('error' in result) {
            toast({
              bg: 'danger',
              title: translate('error'),
              message: result.error.message,
              autohide: false,
            });
          }
        }}
        successMessage={translate('deleteSuccessful')}
      />

      <Row className="mt-2 w-auto">
        <Col md={6}>
          <h2>
            {translate('iamPolicies')}
            <Badge pill className="ms-3">
              {meta.total}
            </Badge>
          </h2>
        </Col>
        <Col md={{ span: 5, offset: 1 }} className="text-end">
          <Button
            onClick={() => navigate('/infrastructure/iam-policies/import')}
            className="me-2"
          >
            <FontAwesomeIcon icon="plus" /> {translate('import')}
          </Button>
          <Button
            onClick={() => navigate('/infrastructure/iam-policies/new')}
          >
            <FontAwesomeIcon icon="plus" /> {translate('create')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <IamPolicySearchFields
            disabled={isLoading}
            setSearchParameters={setSearchParameters}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <IamPolicyPaginatedTable
            iamPolicies={data}
            sortBy={sortBy}
            setSortBy={(updatedSortKey: IamPolicySortBy) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            onPolicyNameClicked={(iamPolicy: IIamPolicy) => navigate(`./iam-policies/${iamPolicy.id}/edit`)}
            sortDirection={sortDirection}
            pageCount={meta.last_page}
            currentPage={meta.current_page}
            onPageChange={setPage}
            actionColumn={{
              dataIndex: 'id',
              title: '',
              render: (_, version) => {
                return (
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setVersionToDelete(version)}
                  >
                    <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                  </Button>
                );
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SearchIamPolicies;

import React from 'react';
import PropTypes from 'prop-types';
import PaginationControl from 'common/PaginationControl';
import PaginationPropTypes from 'propTypes/PaginationPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';
import ApplicationMicroserviceJobTable from 'common/applicationMicroservice/ApplicationMicroserviceJobTable';
import ApplicationMicroserviceJobPropTypes from 'propTypes/application/ApplicationMicroserviceJobPropTypes';

const ApplicationMicroserviceJobPaginatedTable = ({ applicationMicroserviceJobs, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onUserSelected, actionColumn }) => (
  <>
    <ApplicationMicroserviceJobTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      applicationMicroserviceJobs={applicationMicroserviceJobs}
      onUserSelected={onUserSelected}
      actionColumn={actionColumn}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

ApplicationMicroserviceJobPaginatedTable.propTypes = {
  applicationMicroserviceJobs: PropTypes.arrayOf(ApplicationMicroserviceJobPropTypes),
  onUserSelected: PropTypes.func,
  actionColumn: ActionColumnPropTypes,
  ...PaginationPropTypes,
};

ApplicationMicroserviceJobPaginatedTable.defaultProps = {
  applicationMicroserviceJobs: [],
  onUserSelected: undefined,
  actionColumn: null,
};

export default ApplicationMicroserviceJobPaginatedTable;

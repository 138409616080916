import { Col, Container, Row } from 'react-bootstrap';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';
import IamPolicyForm from 'common/infrastructure/iamPolicies/IamPolicyForm';
import { IIamPolicyApiErrors } from 'types/infrastructure/IIamPolicy';
import { useEditIamPolicyMutation, useGetIamPolicyQuery } from 'api/iamPolicySlice';
import useToast from 'hooks/useToast';

const Edit = () => {
  const navigate = useNavigate();
  const { iamPolicyId } = useParams();
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();
  const { data, isLoading: fetchIsLoading } = useGetIamPolicyQuery(iamPolicyId as string);
  const [editIamPolicy, { isLoading }] = useEditIamPolicyMutation();
  const [apiErrors, setApiErrors] = useState<IIamPolicyApiErrors | null>(null);
  const toast = useToast();

  if (!isSuperUser) return <Unauthorized />;
  if (fetchIsLoading || permissionLoading) return (<Spinner size="10x" coverViewport />);
  if (!data) return (<Error />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('createIamPolicy')}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <IamPolicyForm
            submitText={translate('update')}
            disabled={isLoading || fetchIsLoading}
            initialValues={data}
            onSubmit={async (values) => {
              const result = await editIamPolicy({
                ...data,
                ...values,
              });

              if ('error' in result) {
                setApiErrors(result.error.errors);
                toast({
                  bg: 'danger',
                  title: translate('error'),
                  message: result.error.message,
                  autohide: false,
                });
              } else if ('data' in result) {
                toast({
                  bg: 'success',
                  title: translate('success'),
                  message: translate('updatedSuccessfully'),
                  autohide: true,
                });

                navigate('..#iamPolicies');
              }
            }}
            onCancel={() => navigate('..#iamPolicies')}
            apiErrors={apiErrors}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Edit;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import useSearchApplicationMicroserviceJobs from 'hooks/useSearchApplicationMicroserviceJobs';
import useInterval from 'hooks/useInterval';
import { REFETCH_DELAY_IN_SECONDS } from 'config';
import ApplicationMicroserviceJobPaginatedTable from '../ApplicationMicroserviceJobPaginatedTable';
import ApplicationMicroserviceJobSearchFields from '../ApplicationMicroserviceJobSearchFields';

const ApplicationMicroserviceJobSearchTable = ({ microserviceId }) => {
  const {
    result: queryResult,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStatus,
    setSearchStatus,
    setSearchType,
    searchType,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    refetch,
  } = useSearchApplicationMicroserviceJobs({ microserviceId });

  useInterval(() => {
    refetch();
  }, REFETCH_DELAY_IN_SECONDS * 1000);

  if (queryResult.isLoading || !queryResult.data) {
    return (
      <Spinner />
    );
  }

  const { data, meta } = queryResult.data;

  return (
    <Card className="bg-dark">
      <Card.Header>
        <Row>
          <Col>
            <h3>Microservice Jobs</h3>
          </Col>
          <Col className="text-end">
            <small className="text-muted">Results update every {REFETCH_DELAY_IN_SECONDS} seconds</small>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <ApplicationMicroserviceJobSearchFields
              searchType={searchType}
              setSearchType={setSearchType}
              searchStatus={searchStatus}
              setSearchStatus={setSearchStatus}
              toDate={toDate}
              setToDate={setToDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
              take={take}
              setTake={setTake}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ApplicationMicroserviceJobPaginatedTable
              setSortBy={(updatedSortKey) => {
                if (sortBy === updatedSortKey) {
                  setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                } else {
                  setSortBy(updatedSortKey);
                }
              }}
              sortBy={sortBy}
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              applicationMicroserviceJobs={data}
              pageCount={meta.last_page}
              currentPage={meta.current_page}
              onPageChange={setPage}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ApplicationMicroserviceJobSearchTable.propTypes = {
  microserviceId: PropTypes.string.isRequired,
};

export default ApplicationMicroserviceJobSearchTable;

import React, { useEffect } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import OrganizationPermissionsCard from 'App/AppRoutes/Organizations/Detail/ApplicationsTab/ApplicationListItem/OrganizationRoleDetailPanel/OrganizationPermissionsCard';
import IApplicationRole from 'types/application/IApplicationRole';
import UserRoleTeamsCard from 'App/AppRoutes/Organizations/Detail/User/UserRoleDetailPanel/UserRoleTeamsCard';
import UserRoleGroupsCard from 'App/AppRoutes/Organizations/Detail/User/UserRoleDetailPanel/UserRoleGroupsCard';
import UserRoleListItem from 'App/AppRoutes/Organizations/Detail/User/UserRoleDetailPanel/UserRoleListItem';

const UserRoleDetailPanel: React.FC<{
  appRoles: IApplicationRole[]
  selectedRole: IApplicationRole | null
  setSelectedRole: (role: IApplicationRole | null) => void
}> = ({ appRoles, selectedRole, setSelectedRole }) => {
  useEffect(() => {
    if (selectedRole) {
      if (!appRoles.find((role: IApplicationRole) => role.id === selectedRole.id)) {
        setSelectedRole(null);
      }
    }
  }, [appRoles]);

  return appRoles && (
    <Col md={9} className="d-flex bg-dark p-2">
      <Col md={5} className="">
        <Card bg="dark" className="border-0">
          <Card.Header className="px-2">
            <Row>
              <Col>
                <h4>{translate('roles')}</h4>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0 table-responsive">
            <Table variant="dark" className="mb-0">
              <tbody>
                {
                  appRoles.map((appRole) => (
                    <UserRoleListItem
                      key={appRole.id}
                      appRole={appRole}
                      isSelected={selectedRole === appRole}
                      setSelectedRole={setSelectedRole}
                    />
                  ))
                }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {selectedRole && (
        <Col className="p-2 bg-dark-secondary">
          <OrganizationPermissionsCard
            permissions={selectedRole.permissions || []}
          />
          <UserRoleGroupsCard
            groups={selectedRole.groups || []}
          />
          <UserRoleTeamsCard
            teams={selectedRole.teams || []}
          />
        </Col>
      )}
    </Col>
  );
};

export default UserRoleDetailPanel;

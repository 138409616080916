import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useRemoveGroupMemberMutation } from 'api/groupSlice';
import UserPropTypes from 'propTypes/user/UserPropTypes';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';

const RemoveGroupMemberModal = ({ show, handleClose, group, member }) => {
  const [removeGroupMember, { isLoading }] = useRemoveGroupMemberMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('removeGroupMember')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmRemoveGroupMember', { groupName: group.name, firstName: member.first_name, lastName: member.last_name })}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await removeGroupMember({ groupId: group.id, userId: member.id });
            handleClose();
          }}
        >
          {translate('remove')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveGroupMemberModal.propTypes = {
  show: PropTypes.bool.isRequired,
  group: GroupPropTypes.isRequired,
  member: UserPropTypes.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RemoveGroupMemberModal;

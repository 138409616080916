import React from 'react';
import PaginationControl from 'common/PaginationControl';
import { SortDirection } from 'types/ListSearchTypes';
import { SortBy } from 'hooks/useSearchTeams';
import TeamTable from '../TeamTable';
import ITeam from 'types/team/ITeam';

const TeamPaginatedTable: React.FC<{
  teams: ITeam[]
  sortBy?: string
  setSortBy?: (sortBy: SortBy) => void
  sortDirection?: SortDirection
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
}> = ({ teams, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection }) => (
  <>
    <TeamTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      teams={teams}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

export default TeamPaginatedTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const CardWrapper = ({ title, body, className = '' }) => {
  return (
    <Card className={`bg-dark mb-3 p-2 ${className}`}>
      <Card.Title className="d-flex justify-content-between">
        {title}
      </Card.Title>

      <Card.Body>
        {body}
      </Card.Body>
    </Card>
  );
};

CardWrapper.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
};

export default CardWrapper;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useDeleteGroupMutation } from 'api/groupSlice';
import IGroup from 'types/group/IGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteGroupModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  group: IGroup;
  handleDelete?: (group: IGroup) => Promise<void>
}> = ({ show, handleClose, group, handleDelete }) => {
  const navigate = useNavigate();
  const [deleteGroup, { isLoading }] = useDeleteGroupMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteGroup')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmDeleteGroup', { groupName: group.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" className="me-1" />
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            if (handleDelete) {
              await handleDelete(group);
            } else {
              await deleteGroup(group);
            }

            navigate('..');
          }}
        >
          <FontAwesomeIcon icon="trash-alt" className="me-1" />
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGroupModal;

import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { IApplicationMicroserviceS3 } from 'types/application/microservice/IS3Microservice';
import DangerZone from './DangerZone';
import ApplicationMicroserviceEventNotifications from 'common/applicationMicroservice/ApplicationMicroserviceEventNotifications';
import MicroserviceChartVersionSelectorCard from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard';
import ApexHelmChartVersionExplainTooltip from 'common/applicationMicroservice/ApexHelmChartVersionExplainTooltip';

const Configuration: React.FC<{
  isDeveloperAuthorized: boolean;
  microservice: IApplicationMicroserviceS3;
}> = ({ microservice, isDeveloperAuthorized }) => {
  return (
    <Accordion alwaysOpen>
      <>
        <Card bg="dark" className="mt-3">
          <Card.Header>
            <Row>
              <Col className="text-start">
                {translate('apexObjectStoreVersions')}  <ApexHelmChartVersionExplainTooltip />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span className="text-muted">
                      {translate('dev')} {microservice.dev_chart_version.version}
                    </span>
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {translate('staging')} {microservice.staging_chart_version.version}
                    </span>
                  </Col>
                  <Col>
                    <span className="text-muted">
                      {translate('prod')} {microservice.prod_chart_version.version}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col className="text-end">
                <AccordionToggleLink
                  eventKey="apex-versions"
                  inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                  activeText={translate('close')}
                />
              </Col>
            </Row>
          </Card.Header>
        </Card>
        <Accordion.Collapse eventKey="apex-versions">
          <MicroserviceChartVersionSelectorCard
            microservice={microservice}
            isDeveloperAuthorized={isDeveloperAuthorized}
          />
        </Accordion.Collapse>

        <Card bg="dark" className="mt-3">
          <Card.Header>
            <Row>
              <Col className="text-start">
                Event Notifications
              </Col>
              <Col>
                <span className="text-muted">
                  {microservice.event_notifications_count} {translate('eventNotifications')}
                </span>
              </Col>
              <Col className="text-end">
                <AccordionToggleLink
                  eventKey="2"
                  inactiveText={translate('edit')}
                  activeText={translate('close')}
                />
              </Col>
            </Row>
          </Card.Header>
        </Card>

        <Accordion.Collapse eventKey="2">
          <Card bg="dark">
            <Card.Body>
              <ApplicationMicroserviceEventNotifications microserviceId={microservice.id} />
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </>

      {isDeveloperAuthorized && (
        <>
          <Card bg="dark" className="mt-3">
            <Card.Header>
              <Row>
                <Col className="text-start text-danger">
                  {translate('dangerZone')}
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="3"
                    inactiveText={translate('open')}
                    activeText={translate('close')}
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="3">
            <DangerZone microservice={microservice} />
          </Accordion.Collapse>
        </>
      )}
    </Accordion>
  );
};

export default Configuration;

import React from 'react';
import PropTypes from 'prop-types';
import PaginationControl from 'common/PaginationControl';
import PaginationPropTypes from 'propTypes/PaginationPropTypes';
import ApplicationTable from 'common/application/ApplicationTable';
import ApplicationPropTypes from 'propTypes/application/ApplicationPropTypes';

const ApplicationPaginatedTable = ({ applications, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onApplicationSelected }) => (
  <>
    <ApplicationTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      applications={applications}
      onApplicationSelected={onApplicationSelected}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

ApplicationPaginatedTable.propTypes = {
  applications: PropTypes.arrayOf(ApplicationPropTypes),
  onApplicationSelected: PropTypes.func,
  ...PaginationPropTypes,
};

ApplicationPaginatedTable.defaultProps = {
  applications: [],
  onApplicationSelected: undefined,
};

export default ApplicationPaginatedTable;

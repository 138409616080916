import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';

const ApplicationMicroserviceHealthCheckStatus = (props) => {
  if (props.isHealthy) {
    return <Badge bg="success">{translate('healthy')}</Badge>
  }

  return <Badge bg="danger">{translate('unhealthy')}</Badge>
};

ApplicationMicroserviceHealthCheckStatus.propTypes = {
  isHealthy: PropTypes.bool.isRequired,
};

export default ApplicationMicroserviceHealthCheckStatus;

import { FC, useState } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApexHelmChartVersionPaginatedTable from 'common/infrastructure/apexHelmChartVersions/ApexHelmChartVersionPaginatedTable';
import useSearchApexHelmChartVersions, { ApexHelmChartVersionsSortBy } from 'hooks/useSearchApexHelmChartVersions';
import ApexHelmChartVersionSearchFields from 'common/infrastructure/apexHelmChartVersions/ApexHelmChartVersionSearchFields';
import NewApexHelmChartVersion from './NewApexHelmChartVersion';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';
import EditApexHelmChartVersion from './EditApexHelmChartVersion';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import { useDeleteApexHelmChartVersionMutation } from 'api/apexHelmChartVersionSlice';

const SearchApexHelmChartVersions: FC = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [versionToEdit, setVersionToEdit] = useState<IApexHelmChartVersion | null>(null);
  const [versionToDelete, setVersionToDelete] = useState<IApexHelmChartVersion | null>(null);

  const [deleteVersion, { isLoading: deleteIsLoading }] = useDeleteApexHelmChartVersionMutation();

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchApexHelmChartVersions();
  const { error, isLoading, data: paginatedData } = queryResult;

  if (!paginatedData) return (<Spinner animation='border' />);
  if (error) return (<Error coverPage />);

  const { data, meta } = paginatedData;

  return (
    <>
      <NewApexHelmChartVersion
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
      />
      {
        versionToEdit && (
          <EditApexHelmChartVersion
            show={!!versionToEdit}
            apexHelmChartVersion={versionToEdit}
            handleClose={() => setVersionToEdit(null)}
          />
        )
      }
      <ConfirmationModal
        show={!!versionToDelete}
        title={translate('confirmDeletion')}
        confirmationText={translate('confirmDeleteApexHelmChartVersion', {
          chartName: versionToDelete?.chart_name,
          version: versionToDelete?.version,
        })}
        disabled={deleteIsLoading}
        handleClose={() => setVersionToDelete(null)}
        onConfirm={async () => await deleteVersion(versionToDelete as IApexHelmChartVersion)}
        successMessage={translate('deleteSuccessful')}
      />

      <Row className="mt-2 w-auto">
        <Col md={6}>
          <h2>
            {translate('apexHelmChartVersions')}
            <Badge pill className="ms-3">
              {meta.total}
            </Badge>
          </h2>
        </Col>
        <Col md={{ span: 4, offset: 2 }} className="text-end">
          <Button
            onClick={() => setShowCreateModal(true)}
            className="text-nowrap"
          >
            <FontAwesomeIcon icon="plus" /> {translate('create')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <ApexHelmChartVersionSearchFields
            disabled={isLoading}
            setSearchParameters={setSearchParameters}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <ApexHelmChartVersionPaginatedTable
            apexHelmChartVersions={data}
            sortBy={sortBy}
            setSortBy={(updatedSortKey: ApexHelmChartVersionsSortBy) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            onChartNameClicked={(version: IApexHelmChartVersion) => setVersionToEdit(version)}
            sortDirection={sortDirection}
            pageCount={meta.last_page}
            currentPage={meta.current_page}
            onPageChange={setPage}
            actionColumn={{
              dataIndex: 'id',
              title: '',
              render: (_, version) => {
                return (
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setVersionToDelete(version)}
                  >
                    <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                  </Button>
                );
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SearchApexHelmChartVersions;

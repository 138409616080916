import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useDeleteApiMicroserviceMutation } from 'api/apiMicroserviceSlice';
import { IApplicationMicroserviceApi } from 'types/application/microservice/IApiMicroservice';
import useToast from 'hooks/useToast';

const DeleteApiMicroserviceModal: React.FC<{
  show: boolean
  handleClose: () => void
  microservice: IApplicationMicroserviceApi
}> = ({ show, handleClose, microservice }) => {
  const [deleteApiMicroservice, { isLoading }] = useDeleteApiMicroserviceMutation();
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteApplicationMicroservice')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmDeleteApplicationMicroservice', { microserviceName: microservice.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await deleteApiMicroservice(microservice.microserviceable);

            toast({
              bg: 'success',
              title: translate('success'),
              message: translate('queuedForRemoval', { name: microservice.name }),
              autohide: true,
            });

            navigate('..');
          }}
        >
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteApiMicroserviceModal;

import React from 'react';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { useGetExecutableLinksQuery } from 'api/cliConfigSlice';
import { translate } from '@apex/react-toolkit/lib';
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';

const Cli: React.FC = () => {
  const { data, isLoading, error } = useGetExecutableLinksQuery(undefined);

  if (isLoading) return (<Spinner animation='border' />);
  if (error || !data) return (<Error coverPage />);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Card bg="dark">
            <Card.Header>
              <Card.Title>{translate('executables')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                {data.map((link: string) => {
                  const split = link.split('/');

                  return (
                    <ListGroup.Item key={link}>
                      <a href={link} >{split[split.length - 1]}</a>
                    </ListGroup.Item>
                  )
                })}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Cli;

import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useGetUserQuery } from 'api/userSlice';
import RemoveUserModal from 'App/AppRoutes/Users/Detail/RemoveUserModal';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';

const Detail = () => {
  const { userId } = useParams();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const { data: user, isFetching, error } = useGetUserQuery({ id: userId });

  if (error) return <Error error={error} coverPage />;

  if (isFetching) return (<Spinner size="10x" coverViewport />);

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col>
            <h1>{user.first_name} {user.last_name}</h1>
          </Col>
          <Col className="text-end">
            <Button as={Link} to="./edit">{translate('edit')}</Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4}>
            <Row>
              <Col><strong>{translate('firstName')}:</strong> {user.first_name}</Col>
            </Row>
            <Row>
              <Col><strong>{translate('lastName')}:</strong> {user.last_name}</Col>
            </Row>
            <Row>
              <Col><strong>{translate('email')}:</strong> {user.email}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button variant="danger" onClick={() => setShowRemoveModal(true)}>{translate('deleteUser')}</Button>
          </Col>
        </Row>
      </Container>
      <RemoveUserModal
        show={showRemoveModal}
        handleClose={() => setShowRemoveModal(false)}
        user={user}
      />
    </>
  );
};

export default Detail;

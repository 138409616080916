import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import IOrganization from 'types/organization/IOrganization';
import { SortDirection } from 'types/ListSearchTypes';
import { SortBy } from 'hooks/useSearchOrganizations';
import { InteractiveTableColumn } from 'common/InteractiveTable';

const OrganizationTable: React.FC<{
  organizations: IOrganization[]
  sortBy?: string
  setSortBy?: (sortBy: SortBy) => void
  sortDirection?: SortDirection
  setSortDirection?: (sortDirection: SortDirection) => void
  actionColumn?: InteractiveTableColumn<IOrganization>;
}> = ({ organizations, sortBy, setSortBy, sortDirection, actionColumn }) => {
  const columns: InteractiveTableColumn<IOrganization>[] = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy && setSortBy('name'),
        className: 'clickable',
      }),
      render: (name: string, { id }: IOrganization) => (<Link to={`/organizations/${id}`}>{name}</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy && setSortBy('description'),
        className: 'clickable',
      }),
    },
    {
      title: translate('domain'),
      dataIndex: 'domain',
      sortDirection: sortBy === 'domain' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy && setSortBy('domain'),
        className: 'clickable',
      }),
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={organizations}
      columns={columns}
    />
  );
};

export default OrganizationTable;

import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useAddDatabaseMicroserviceMutation, useListSupportedDatabasesQuery } from 'api/dbMicroserviceSlice';
import RelationalDatabaseForm from 'common/applicationMicroservice/relationalDatabase/RelationalDatabaseForm';
import React, { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

const NewRelationalDatabase = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { data: supportedDatabases, isLoading: dbLoading } = useListSupportedDatabasesQuery();
  const [addDbMicroservice, { isLoading, error: mutationError }] = useAddDatabaseMicroserviceMutation();
  const [apiErrors, setApiErrors] = useState(null);

  const handleSubmit = async (formData) => {
    const { error } = await addDbMicroservice({ ...formData, application_id: applicationId });

    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/applications/${applicationId}/microservices`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/`);
  };

  return (
    <>
      {(mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>)}
      {
        dbLoading
        && (
          <Row className="justify-content-around">
            <Col md="4">
              <Spinner size="5x" />
            </Col>
          </Row>
        )
      }
      {
        supportedDatabases
        && (
          <RelationalDatabaseForm
            supportedDatabases={supportedDatabases}
            disabled={isLoading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            apiErrors={apiErrors}
          />
        )
      }
    </>
  );
};

export default NewRelationalDatabase;

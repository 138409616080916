import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const maintainerPropTypeProperties = {
  ...BaseModelPropTypes,
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  maintainer_role: PropTypes.string.isRequired,
};

export default PropTypes.shape({
  ...maintainerPropTypeProperties,
});

import { FC, useState } from 'react';
import { Accordion, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useGetKubernetesRoleConfigQuery } from 'api/roleConfigsSlice';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteKubernetesRoleConfigModal from 'App/AppRoutes/Infrastructure/KubernetesRoles/Detail/DeleteKubernetesRoleConfigModal';

const KubernetesRoleConfigDetail: FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();
  const { kubernetesRoleId } = useParams();

  const { data: roleConfig, isFetching, error } = useGetKubernetesRoleConfigQuery(kubernetesRoleId as string);

  if (!isSuperUser) return <Unauthorized />;
  if (isFetching || permissionLoading) return (<Spinner size="10x" coverViewport />);
  if (error || !roleConfig) return (<Error coverPage />);

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col md={7}>
            <Row>
              <Col>
                <h1>{roleConfig.name}</h1>
              </Col>
            </Row>
            <Row>
              <Col>{roleConfig.description}</Col>
            </Row>
            <Row>
              <Col>
                <strong>{translate('type')}</strong>: {roleConfig.type}
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <div className="float-end">
              <Button variant="secondary" onClick={() => navigate(`/infrastructure/kubernetes-roles/${kubernetesRoleId}/edit`)}>
                <FontAwesomeIcon icon="edit" className="me-1" />
                {translate('editRoleConfig')}
              </Button>
              <Button variant="danger" className="ms-2" onClick={() => setShowDeleteModal(true)}>
                <FontAwesomeIcon icon="trash-alt" className="me-1" />
                {translate('deleteRoleConfig')}
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h2>{translate('rules')}</h2>
          </Col>
        </Row>
        <Accordion alwaysOpen className="mt-4" defaultActiveKey="0">
          {
            roleConfig.rules && roleConfig.rules.map((rule, index) => (
              <div className="mb-3">
                <Card bg="dark">
                  <Card.Header>
                    <Row>
                      <Col className="text-start">
                        {rule.name}
                      </Col>
                      <Col className="text-end">
                        <AccordionToggleLink
                          eventKey={index.toString()}
                          inactiveText={translate('view')}
                          activeText={translate('close')}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                </Card>
                <Accordion.Collapse eventKey={index.toString()}>
                  <Card bg="dark">
                    <Card.Body>
                      <Row className="mb-1">
                        <Col md={3}>{translate('apiGroup')}:</Col>
                        <Col>{rule.api_group}</Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={3}><h6>{translate('resources')}:</h6></Col>
                      </Row>
                      {
                        rule.resources && rule.resources.map(resource => (
                          <Row className="mb-1 ms-2">
                            <Col md={3}>{resource.name}:</Col>
                            <Col>{resource.verbs?.join(', ')}</Col>
                          </Row>
                        ))
                      }
                    </Card.Body>
                  </Card>
                </Accordion.Collapse>
              </div>
            ))
          }
        </Accordion>
      </Container>
      <DeleteKubernetesRoleConfigModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        roleConfig={roleConfig}
      />
    </>
  );
};

export default KubernetesRoleConfigDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import UserPropTypes from 'propTypes/user/UserPropTypes';

const AddedMemberList = ({ addedMembers, dimissUser }) => {
  return (

    <Card style={{ minHeight: '100%' }}>
      <Card.Body variant="secondary">
        {
          addedMembers.map(user => (
            <Alert
              key={user.id}
              dismissible
              className="alert-fit-content ms-3"
              onClose={() => dimissUser(user)}
            >
              {user.first_name} {user.last_name}
            </Alert>
          ))
        }
      </Card.Body>
    </Card>

  );
};

AddedMemberList.propTypes = {
  addedMembers: PropTypes.arrayOf(UserPropTypes).isRequired,
  dimissUser: PropTypes.func.isRequired,
};

export default AddedMemberList;

import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { translate } from "@apex/react-toolkit/lib";
import FormFeedback from "common/FormFeedback";
import { IIamPolicyApiErrors } from 'types/infrastructure/IIamPolicy';

export interface InitialValues {
  id?: string;
  policy_name: string;
  policy_json: string;
}

export type IamPolicyFormValues = InitialValues;

const defaultInitialValues: InitialValues = {
  policy_name: "",
  policy_json: "",
};

const IamPolicyForm: React.FC<{
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => void;
  disabled?: boolean;
  onCancel: () => void;
  apiErrors?: IIamPolicyApiErrors | null;
  submitText?: string;
}> = ({
  initialValues = defaultInitialValues,
  disabled = false,
  onCancel,
  onSubmit,
  apiErrors,
  submitText = translate("create"),
}) => {
    const schema = yup.object({
      policy_name: yup.string()
        .matches(/^[a-zA-Z0-9+=,.@-]{1,128}$/, `Maximum 128 characters. Use alphanumeric and '+=,.@-_' characters.`)
        .required(translate('nameRequired')),
      policy_json: yup.string().test(
        'is-json',
        'valid json message',
        (value: string | undefined) => {
          try {
            JSON.parse(value as string);
          } catch (e) {
            return false;
          }
          return true;
        }
      )
    });

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group className="mb-3" controlId="formPolicyName">
                <Form.Label>{translate("policyName")}</Form.Label>
                <Form.Control
                  required
                  readOnly={!!initialValues.id}
                  name="policy_name"
                  type="text"
                  placeholder={translate("policyName")}
                  onBlur={handleBlur}
                  value={values.policy_name}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.policy_name && !errors.policy_name}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="policy_name"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formPolicyJson">
                <Form.Label>{translate("policyJson")}</Form.Label>
                <Form.Control
                  required
                  name="policy_json"
                  as="textarea"
                  rows={15}
                  placeholder={translate("policyJson")}
                  onBlur={handleBlur}
                  value={values.policy_json}
                  onChange={handleChange}
                  disabled={disabled}
                  isValid={touched.policy_json && !errors.policy_json}
                />
                <FormFeedback
                  touched={touched}
                  errors={errors}
                  apiErrors={apiErrors}
                  fieldName="policy_json"
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="secondary"
                  type="button"
                  disabled={disabled}
                  onClick={onCancel}
                  className="me-2"
                >
                  {translate("cancel")}
                </Button>
                <Button variant="primary" type="submit" disabled={disabled}>
                  {submitText}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default IamPolicyForm;

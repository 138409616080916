import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { applicationRoleProperties } from 'propTypes/application/ApplicationRolePropTypes';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';

const PermissionRolesTable = ({ roles, sortBy, setSortBy, sortDirection, applicationId }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/applications/${applicationId}/roles/${id}`}>{ first }</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
  ];

  return (
    <>
      <InteractiveTable
        dataId="id"
        data={roles}
        columns={columns}
        sortDirection={sortDirection}
      />
    </>
  );
};

PermissionRolesTable.propTypes = {
  ...SortPropTypes,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      ...applicationRoleProperties,
    }),
  ).isRequired,
  applicationId: PropTypes.string.isRequired,
};

export default PermissionRolesTable;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Breadcrumbs from 'common/Breadcrumbs';
import Home from 'App/AppRoutes/Home';
import Users from 'App/AppRoutes/Users';
import Groups from 'App/AppRoutes/Groups';
import Organizations from 'App/AppRoutes/Organizations';
import Applications from 'App/AppRoutes/Applications';
import PageNotFound from 'common/PageNotFound';
import Infrastructure from 'App/AppRoutes/Infrastructure';

const AppRoutes = () => {
  return (
    <>
      <Breadcrumbs />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="users/*" element={<Users />} />
        <Route path="groups/*" element={<Groups />} />
        <Route path="applications/*" element={<Applications />} />
        <Route path="organizations/*" element={<Organizations />} />
        <Route path="infrastructure/*" element={<Infrastructure />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ITeam from 'types/team/ITeam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteTeamModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  team: ITeam;
  disabled?: boolean;
  handleDelete: (team: ITeam) => Promise<void>
}> = ({ show, handleClose, team, disabled = false, handleDelete }) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteTeam')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmDeleteTeam', { teamName: team.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={disabled}
          variant="secondary"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" className="me-1" />
          {translate('cancel')}
        </Button>
        <Button
          disabled={disabled}
          variant="danger"
          onClick={async () => {
            await handleDelete(team);
          }}
        >
          <FontAwesomeIcon icon="trash-alt" className="me-1" />
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTeamModal;

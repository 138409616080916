import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const applicationMicroserviceProperties = {
  ...BaseModelPropTypes,
};

export default PropTypes.shape({
  ...applicationMicroserviceProperties,
});

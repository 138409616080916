import { useState, useEffect } from 'react';
import { useLazyGetTeamGroupsQuery } from "api/teamSlice";
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import IGroup from 'types/group/IGroup';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';

export type SortBy = 'name' | 'description';
export type UseSearchTeamGroupsSearchParameters = {
  name?: string;
  description?: string;
};

const useSearchTeamGroups:
  (args: { teamId: string }) => {
    setTake: (take: number) => void;
    take: number;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    sortDirection: SortDirection;
    setSortDirection: (sortDirection: SortDirection) => void;
    setSearchParameters: (searchParameters: UseSearchTeamGroupsSearchParameters) => void;
    searchParameters: UseSearchTeamGroupsSearchParameters;
    page: number
    setPage: (page: number) => void;
    result: RtkQueryResult<PaginatedResult<IGroup>>
  }
  = ({ teamId }) => {
    const [take, setTake] = useState<number>(10);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [page, setPage] = useState(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [searchParameters, setSearchParameters] = useState<UseSearchTeamGroupsSearchParameters>({});
    const [getTeamGroups, result] = useLazyGetTeamGroupsQuery();

    const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchTeamGroupsSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      getTeamGroups({ teamId, queryParameters });
    }, [getTeamGroups, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      getTeamGroups({ teamId, queryParameters });
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchTeamGroups;

import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGetApplicationMicroservicesQuery } from 'api/applicationSlice';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { ComputeBoundMicroservice, ComputeBoundMicroserviceTypeStrings, IApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { Option } from 'react-bootstrap-typeahead/types/types';
import IFilesystemMicroservice, { FilesystemMountErrors, IApplicationMicroserviceFilesystem } from 'types/application/microservice/IFilesystemMicroservice';
import MenuItem from 'common/typeahead/MenuItem';
import FilesystemMountForm from '../FilesystemMountForm';
import { useMountFilesystemMutation } from 'api/filesystemMicroserviceSlice';
import { Alert, Col, Container, Row } from 'react-bootstrap';

const FilesystemMounter: React.FC<{
  applicationId: string;
  microservice: IApplicationMicroservice<ComputeBoundMicroserviceTypeStrings, ComputeBoundMicroservice>;
  currentlyMountedFilesystems: IFilesystemMicroservice[],
}>
  = ({ microservice, applicationId, currentlyMountedFilesystems }) => {
    const [selectedFilesystem, setSelectedFilesystem] = useState<IApplicationMicroserviceFilesystem | null>(null);
    const [mountErrors, setMountErrors] = useState<FilesystemMountErrors | undefined>(undefined);
    const [mountFilesystem, { isLoading: mountIsLoading }] = useMountFilesystemMutation();
    const currentlyMountedIds = currentlyMountedFilesystems.map((fs: IFilesystemMicroservice) => fs.id);
    const { data, isLoading, error } = useGetApplicationMicroservicesQuery({
      applicationId,
      queryParameters: {
        include_type: 'filesystem',
        limit: 100,
      },
    });

    const disabled = isLoading || mountIsLoading;
    if (isLoading) return (<Spinner />);
    if (error || !data) return (<Error error={error} />);

    if (data.data.length === 0) {
      return (
        <Container>
          <Row>
            <Col>
              <Alert variant="info">
                {translate('noFilesystemsProvisionedYet')}
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    const notCurrentlyMountedFilesystems = data.data.filter((fsMs) => !currentlyMountedIds.includes(fsMs.application_microserviceable_id));

    return (
      <>
        <Typeahead
          id="filesystem-selector"
          disabled={disabled}
          labelKey="name"
          options={notCurrentlyMountedFilesystems}
          placeholder={translate('filesystem')}
          selected={selectedFilesystem ? [selectedFilesystem as Option] : []}
          onChange={(selected) => {
            if (selected.length > 0) {
              setSelectedFilesystem(selected.length ? selected[0] as IApplicationMicroserviceFilesystem : null);
            } else {
              setSelectedFilesystem(null);
            }
          }}
          // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
          renderMenuItemChildren={(fs: IApplicationMicroserviceFilesystem) => {
            return (
              <MenuItem
                label={fs.name}
                smallText={fs.description}
                searchText={fs.name}
              />
            );
          }}
        />
        {
          selectedFilesystem && (
            <>
              <hr />
              <FilesystemMountForm
                disabled={disabled}
                filesystem={selectedFilesystem.microserviceable}
                apiErrors={mountErrors}
                onSubmit={async (formInput) => {
                  const result: { data: IFilesystemMicroservice } | { error: FilesystemMountErrors }
                    = await mountFilesystem({
                      filesystemId: selectedFilesystem.microserviceable.id,
                      applicationMicroserviceId: microservice.id,
                      data: formInput,
                    });

                  if ('error' in result) {
                    setMountErrors(result.error);
                  } else {
                    setSelectedFilesystem(null);
                  }
                }}
                onCancel={() => setSelectedFilesystem(null)}
              />
            </>
          )
        }
      </>
    );
  };

export default FilesystemMounter;

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import { ImageTile } from '@apex/react-toolkit/components';
import ApplicationForm from 'common/application/ApplicationForm';

const ApplicationFormWithPreview = ({ isLoading, handleSubmit, handleCancel, apiErrors, initialValues, primaryAction }) => {
  const [formValues, setFormValues] = useState(initialValues);

  return (
    <Row className="justify-content-md-center">
      <Col md={4}>
        <ApplicationForm
          initialValues={initialValues}
          primaryAction={primaryAction}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          disabled={isLoading}
          apiErrors={apiErrors}
          onFormValuesChange={setFormValues}
        />
      </Col>
      <Col md={3}>
        <h5>{translate('tilePreview')}</h5>
        <ImageTile
          title={formValues?.name || 'Name'}
          icon={formValues?.icon || 'square'}
          backgroundColor={formValues?.background_color}
          src={`/api/applications/${initialValues.id}/icon-img`}
        />
      </Col>
    </Row>
  );
};

ApplicationFormWithPreview.defaultProps = {
  initialValues: {
    name: '',
    description: '',
    icon: 'square',
    background_color: '#43494F',
    text_color: 'text-light',
    scopes: [],
  },
};

ApplicationFormWithPreview.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
    background_color: PropTypes.arrayOf(PropTypes.string),
    text_color: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.arrayOf(PropTypes.string),
    scopes: PropTypes.arrayOf(PropTypes.string),
  }),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    background_color: PropTypes.string,
    text_color: PropTypes.string,
    icon: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string),
  }),
  primaryAction: PropTypes.string.isRequired,
};

export default ApplicationFormWithPreview;

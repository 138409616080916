import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import PaginationControl from 'common/PaginationControl';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';

import IOrganization from 'types/organization/IOrganization';
import useToast from 'hooks/useToast';
import IApplication from 'types/application/IApplication';
import OrganizationSearchFields from 'common/organizations/OrganizationSearchFields';
import AddApplicationOrganizationTable from 'App/AppRoutes/Applications/Application/Organizations/OrganizationsBody/AddOrganizationModal/AddApplicationOrganizationTable';
import useSearchOrganizations from 'hooks/useSearchOrganizations';
import { useAddApplicationOrganizationsMutation } from 'api/applicationSlice';
import AddedOrganizationList from './AddedOrganizationList';

const AddOrganizationModal: React.FC<{
  application: IApplication;
  show: boolean;
  handleClose: () => void;
}> = ({ show, handleClose, application }) => {
  const [selectedOrgHash, setSelectedOrgHash] = useState<{ [id: string]: IOrganization }>({});
  const [addApplicationOrgs, { isLoading: addOrgsLoading, error: addOrgsError }] = useAddApplicationOrganizationsMutation();
  const toast = useToast();

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
  } = useSearchOrganizations({ initialSearchParameters: { relationships: ['applications'] } });

  const {
    data: paginatedData,
    error,
    isLoading,
    isFetching,
  } = queryResult;

  if (isLoading) return (<Spinner size="10x" coverViewport />);
  if (error || addOrgsError || !paginatedData) return (<Error />);


  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w add-relation"
    >
      <Modal.Header className="border-0">
        <h1>{translate('addOrganizationsToApplication')}</h1>
      </Modal.Header>
      <Modal.Body>
        {(error || addOrgsError || !paginatedData) && <Error />}
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <OrganizationSearchFields
                  disabled={false}
                  setSearchObj={(searchObj) => {
                    const searchParameters = searchObj ? { [searchObj.option]: searchObj.value } : {};
                    if (searchObj) {
                      setSearchParameters({
                        ...searchParameters,
                        relationships: ['applications'],
                      })
                    }
                  }}
                  take={take}
                  setTake={setTake}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <AddApplicationOrganizationTable
                  application={application}
                  organizations={queryResult?.data?.data || []}
                  loading={isFetching || addOrgsLoading}
                  addSelectedOrg={(org: IOrganization) => {
                    const updatedSelectedOrgHash = { ...selectedOrgHash };
                    if (updatedSelectedOrgHash[org.id]) delete updatedSelectedOrgHash[org.id];
                    else updatedSelectedOrgHash[org.id] = org;
                    setSelectedOrgHash(updatedSelectedOrgHash);
                  }}
                  stagedOrgHash={selectedOrgHash}
                />
                <PaginationControl
                  pagesToShow={3}
                  pageCount={queryResult?.data?.meta?.last_page || 1}
                  currentPage={queryResult?.data?.meta?.current_page || 0}
                  onPageChange={setPage}
                />
              </Col>
              <Col md={6}>
                <AddedOrganizationList
                  addedOrgs={Object.values(selectedOrgHash)}
                  dismissOrg={org => {
                    const updatedSelectedOrgHash = { ...selectedOrgHash };
                    delete updatedSelectedOrgHash[org.id];
                    setSelectedOrgHash(updatedSelectedOrgHash);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={() => {
          setSelectedOrgHash({});
          handleClose();
        }}>
          {translate('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const response = await addApplicationOrgs({
              applicationId: application.id,
              data: Object.values(selectedOrgHash).map(({ id }) => id),
            });

            if ('error' in response) {
              toast({
                bg: 'danger',
                title: translate('error'),
                message: translate('anUnexpectedErrorOccurred'),
                autohide: false,
              });
            }
            setSelectedOrgHash({});
            handleClose();
          }}
        >
          {translate('addOrganizations')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOrganizationModal;

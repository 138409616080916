import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from "@apex/react-toolkit/components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IOrganization from 'types/organization/IOrganization';
import useSearchOrganizationGroups from 'hooks/useSearchOrganizationGroups';
import GroupSearchFields from 'common/group/GroupSearchFields';
import OrganizationGroupTable from './OrganizationGroupTable';

const GroupsTab: React.FC<{
  organization: IOrganization;
}> = ({
  organization
}) => {
  const navigate = useNavigate();

  const {
    result,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchOrganizationGroups({ organizationId: organization.id });

  const { isFetching, data } = result;

  return (
    <Container>
      <Row>
        <Col>
          <h2>
            {translate('groups')} <Badge pill>{data?.meta?.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          <Button variant="primary" onClick={() => navigate('groups/new')}>
            <FontAwesomeIcon icon="plus" />
            <span className="ms-2">{translate('addGroup')}</span>
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <GroupSearchFields
            disabled={isFetching}
            setSearchObj={({ option, value }) => {
              setSearchParameters({ [option]: value });
            }}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {
            (isFetching) ? (<Spinner size="5x" />)
              : (
                <OrganizationGroupTable
                  sortBy={sortBy}
                  setSortBy={(updatedSortKey) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortDirection={sortDirection}
                  groups={data?.data || []}
                  pageCount={data?.meta.last_page as number}
                  currentPage={data?.meta.current_page as number}
                  onPageChange={setPage}
                />
              )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default GroupsTab;

import { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import { useUpdateApplicationMicroserviceHttpPortMutation } from 'api/applicationMicroserviceSlice';
import useToast from 'hooks/useToast';

const HttpPortForm: FC<{
  microserviceId: string
  httpPort: number
  isDeveloperAuthorized: boolean
}> = ({ microserviceId, httpPort, isDeveloperAuthorized }) => {
  const schema = yup.object({
    http_port: yup.number().required(translate('httpPortRequired')),
  });
  const toast = useToast();
  const [editHttpPort, editResult] = useUpdateApplicationMicroserviceHttpPortMutation();

  const onSubmit = async (formData: { http_port: number }) => {
    try {
      await editHttpPort({
        id: microserviceId,
        ...formData,
      });

      toast({
        bg: 'success',
        title: translate('updated'),
        message: translate('httpPortUpdatedSuccessfully'),
        autohide: true,
      });
    } catch (e) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    }
  };

  return (
    <Card bg="dark">
      <Card.Body>
        <Formik
          validationSchema={schema}
          initialValues={{ http_port: httpPort }}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleReset,
            handleChange,
            touched,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Form.Group className="mb-3" controlId="formHttpPort">
                  <Form.Control
                    className="w-25"
                    required
                    name="http_port"
                    type="number"
                    placeholder={translate('httpPort')}
                    value={values.http_port}
                    onChange={handleChange}
                    isValid={touched.http_port && !errors.http_port}
                    readOnly={!isDeveloperAuthorized}
                  />
                </Form.Group>
              </Row>
              {
                isDeveloperAuthorized && (
                  <>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={handleReset}
                      disabled={editResult.isLoading}
                      className="me-2"
                    >
                      {translate('cancel')}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={editResult.isLoading}
                    >
                      {
                        editResult.isLoading
                          ? <Spinner size="1x" />
                          : translate('save')
                      }
                    </Button>
                  </>
                )
              }
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

HttpPortForm.propTypes = {
  httpPort: PropTypes.number.isRequired,
  microserviceId: PropTypes.string.isRequired,
};

export default HttpPortForm;

import PropTypes from 'prop-types';

export const SortPropTypes = {
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
};

export default PropTypes.shape({
  ...SortPropTypes,
});

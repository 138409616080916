import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import UserSearchFields from 'common/user/UserSearchFields';
import PaginationControl from 'common/PaginationControl';
import AddedMemberList from 'App/AppRoutes/Groups/Detail/DetailBody/AddGroupMemberModal/AddedMemberList';
import { Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import useSearchUsers, { UseSearchUsersSearchParameters } from 'hooks/useSearchUsers';
import { useAddOrganizationMembersMutation } from 'api/organizationSlice';
import AddOrganizationMemberTable from './AddOrganizationMemberTable';

import { IUserWithOrg } from 'types/IUser';
import IOrganization from 'types/organization/IOrganization';
import useToast from 'hooks/useToast';

const AddOrganizationMemberModal: React.FC<{
  organization: IOrganization;
  show: boolean;
  handleClose: () => void;
}> = ({ show, handleClose, organization }) => {
  const [selectedUserHash, setSelectedUserHash] = useState<{ [id: string]: IUserWithOrg }>({});
  const [addOrgMembers, { isLoading, error }] = useAddOrganizationMembersMutation();
  const toast = useToast();

  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
  } = useSearchUsers({
    initialSearchParameters: {
      relationships: ['organizations'],
    }
  });

  const setSearchParametersWithRelationship = (updatedSearchParams: UseSearchUsersSearchParameters) => {
    setSearchParameters({
      ...updatedSearchParams,
      relationships: ['organizations'],
    });
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w add-relation"
    >
      <Modal.Header className="border-0">
        <h1>{translate('addMembersToOrganization')}</h1>
      </Modal.Header>
      <Modal.Body>
        {
          error && (<Error />)
        }
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <UserSearchFields
                  disabled={queryResult.isFetching}
                  searchParameters={searchParameters}
                  setSearchParameters={setSearchParametersWithRelationship}
                  take={take}
                  setTake={setTake}
                  includeApproved={false}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <AddOrganizationMemberTable
                  organization={organization}
                  stagedMemberHash={selectedUserHash}
                  users={queryResult?.data?.data as IUserWithOrg[] || []}
                  loading={isLoading}
                  addSelectedUser={(user: IUserWithOrg) => {
                    const updatedSelectedUserHash = { ...selectedUserHash };
                    if (updatedSelectedUserHash[user.id]) delete updatedSelectedUserHash[user.id];
                    else updatedSelectedUserHash[user.id] = user;
                    setSelectedUserHash(updatedSelectedUserHash);
                  }}
                />
                <PaginationControl
                  pagesToShow={3}
                  pageCount={queryResult?.data?.meta?.last_page || 1}
                  currentPage={queryResult?.data?.meta?.current_page || 0}
                  onPageChange={setPage}
                />
              </Col>
              <Col md={6}>
                <AddedMemberList
                  addedMembers={Object.values(selectedUserHash)}
                  dimissUser={user => {
                    const updatedSelectedUserHash = { ...selectedUserHash };
                    delete updatedSelectedUserHash[user.id];
                    setSelectedUserHash(updatedSelectedUserHash);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={() => {
          setSelectedUserHash({});
          handleClose();
        }}>
          {translate('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const response = await addOrgMembers({
              organizationId: organization.id,
              data: Object.values(selectedUserHash).map(({ id }) => id),
            });

            if ('error' in response) {
              toast({
                bg: 'danger',
                title: translate('error'),
                message: translate('anUnexpectedErrorOccurred'),
                autohide: false,
              });
            }
            setSelectedUserHash({});
            handleClose();
          }}
        >
          {translate('addMembers')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOrganizationMemberModal;

import React, { useRef } from "react";
import { translate } from '@apex/react-toolkit/lib';
import { Button, ButtonProps } from 'react-bootstrap';

const UploadButton: React.FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  accept: React.InputHTMLAttributes<HTMLInputElement>["accept"]
  variant?: ButtonProps["variant"]
  btnText?: string
}> = ({
  onChange,
  accept,
  variant = "primary",
  btnText = translate('upload'),
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        onChange={onChange}
        className="d-none"
        type="file"
        accept={accept}
      />
      <Button
        variant={variant}
        onClick={handleUpload} >
        { btnText }
      </Button>
    </>
  );
}

export default UploadButton;

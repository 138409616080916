import { useMemo } from 'react';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import { useGetUserAppMaintainerRoleQuery } from 'api/authSlice';

const useIsApplicationMaintainer = (applicationId) => {
  const { isSuperUser } = useCheckUserPermission();
  const queryResult = useGetUserAppMaintainerRoleQuery({ applicationId });
  const { data } = queryResult;

  const isAppMaintainer = useMemo(() => {
    if (isSuperUser) return true;
    if (!data) return false;
    return !!data.maintainer_role;
  }, [data]);

  const isViewerAuthorized = useMemo(() => {
    return isAppMaintainer;
  }, [data]);

  const isAdministratorAuthorized = useMemo(() => {
    if (isSuperUser) return true;
    if (!data) return false;

    const userRole = data.maintainer_role;

    return [DEVELOPER, ADMINISTRATOR].includes(userRole);
  }, [data]);

  const isDeveloperAuthorized = useMemo(() => {
    if (isSuperUser) return true;
    if (!data) return false;

    return data.maintainer_role === DEVELOPER;
  }, [data]);

  const isAppMaintainerTypeAuthorized = (maintainerRole) => {
    if (isSuperUser) return true;
    if (!data) return false;

    const userRole = data.maintainer_role;

    if (maintainerRole === VIEWER) return isViewerAuthorized;
    if (maintainerRole === ADMINISTRATOR) return isAdministratorAuthorized;
    if (maintainerRole === DEVELOPER && userRole === true) isDeveloperAuthorized

    throw Error('Invalid role provided to isMaintainerRoleAuthorized')
  };

  return {
    ...queryResult,
    isAppMaintainer,
    isAppMaintainerTypeAuthorized,
    isViewerAuthorized,
    isAdministratorAuthorized,
    isDeveloperAuthorized,
    isSuperUser,
  };
};

export const ADMINISTRATOR = 'administrator';
export const DEVELOPER = 'developer';
export const VIEWER = 'viewer';

export default useIsApplicationMaintainer;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconWithTooltip from 'common/IconWithTooltip';
import useHealthcheck from 'hooks/useHealthcheck';

const HealthcheckStatus = ({ microserviceId, environment, tag }) => {
  const {isHealthy, isLoading} = useHealthcheck(microserviceId, environment, false);
  const isDev = environment === 'dev';
  const isDeployed = tag !== null;

  if (isLoading) return <FontAwesomeIcon icon="spinner" pulse color="grey" />;
  if (isHealthy === null) return null; // Healthcheck in progress
  if (!isDev && !isDeployed) return <FontAwesomeIcon icon="minus-circle" color="grey" />;
  if (isHealthy) return <IconWithTooltip icon="check-circle" color="green" tooltip={tag} />;
  return <IconWithTooltip icon="times-circle" color="red" tooltip={tag} />;
};

HealthcheckStatus.propTypes = {
  microserviceId: PropTypes.string.isRequired,
  environment: PropTypes.oneOf(['dev', 'staging', 'prod']).isRequired,
  tag: PropTypes.string,
};

export default HealthcheckStatus;

import React, { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Badge, Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useGetGroupApplicationsQuery } from 'api/groupSlice';
import { useGetOrgnanizationQuery, useDeleteOrganizationGroupMutation, useGetOrganizationGroupQuery } from 'api/organizationSlice';
import useSearchGroupRoles from 'hooks/useSearchGroupRoles';
import GroupApplicationsAndRolesAccordion from './GroupApplicationsAndRolesAccordion.tsx';
import styles from './styles.module.css';
import ApexCard from 'common/Card';
import ITeam from 'types/team/ITeam.js';
import UserPaginatedTable from 'common/user/UserPaginatedTable';
import UserSearchFields from 'common/user/UserSearchFields';
import useSearchGroupMembers from 'hooks/useSearchGroupMembers';
import useGetCurrentUserOrganizationRole from 'hooks/useGetCurrentUserOrganizationRole';
import DeleteGroupModal from 'common/group/DeleteGroupModal';
import useToast from 'hooks/useToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrganizationGroupDetail: React.FC = () => {
  const { groupId, orgId } = useParams();
  const {
    data: organization,
    isLoading,
    error,
  } = useGetOrgnanizationQuery(orgId as string);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { data: group, isLoading: groupIsLoading, error: groupError } = useGetOrganizationGroupQuery({ groupId: groupId as string, organizationId: orgId as string });
  const [deleteGroup, deleteGroupResult] = useDeleteOrganizationGroupMutation();
  const { data: applications } = useGetGroupApplicationsQuery(groupId as string);
  const { result: rolesResult } = useSearchGroupRoles({ groupId: groupId as string, defaultTake: 200 });
  const {
    result: membersResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchGroupMembers({ groupId: groupId as string });
  const { isAdmin } = useGetCurrentUserOrganizationRole({
    organizationId: orgId as string,
  });
  const toast = useToast();
  const groupRoles = rolesResult?.data?.data || [];

  useEffect(() => {
    if (deleteGroupResult.error) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    }

    if (deleteGroupResult.isSuccess) {
      toast({
        bg: 'success',
        title: translate('success'),
        message: translate('deleteSuccessful'),
        autohide: true,
      });
    }
  }, [deleteGroupResult]);

  if (isLoading || !organization || groupIsLoading || membersResult.isLoading) return <Spinner />
  if (error || groupError || !group) return <Error />

  return (
    <Container>
      {
        showDeleteModal && (
          <DeleteGroupModal
            show
            group={group}
            handleClose={() => setShowDeleteModal(false)}
            handleDelete={async (group) => {
              await deleteGroup({ organizationId: organization.id, group });
            }}
          />
        )
      }
      <Row className="mt-4">
        <Col>
          <h2>{organization.name}</h2>
          <h3>Group: {group.name}</h3>
          <p>{group.description}</p>
        </Col>
        <Col className="text-end">
          {isAdmin && (
            <>
              <Link to="edit" className="btn btn-primary">
                <FontAwesomeIcon icon="pencil" className="me-1" />
                {translate('edit')}
              </Link>
              <Button variant="danger" className="ms-2" onClick={() => setShowDeleteModal(true)}>
                <FontAwesomeIcon icon="trash-alt" className="me-1" />
                {translate('delete')}
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col className="">
          <ApexCard
            title={translate('teams')}
            body={(
              <Table variant="dark" striped>
                <thead>
                  <tr>
                    <th className="text-center">{translate('name')}</th>
                    <th className="text-center">{translate('carNumber')}</th>
                    <th className="text-center">{translate('series')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (group && group.teams) && group.teams.map((team: ITeam) => (
                      <tr key={team.id}>
                        <td>{team.name}</td>
                        <td>{team.car_number}</td>
                        <td>{team.series && team.series.name}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            )} />
        </Col>
        <Col>
          <Card bg="dark">
            <Card.Header>
              <h4>{translate('applicationsAndRoles')}</h4>
            </Card.Header>
            <Card.Body className={`${styles.hidesScrollBar} ${styles.applicationCardBody}`}>
              <GroupApplicationsAndRolesAccordion
                applications={applications || []}
                groupRoles={groupRoles}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h3>{translate('members')} <Badge pill>{membersResult?.data?.meta?.total}</Badge></h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <UserSearchFields
                disabled={membersResult.isFetching}
                searchParameters={searchParameters}
                setSearchParameters={setSearchParameters}
                take={take}
                setTake={setTake}
                includeApproved={false}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <UserPaginatedTable
                users={membersResult?.data?.data || []}
                sortBy={sortBy}
                setSortBy={(updatedSortKey) => {
                  if (sortBy === updatedSortKey) {
                    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
                  } else {
                    setSortBy(updatedSortKey);
                  }
                }}
                sortDirection={sortDirection}
                pageCount={membersResult?.data?.meta?.last_page || 1}
                currentPage={membersResult?.data?.meta?.current_page || 0}
                onPageChange={setPage}
                isFetching={membersResult.isFetching}
                approvalColumn={false}
                userUrl={`/organizations/${orgId}/users`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationGroupDetail;

import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { IApplicationMicroserviceFilesystem, IFilesystemMicroserviceMetadata } from 'types/application/microservice/IFilesystemMicroservice';
import { useGetFilesystemMicroserviceQuery } from 'api/filesystemMicroserviceSlice';
import FilesystemInfo from 'common/applicationMicroservice/filesystem/FilesystemInfo';
import Environment from 'types/Environment';

type FilesystemKey = 'dev_filesystem' | 'staging_filesystem' | 'prod_filesystem';

const FilesystemMicroserviceConfiguration: React.FC<{
  microservice: IApplicationMicroserviceFilesystem;
}> = ({ microservice }) => {
  const { microserviceable: filesystemMicroservice } = microservice;
  const { data: filesystem, isLoading, error } = useGetFilesystemMicroserviceQuery(filesystemMicroservice.id);

  if (isLoading) {
    return (<Spinner size="5x" />);
  }

  if (error || !filesystem) {
    return (<Error error={error} />);
  }

  const filesystemExists = filesystem.dev_filesystem || filesystem.staging_filesystem || filesystem.prod_filesystem;

  return (
    <Accordion alwaysOpen>
      {
        !filesystemExists && (
          <Card bg="dark" className="mt-3">
            <Card.Header>
              <Row>
                <Col className="text-start text-warning">
                  {translate('noFilesystemInstancesExistYet')}
                </Col>
              </Row>
            </Card.Header>
          </Card>
        )
      }
      {
        filesystemExists && ['dev', 'staging', 'prod'].map((env: string) => filesystem[`${env}_filesystem` as FilesystemKey]
          && (
            <FilesystemInfo
              environment={env as Environment}
              metadata={filesystem[`${env}_filesystem` as FilesystemKey] as IFilesystemMicroserviceMetadata}
              filesystemMicroservice={filesystem}
            />
          ))
      }
    </Accordion>
  );
};

export default FilesystemMicroserviceConfiguration;

import { useState } from 'react';

import WorkerForm from 'common/applicationMicroservice/worker/WorkerForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAddWorkerMicroserviceMutation } from 'api/workerMicroserviceSlice';
import { Alert } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import IWorkerMicroservice, { IWorkerMicroserviceFormErrors, IWorkerMicroserviceFormValues } from 'types/application/microservice/IWorkerMicroservice';
import { useGetNodeGroupTypesQuery } from 'api/nodeGroupSlice';

const NewWorker = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [addWorkerMicroservice, { isLoading, error: mutationError }] = useAddWorkerMicroserviceMutation();
  const { data: nodeGroupTypes, isLoading: nodeGroupTypesLoading, error: nodeGroupTypesError } = useGetNodeGroupTypesQuery();
  const [apiErrors, setApiErrors] = useState<undefined | IWorkerMicroserviceFormErrors['errors']>(undefined);

  const handleSubmit = async (formData: IWorkerMicroserviceFormValues) => {
    const result: { data: IWorkerMicroservice } | { error: IWorkerMicroserviceFormErrors }
    = await addWorkerMicroservice({ ...formData, application_id: applicationId as string });

    if ('error' in result) {
      setApiErrors(result.error.errors);
    } else if ('data' in result) {
      navigate(`/applications/${applicationId}/microservices`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/microservices/`);
  };

  if (nodeGroupTypesError) return <Error coverPage />;
  if (!nodeGroupTypes || nodeGroupTypesLoading) return <Spinner />;

  return nodeGroupTypes && (
    <>
      {(mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>)}
      <WorkerForm
        disabled={isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        apiErrors={apiErrors}
        nodeGroupTypes={nodeGroupTypes}
      />
    </>
  );
};

export default NewWorker;

import { apiSlice } from 'api/apiSlice';
import { NodeGroupFormValues } from 'common/infrastructure/nodeGroups/NodeGroupForm';
import IDeployment from 'types/IDeployment';
import IQueryParameters from 'types/IQueryParameters';
import PaginatedResult from 'types/PaginatedResult';
import INodeGroup from 'types/infrastructure/INodeGroup';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addNodeGroup: builder.mutation<INodeGroup, NodeGroupFormValues>({
      query: (data: INodeGroup) => ({ url: 'node-groups', method: 'post', data }),
      invalidatesTags: () => [{ type: 'NodeGroups' }],
    }),

    getNodeGroup: builder.query<INodeGroup, string>({
      query: (id: string) => ({ url: `node-groups/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'NodeGroups', id }],
    }),

    listNodeGroups: builder.query<PaginatedResult<INodeGroup>, IQueryParameters>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'node-groups', method: 'get', queryParameters };
      },
      providesTags: ['NodeGroups'],
    }),

    editNodeGroup: builder.mutation<INodeGroup, INodeGroup>({
      query: data => ({ url: `node-groups/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'NodeGroups', id }],
    }),

    deleteNodeGroup: builder.mutation<null, INodeGroup>({
      query: (data: INodeGroup) => ({ url: `node-groups/${data.id}`, method: 'delete', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'NodeGroups', id }],
    }),

    getNodeGroupDeployments: builder.query<PaginatedResult<IDeployment>, { nodeGroupId: string, queryParameters: IQueryParameters }>({
      query: ({ nodeGroupId, queryParameters }) => {
        return { url: `node-groups/${nodeGroupId}/deployments`, method: 'get', queryParameters }
      },
      providesTags: ['Deployments'],
    }),

    getNodeGroupTypes: builder.query<string[], void>({
      query: () => ({ url: 'node-group-types', method: 'get' }),
      providesTags: () => [{ type: 'NodeGroupTypes' }],
    })
  }),
});

export const {
  useAddNodeGroupMutation,
  useGetNodeGroupQuery,
  useGetNodeGroupTypesQuery,
  useListNodeGroupsQuery,
  useLazyListNodeGroupsQuery,
  useDeleteNodeGroupMutation,
  useEditNodeGroupMutation,
  useLazyGetNodeGroupDeploymentsQuery,
} = extendedApiSlice;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CronForm from './CronFrom';
import IScheduledJobCron from 'types/application/microservice/IScheduledJobCron';
import { useEditCronMutation } from 'api/scheduledJobCronSlice';

const CronCard: React.FC<{
  cron: IScheduledJobCron,
  canActivate: boolean
}> = ({ cron, canActivate }) => {
  const [updateCron, updateResult] = useEditCronMutation();

  return (
    <Card bg="dark">
      <Card.Body>
        <Row>
          <Col>
            <CronForm
              apiErrors={updateResult.error}
              disabled={updateResult.isLoading}
              onSubmit={(values: IScheduledJobCron) => updateCron(values)}
              canActivate={canActivate}
              initialValues={{
                ...cron
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CronCard;

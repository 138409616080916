import { useEffect } from 'react';
import { useLazyGetApplicationMicroserviceEventNotificationsQuery } from 'api/applicationMicroserviceSlice';

const useSearchApplicationMicroserviceEventNotifications = ({ microserviceId }) => {
  const [getApplicationMicroserviceEventNotifications, result] = useLazyGetApplicationMicroserviceEventNotificationsQuery();

  useEffect(() => {
    getApplicationMicroserviceEventNotifications({ microserviceId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationMicroserviceEventNotifications]);

  return {
    result,
  };
};

export default useSearchApplicationMicroserviceEventNotifications;

import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useLazyGetApplicationMicroserviceJobsQuery } from 'api/applicationMicroserviceSlice';

const useSearchApplicationMicroserviceJobs = ({ microserviceId }) => {
  const to = DateTime.now().plus({ days: 1 }).toJSDate();
  const from = DateTime.now().minus({ days: 7 }).toJSDate();

  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState('created_at');
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchType, setSearchType] = useState('');
  const [toDate, setToDate] = useState(to.toISOString());
  const [fromDate, setFromDate] = useState(from.toISOString());
  const [getApplicationMicroserviceJobs, result] = useLazyGetApplicationMicroserviceJobsQuery();

  const queryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };

  if (searchStatus !== '') queryParameters.status = searchStatus;
  if (searchType !== '') queryParameters.type = searchType;
  queryParameters.from_date = fromDate;
  queryParameters.to_date = toDate;

  useEffect(() => {
    if (searchStatus !== '') queryParameters.status = searchStatus;
    if (searchType !== '') queryParameters.type = searchType;

    getApplicationMicroserviceJobs({ microserviceId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationMicroserviceJobs, take, page, sortBy, sortDirection, toDate, fromDate, searchStatus]);

  useEffect(() => {
    setPage(1);
    getApplicationMicroserviceJobs({ microserviceId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStatus,
    setSearchStatus,
    searchType,
    setSearchType,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    page,
    setPage,
    result,
    refetch: () => getApplicationMicroserviceJobs({ microserviceId, queryParameters }),
  };
};

export default useSearchApplicationMicroserviceJobs;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SearchFilter from 'common/SearchFilter';
import { Row, Col, Badge, Form, Button } from 'react-bootstrap';
import { useLazyGetApplicationRolesQuery } from 'api/applicationSlice';
import PaginationControl from 'common/PaginationControl';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoleTable from 'App/AppRoutes/Applications/Application/Roles/RolesBody/RoleTable';
import DeleteRoleModal from 'common/applicationRole/DeleteRoleModal';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import { SortDirection } from 'types/ListSearchTypes';
import IApplicationRole from 'types/application/IApplicationRole';
import { SortBy } from './RoleTable';

const RolesBody: React.FC = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [roleQueuedForDelete, setRoleQueuedForDelete] = useState<IApplicationRole | null>(null);
  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState<SortBy>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [searchObj, setSearchObj] = useState<{ option: string, value: string } | null>(null);
  const [page, setPage] = useState(1);

  const [getApplicationRoles, { data: paginatedData, isLoading, isFetching }] = useLazyGetApplicationRolesQuery();
  const { isAdministratorAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);

  const queryParameters: {
    limit: number;
    page: number;
    sortBy: SortBy;
    sortDirection: SortDirection;
    name?: string;
    description?: string;
    any?: string;
  } = {
    limit: take,
    page,
    sortBy: sortBy,
    sortDirection: sortDirection,
  };
  const searchBy = searchObj?.option;
  const searchVal = searchObj?.value;

  if (searchBy && searchVal) queryParameters[searchBy as 'name' | 'description' | 'any'] = searchVal;

  useEffect(() => {
    getApplicationRoles({ queryParameters, applicationId: applicationId as string });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationRoles, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getApplicationRoles({
      applicationId: applicationId as string,
      queryParameters: {
        ...queryParameters,
        page: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObj, take]);

  if (isLoading || appAuthIsFetching || !paginatedData) return (<Spinner />);

  const { data: roles, meta } = paginatedData;

  return (
    <>
      {
        roleQueuedForDelete && (
          <DeleteRoleModal
            show
            handleClose={() => setRoleQueuedForDelete(null)}
            applicationRole={roleQueuedForDelete}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            {translate('roles')} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {isAdministratorAuthorized && (
            <Button onClick={() => navigate(`/applications/${applicationId}/roles/new`)}>
              <FontAwesomeIcon icon="plus" /> {translate('addRole')}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>{translate('searchBy')}</Form.Label>
          <SearchFilter
            disabled={isFetching}
            onUpdate={setSearchObj}
            options={[
              {
                option: 'name',
                display: translate('name'),
              },
              {
                option: 'description',
                display: translate('description'),
              },
              {
                option: 'any',
                display: translate('any'),
              },
            ]}
          />
        </Col>
        <Col md={{ span: 2, offset: 6 }}>
          <Form.Group>
            <Form.Label>{translate('resultsPerPage')}</Form.Label>
            <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <RoleTable
            canMutateRoles={isAdministratorAuthorized}
            roles={roles}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            sortDirection={sortDirection}
            applicationId={applicationId as string}
            queueRoleForDelete={setRoleQueuedForDelete}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default RolesBody;

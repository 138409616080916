import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';
import ApplicationMicroservicePropTypes from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';

const MicroserviceTable = ({ microservices, applicationId, sortBy, setSortBy, sortDirection }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/applications/${applicationId}/microservices/${id}`}>{first}</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
    {
      title: translate('dev'),
      dataIndex: 'dev_tag',
    },
    {
      title: translate('staging'),
      dataIndex: 'staging_tag',
      render: (_, { staging_tag }) => (staging_tag) ? staging_tag : <Badge bg="warning" text="dark">{translate('notDeployed')}</Badge>,
    },
    {
      title: translate('prod'),
      dataIndex: 'prod_tag',
      render: (_, { prod_tag }) => (prod_tag) ? prod_tag : <Badge bg="warning" text="dark">{translate('notDeployed')}</Badge>,
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={microservices}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

MicroserviceTable.propTypes = {
  ...SortPropTypes,
  applicationId: PropTypes.string.isRequired,
  microservices: PropTypes.arrayOf(ApplicationMicroservicePropTypes).isRequired,
};

export default MicroserviceTable;

import { apiSlice } from 'api/apiSlice';
import ITrustedSource, { ITrustedSourceFormFields } from 'types/application/ITrustedSource';
import IDatabaseMicroservice, { IDatabaseMicroserviceWithMetadata, IDmsReplication, IDmsReplicationConfig, INewDatabaseValues, ISupportedVersion } from 'types/application/microservice/IDatabaseMicroservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addDatabaseMicroservice: builder.mutation<IDatabaseMicroservice, INewDatabaseValues>({
      query: data => ({ url: 'database-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

    getDatabaseMicroservice: builder.query<IDatabaseMicroserviceWithMetadata, string>({
      query: (id: string) => ({ url: `database-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),

    listSupportedDatabases: builder.query<ISupportedVersion[], undefined>({
      query: () => {
        return { url: 'database-microservices/supported-databases', method: 'get' };
      },
      providesTags: ['SupportedDatabases'],
    }),

    addDatabaseTrustedSource: builder.mutation<ITrustedSource, { data: ITrustedSourceFormFields; dbId: string; }>({
      query: ({ data, dbId }) => ({ url: `database-microservices/${dbId}/trusted-sources`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'TrustedSources' }],
    }),

    getDatabaseTrustedSources: builder.query<ITrustedSource[], string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/trusted-sources`, method: 'get' }),
      providesTags: () => ['TrustedSources'],
    }),

    deleteDatabaseTrustedSource: builder.mutation<ITrustedSource[], { dbId: string, trustedSourceId: string }>({
      query: ({ dbId, trustedSourceId }) => ({ url: `database-microservices/${dbId}/trusted-sources/${trustedSourceId}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'TrustedSources' }],
    }),

    getDatabaseReplicationConfig: builder.query<IDmsReplicationConfig, string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/replication-config`, method: 'get' }),
      providesTags: () => ['DmsReplicationConfigs'],
    }),

    getDatabaseReplication: builder.query<IDmsReplication, string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/replication`, method: 'get' }),
      providesTags: () => ['DmsReplications'],
    }),

    createReplicationConfig: builder.mutation<null, string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/replication-config`, method: 'post' }),
      invalidatesTags: () => [{ type: 'DmsReplicationConfigs' }],
    }),

    startReplication: builder.mutation<null, string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/start-replication`, method: 'post' }),
    }),

    stopReplication: builder.mutation<null, string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/stop-replication`, method: 'post' }),
    }),

    deleteReplicationConfig: builder.mutation<null, string>({
      query: (dbId: string) => ({ url: `database-microservices/${dbId}/replication-config`, method: 'delete' }),
    }),
  }),
});

export const {
  useAddDatabaseMicroserviceMutation,
  useGetDatabaseMicroserviceQuery,
  useListSupportedDatabasesQuery,
  useAddDatabaseTrustedSourceMutation,
  useGetDatabaseTrustedSourcesQuery,
  useDeleteDatabaseTrustedSourceMutation,
  useGetDatabaseReplicationConfigQuery,
  useGetDatabaseReplicationQuery,
  useCreateReplicationConfigMutation,
  useStartReplicationMutation,
  useStopReplicationMutation,
  useDeleteReplicationConfigMutation,
} = extendedApiSlice;

import React, { Suspense } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import Search from 'App/AppRoutes/Users/Search';
import Detail from 'App/AppRoutes/Users/Detail';
import New from 'App/AppRoutes/Users/New';
import Edit from 'App/AppRoutes/Users/Edit';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';

const Users = () => {
  const { checkUserPermission } = useCheckUserPermission();

  if (!checkUserPermission()) {
    return (
      <Unauthorized />
    );
  }

  return (
    <Suspense fallback={<h1>{translate('loading')} . . .</h1>}>
      <Routes>
        <Route exact path="/" element={<Search />} />
        <Route path="new" element={<New />} />
        <Route path=":userId" element={<Detail />} />
        <Route path=":userId/edit" element={<Edit />} />
      </Routes>
    </Suspense>
  );
};

export default Users;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useEditApplicationMutation, useGetApplicationQuery } from 'api/applicationSlice';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import ApplicationFormWithPreview from 'common/application/ApplicationFormWithPreview';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import Unauthorized from 'common/Unauthorized';

const Edit = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { data: application, isFetching, error: fetchingError } = useGetApplicationQuery(applicationId);
  const [editApplication, { isLoading }] = useEditApplicationMutation();
  const { error: appAuthError, isSuperUser, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);

  const [apiErrors, setApiErrors] = useState(null);

  const handleSubmit = async (formData) => {
    const { error } = await editApplication(formData);
    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`../../${applicationId}`);
    }
  };

  const handleCancel = () => {
    navigate('../');
  };

  if (fetchingError || appAuthError) {
    return (<Error />);
  }

  if (isFetching || appAuthIsFetching) {
    return (<Spinner />);
  }

  if(!isSuperUser) {
    return (<Unauthorized />);
  }

  return (
    <Container>
      <ApplicationFormWithPreview
        isLoading={isLoading || isFetching}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        apiErrors={apiErrors}
        primaryAction={translate('save')}
        initialValues={{ ...application }}
      />
    </Container>
  );
};

export default Edit;

import React from 'react';
import PropTypes from 'prop-types';
import { Error } from '@apex/react-toolkit/components';

class ErrorBoundary extends React.Component {
  static propTypes = {
    renderError: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
  };

  static defaultProps = {
    renderError: ({ error }) => {
      return (
        <Error error={error} />
      );
    },
  };

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;

    if (error) {
      return this.props.renderError(
        {
          error,
          errorInfo,
          clearError: () => this.setState({ error: null, errorInfo: null }),
        }
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetApplicationQuery } from 'api/applicationSlice';
import GroupsBody from 'App/AppRoutes/Applications/Application/Groups/GroupsBody';

const Groups = () => {
  const { applicationId } = useParams();
  const { data: application, isFetching, error } = useGetApplicationQuery(applicationId);

  if (error) return (<Error coverPage />);
  if (isFetching) return (<Spinner size="10x" coverViewport />);

  return (
    <Container>
      <Row className="mt-4">
        <Col md={10}>
          <h1>{application.name}</h1>
        </Col>
      </Row>
      <GroupsBody />
    </Container>
  );
};

export default Groups;

import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const ApplicationsTabRedirect: React.FC = () => {
  const { orgId } = useParams();

  return (
    <Navigate to={`/organizations/${orgId}#applications`} />
  );
}

export default ApplicationsTabRedirect;

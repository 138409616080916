import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Button, Card, Col, FormControl, Row } from 'react-bootstrap';
import { IApplicationMicroserviceWorker } from 'types/application/microservice/IWorkerMicroservice';
import { useUpdateWorkerMicroserviceMutation } from 'api/workerMicroserviceSlice';

const NetworkConfiguration: React.FC<{
  microservice: IApplicationMicroserviceWorker;
  isDeveloperAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized }) => {
  const [updateWorker, { isLoading }] = useUpdateWorkerMicroserviceMutation();
  const worker = microservice.microserviceable;
  const [formPort, setFormPort] = useState<number | null>(worker.network_port);
  const updateEnabled = worker.network_port !== formPort;
  const disableBtnEnabled = worker.network_port !== null;

  return (
    <Card bg="dark">
      <Card.Body>
        <Row>
          <Col>
            <Row>
              <Col md={2}>
                <h3>{translate('endpoints')}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{translate('dev')}</strong>: {worker.dev_endpoint}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{translate('staging')}</strong>: {worker.staging_endpoint}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{translate('prod')}</strong>: {worker.prod_endpoint}
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Row>
            <Col md={3}>
              <h3>{translate('networkPort')}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="text-end">
              <FormControl
                readOnly={!isDeveloperAuthorized}
                disabled={isLoading}
                type="number"
                step={1}
                min={0}
                value={formPort || ''}
                onChange={e => setFormPort(parseInt(e.target.value) || null)}
              />
            </Col>
            <Col>
              <Button disabled={!updateEnabled || isLoading || !isDeveloperAuthorized} variant="primary" className="me-2" onClick={() => {
                updateWorker({
                  ...worker,
                  network_port: formPort,
                });
              }}>
                {translate('update')}
              </Button>
              <Button disabled={!disableBtnEnabled || isLoading || !isDeveloperAuthorized} variant="danger" onClick={async () => {
                await updateWorker({
                  ...worker,
                  network_port: null,
                });

                setFormPort(null);
              }}>
                {translate('disableNetworking')}
              </Button>
            </Col>
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NetworkConfiguration;

import React from 'react';
import { Spinner, Error } from "@apex/react-toolkit/components";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import GroupSearchFields from 'common/group/GroupSearchFields';
import GroupPaginatedTable from 'common/group/GroupPaginatedTable';
import useSearchTeamGroups from 'hooks/useSearchTeamGroups';
import useGetCurrentUserOrganizationRole from 'hooks/useGetCurrentUserOrganizationRole';
import Unauthorized from 'common/Unauthorized';
import useCheckUserPermission from 'hooks/useCheckUserPermission';

const GroupsTab = () => {
  const { teamId, orgId } = useParams();
  const { isAdmin } = useGetCurrentUserOrganizationRole({
    organizationId: orgId as string,
  });
  const { isSuperUser } = useCheckUserPermission();

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchTeamGroups({ teamId: teamId as string });

  const { data, isLoading, isFetching, error } = queryResult;
  if (!isAdmin && !isSuperUser) return (<Unauthorized />);
  if (isLoading || !data) return <Spinner size="10x" coverViewport />;
  if (error) return <Error />;

  return (
    <Container>
      <Row className="my-2">
        <Col>
          <GroupSearchFields
            disabled={isFetching}
            setSearchObj={({ option, value }) => {
              setSearchParameters({ [option]: value });
            }}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {
            (isFetching) ? (<Spinner size="5x" />)
              : (
                <GroupPaginatedTable
                  sortBy={sortBy}
                  setSortBy={(updatedSortKey) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortDirection={sortDirection}
                  groups={data?.data}
                  pageCount={data?.meta.last_page as number}
                  currentPage={data?.meta.current_page as number}
                  onPageChange={setPage}
                />
              )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default GroupsTab;

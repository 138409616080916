import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import PaginationControl from 'common/PaginationControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionsTable from 'App/AppRoutes/Applications/Application/Permissions/PermissionsBody/PermissionTable';
import DeletePermissionModal from 'App/AppRoutes/Applications/Application/Permissions/PermissionsBody/DeletePermissionModal';
import ApplicationPermissionSearchFields from 'common/applicationPermission/ApplicationPermissionSearchFields';
import useSearchApplicationPermissions from 'hooks/useSearchApplicationPermissions';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

const PermissionsBody = () => {
  const { applicationId } = useParams();
  const [permissionQueuedForDelete, setPermissionQueuedForDelete] = useState(null);
  const { isAdministratorAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    page,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchApplicationPermissions({ applicationId });

  if (queryResult.isLoading || !queryResult.data || appAuthIsFetching) return (<Spinner />);

  const { data: permissions, meta } = queryResult.data;

  return (
    <>
      {
        permissionQueuedForDelete && (
          <DeletePermissionModal
            show
            handleClose={() => setPermissionQueuedForDelete(null)}
            applicationPermission={permissionQueuedForDelete}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            {translate('permissions')} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {
            isAdministratorAuthorized && (
              <Button as={Link} to={`/applications/${applicationId}/permissions/new`}>
                <FontAwesomeIcon icon="plus" /> {translate('addPermission')}
              </Button>
            )
          }
        </Col>
      </Row>
      <ApplicationPermissionSearchFields
        disabled={queryResult.isFetching}
        setSearchParameters={setSearchParameters}
        take={take}
        setTake={setTake}
      />
      <Row className="mt-2">
        <Col>
          <PermissionsTable
            canMutatePermissions={isAdministratorAuthorized}
            permissions={permissions}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            applicationId={applicationId}
            queuePermissionForDelete={setPermissionQueuedForDelete}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default PermissionsBody;

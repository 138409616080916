import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const GroupsTabRedirect: React.FC = () => {
  const { orgId } = useParams();

  return (
    <Navigate to={`/organizations/${orgId}#groups`} />
  );
}

export default GroupsTabRedirect;

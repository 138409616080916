import React, { useState } from 'react';

import ApiForm from 'common/applicationMicroservice/api/ApiForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAddApiMicroserviceMutation } from 'api/apiMicroserviceSlice';
import { Alert } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';

const NewApi = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [addApiMicroservice, { isLoading, error: mutationError }] = useAddApiMicroserviceMutation();
  const [apiErrors, setApiErrors] = useState(null);


  const handleSubmit = async (formData) => {
    const { error } = await addApiMicroservice({ ...formData, application_id: applicationId });

    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/applications/${applicationId}/microservices`);
    }
  };

  const handleCancel = () => {
    navigate(`/applications/${applicationId}/microservices/`);
  };

  return (
    <>
      {(mutationError && !apiErrors) && (<Alert variant="danger">{translate('anUnexpectedErrorOccurred')}</Alert>)}
      <ApiForm
        disabled={isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        apiErrors={apiErrors}
      />
    </>
  );
};

export default NewApi;

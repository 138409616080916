import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InteractiveTable from 'common/InteractiveTable';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userPropTypeProperties } from 'propTypes/user/UserPropTypes';
import { translate } from '@apex/react-toolkit/lib';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';
import { capitalize } from 'lodash';
import { selectCurrentUser } from 'localState/authSlice';

const memberRoles = [
  'member',
  'admin',
];

const GroupMembersTable = ({ disabled, groupMembers, sortBy, setSortBy, sortDirection, setSelectedMember, onSelectMemberRole }) => {
  const currentUser = useSelector(selectCurrentUser);
  const columns = [
    {
      title: translate('firstName'),
      dataIndex: 'first_name',
      sortDirection: sortBy === 'first_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('first_name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/users/${id}`}>{first}</Link>),
    },
    {
      title: translate('lastName'),
      dataIndex: 'last_name',
      sortDirection: sortBy === 'last_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('last_name'),
        className: 'clickable',
      }),
    },
    {
      title: translate('email'),
      dataIndex: 'email',
      sortDirection: sortBy === 'email' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('email'),
        className: 'clickable',
      }),
    },
    {
      title: translate('role'),
      dataIndex: 'role',
      render: (_, member) => (
        <DropdownButton
          size="sm"
          variant="secondary"
          title={capitalize(member.role)}
          onSelect={(eventKey) => onSelectMemberRole(member.id, eventKey)}
          menuVariant="dark"
          disabled={member.id === currentUser.id}
        >
          {
            memberRoles.map(item => (
              <Dropdown.Item
                key={item}
                eventKey={item}
                active={item === member.role}
                as="button"
              >
                {capitalize(item)}
              </Dropdown.Item>
            ))
          }
        </DropdownButton>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_, member) => (
        <div className="text-center">
          <Button
            variant="danger"
            disabled={disabled}
            size="sm"
            onClick={() => setSelectedMember(member)}
          >
            <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={groupMembers}
      columns={columns}
    />
  );
};

GroupMembersTable.propTypes = {
  ...SortPropTypes,
  disabled: PropTypes.bool.isRequired,
  groupMembers: PropTypes.arrayOf(
    PropTypes.shape({
      ...userPropTypeProperties,
      role: PropTypes.oneOf(['member', 'admin']),
    })
  ).isRequired,
  setSelectedMember: PropTypes.func.isRequired,
  onSelectMemberRole: PropTypes.func.isRequired,
};

export default GroupMembersTable;

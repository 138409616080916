import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const groupProperties = {
  ...BaseModelPropTypes,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default PropTypes.shape({
  ...groupProperties,
});

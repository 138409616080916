import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import ApplicationPermissionPropTypes from 'propTypes/application/ApplicationPermissionPropTypes';

const AddedPermissionList = ({ addedPermissions, dimissPermission }) => {
  return (
    <Card style={{ minHeight: '100%' }}>
      <Card.Body variant="secondary">
        {
          addedPermissions.map(permission => (
            <Alert
              key={permission.id}
              dismissible
              className="alert-fit-content ms-3"
              onClose={() => dimissPermission(permission)}
            >
              {permission.name}
            </Alert>
          ))
        }
      </Card.Body>
    </Card>

  );
};

AddedPermissionList.propTypes = {
  addedPermissions: PropTypes.arrayOf(ApplicationPermissionPropTypes).isRequired,
  dimissPermission: PropTypes.func.isRequired,
};

export default AddedPermissionList;

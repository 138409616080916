import { apiSlice } from 'api/apiSlice';

import ITrustedSource from 'types/application/ITrustedSource';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTrustedSource: builder.query<ITrustedSource, string>({
      query: (id: string) => ({ url: `trusted-sources/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'TrustedSources', id }],
    }),
  }),
});

export const {
  useGetTrustedSourceQuery,
} = extendedApiSlice;

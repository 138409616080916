import { apiSlice } from 'api/apiSlice';
import IWorkerMicroservice, { INewWorkerMicroserviceValues } from 'types/application/microservice/IWorkerMicroservice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addWorkerMicroservice: builder.mutation<IWorkerMicroservice, INewWorkerMicroserviceValues >({
      query: (data: INewWorkerMicroserviceValues) => ({ url: 'worker-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    updateWorkerMicroservice: builder.mutation<IWorkerMicroservice, IWorkerMicroservice >({
      query: data => ({ url: `worker-microservices/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
    deleteWorkerMicroservice: builder.mutation<null, IWorkerMicroservice>({
      query: (data: IWorkerMicroservice) => ({
        url: `worker-microservices/${data.id}`, method: 'delete',
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

  }),
});

export const {
  useAddWorkerMicroserviceMutation,
  useUpdateWorkerMicroserviceMutation,
  useDeleteWorkerMicroserviceMutation,
} = extendedApiSlice;

import { Card, Col, Row, Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ITeam from 'types/team/ITeam';
import { NavLink, useParams } from 'react-router-dom';

const UserRoleTeamsCard: React.FC<{
  teams: ITeam[]
}> = ({ teams }) => {
  const { orgId } = useParams();

  return (
    <Card bg="dark" className="mt-2">
      <Card.Header className="ps-4 pe-0">
        <Row className="w-100">
          <Col>
            <h4>{translate('teams')}</h4>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table variant="dark" striped>
          <tbody>
            {
              teams?.map((team) => (
                <tr className="align-middle" key={team.id}>
                  {
                    team.organization_id === orgId ? (
                      <td>
                        <NavLink to={`/organizations/${orgId}/teams/${team.id}`}>
                          {team.name}
                        </NavLink>
                      </td>
                    ) : (
                      <td>
                        {team.name}
                      </td>
                    )
                  }
                  <td>
                    {team.series?.name}
                  </td>
                  <td>
                    no. {team.car_number}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default UserRoleTeamsCard;

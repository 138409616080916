import React from 'react';
import { Deployment } from 'kubernetes-types/apps/v1';
import { Card, Col, Row } from 'react-bootstrap';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { translate } from '@apex/react-toolkit/lib';

const getStatusTextColor = (statusText: string): string => {
  if (statusText === 'Available') return 'text-success';
  if (statusText === 'Progressing') return 'text-warn';
  return 'text-danger';
}

const DeploymentStatusCardHeader: React.FC<{ deployment: Deployment }> = ({ deployment }) => {
  const currentDeploymentStatusCondition = deployment.status?.conditions?.[deployment.status?.conditions?.length - 1];

  return (
    <Card.Header>
      <Row>
        <Col>
          <span className="text-muted">
            {
              deployment.status && deployment.status.conditions ? (
                <>
                  <span className="me-2">{translate('replicas')}:</span>
                  <span className={getStatusTextColor(currentDeploymentStatusCondition?.type as string)}>
                    {currentDeploymentStatusCondition?.type as string}
                  </span>
                  <span className="ms-2">{deployment.status?.replicas} / {deployment.status?.readyReplicas} / {deployment.status?.availableReplicas}</span>
                </>
              ) : (
                <span className="text-danger">Replica Status Unavailble at this time</span>
              )
            }
          </span>
        </Col>
        <Col className="text-end">
          <AccordionToggleLink
            eventKey="0"
            inactiveText={translate('view')}
            activeText={translate('close')}
          />
        </Col>
      </Row>
    </Card.Header>
  );
}

export default DeploymentStatusCardHeader;

import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getExecutableLinks: builder.query<string[], undefined>({
      query: () => ({ url: 'apex-cli-config/v1/executable-links', method: 'get' }),
    }),
  }),
});

export const {
  useGetExecutableLinksQuery,
} = extendedApiSlice;

import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Row, Col, Accordion, Badge, ListGroup, Card } from 'react-bootstrap';
import IStage, { IStageJob } from 'types/IStage';

const StageAccordion: React.FC<{ stage: IStage }> = ({ stage }) => {
  let statusClass = 'warning'
  if (stage.status === 'COMPLETE') statusClass = 'success';
  if (stage.status === 'FAILED') statusClass = 'danger';

  return (
    <Accordion key={stage.id} flush>
      <Accordion.Header>
        <Col md={1}>
          <strong className="text-primary">{translate('stage')}: {stage.index}</strong>
        </Col>
        <Col md={4}>
          <strong>{stage.type}</strong>
        </Col>
        <Col md={1}>
          {translate('jobs')}: <Badge bg="secondary" pill>{stage.jobs.length}</Badge>&nbsp;
        </Col>
        <Col md={{ span: 2, offset: 1 }}>
          <strong className={`text-${statusClass}`}>
            {stage.status}
          </strong>
        </Col>
      </Accordion.Header>
      <Accordion.Body style={{ padding: 0 }}>
        <Card>
          <ListGroup className="list-group-flush">
            {stage.jobs.map((stageJob: IStageJob) => {
              const { jobable } = stageJob;

              let jobStatusClass = 'warning'
              if (jobable.status === 'COMPLETE') jobStatusClass = 'success';
              if (jobable.status === 'FAILED') jobStatusClass = 'danger';

              return (
                <ListGroup.Item key={jobable.id}>
                  <Row>
                    <Col md={2}>
                      {stageJob.jobable_type}
                    </Col>
                    {
                      'type' in jobable && (
                        <Col md={3}>
                          {jobable.type}
                        </Col>
                      )
                    }
                    {
                      'target_environment' in jobable && (
                        <Col md={3}>
                          {jobable.target_environment}
                        </Col>
                      )
                    }
                    <Col>
                      <strong className={`text-${jobStatusClass}`}>
                        {jobable.status}
                      </strong>
                    </Col>
                    <Col>
                      {jobable.exception_message}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
      </Accordion.Body>
    </Accordion>
  );
};

export default StageAccordion;

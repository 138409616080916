import { FC } from 'react';
import { Alert, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Type = 'danger' | 'warning' | 'success' | 'info';

export const Admonition: FC<{ type: Type, message: string }> = ({ type, message }) => {
  const iconKeys = {
    'danger': 'times-circle',
    'warning': 'warning',
    'info': 'info',
    'success': 'check',
  };

  const getAdmonitionIcon = (type: string) => {
    if (type === 'info') {
      return (
        <Badge pill bg={type}>
          <FontAwesomeIcon icon="info" />
        </Badge>
      );
    }

    return (
      <>
        {/* @ts-expect-error adsfad */}
        <FontAwesomeIcon icon={iconKeys[type]} />
      </>
    );
  };

  return (
    <Alert variant={type} className={`border border-5 border-top-0 border-bottom-0 border-end-0 border-${type}`}>
      <Alert.Heading>
        {getAdmonitionIcon(type)}
        <span className="ms-2" style={{ textTransform: 'capitalize' }}>
          {type}!
        </span>
      </Alert.Heading>
      <p>{message}</p>
    </Alert>
  );
}

import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addLambdaMicroservice: builder.mutation({
      query: data => ({ url: 'lambda-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useAddLambdaMicroserviceMutation,
} = extendedApiSlice;

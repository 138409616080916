import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InteractiveTable from 'common/InteractiveTable';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationPermissionPropTypes from 'propTypes/application/ApplicationPermissionPropTypes';
import { translate } from '@apex/react-toolkit/lib';

const AddPermissionTable = ({ permissions, addSelectedPermission, currentPermissions, loading, stagedPermissionHash }) => {
  const currentPermissionHash = useMemo(() => {
    const permissionHash = {};
    currentPermissions.forEach(permission => {
      permissionHash[permission.id] = permission;
    });
    return permissionHash;
  }, [currentPermissions]);

  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
    },
    {
      title: translate('description'),
      dataIndex: 'description',
    },
    {
      title: '',
      dataIndex: 'id',
      onTd: () => ({ className: 'text-center' }),
      render: (_, permission) => {
        if (currentPermissionHash[permission.id]) return (<span className="text-success">{translate('added')}</span>);
        if (stagedPermissionHash[permission.id]) return (<span className="text-warning">{translate('queued')}</span>);
        return (
          <Button className="btn-xs" onClick={() => addSelectedPermission(permission)} disabled={loading}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        );
      },
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={permissions}
      columns={columns}
    />
  );
};

AddPermissionTable.propTypes = {
  permissions: PropTypes.arrayOf(ApplicationPermissionPropTypes).isRequired,
  addSelectedPermission: PropTypes.func.isRequired,
  currentPermissions: PropTypes.arrayOf(ApplicationPermissionPropTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  stagedPermissionHash: PropTypes.object.isRequired,
};

export default AddPermissionTable;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useRemoveApplicationOrganizationMutation } from 'api/applicationSlice';
import IOrganization from 'types/organization/IOrganization';

const RemoveOrganizationModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  applicationId: string;
  organization: IOrganization;
}> = ({ show, handleClose, applicationId, organization }) => {
  const [removeApplicationOrg, { isLoading }] = useRemoveApplicationOrganizationMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('removeOrganization')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmRemoveName', { name: organization.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await removeApplicationOrg({ applicationId, organizationId: organization.id });
            handleClose();
          }}
        >
          {translate('remove')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveOrganizationModal;

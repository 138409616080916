import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IApplicationRole from 'types/application/IApplicationRole';
import { useNavigate, useParams } from 'react-router-dom';
import styles from 'App/AppRoutes/Organizations/Detail/User/UserRoleDetailPanel/UserRoleListItem/UserRoleListItem.module.css';

const UserRoleListItem: React.FC<{
  appRole: IApplicationRole,
  setSelectedRole: (role: IApplicationRole) => void,
  isSelected: boolean,
}> = ({ appRole, setSelectedRole, isSelected }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const isOrgOwned = appRole.organization_id === orgId;

  return (
    <tr className={`align-middle ${isSelected ? styles.selectedSecondary : ''}`} onClick={() => setSelectedRole(appRole)}>
      {/* colSpan prop is so we don't render the button, but the row still fills the full table width */}
      <td className="py-3" colSpan={isOrgOwned ? 1 : 2}>
        {appRole.name}
      </td>
      {
        isOrgOwned && (
          <td className="pe-0 me-0 text-end">
            <OverlayTrigger
              trigger="click"
              placement="right"
              rootClose
              overlay={(
                <Popover>
                  <Popover.Body>
                    <Button
                      onClick={() => navigate(`applications/${appRole.application_id}/roles/${appRole.id}/edit`)}
                      variant="secondary"
                      size="sm"
                      className="text-nowrap"
                    >
                      <FontAwesomeIcon
                        icon="pencil"
                      />
                      <span className="ms-2">{translate('editRole')}</span>
                    </Button>
                  </Popover.Body>
                </Popover>
              )}
            >
              <Button
                variant="link"
                className="text-white"
              >
                <FontAwesomeIcon icon="ellipsis-v" />
              </Button>
            </OverlayTrigger>
          </td>
        )
      }
    </tr>
  );
};

export default UserRoleListItem;

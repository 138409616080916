import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Error, Spinner } from "@apex/react-toolkit/components";
import { useEditTeamMutation, useGetTeamQuery } from "api/teamSlice";
import ITeam, { ITeamApiErrors } from "types/team/ITeam";
import TeamForm, { InitialValues } from "common/teams/TeamForm";
import { translate } from "@apex/react-toolkit/lib";
import useSearchTeamMembers from "hooks/useSearchTeamMembers";
import Unauthorized from 'common/Unauthorized';
import useGetCurrentUserOrganizationRole from 'hooks/useGetCurrentUserOrganizationRole';

const EditTeam: React.FC = () => {
  const { teamId, orgId } = useParams();
  const { isAdmin } = useGetCurrentUserOrganizationRole({
    organizationId: orgId as string,
  });
  const { data: team, isLoading, error } = useGetTeamQuery(teamId as string);
  const { result: membersResult } = useSearchTeamMembers({ teamId: teamId as string, defaultTake: 25 });
  const { error: membersError, isLoading: membersLoading } = membersResult;
  const navigate = useNavigate();

  const [editTeam, { isLoading: editLoading, error: editError }] = useEditTeamMutation();

  if (isLoading || membersLoading) return (<Spinner />);
  if (orgId === undefined || membersError || !team) return (<Error coverPage />);
  if (!isAdmin) return (<Unauthorized />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate("editTeam")}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          {
            <TeamForm
              apiErrors={editError}
              initialValues={team}
              organizationId={orgId}
              submitText={translate('save')}
              currentUsers={membersResult?.data?.data || []}
              onSubmit={async (values: InitialValues) => {
                const result: { data: ITeam } | { error: ITeamApiErrors } =
                  await editTeam({ team, data: { ...values, organization_id: team.organization_id } });

                if (!error && "data" in result) {
                  navigate(`/organizations/${orgId}/teams/${result.data.id}`);
                }
              }}
              disabled={isLoading || membersLoading || editLoading}
              onCancel={() => navigate(`/organizations/${orgId}/teams/${teamId}`)}
            />
          }
        </Col>
      </Row>
    </Container>
  );
};

export default EditTeam;

import { Highlighter } from "react-bootstrap-typeahead";

const MenuItem: React.FC<{
  label: string
  smallText: string
  searchText: string
}> = ({ label, smallText, searchText }) => {
  return (
    <>
      <Highlighter search={searchText}>{label}</Highlighter>
      <div>
        <small>{smallText}</small>
      </div>
    </>
  );
};

export default MenuItem;

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';
import ApplicationMicroserviceJobPropTypes from 'propTypes/application/ApplicationMicroserviceJobPropTypes';
import { formatDateTime } from 'helpers/datetime';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ApplicationMicroserviceJobTable = ({ applicationMicroserviceJobs, sortBy, setSortBy, sortDirection }) => {
  const { isSuperUser } = useCheckUserPermission();

  const columns = [
    {
      title: translate('created'),
      dataIndex: 'created_at',
      sortDirection: sortBy === 'created_at' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('created_at'),
        className: 'clickable',
      }),
      render: createdAt => formatDateTime(createdAt),
    },
    {
      title: translate('updated'),
      dataIndex: 'updated_at',
      sortDirection: sortBy === 'updated' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('updated'),
        className: 'clickable',
      }),
      render: updatedAt => formatDateTime(updatedAt),
    },
    {
      title: translate('type'),
      dataIndex: 'type',
      sortDirection: sortBy === 'type' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('type'),
        className: 'clickable',
      }),
      render: type => translate(type),
    },
    {
      title: translate('status'),
      dataIndex: 'status',
      sortDirection: sortBy === 'status' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('status'),
        className: 'status',
      }),
      render: (status, job) => {
        return (
          <span>
            {status}
            {(status === 'FAILED' && isSuperUser) && (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {job.exception_message}
                  </Tooltip>
                }
              >
                <Badge pill bg="secondary" className="ms-2">
                  ?
                </Badge>
              </OverlayTrigger>
            )}
          </span>
        )
      }
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={applicationMicroserviceJobs}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

ApplicationMicroserviceJobTable.propTypes = {
  ...SortPropTypes,
  applicationMicroserviceJobs: PropTypes.arrayOf(ApplicationMicroserviceJobPropTypes).isRequired,
};

export default ApplicationMicroserviceJobTable;

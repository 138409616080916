import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import IGroup from 'types/group/IGroup';
import { SortBy } from 'hooks/useSearchGroups';
import { SortDirection } from 'types/ListSearchTypes';
import IOrganization from 'types/organization/IOrganization';
import { Badge } from 'react-bootstrap';

const GroupTable: React.FC<{
  groups: IGroup[];
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  actionColumn: InteractiveTableColumn<IGroup>
}> = ({ groups, sortBy, setSortBy, sortDirection, actionColumn }) => {
  const columns: InteractiveTableColumn<IGroup>[] = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (name, { id }) => (<Link to={`/groups/${id}`}>{name}</Link>),
    },
    {
      title: translate('organization'),
      dataIndex: 'organization',
      render: (organization?: IOrganization) => organization ? organization.name : <Badge bg="warning">{translate('n/a')}</Badge>,
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={groups}
      columns={columns}
    />
  );
};

export default GroupTable;

// given 'Apex Admin K8s Proxy'
//     kebabCase from lodash would output 'apex-admin-k-8-s-proxy'
//     this function will output 'apex-admin-k8s-proxy'
// the latter is inline with the Str::slug() function we use in PHP
export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

import { FC } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { Link } from 'react-router-dom';
import { NodeGroupSortBy } from 'hooks/useSearchNodeGroups';
import INodeGroup from 'types/infrastructure/INodeGroup';
import { SortDirection } from 'types/ListSearchTypes';

const NodeGroupTable: FC<{
  nodeGroups: INodeGroup[]
  sortBy: NodeGroupSortBy
  setSortBy: (sortBy: NodeGroupSortBy) => void
  sortDirection: SortDirection
}> = ({ nodeGroups, sortBy, setSortBy, sortDirection }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first: string, { id }: { id: string }) => (<Link to={`/infrastructure/node-groups/${id}`}>{ first }</Link>),
    },
    {
      title: translate('instanceTypes'),
      dataIndex: 'instance_types',
      render: (instanceTypes: string[]) => (<p>{instanceTypes?.join(', ')}</p>)
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={nodeGroups}
      columns={columns}
      // @ts-expect-error need to pass sort direction but it's technically not a prop?
      sortDirection={sortDirection}
    />
  );
};

export default NodeGroupTable;

import React, { Suspense } from 'react';
import { useParams, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import Cards from 'App/AppRoutes/Applications/Application/Roles/Detail/Cards';
import Permissions from 'App/AppRoutes/Applications/Application/Roles/Detail/Permissions';
import Groups from 'App/AppRoutes/Applications/Application/Roles/Detail/Groups';
import PageNotFound from 'common/PageNotFound';

const Detail: React.FC = () => {
  const params = useParams();
  const applicationId = params.applicationId as string;
  const roleId = params.roleId as string;

  return (
    <Container className="mt-4">
      <Suspense fallback={<h1>{translate('loading')} . . .</h1>}>
        <Routes>
          <Route path="/" element={<Cards applicationId={applicationId} roleId={roleId} />} />
          <Route path="/permissions" element={<Permissions applicationId={applicationId} roleId={roleId} />} />
          <Route path="groups" element={<Groups />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Container>
  );
};

export default Detail;

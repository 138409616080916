import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';


export default PropTypes.shape({
  ...BaseModelPropTypes,
  exception_message: PropTypes.string,
  status: PropTypes.string.isRequired,
  target_environment: PropTypes.string.isRequired,
  execute_at: PropTypes.string.isRequired,
});

import { useState, useEffect } from 'react';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import { useLazyListOrganizationTeamsQuery } from 'api/organizationSlice';
import ITeam from 'types/team/ITeam';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';

export type SortBy = 'name' | 'car_number';

export type UseSearchTeamsSearchParameters = {
  name?: string
  domain?: string
  any?: string
};


const useSearchOrganizationTeams:
  (args: {organizationId: string, defaultTake?: number }) => {
    setTake: (take: number) => void;
    take: number;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    sortDirection: SortDirection;
    setSortDirection: (sortDirection: SortDirection) => void;
    setSearchParameters: (searchParameters: UseSearchTeamsSearchParameters) => void;
    searchParameters: UseSearchTeamsSearchParameters;
    page: number
    setPage: (page: number) => void;
    result: RtkQueryResult<PaginatedResult<ITeam>>
  }
  = ({ organizationId, defaultTake = 10 }) => {
    const [take, setTake] = useState<number>(defaultTake);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [page, setPage] = useState<number>(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [searchParameters, setSearchParameters] = useState<UseSearchTeamsSearchParameters>({});
    const [getTeams, result] = useLazyListOrganizationTeamsQuery();

    const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchTeamsSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      getTeams({ organizationId, queryParameters });
    }, [getTeams, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      getTeams({ organizationId, queryParameters });
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchOrganizationTeams;

import React from 'react';
import { IApplicationMicroserviceFilesystem } from 'types/application/microservice/IFilesystemMicroservice';
import { translate } from '@apex/react-toolkit/lib';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { Badge, Card, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { useGetMountedMicroservicesQuery } from 'api/filesystemMicroserviceSlice';
import { ComputeBoundMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MountedMicroservices: React.FC<{ microservice: IApplicationMicroserviceFilesystem }> = ({
  microservice,
}) => {
  const { data, isLoading, error } = useGetMountedMicroservicesQuery(microservice.application_microserviceable_id);

  if (isLoading) return (<Spinner />);
  if (error) return <Error />;

  return (
    <Card bg="dark">
      <Card.Body>
        {!data?.length ? (
          <p className="text-warning">
            {translate('noMicroservicesMountedYet')}
          </p>
        ): (
                  <Table variant="dark">
                  <thead>
                    <tr>
                      <th>
                        {translate('name')}
                        <OverlayTrigger
                          rootClose
                          overlay={(
                            <Popover>
                              <Popover.Body>
                                {translate('mountedOnMicroserviceDetailInfo')}
                              </Popover.Body>
                            </Popover>
                          )}
                        >
                          <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                            <FontAwesomeIcon icon="info" />
                          </Badge>
                        </OverlayTrigger>
                      </th>
                      <th>{translate('type')}</th>
                      <th>{translate('mountPath')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((computeBoundMs: ComputeBoundMicroservice) => {
                      return (
                        <tr key={computeBoundMs.id}>
                          <td>
                            <Link to={`/applications/${computeBoundMs?.application_microservice?.application_id}/microservices/${computeBoundMs?.application_microservice?.id}`}>
                              {computeBoundMs?.application_microservice?.name}
                            </Link>
                          </td>
                          <td>
                            <Badge bg="secondary">
                              {computeBoundMs?.application_microservice?.application_microserviceable_type}
                            </Badge>
                          </td>
                          <td><pre>{computeBoundMs?.mount_path}</pre></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
        )}
      </Card.Body>
    </Card>
  )
};

export default MountedMicroservices;

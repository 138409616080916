import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import SearchFilter from 'common/SearchFilter';
import { UseSearchNodeGroupsSearchParameters } from 'hooks/useSearchNodeGroups';

const NodeGroupSearchFields: FC<{
  disabled: boolean;
  setSearchParameters: (searchParameters: UseSearchNodeGroupsSearchParameters) => void
  take: number;
  setTake: (value: number) => void;
}> = ({ disabled, setSearchParameters, take, setTake }) => {
  return (
    <Row>
      <Col md={6} className="text-start">
        <Form.Label>{translate('searchBy')}</Form.Label>
        <SearchFilter
          disabled={disabled}
          onUpdate={option => {
            const updatedParameters = {
              [option.option]: option.value,
            };
            setSearchParameters(updatedParameters);
          }}
          options={[
            {
              option: 'name',
              display: translate('name'),
            },
            {
              option: 'environment',
              display: translate('environment'),
            },
            {
              option: 'any',
              display: translate('any'),
            },
          ]}
        />
      </Col>
      <Col md={{ span: 3, offset: 3 }} className="text-end">
        <Form.Group>
          <Form.Label>{translate("resultsPerPage")}</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={take}
            onChange={(e) => setTake(+e.target.value)}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default NodeGroupSearchFields;

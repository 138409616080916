import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IApplicationRole from 'types/application/IApplicationRole';
import { SortDirection } from 'types/ListSearchTypes';
import IOrganization from 'types/organization/IOrganization';

export type SortBy = 'name' | 'description';

const RoleTable: React.FC<{
  roles: IApplicationRole[]
  sortBy: SortBy
  setSortBy: (sortBy: SortBy) => void
  sortDirection: SortDirection
  applicationId: string
  queueRoleForDelete: (role: IApplicationRole | null) => void
  canMutateRoles?: boolean
}> = ({ roles, sortBy, setSortBy, sortDirection, applicationId, queueRoleForDelete, canMutateRoles = false }) => {
  const columns: InteractiveTableColumn<IApplicationRole>[] = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first: string, { id }: IApplicationRole) => (<Link to={`/applications/${applicationId}/roles/${id}`}>{first}</Link>),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
      sortDirection: sortBy === 'description' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('description'),
        className: 'clickable',
      }),
    },
    {
      title: translate('organization'),
      dataIndex: 'organization',
      render: (organization: IOrganization | null) => {
        if (!organization) {
          return translate('n/a');
        }
        return organization.name;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_: string, role: IApplicationRole) => {
        // Org Admin should be the only ones who can delete roles belonging to apps. We'll
        // have to sort out what the long term solution is for this later

        return canMutateRoles && (
          <Button
            disabled={!canMutateRoles || !!role.organization}
            variant="danger"
            size="sm"
            className="text-nowrap"
            onClick={() => queueRoleForDelete(role)}
          >
            <FontAwesomeIcon icon="trash" fixedWidth /> {translate('delete')}
          </Button>
        );
      },
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={roles}
      columns={columns}
    />
  );
};

export default RoleTable;

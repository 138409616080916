/* eslint-disable camelcase */
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { startCase } from 'lodash';
import { useGetApplicationQuery } from 'api/applicationSlice';
import { useGetApplicationMicroserviceQuery } from 'api/applicationMicroserviceSlice';
import { useGetApplicationRoleQuery } from 'api/applicationRoleSlice';
import { useGetApplicationPermissionQuery } from 'api/applicationPermissionSlice';
import { useGetUserQuery } from 'api/userSlice';
import { useGetGroupQuery } from 'api/groupSlice';
import { useGetOrgnanizationQuery } from 'api/organizationSlice';
import { useGetTeamQuery } from 'api/teamSlice';
import { useGetNodeGroupQuery } from 'api/nodeGroupSlice';
import { useGetKubernetesRoleConfigQuery } from 'api/roleConfigsSlice';
import { useGetTrustedSourceQuery } from 'api/trustedSourceSlice';

const NamedSegment = ({ id, getDataFunc, getDisplayableText = (data) => data.name }) => {
  const { data } = getDataFunc(id);
  if (!data) return id;
  return getDisplayableText(data);
};

const Breadcrumbs = () => {
  const location = useLocation();
  const segments = location.pathname.split('/');
  let curRef = '/';

  segments.shift();
  if (segments[segments.length - 1] === '') segments.pop();
  const len = segments.length;

  return (
    <Breadcrumb className="mt-3">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
        Home
      </Breadcrumb.Item>
      {segments.map((segment, idx) => {
        curRef += `${segment}/`;
        const prevIndex = idx - 1;
        const prevSegment = prevIndex >= 0 && segments[prevIndex];

        return (
          <Breadcrumb.Item
            active={(idx === len - 1)}
            linkAs={Link}
            linkProps={{ to: curRef }}
            key={segment}
          >
            {
              // eslint-disable-next-line func-names
              (function () {
                if (prevSegment === 'applications') return (<NamedSegment id={segment} getDataFunc={useGetApplicationQuery} />);
                if (prevSegment === 'microservices') return (<NamedSegment id={segment} getDataFunc={useGetApplicationMicroserviceQuery} />);
                if (prevSegment === 'roles') return (<NamedSegment id={segment} getDataFunc={useGetApplicationRoleQuery} />);
                if (prevSegment === 'permissions') return (<NamedSegment id={segment} getDataFunc={useGetApplicationPermissionQuery} />);
                if (prevSegment === 'users') return (<NamedSegment id={segment} getDataFunc={useGetUserQuery} getDisplayableText={({ first_name, last_name }) => `${first_name} ${last_name}`} />);
                if (prevSegment === 'groups') return (<NamedSegment id={segment} getDataFunc={useGetGroupQuery} />);
                if (prevSegment === 'organizations') return (<NamedSegment id={segment} getDataFunc={useGetOrgnanizationQuery} />);
                if (prevSegment === 'teams') return (<NamedSegment id={segment} getDataFunc={useGetTeamQuery} />);
                if (prevSegment === 'node-groups') return (<NamedSegment id={segment} getDataFunc={useGetNodeGroupQuery} />);
                if (prevSegment === 'kubernetes-roles') return (<NamedSegment id={segment} getDataFunc={useGetKubernetesRoleConfigQuery} />);
                if (prevSegment === 'trusted-sources') return (<NamedSegment id={segment} getDataFunc={useGetTrustedSourceQuery} />);
                return startCase(segment.split('-').join(' '));
              }())
            }
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;

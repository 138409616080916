import React from 'react';
import PaginationControl from 'common/PaginationControl';
import UserTable from 'common/user/UserTable';
import IUser from 'types/IUser';
import { SortBy } from 'hooks/useSearchUsers';
import { SortDirection } from 'types/ListSearchTypes';
import { InteractiveTableColumn } from 'common/InteractiveTable';

const UserPaginatedTable: React.FC<{
  users: IUser[]
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  setSortDirection?: (sortDirection: SortDirection) => void;
  actionColumn?: InteractiveTableColumn<IUser>;
  isFetching?: boolean;
  approvalColumn?: boolean;
  userUrl?: string;
}> = ({
  users,
  pageCount,
  currentPage,
  onPageChange,
  sortBy,
  setSortBy,
  sortDirection,
  actionColumn,
  isFetching = false,
  approvalColumn = true,
  setSortDirection,
  userUrl
}) => (
    <>
      <UserTable
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        users={users}
        actionColumn={actionColumn}
        isFetching={isFetching}
        approvalColumn={approvalColumn}
        setSortDirection={setSortDirection}
        userUrl={userUrl}
      />
      <PaginationControl
        pagesToShow={3}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </>
  );

export default UserPaginatedTable;

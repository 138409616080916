import { useEffect, useState } from 'react';
import { useLazyListApexHelmChartVersionsQuery } from 'api/apexHelmChartVersionSlice';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import PaginatedResult from 'types/PaginatedResult';
import RtkQueryResult from 'types/RtkQueryResult';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';

export type ApexHelmChartVersionsSortBy = 'chart_name' | 'version' | 'deprecation_date' | 'eol_date';
export type UseSearchApexHelmChartVersionsSearchParameters = {
  chart_name?: string
  any?: string
}

const useSearchApexHelmChartVersions:
  (args?: { initialSearchParameters: UseSearchApexHelmChartVersionsSearchParameters }) => {
    setTake: (take: number) => void
    take: number
    sortBy: ApexHelmChartVersionsSortBy
    setSortBy: (sortBy: ApexHelmChartVersionsSortBy) => void
    sortDirection: SortDirection
    setSortDirection: (sortDirection: SortDirection) => void
    setSearchParameters: (searchParameters: UseSearchApexHelmChartVersionsSearchParameters) => void
    searchParameters: UseSearchApexHelmChartVersionsSearchParameters
    page: number
    setPage: (page: number) => void
    result: RtkQueryResult<PaginatedResult<IApexHelmChartVersion>>
  } = (args) => {
    const initialSearchParameters = args?.initialSearchParameters || {};
    const [take, setTake] = useState<number>(10);
    const [sortBy, setSortBy] = useState<ApexHelmChartVersionsSortBy>('chart_name');
    const [page, setPage] = useState<number>(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [searchParameters, setSearchParameters] = useState<UseSearchApexHelmChartVersionsSearchParameters>(initialSearchParameters);
    const [fetchApexHelmChartVersions, result] = useLazyListApexHelmChartVersionsQuery();

    const queryParameters: PagniateSortSearchQueryParameters<ApexHelmChartVersionsSortBy, UseSearchApexHelmChartVersionsSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      fetchApexHelmChartVersions(queryParameters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchApexHelmChartVersions, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      fetchApexHelmChartVersions(queryParameters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchApexHelmChartVersions;

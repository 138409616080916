import React from 'react';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { useGetApplicationPipelinesQuery } from 'api/applicationSlice';
import PiplineAccordion from 'common/pipeline/PiplineAccordion';
import IPipeline from 'types/IPipeline';
import IApplication from 'types/application/IApplication';

const ApplicationPipelineMonitor: React.FC<{ application: IApplication }> = ({ application }) => {
  const { data, isLoading, error } = useGetApplicationPipelinesQuery({ applicationId: application.id });

  if (isLoading) return (<Spinner />);
  if (error || !data) return (<Error error={error} />);

  return (
    <>
      {data.data.map((pipeline: IPipeline) => <PiplineAccordion pipeline={pipeline} key={pipeline.id} />)}
    </>
  );
};

export default ApplicationPipelineMonitor;

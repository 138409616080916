import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import ApplicationMicroserviceTable from 'common/applicationMicroservice/ApplicationMicroserviceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

import { translate } from '@apex/react-toolkit/lib';

const MicroservicesBody = () => {
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);

  const {
    result: queryResult,
    sortBy,
    setSortBy,
    sortDirection,
  } = useSearchApplicationMicroservices({ applicationId });

  return (
    <>
      <Row>
        <Col>
          <h2>{translate('microservices')}</h2>
        </Col>
        <Col className="text-end">
          {
            isDeveloperAuthorized && (
              <Button as={Link} to={`/applications/${applicationId}/microservices/new`}>
                <FontAwesomeIcon icon="plus" /> {translate('addMicroservice')}
              </Button>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicationMicroserviceTable
            applicationId={applicationId}
            microservices={queryResult?.data?.data || []}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
          />
        </Col>
      </Row>
    </>
  );
};

export default MicroservicesBody;

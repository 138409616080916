import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import { useEditNodeGroupMutation, useGetNodeGroupQuery } from 'api/nodeGroupSlice';
import { INodeGroupApiErrors } from 'types/infrastructure/INodeGroup';
import NodeGroupForm, { NodeGroupFormValues } from 'common/infrastructure/nodeGroups/NodeGroupForm';
import Unauthorized from 'common/Unauthorized';

const EditNodeGroup: FC = () => {
  const navigate = useNavigate();
  const { nodeGroupId } = useParams();
  const [apiErrors, setApiErrors] = useState<Pick<INodeGroupApiErrors, 'errors'> | null>(null);
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();

  const { data: nodeGroup, isFetching, error } = useGetNodeGroupQuery(nodeGroupId as string);

  const [editNodeGroup, { isLoading }] = useEditNodeGroupMutation();

  if (!isSuperUser) return <Unauthorized />;
  if (isFetching || permissionLoading) return (<Spinner size="10x" coverViewport />);
  if (error || !nodeGroup) return (<Error coverPage />);

  const handleSubmit = async (formData: NodeGroupFormValues) => {
    // @ts-expect-error don't know why this is erroring
    const { error } = await editNodeGroup({ ...formData, id: nodeGroup.id });
    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/infrastructure/node-groups/${nodeGroup.id}`);
    }
  };

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>Edit {nodeGroup.name}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
        <NodeGroupForm
            initialValues={nodeGroup}
            disabled={isLoading}
            onCancel={() => navigate('..')}
            onSubmit={handleSubmit}
            apiErrors={apiErrors}
            // @ts-expect-error this is a valid icon
            icon="floppy-disk"
            submitText={translate('save')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EditNodeGroup;

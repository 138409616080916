import React from 'react';
import { Error } from '@apex/react-toolkit/components';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useDeleteApplicationRoleMutation } from 'api/applicationRoleSlice';
import IApplicationRole from 'types/application/IApplicationRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteRoleModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  applicationRole?: IApplicationRole;
  handleDelete?: (role: IApplicationRole) => Promise<void>;
}> = ({ show, handleClose, applicationRole, handleDelete }) => {
  const [deleteRole, { isLoading }] = useDeleteApplicationRoleMutation();

  if (!applicationRole) {
    return (<Error message={translate('noRoleProvided')} />);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteRole')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmDeleteApplicationRole', { roleName: applicationRole.name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon="ban" className="me-1" />
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            if (handleDelete) {
              await handleDelete(applicationRole);
            } else {
              await deleteRole(applicationRole);
            }
            handleClose();
          }}
        >
          <FontAwesomeIcon icon="trash-alt" className="me-1" />
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteRoleModal;

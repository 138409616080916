import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Badge, Button } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import GroupPaginatedTable from 'common/group/GroupPaginatedTable';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupSearchFields from 'common/group/GroupSearchFields';
import useSearchGroups from 'hooks/useSearchGroups';

const Search = () => {
  const navigate = useNavigate();
  const {
    result: queryResult,
    setSearchObj,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchGroups({ relationships: ['organization'] });

  const { error, isFetching, data: paginatedData } = queryResult;

  if (error) return (<Error coverPage />);
  if (!paginatedData) return (<Spinner size="10x" coverViewport />);

  const { data, meta } = paginatedData;

  return (
    <Container>
      <Row className="mt-5">
        <Col md={4}>
          <h2>{translate('groups')} <Badge pill bg="secondary">{meta.total}</Badge></h2>
        </Col>
        <Col md={{ span: 2, offset: 6 }} className="text-end">
          <Button onClick={() => navigate('/groups/new')}>
            <FontAwesomeIcon icon="plus" /> {translate('addGroup')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <GroupSearchFields
            disabled={queryResult.isFetching}
            setSearchObj={setSearchObj}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {
            (isFetching) ? (<Spinner size="5x" />)
              : (
                <GroupPaginatedTable
                  sortBy={sortBy}
                  setSortBy={(updatedSortKey) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortDirection={sortDirection}
                  groups={data}
                  pageCount={meta.last_page}
                  currentPage={meta.current_page}
                  onPageChange={setPage}
                />
              )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default Search;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import RemoveMicroserviceModal from 'common/applicationMicroservice/RemoveApplicationMicroserviceModal';

const ApplicationMicroserviceDangerZone = ({ microservice }) => {
  const [microserviceQueuedForRemoval, setMicroserviceQueuedForRemoval] = useState(null);

  return (
    <>
      {
        microserviceQueuedForRemoval && (
          <RemoveMicroserviceModal
            show={microserviceQueuedForRemoval !== null}
            handleClose={() => setMicroserviceQueuedForRemoval(null)}
            microservice={microservice}
          />
        )
      }
      <Card bg="dark">
        <Card.Body>
          <Button
            variant="danger"
            onClick={() => setMicroserviceQueuedForRemoval(microservice)}
          >
            Delete microservice
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

ApplicationMicroserviceDangerZone.propTypes = {
  microservice: PropTypes.object.isRequired,
};

export default ApplicationMicroserviceDangerZone;

import { translate } from '@apex/react-toolkit/lib';
import { useLazySearchIamPoliciesQuery, useImportPolicyMutation } from 'api/iamPolicySlice';
import ConfirmationModal from 'common/ConfirmationModal/ConfirmationModal';
import MenuItem from 'common/typeahead/MenuItem';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router-dom';
import { AwsIamPolicy } from 'types/infrastructure/IIamPolicy';

const IamPolicyImporter: React.FC = () => {
  const [searchIamPolicies, { data, isLoading }] = useLazySearchIamPoliciesQuery();
  const [selectedPolicy, setSelectedPolicy] = useState<AwsIamPolicy | null>(null);
  const [showConfirmImport, setShowConfirmImport] = useState<boolean>(false);
  const [importPolicy, { isLoading: importIsLoading }] = useImportPolicyMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const importDisabled = isLoading || importIsLoading || !selectedPolicy;

  return (
    <Container>
      <ConfirmationModal
        show={showConfirmImport}
        title={translate('confirmImport')}
        confirmationText={translate('confirmImportBody', { policyName: selectedPolicy?.PolicyName, policyArn: selectedPolicy?.Arn })}
        disabled={importDisabled}
        handleClose={() => setShowConfirmImport(false)}
        onConfirm={async () => {
          const result = await importPolicy({ policyArn: selectedPolicy?.Arn as string });

          if ('error' in result) {
            toast({
              bg: 'danger',
              title: translate('error'),
              message: result.error.message,
              autohide: false,
            });
          } else if ('data' in result) {
            toast({
              bg: 'success',
              title: translate('success'),
              message: translate('created'),
              autohide: true,
            });

            navigate('../#iamPolicies');
          }
        }}
      />

      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('importPolicy')}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <AsyncTypeahead
            paginate={false}
            filterBy={() => true}
            id="policy-iam-searcher"
            labelKey="PolicyName"
            isLoading={isLoading}
            minLength={2}
            selected={selectedPolicy ? [selectedPolicy] : []}
            // @ts-expect-error more typeahead type failures
            onChange={(selected: AwsIamPolicy[]) => {
              console.log('selected', selected[0]);
              setSelectedPolicy(selected[0]);
            }}
            onSearch={(query: string) => {
              searchIamPolicies({ searchTerm: query })
            }}
            options={data || []}
            placeholder={translate('searchPolicyNameOrArn')}
            // @ts-expect-error yeah yeah yeah
            renderMenuItemChildren={(option: AwsIamPolicy, { text }) => (
              <MenuItem
                label={option.PolicyName}
                smallText={option.Arn}
                searchText={text}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-center">
          <Button disabled={importDisabled} onClick={() => setShowConfirmImport(true)}>
            {translate('import')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default IamPolicyImporter;

import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    isAuthorizedForEnvironment: builder.query({
      query: () => ({ url: 'is-authorized-for-environment', method: 'get' })
    }),
    listOauthScopes: builder.mutation({
      query: () => ({ url: 'oauth-scopes', method: 'get' }),
    }),
    listUserPermissions: builder.query({
      query: () => ({ url: 'list-user-permissions', method: 'get', altBasePath: 'auth' }),
    }),
    isAppMember: builder.query({
      query: () => ({ url: 'is-app-member', method: 'get', altBasePath: 'auth' }),
    }),
    getUserAppMaintainerRole: builder.query({
      query: ({ applicationId }) => {
        return { url: `applications/${applicationId}/user-maintainer-role`, method: 'get' };
      },
    }),
  }),
});

export const {
  useIsAuthorizedForEnvironmentQuery,
  useListOauthScopesMutation,
  useListUserPermissionsQuery,
  useIsAppMemberQuery,
  useGetUserAppMaintainerRoleQuery,
} = extendedApiSlice;

import React, { useState } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import PaginationControl from 'common/PaginationControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import UserSearchFields from 'common/user/UserSearchFields';
import IOrganization, { IOrganizationMember, OrganizationRole } from 'types/organization/IOrganization';
import useSearchOrganizationMembers from 'hooks/useSearchOrganizationMembers';
import OrganizationMembersTable from './OrganizationMembersTable';
import { useUpdateOrganizationMemberRoleMutation } from 'api/organizationSlice';
import AddOrganizationMemberModal from './AddOrganizationMemberModal';
import RemoveOrganizationMemberModal from './RemoveOrganizationMemberModal';
import IUser from 'types/IUser';

const MembersTab: React.FC<{
  organization: IOrganization;
  isAdmin?: boolean;
  isSuperUser?: boolean;
}> = ({ organization, isAdmin, isSuperUser }) => {
  const [showAddMembersModal, setShowAddMembersModal] = useState<boolean>(false);
  const [memberQueuedForRemoval, setMemberQueuedForRemoval] = useState<IUser | null>(null);

  const [updateRole, { isLoading: updateIsLoading }] = useUpdateOrganizationMemberRoleMutation();

  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
    page,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchOrganizationMembers({ organizationId: organization.id });

  const {
    data: paginatedData,
    error,
    isLoading,
    isFetching,
  } = queryResult;

  if (isLoading || !paginatedData) return (<Spinner size="10x" coverViewport />);
  if (error) return (<Error coverPage />);

  const { meta, data: orgMembers } = paginatedData;
  const disabled = !!(isFetching || updateIsLoading);

  return (
    <>
      {
        (isAdmin || isSuperUser) && (
          <AddOrganizationMemberModal
            show={showAddMembersModal}
            organization={organization}
            handleClose={() => setShowAddMembersModal(false)}
          />
        )
      }
      {
        memberQueuedForRemoval && (
          <RemoveOrganizationMemberModal
            show={memberQueuedForRemoval !== null}
            handleClose={() => setMemberQueuedForRemoval(null)}
            member={memberQueuedForRemoval}
            organization={organization}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            {translate('members')} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {
            (isAdmin || isSuperUser) && (
              <Button
                onClick={() => setShowAddMembersModal(true)}
                disabled={disabled}
              >
                <FontAwesomeIcon icon="plus" /> {translate('addMembers')}
              </Button>
            )
          }
        </Col>
      </Row>
      <UserSearchFields
        disabled={disabled}
        searchParameters={searchParameters}
        setSearchParameters={setSearchParameters}
        take={take}
        setTake={setTake}
        includeApproved={false}
      />
      <Row className="mt-2">
        <Col>
          <OrganizationMembersTable
            organizationMembers={orgMembers}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            canRemove={!!(isAdmin || isSuperUser)}
            canPromote={!!(isAdmin || isSuperUser)}
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSelectedMember={(member: IOrganizationMember) => setMemberQueuedForRemoval(member)}
            disabled={disabled}
            onSelectMemberRole={(user: IOrganizationMember, role: OrganizationRole) => {
              updateRole({ organizationId: organization.id, userId: user.id, data: { role } });
            }}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default MembersTab;

import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const databaseMicroserviceProperties = {
  ...BaseModelPropTypes,
  engine: PropTypes.string.isRequired,
  engine_version: PropTypes.string.isRequired,
  database_name: PropTypes.string.isRequired,
  dev_instance: PropTypes.object,
  staging_instance: PropTypes.object,
  prod_instance: PropTypes.object,
};

export default PropTypes.shape({
  ...databaseMicroserviceProperties,
});

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import UserPropTypes from 'propTypes/user/UserPropTypes';
import { useDeleteUserMutation } from 'api/userSlice';

const RemoveUserModal = ({ show, handleClose, user }) => {
  const navigate = useNavigate();
  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('deleteUser')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmDeleteUser', { firstName: user.first_name, lastName: user.last_name })}
        </p>
        <p>
          {translate('thisOperationCannotBeUndone')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await deleteUser(user);
            navigate('..');
          }}
        >
          {translate('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  user: UserPropTypes.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RemoveUserModal;

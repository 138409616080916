import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IOrganization from 'types/organization/IOrganization';
import IUser from 'types/IUser';
import { useRemoveOrganizationMemberMutation } from 'api/organizationSlice';

const RemoveOrganizationMemberModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  organization: IOrganization;
  member: IUser;
}>
= ({ show, handleClose, organization, member }) => {
  const [removeOrganizationMember, { isLoading }] = useRemoveOrganizationMemberMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('removeOrganizationMember')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmRemoveOrgMember', { organizationName: organization.name, firstName: member.first_name, lastName: member.last_name })}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await removeOrganizationMember({ organizationId: organization.id, userId: member.id });
            handleClose();
          }}
        >
          {translate('remove')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveOrganizationMemberModal;

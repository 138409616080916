export default {
  deprecatedExplanation: 'This version is currently deprecated and support will be discontinued when End of Life is reached.',
  eolExplanation: 'This version is no longer supported and will be automatically updated to the next available version in the next maintenance window.',
  microserviceChartVersionExplanation: 'The Apex version of this microservice which dictates what Apex provided features and resources are available in each environment.',

  apexVersion: 'Apex Version',
  apexUiVersions: 'Apex UI Versions',
  apexApiVersions: 'Apex API Versions',
  apexRelationalDatabaseVersions: 'Apex Relational Database Versions',
  apexMountableFilesystemVersions: 'Apex Mountable Filesystem Versions',
  apexRedisVersions: 'Apex Redis Versions',
  apexObjectStoreVersions: 'Apex Object Store Versions',
  apexShcheduledJobVersions: 'Apex Scheduled Job Versions',
  apexWorkerVersions: 'Apex WorkerVersions',
  apexLambdaVersions: 'Apex Lambda Versions',
}

import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IApplication from 'types/application/IApplication';
import AppDetailsFormWithTilePreview from './AppDetailsFormWithTilePreview';
import AppImageUploader from './AppImageUploader';
import { useEditApplicationMutation } from 'api/applicationSlice';
import useToast from 'hooks/useToast';

const Details: React.FC<{
  application: IApplication
}> = ({ application }) => {
  const [editApplication, { isLoading, error: apiErrors }] = useEditApplicationMutation();
  const toast = useToast();

  return (
    <Tab.Container defaultActiveKey="info">
      <Row>
        <Col md={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="info">{translate('info')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="image">{translate('image')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col md={9}>
          <Tab.Content>
            <Tab.Pane eventKey="info">
              <AppDetailsFormWithTilePreview
                initialValues={application}
                disabled={isLoading}
                apiErrors={apiErrors}
                onSubmit={async (values) => {
                  await editApplication(values);
                  toast({
                    bg: 'success',
                    title: translate('success'),
                    message: translate('updatedSuccessfully'),
                    autohide: true,
                  });
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="image">
              <Row>
                <Col>
                  <AppImageUploader application={application} />
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default Details;

import { useState, useEffect } from 'react';
import { useLazyGetApplicationPermissionsQuery } from 'api/applicationSlice';
import IPermission from 'types/application/IPermission';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';
import { SortDirection } from 'types/ListSearchTypes';

export type SortBy = 'name' | 'description';
export type UseSearchApplicationPermissionsSearchParameters = {
  name?: string
  description?: string
  any?: string
};

const useSearchApplicationPermissions:
  (args: { applicationId: string }) => {
    setTake: (take: number) => void;
    take: number;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    sortDirection: SortDirection;
    setSortDirection: (sortDirection: SortDirection) => void;
    setSearchParameters: (searchParameters: UseSearchApplicationPermissionsSearchParameters) => void;
    searchParameters: UseSearchApplicationPermissionsSearchParameters;
    page: number
    setPage: (page: number) => void;
    result: RtkQueryResult<PaginatedResult<IPermission>>
  } = ({ applicationId }) => {
    const [take, setTake] = useState(50);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [page, setPage] = useState<number>(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [searchParameters, setSearchParameters] = useState<UseSearchApplicationPermissionsSearchParameters>({});
    const [getApplicationPermissions, result] = useLazyGetApplicationPermissionsQuery();

    const queryParameters = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      getApplicationPermissions({ applicationId, queryParameters });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getApplicationPermissions, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      getApplicationPermissions({ applicationId, queryParameters });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      searchParameters,
      setSearchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchApplicationPermissions;

import React from 'react';

import { useNavigate } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Badge, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationSearchFields from 'common/organizations/OrganizationSearchFields';
import useSearchOrganizations, { SortBy } from 'hooks/useSearchOrganizations';
import { Error } from '@apex/react-toolkit/components';
import OrganizationPaginatedTable from 'common/organizations/OrganizationPaginatedTable';

const Search = () => {
  const navigate = useNavigate();
  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchOrganizations();

  const { error, isLoading, data: paginatedData } = queryResult;

  if (!paginatedData) return (<Spinner animation='border' />);
  if (error) return (<Error coverPage />);

  const { data, meta } = paginatedData;

  return (
    <Container>
      <Row className="mt-5">
        <Col md={4}>
          <h2>{translate('organizations')} <Badge pill bg="secondary">{paginatedData?.meta?.total}</Badge></h2>
        </Col>
        <Col md={{ span: 2, offset: 6 }} className="text-end">
          <Button onClick={() => navigate('/organizations/new')} className="text-nowrap">
            <FontAwesomeIcon icon="plus" /> {translate('createOrganization')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <OrganizationSearchFields
            disabled={queryResult.isFetching}
            setSearchObj={(searchObj) => {
              const searchParameters = searchObj ? { [searchObj.option]: searchObj.value } : {};
              if (searchObj) {
                setSearchParameters(searchParameters)
              }
            }}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {
            (isLoading) ? (<Spinner animation='border' />)
              : (
                <OrganizationPaginatedTable
                  sortBy={sortBy}
                  setSortBy={(updatedSortKey: SortBy) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortDirection={sortDirection}
                  organizations={data}
                  pageCount={meta.last_page}
                  currentPage={meta.current_page}
                  onPageChange={setPage}
                />
              )
          }
        </Col>
      </Row>
    </Container >
  );
}

export default Search;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TOKEN_PATH, TOKEN_PATH_IMPERSONATOR } from '@apex/react-toolkit/lib';
import cookie from 'helpers/cookie';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import { useIsAppMemberQuery } from 'api/authSlice';
import { setUser, setToken } from 'localState/authSlice';

const { protocol, host } = window.location;
const baseUrl = host.startsWith('localhost') ? 'http://localhost/api/platform' : `${protocol}//${host}/api/platform`;

const useImpersonate = () => {
  const dispatch = useDispatch();
  const { refetch: reftechPermissions } = useCheckUserPermission();
  const { refetch: reftechIsAppMember } = useIsAppMemberQuery();

  const [result, setResult] = useState({
    data: null,
    error: null,
    isLoading: false,
  });

  const fetchOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  };

  const impersonate = async (userId) => {
    setResult({
      ...result,
      isLoading: true,
    });

    try {
      const response = await fetch(`${baseUrl}/impersonate/${userId}`, fetchOptions);
      const data = await response.json();
      const updatedResult = {
        data: null,
        error: null,
        isLoading: false,
      };

      if (response.ok) {
        updatedResult.data = data;

        const actualUserToken = cookie.get(TOKEN_PATH);
        cookie.set(TOKEN_PATH, data.token);

        // if already impersonating, this should be already set to the original user's token
        if (!cookie.get(TOKEN_PATH_IMPERSONATOR)) {
          cookie.set(TOKEN_PATH_IMPERSONATOR, actualUserToken);
        }

        document.dispatchEvent(new Event('platform.impersonate'));

        dispatch(setToken(data.token));
        dispatch(setUser(data.user));

        reftechIsAppMember();
        reftechPermissions();
      } else {
        updatedResult.error = data;
      }

      setResult(updatedResult);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to impersonate');
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return [impersonate, result];
};

export default useImpersonate;

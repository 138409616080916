import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    editComputeDetails: builder.mutation({
      query: data => ({ url: `compute-details/${data.id}`, method: 'put', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),
  }),
});

export const {
  useEditComputeDetailsMutation,
} = extendedApiSlice;

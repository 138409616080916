import { apiSlice } from 'api/apiSlice';
import IQueryParameters from 'types/IQueryParameters';
import { HorizontalPodAutoscaler } from 'kubernetes-types/autoscaling/v2';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listK8sHpas: builder.query<HorizontalPodAutoscaler[], { namespace: string, queryParameters?: IQueryParameters }>({
      query: ({ namespace, queryParameters }) => ({
        url: `hpas/${namespace}`,
        queryParameters,
        method: 'get',
        altBasePath: 'proxy'
      }),
    }),
  }),
});

export const {
  useLazyListK8sHpasQuery,
} = extendedApiSlice;

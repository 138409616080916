import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from 'common/FormFeedback';

const ApplicationPermissionForm = ({ initialValues, disabled, header, onCancel, onSubmit, apiErrors, submitText }) => {
  const schema = yup.object({
    name: yup.string()
      .matches(/^[a-z]+(?:_[a-z]+)*$/, translate('permissionNameSnakeCase'))
      .required(translate('nameRequired')),
    description: yup.string().nullable(),
  });

  return (
    <>
      {header && <h2>{header}</h2>}
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>

            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder="Name"
                value={values.name}
                onBlur={handleBlur}
                onChange={(e) => {
                  const value = e.target.value || '';
                  setFieldValue(
                    'name',
                    value.replace(/ /g, '_')
                      .replace(/-/g, '_')
                      .replace(/[&/\\#,+()$@~%=.'":;|*!?<>{}0-9[]^]/g, '')
                      .toLowerCase()
                  );
                }}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <small className="text-muted">
                this field only accepts lowercase letters and underscores
              </small>
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDomain">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                value={values.description || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>

            <Form.Group>
              {onCancel && (
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  onClick={onCancel}
                >
                  {translate('cancel')}
                </Button>
              )}

              {onSubmit && (
                <Button
                  type="submit"
                  variant="primary"
                  className="mx-2"
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              )}
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

ApplicationPermissionForm.defaultProps = {
  initialValues: {
    name: '',
    description: '',
  },
  header: null,
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  submitText: translate('save'),
};

ApplicationPermissionForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  apiErrors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool.isRequired,
  header: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
};

export default ApplicationPermissionForm;

import { useState, useEffect } from 'react';
import { useLazyListUsersQuery } from 'api/userSlice';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';
import IUser from 'types/IUser';

export type SortBy = 'first_name' | 'last_name' | 'email';
type UserRelationships = 'organizations' | 'groups';

export type UseSearchUsersSearchParameters = {
  first_name?: string;
  last_name?: string;
  email?: string;
  approved?: '0' | '1' | 'any';
  any?: string;
  relationships?: UserRelationships[]
};

const useSearchUsers: (args?: {
  initialSearchParameters?: UseSearchUsersSearchParameters;
}) => {
  setTake: (take: number) => void;
  take: number;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  setSearchParameters: (searchParameters: UseSearchUsersSearchParameters) => void;
  searchParameters: UseSearchUsersSearchParameters;
  page: number
  setPage: (page: number) => void
  result: RtkQueryResult<PaginatedResult<IUser>>
} = (args) => {
  const initialSearchParameters = args?.initialSearchParameters || {};
  const [take, setTake] = useState<number>(10);
  const [sortBy, setSortBy] = useState<SortBy>('first_name');
  const [page, setPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [searchParameters, setSearchParameters] = useState<UseSearchUsersSearchParameters>(initialSearchParameters);
  const [fetchUsers, result] = useLazyListUsersQuery();

  const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchUsersSearchParameters> = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
    ...searchParameters,
  };

  useEffect(() => {
    fetchUsers(queryParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUsers, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    fetchUsers(queryParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters, take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    setSearchParameters,
    searchParameters,
    page,
    setPage,
    result,
  };
};

export default useSearchUsers;

import { FC } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import { SortDirection } from 'types/ListSearchTypes';
import IIamPolicy from 'types/infrastructure/IIamPolicy';
import { IamPolicySortBy } from 'hooks/useSearchIamPolicies';
import { Button } from 'react-bootstrap';

const IamPolicyTable: FC<{
  iamPolicies: IIamPolicy[];
  sortBy: IamPolicySortBy;
  setSortBy: (value: IamPolicySortBy) => void;
  sortDirection: SortDirection;
  onPolicyNameClicked?: (iamPolicy: IIamPolicy) => void;
  actionColumn?: InteractiveTableColumn<IIamPolicy>;
  withEnvPivot?: boolean
}> = ({ iamPolicies, sortBy, setSortBy, sortDirection, onPolicyNameClicked, withEnvPivot, actionColumn }) => {
  const columns: InteractiveTableColumn<IIamPolicy>[] = [
    {
      title: translate('policyName'),
      dataIndex: 'policy_name',
      sortDirection: sortBy === 'policy_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('policy_name'),
        className: 'clickable',
      }),
      render: (chartName: string, iamPolicy: IIamPolicy) => {
        return (
          <Button variant="link" onClick={onPolicyNameClicked ? () => onPolicyNameClicked(iamPolicy) : undefined}>
            {chartName}
          </Button >
        );
      },
    },
  ];

  if (withEnvPivot) {
    columns.push(
      {
        title: translate('environment'),
        dataIndex: 'pivot.environment',
      },
    );
  }

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={iamPolicies}
      columns={columns}
    />
  );
};

export default IamPolicyTable;

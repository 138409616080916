import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import PropTypes from 'prop-types';
import useSearchApplicationActivity from 'hooks/useSearchApplicationActivity';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import useInterval from 'hooks/useInterval';
import { REFETCH_DELAY_IN_SECONDS } from 'config';
import ApplicationActivityPaginatedTable from '../ApplicationActivityPaginatedTable';
import ApplicationActivitySearchFields from '../ApplicationActivitySearchFields';

const ApplicationActivitySearchTable = ({ applicationId, applicationName }) => {
  const {
    result: applicationActivityQueryResult,
    take,
    setTake: setTakeApplicationActivity,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchName,
    setSearchName,
    setSearchEnvironment,
    searchEnvironment,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    refetch: refetchApplicationActivity,
  } = useSearchApplicationActivity({ applicationId });
  const {
    result: applicationMicroserviceQueryResult,
    refetch: refetchApplicationMicroservices,
  } = useSearchApplicationMicroservices({ applicationId });

  useInterval(() => {
    refetchApplicationActivity();
    refetchApplicationMicroservices();
  }, REFETCH_DELAY_IN_SECONDS * 1000);

  if (
    applicationActivityQueryResult.isError
    || applicationMicroserviceQueryResult.isError
  ) {
    return <Error coverPage />;
  }

  if (
    !applicationActivityQueryResult.isSuccess
    || !applicationMicroserviceQueryResult.isSuccess
  ) {
    return <Spinner />;
  }

  return (
    <Card className="bg-dark">
      <Card.Header>
        <Row>
          <Col className="text-end">
            <small className="text-muted">Results update every {REFETCH_DELAY_IN_SECONDS} seconds</small>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <ApplicationActivitySearchFields
              searchEnvironment={searchEnvironment}
              setSearchEnvironment={setSearchEnvironment}
              searchName={searchName}
              setSearchName={setSearchName}
              toDate={toDate}
              setToDate={setToDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
              take={take}
              setTake={setTakeApplicationActivity}
              names={[
                applicationName,
                ...applicationMicroserviceQueryResult.data.data.map(({ name }) => name),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ApplicationActivityPaginatedTable
              setSortBy={(updatedSortKey) => {
                if (sortBy === updatedSortKey) {
                  setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                } else {
                  setSortBy(updatedSortKey);
                }
              }}
              sortBy={sortBy}
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              applicationActivities={applicationActivityQueryResult.data.data}
              pageCount={applicationActivityQueryResult.data.meta.last_page}
              currentPage={applicationActivityQueryResult.data.meta.current_page}
              onPageChange={setPage}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ApplicationActivitySearchTable.propTypes = {
  applicationId: PropTypes.string.isRequired,
};

export default ApplicationActivitySearchTable;

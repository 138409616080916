import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationPropTypes from 'propTypes/application/ApplicationPropTypes';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import Card from 'common/Card';
import HealthcheckStatus from './HealthcheckStatus';

const AppHealthcheck = ({ application }) => {
  const { result } = useSearchApplicationMicroservices({
    applicationId: application.id,
    initialSearchObj: {
      exclude_type: ['scheduled_job', 's3', 'filesystem'],
    }
  });

  return (
    <Card
      title={translate('appHealthcheck')}
      body={(
        <Table variant="dark" striped>
          <thead>
            <tr>
              <th>Microservice Name</th>
              <th className="text-start">Type</th>
              <th className="text-center">Dev</th>
              <th className="text-center">Staging</th>
              <th className="text-center">Prod</th>
            </tr>
          </thead>
          <tbody>
            {result?.data?.data.map(microservice => (
              <tr key={microservice.id} className="align-middle" style={{ height: 50 }}>
                <td>
                  <Link to={`/applications/${application.id}/microservices/${microservice.id}`}>{microservice.name}</Link>
                </td>
                <td className="text-muted text-start">
                  {microservice.application_microserviceable_type}
                </td>
                <td className="text-center">
                  <HealthcheckStatus
                    microserviceId={microservice.id}
                    environment="dev"
                    tag={microservice.dev_tag}
                  />
                </td>
                <td className="text-center">
                  <HealthcheckStatus
                    microserviceId={microservice.id}
                    environment="staging"
                    tag={microservice.staging_tag}
                  />
                </td>
                <td className="text-center">
                  <HealthcheckStatus
                    microserviceId={microservice.id}
                    environment="prod"
                    tag={microservice.prod_tag}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    />
  );
};

AppHealthcheck.propTypes = {
  application: ApplicationPropTypes,
};

export default AppHealthcheck;

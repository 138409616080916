import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import Environment from 'types/Environment';
import AccordionToggleLink from 'common/AccordionToggleLink';
import IFilesystemMicroservice, { IFilesystemMicroserviceMetadata } from 'types/application/microservice/IFilesystemMicroservice';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import CopyableLineItem from 'common/copyableLineItem';

const FilesystemInfo: React.FC<{
  environment: Environment;
  metadata: IFilesystemMicroserviceMetadata;
  filesystemMicroservice: IFilesystemMicroservice;
}> = ({ environment, metadata }) => {
  const { isSuperUser } = useCheckUserPermission();

  return (
    <div className="mb-4">
      <Card bg="dark">
        <Card.Header>
          <Row>
            <Col md="3" className="text-start">
              {`${translate(environment)} ${translate('info')}`}
            </Col>
            <Col>
              <span className="text-muted me-4">{environment}</span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey={environment}
                inactiveText="View"
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey={environment}>
        <Card bg="dark">
          <Card.Body>
            <Row className="mb-2">
              <Col md="2">
                <strong>{translate('size')} ({translate('bytes')}): </strong>
              </Col>
              <Col md="6">
                {metadata.size_in_bytes}
              </Col>
            </Row>
            <Row>
              <Col>
                {isSuperUser && (
                  <>
                    <CopyableLineItem
                      key="arn"
                      field="ARN"
                      value={metadata.arn}
                      isReady
                      buttonVariant="link"
                      buttonSize="sm"
                      secureField={false}
                    />
                    <CopyableLineItem
                      key="filesystemId"
                      field="FilesystemId"
                      value={metadata.file_system_id}
                      isReady
                      buttonVariant="link"
                      buttonSize="sm"
                      secureField={false}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </div>
  );
};

export default FilesystemInfo;

import { FC, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEditApplicationMicroserviceKubernetesRolesMutation, useGetApplicationMicroserviceKubernetesRolesQuery } from 'api/applicationMicroserviceSlice';
import IRoleConfig, { IRoleRule } from 'types/infrastructure/IRoleConfig';
import useSearchRoleConfigs from 'hooks/useSearchKubernetesRoleConfigs';
import MenuItem from "common/typeahead/MenuItem";
import useToast from 'hooks/useToast';

const MicroserviceKubernetesRoleConfigForm: FC<{
  microserviceId: string
  isSuperUserAuthorized?: boolean
}> = ({ microserviceId, isSuperUserAuthorized = false }) => {
  const toast = useToast();
  const [showTypeahead, setShowTypeahead] = useState<boolean>(false);
  const [formButtonsDisabled, setFormButtonsDisabled] = useState<boolean>(true);
  const [roles, setRoles] = useState<IRoleConfig[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IRoleConfig[]>([]);
  const { data: kubernetesRoles, isLoading, error } = useGetApplicationMicroserviceKubernetesRolesQuery({ microserviceId });
  const [editKubernetesRoles, editResult] = useEditApplicationMicroserviceKubernetesRolesMutation();

  const {
    setSearchParameters: setSearchRoleConfigsParameters,
    result: roleConfigsResult,
  } = useSearchRoleConfigs();

  useEffect(() => {
    if (kubernetesRoles) {
      setRoles(kubernetesRoles);
    }
  }, [kubernetesRoles]);

  useEffect(() => {
    if (kubernetesRoles) {
      if (editResult.isLoading || (selectedRoles.length < 1 && roles.length === kubernetesRoles.length)) {
        setFormButtonsDisabled(true);
      } else {
        setFormButtonsDisabled(false);
      }
    }
  }, [selectedRoles, roles, kubernetesRoles, editResult]);

  const updateKubernetesRoles = async () => {
    try {
      let rolesCopy = JSON.parse(JSON.stringify(roles));
      const selectedCopy = JSON.parse(JSON.stringify(selectedRoles));
      rolesCopy = [
        ...rolesCopy,
        ...selectedCopy,
      ];
      const ids = rolesCopy.map((r: IRoleConfig) => r.id);
      await editKubernetesRoles({ microserviceId, roles: ids });

      toast({
        bg: 'success',
        title: translate('updated'),
        message: translate('kubernetesRolesSavedSuccessfully'),
        autohide: true,
      });
    } catch (e) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    }
    setSelectedRoles([]);
    setShowTypeahead(false);
  };

  if (isLoading) return <Spinner />;
  if (error) return <Error />;


  return roles && (
    <Card bg="dark">
      <Card.Body>
        {
          roles.length === 0 ? (
            <Card className="mb-4 p-3 bg-dark-secondary">
              <Row>
                <Col className="text-warning text-center">
                  There are no kubernetes roles attached to this microservice.
                  {
                    !isSuperUserAuthorized && (
                      <> Please reach out to an administrator if you need assistance with adding roles to your microservice.</>
                    )
                  }
                </Col>
              </Row>
            </Card>
          ) : roles.map((roleConfig: IRoleConfig, roleIndex: number) => (
            <Card className="mb-4 p-3 bg-dark-secondary" key={roleConfig.id}>
              <Row>
                <Col md={10} className="d-inline-flex">
                  <h5>{roleConfig.name}</h5>
                  <div> { /* this is a magical div that aligns the stars. And by the stars I mean the heading and the badge */}
                    <Badge
                      bg="secondary"
                      className="ms-2"
                    >
                      {roleConfig.type === 'role' ? translate('role') : translate('clusterRole')}
                    </Badge>
                  </div>
                </Col>
                {
                  isSuperUserAuthorized && (
                    <Col className="text-end" md={2}>
                      <Button
                        variant="danger"
                        className="ms-2"
                        onClick={() => {
                          const copy = JSON.parse(JSON.stringify(roles));
                          copy.splice(roleIndex, 1);
                          setRoles(copy);
                        }}
                      >
                        <FontAwesomeIcon icon="trash-alt" />
                        <span className="ms-2">{translate('remove')}</span>
                      </Button>
                    </Col>
                  )
                }
              </Row>
              {
                roleConfig.rules && roleConfig.rules.map((rule: IRoleRule) => (
                  <div className="mb-1 ms-4" key={rule.id}>
                    <Row>
                      <Col md={2}>
                        <h6>{rule.name}</h6>
                      </Col>
                      {
                        rule.resources.map((resource, idx) => (
                          <div key={idx}>
                            {
                              idx === 0 && (
                                <Col md={2}>
                                  <h6>{rule.api_group.replace('/apis/', '')}</h6>
                                </Col>
                              )
                            }
                            <Col md={3}>
                              {resource.name}
                            </Col>
                            <Col md={5}>
                              {resource.verbs.join(', ')}
                            </Col>
                          </div>
                        ))
                      }
                    </Row>
                  </div>
                ))
              }
            </Card>
          ))
        }
        {
          isSuperUserAuthorized && (
            <>
              <Row>
                <Col md={4}>
                  {
                    showTypeahead && (
                      <Row className="mb-2">
                        <Col>
                          <AsyncTypeahead
                            multiple
                            paginate={false}
                            placeholder={translate('searchRoleConfigs')}
                            id="async-role-configs-typeahead"
                            delay={300}
                            isLoading={roleConfigsResult.isLoading || roleConfigsResult.isFetching}
                            labelKey="name"
                            onSearch={(query: string) => setSearchRoleConfigsParameters({ any: query })}
                            options={roleConfigsResult.data?.data || []}
                            selected={selectedRoles}
                            // @ts-expect-error more typeahead type failures
                            onChange={setSelectedRoles}
                            renderMenuItemChildren={(option, { text }) => (
                              // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                              <MenuItem label={option.name} smallText={option.description} searchText={text} />
                            )}
                            filterBy={(option) => {
                              // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                              return !selectedRoles.find(r => r.id === option.id) && !roles.find(r => r.id === option.id)
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  }
                  {
                    !showTypeahead && (
                      <Button
                        variant="primary"
                        onClick={() => setShowTypeahead(true)}
                      >
                        <FontAwesomeIcon icon='plus' />
                        <span className="ms-2">{translate('addRole')}</span>
                      </Button>
                    )
                  }
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={4}>
                  <Button
                    variant="secondary"
                    disabled={formButtonsDisabled}
                    onClick={() => {
                      setRoles(kubernetesRoles);
                      setShowTypeahead(false);
                    }}
                  >
                    <FontAwesomeIcon icon="ban" />
                    <span className="ms-2">{translate('cancel')}</span>
                  </Button>
                  <Button
                    variant="primary"
                    className="ms-2"
                    disabled={formButtonsDisabled}
                    onClick={updateKubernetesRoles}
                  >
                    {/* @ts-expect-error this is a valid icon */}
                    <FontAwesomeIcon icon="floppy-disk" />
                    <span className="ms-2">{translate('save')}</span>
                  </Button>
                </Col>
              </Row>
            </>
          )
        }
      </Card.Body>
    </Card>
  );
};

export default MicroserviceKubernetesRoleConfigForm;

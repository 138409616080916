import React from 'react';
import { Spinner } from '@apex/react-toolkit/components';
import { Row, Col, Form } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { formControlIsoToLocalIn, formControlLocalToIsoOut } from 'helpers/datetime';
import { useListMicroserviceJobTypesQuery } from 'api/microserviceJobSlice';
import { snakeCase, upperCase } from 'lodash';

const ApplicationMicroserviceJobSearchFields: React.FC<{
  disabled: boolean;
  fromDate: string;
  searchStatus: string;
  searchType: string;
  setFromDate: (fromDate: string) => void;
  setSearchStatus: (searchStatus: string) => void;
  setSearchType: (searchType: string) => void;
  setToDate: (toDate: string) => void;
  take: number;
  toDate: string;
  setTake: (take: number) => void;
}> = (props) => {
  const {
    disabled,
    fromDate,
    searchStatus,
    searchType,
    setFromDate,
    setSearchStatus,
    setSearchType,
    setToDate,
    take,
    toDate,
    setTake,
  } = props;

  const { data, isLoading } = useListMicroserviceJobTypesQuery();
  if (isLoading) return (<Spinner />);

  const types = [...data as string[]].sort();

  return (
    <Row>
      <Col md={3}>
        <Form.Group>
          <Form.Label>From</Form.Label>
          <Form.Control
            onChange={e => setFromDate(
              formControlLocalToIsoOut(e.target.value) as string
            )}
            value={formControlIsoToLocalIn(fromDate)}
            type="datetime-local"
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>To</Form.Label>
          <Form.Control
            onChange={e => setToDate(
              formControlLocalToIsoOut(e.target.value) as string
            )}
            value={formControlIsoToLocalIn(toDate)}
            type="datetime-local"
          />
        </Form.Group>
      </Col>
      <Col md={2} className="text-start">
        <Form.Label>{translate('type')}</Form.Label>
        <Form.Select
          size="sm"
          onChange={e => setSearchType(e.target.value)}
          disabled={disabled}
          value={searchType}
        >
          <option value="">{translate('any')}</option>
          {
            types.map(type => (
              <option key={type} value={type}>{upperCase(snakeCase(type))}</option>
            ))
          }
        </Form.Select>
      </Col>
      <Col md={2} className="text-start">
        <Form.Label>{translate('status')}</Form.Label>
        <Form.Select
          size="sm"
          onChange={e => setSearchStatus(e.target.value)}
          disabled={disabled}
          value={searchStatus}
        >
          <option value="">{translate('any')}</option>
          <option value="COMPLETE">COMPLETE</option>
          <option value="CREATED">CREATED</option>
          <option value="IN_PROGRESS">IN_PROGRESS</option>
          <option value="FAILED">FAILED</option>
        </Form.Select>
      </Col>
      <Col md={2}>
        <Form.Group>
          <Form.Label>{translate('resultsPerPage')}</Form.Label>
          <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ApplicationMicroserviceJobSearchFields;

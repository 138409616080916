import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useDeployApplicationMicroserviceMutation } from 'api/applicationMicroserviceSlice';
import useToast from 'hooks/useToast';

const ConfirmExecuteModal = ({ show, handleClose, microservice, env }) => {
  const { microserviceable: schedJobMs } = microservice;
  const [deployMicroservice, { isLoading }] = useDeployApplicationMicroserviceMutation();
  const toast = useToast();
  const imageTag = schedJobMs[`${env}_tag`];

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('executeJobName', { jobName: microservice.name })}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        {translate('confirmExecuteScheduledJobNow', {
          name: microservice.name,
          env,
          version: schedJobMs[`${env}_tag`],
        })}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button disabled={isLoading} variant="secondary" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="primary"
          onClick={async () => {
            await deployMicroservice({
              microserviceId: microservice.id,
              environment: env,
              imageTag,
            });

            toast({
              bg: 'success',
              title: translate('success'),
              message: translate('jobEnqueuedSuccessfully', { env, imageTag }),
              autohide: true,
            });

            handleClose();
          }}
        >
          {translate('executeNow')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmExecuteModal;

import { FC } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { Link } from 'react-router-dom';
import { SortDirection } from 'types/ListSearchTypes';
import IRoleConfig from 'types/infrastructure/IRoleConfig';
import { RoleConfigSortBy } from 'hooks/useSearchKubernetesRoleConfigs';

const RoleConfigTable: FC<{
  roleConfigs: IRoleConfig[]
  sortBy: RoleConfigSortBy
  setSortBy: (sortBy: RoleConfigSortBy) => void
  sortDirection: SortDirection
}> = ({ roleConfigs, sortBy, setSortBy, sortDirection }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
      render: (first: string, { id }: { id: string }) => (<Link to={`/infrastructure/kubernetes-roles/${id}`}>{ first }</Link>),
    },
    {
      title: translate('type'),
      dataIndex: 'type',
      sortDirection: sortBy === 'type' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('type'),
        className: 'clickable',
      }),
    },
    {
      title: translate('description'),
      dataIndex: 'description',
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={roleConfigs}
      columns={columns}
      // @ts-expect-error need to pass sort direction but it's technically not a prop?
      sortDirection={sortDirection}
    />
  );
};

export default RoleConfigTable;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InteractiveTable from 'common/InteractiveTable';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import UserPropTypes from 'propTypes/user/UserPropTypes';

const AddMaintainerTable = ({ maintainers, addSelectedMaintainer, currentMaintainers, loading, stagedMaintainerHash }) => {
  const currentMaintainerHash = useMemo(() => {
    const maintainerHash = {};
    currentMaintainers.forEach(maintainer => {
      maintainerHash[maintainer.id] = maintainer;
    });
    return maintainerHash;
  }, [currentMaintainers]);

  const columns = [
    {
      title: translate('firstName'),
      dataIndex: 'first_name',
    },
    {
      title: translate('lastName'),
      dataIndex: 'last_name',
    },
    {
      title: translate('email'),
      dataIndex: 'email',
    },
    {
      title: '',
      dataIndex: 'id',
      onTd: () => ({ className: 'text-center' }),
      render: (_, maintainer) => {
        if (currentMaintainerHash[maintainer.id]) return (<span className="text-success">{translate('added')}</span>);
        if (stagedMaintainerHash[maintainer.id]) return (<span className="text-warning">{translate('queued')}</span>);
        return (
          <Button className="btn-xs" onClick={() => addSelectedMaintainer(maintainer)} disabled={loading}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        );
      },
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={maintainers}
      columns={columns}
    />
  );
};

AddMaintainerTable.propTypes = {
  maintainers: PropTypes.arrayOf(UserPropTypes).isRequired,
  addSelectedMaintainer: PropTypes.func.isRequired,
  currentMaintainers: PropTypes.arrayOf(UserPropTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  stagedMaintainerHash: PropTypes.object.isRequired,
};

export default AddMaintainerTable;

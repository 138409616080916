import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import { useAddApplicationMaintainersMutation } from 'api/applicationSlice';
import AddedMaintainerList from 'App/AppRoutes/Applications/Application/Maintainers/MaintainersBody/AddMaintainersModal/AddedMaintainerList';
import AddMaintainerTable from 'App/AppRoutes/Applications/Application/Maintainers/MaintainersBody/AddMaintainersModal/AddMaintainerTable';
import UserSearchFields from 'common/user/UserSearchFields';
import { Error } from '@apex/react-toolkit/components';
import PaginationControl from 'common/PaginationControl';
import useSearchUsers from 'hooks/useSearchUsers';
import MaintainerPropTypes from 'propTypes/maintainer/MaintainerPropTypes';

const AddMaintainersModal = ({ applicationId, applicationName, show, handleClose, currentMaintainers }) => {
  const [selectedMaintainersHash, setSelectedMaintainersHash] = useState({});
  const [addApplicationMaintainers, { isLoading: addMaintainersLoading, error: addMaintainersError }] = useAddApplicationMaintainersMutation();
  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
  } = useSearchUsers();

  useEffect(() => {
    setSelectedMaintainersHash({});
  }, [show]);

  const isLoading = queryResult.isLoading || addMaintainersLoading;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90w add-relation"
    >
      <Modal.Header className="border-0">
        <h1>{translate('addMaintainerToApplication', { applicationName })}</h1>
      </Modal.Header>
      <Modal.Body>
        {
          (queryResult.error || addMaintainersError) && (<Error />)
        }
        <Row>
          <Col>
            <Row>
              <Col md={6}>
                <UserSearchFields
                  disabled={queryResult.isFetching}
                  searchParameters={searchParameters}
                  setSearchParameters={setSearchParameters}
                  take={take}
                  setTake={setTake}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <AddMaintainerTable
                  stagedMaintainerHash={selectedMaintainersHash}
                  currentMaintainers={currentMaintainers}
                  maintainers={queryResult?.data?.data || []}
                  loading={isLoading}
                  addSelectedMaintainer={maintainer => {
                    const updateSelectedMaintainersHash = { ...selectedMaintainersHash };
                    updateSelectedMaintainersHash[maintainer.id] = maintainer;
                    setSelectedMaintainersHash(updateSelectedMaintainersHash);
                  }}
                />
                <PaginationControl
                  pagesToShow={3}
                  pageCount={queryResult?.data?.meta?.last_page || 1}
                  currentPage={queryResult?.data?.meta?.current_page || 0}
                  onPageChange={setPage}
                />
              </Col>
              <Col md={6}>
                <AddedMaintainerList
                  addedMaintainers={Object.values(selectedMaintainersHash)}
                  dismissMaintainer={maintainer => {
                    const updateSelectedMaintainersHash = { ...selectedMaintainersHash };
                    delete updateSelectedMaintainersHash[maintainer.id];
                    setSelectedMaintainersHash(updateSelectedMaintainersHash);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const response = await addApplicationMaintainers({
              applicationId,
              data: Object.values(selectedMaintainersHash).map(({ id }) => id),
            });

            if (!response.error) {
              handleClose();
            }
          }}
        >
          {translate('addMaintainers')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddMaintainersModal.propTypes = {
  applicationId: PropTypes.string.isRequired,
  applicationName: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentMaintainers: PropTypes.arrayOf(MaintainerPropTypes).isRequired,
};

export default AddMaintainersModal;

import { Accordion, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetNodeGroupQuery } from 'api/nodeGroupSlice';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { useState } from 'react';
import DeleteNodeGroupModal from 'App/AppRoutes/Infrastructure/NodeGroups/Detail/DeleteNodeGroupModal';
import Unauthorized from 'common/Unauthorized';
import NodeGroupDeploymentSearchTable from 'common/infrastructure/nodeGroups/NodeGroupDeploymentSearchTable';

const NodeGroupDetail = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();
  const { nodeGroupId } = useParams();

  const { data: nodeGroup, isFetching, error } = useGetNodeGroupQuery(nodeGroupId as string);

  if (!isSuperUser) return <Unauthorized />;
  if (isFetching || permissionLoading) return (<Spinner size="10x" coverViewport />);
  if (error || !nodeGroup) return (<Error coverPage />);

  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col md={7}>
            <h1>{nodeGroup.name}</h1>
            <Row>
              <Col>
                {nodeGroup.description}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{translate('iamRole')}</strong>: {nodeGroup.iam_role}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>{translate('amiType')}</strong>: {nodeGroup.ami_type}
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <div className="float-end">
              <Button variant="secondary" onClick={() => navigate(`/infrastructure/node-groups/${nodeGroupId}/edit`)}>
                <FontAwesomeIcon icon="edit" className="me-1" />
                {translate('editNodeGroup')}
              </Button>
              <Button variant="danger" className="ms-2" onClick={() => setShowDeleteModal(true)}>
                <FontAwesomeIcon icon="trash-alt" className="me-1" />
                {translate('deleteNodeGroup')}
              </Button>
            </div>
          </Col>
        </Row>
        <Accordion alwaysOpen className="mt-4" defaultActiveKey={["0", "1", "2"]}>
          <Card bg="dark">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('scalability')}
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="0"
                    inactiveText={translate('view')}
                    activeText="Close"
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="0">
            <Card bg="dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col md={3}>{translate('minimumNodeCount')}:</Col>
                  <Col>{nodeGroup.minimum_node_count}</Col>
                </Row>
                <Row className="mb-1">
                  <Col md={3}>{translate('maximumNodeCount')}:</Col>
                  <Col>{nodeGroup.maximum_node_count}</Col>
                </Row>
                <Row className="mb-1">
                  <Col md={3}>{translate('desiredNodeCount')}:</Col>
                  <Col>{nodeGroup.desired_node_count}</Col>
                </Row>
                <Row className="mb-1">
                  <Col md={3}>{translate('capacityType')}:</Col>
                  <Col>{nodeGroup.capacity_type}</Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </Accordion>
        <Accordion alwaysOpen className="mt-4">
          <Card bg="dark">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('computeDetails')}
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="1"
                    inactiveText={translate('view')}
                    activeText="Close"
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="1">
            <Card bg="dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col md={3}>{translate('subnets')}:</Col>
                  <Col>{nodeGroup.subnets?.join(', ')}</Col>
                </Row>
                <Row className="mb-1">
                  <Col md={3}>{translate('instanceType')}:</Col>
                  <Col>{nodeGroup.instance_type}</Col>
                </Row>
                <Row className="mb-1">
                  <Col md={3}>{translate('storageSize')}:</Col>
                  <Col>{nodeGroup.storage_size}</Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </Accordion>
        <Accordion alwaysOpen className="mt-4">
          <Card bg="dark">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('awsTags')}
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="2"
                    inactiveText={translate('view')}
                    activeText="Close"
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="2">
            <Card bg="dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col>
                    {nodeGroup.tags?.map((tag, idx) => (
                      // @ts-expect-error this is sort of a freeform field
                      <Row key={`${idx}_${tag.key}`} >
                        {/* @ts-expect-error this is sort of a freeform field */}
                        <Col Col md={3} > {tag.key}: </Col>
                        {/* @ts-expect-error this is sort of a freeform field */}
                        <Col>{tag.value}</Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </Accordion >
        <Accordion alwaysOpen className="mt-4">
          <Card bg="dark">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('k8sLabels')}
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="2"
                    inactiveText={translate('view')}
                    activeText="Close"
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="2">
            <Card bg="dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col>
                    {nodeGroup.labels?.map((label, idx) => (
                      // @ts-expect-error this is sort of a freeform field
                      <Row key={`${idx}_${label.key}`} >
                        {/* @ts-expect-error this is sort of a freeform field */}
                        <Col md={3}>{label.key}: </Col>
                        {/* @ts-expect-error this is sort of a freeform field */}
                        <Col>{label.value}</Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </Accordion>

        <Accordion alwaysOpen className="mt-4">
          <Card bg="dark">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('deployments')}
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="1"
                    inactiveText={translate('view')}
                    activeText="Close"
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="1">
            <Card bg="dark">
              <Card.Body>
                <NodeGroupDeploymentSearchTable nodeGroupId={nodeGroupId as string} />
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </Accordion>


      </Container >
      <DeleteNodeGroupModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        nodeGroup={nodeGroup}
      />
    </>
  );
};

export default NodeGroupDetail;

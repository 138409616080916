import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Badge } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import UserPaginatedTable from 'common/user/UserPaginatedTable';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserSearchFields from 'common/user/UserSearchFields';
import useSearchUsers from 'hooks/useSearchUsers';
import useImpersonate from 'hooks/useImpersonate';

const Search = () => {
  const navigate = useNavigate();

  const {
    result: queryResult,
    searchParameters,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchUsers();

  const [impersonate, impersonateResult] = useImpersonate();
  const { data: paginatedData, error, isLoading, isFetching } = queryResult;
  if (error) return (<Error coverPage />);

  return (
    <Container>
      <Row className="mt-5">
        <Col md={4}>
          <h2>{translate('users')} <Badge pill variant="secondary">{paginatedData?.meta?.total}</Badge></h2>
        </Col>
        <Col md={{ span: 2, offset: 6 }} className="text-end">
          <Button onClick={() => navigate('/users/new')}>
            <FontAwesomeIcon icon="plus" /> {translate('addUser')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <UserSearchFields
            disabled={isFetching}
            searchParameters={searchParameters}
            setSearchParameters={setSearchParameters}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {
            (isLoading) ? (<Spinner size="5x" />)
              : (
                <UserPaginatedTable
                  sortBy={sortBy}
                  setSortBy={(updatedSortKey) => {
                    if (sortBy === updatedSortKey) {
                      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                    } else {
                      setSortBy(updatedSortKey);
                    }
                  }}
                  sortDirection={sortDirection}
                  users={paginatedData?.data || []}
                  pageCount={paginatedData?.meta?.last_page || 1}
                  currentPage={paginatedData?.meta?.current_page || 0}
                  onPageChange={setPage}
                  isFetching={isFetching}
                  actionColumn={{
                    dataIndex: 'id',
                    render: (userId) => {
                      return (
                        <Button
                          disabled={impersonateResult.isLoading}
                          size="sm"
                          className="text-nowrap"
                          onClick={() => impersonate(userId)}
                        >
                          <FontAwesomeIcon icon="person-from-portal" fixedWidth /> {translate('impersonate')}
                        </Button>
                      );
                    },
                  }}
                />
              )
          }
        </Col>
      </Row>
    </Container>
  );
};

export default Search;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useRemoveApplicationIconImgMutation } from 'api/applicationSlice';
import IApplication from 'types/application/IApplication';

const ConfirmRemoveModal: React.FC<{
  application: IApplication;
  show: boolean;
  handleClose: () => void;
}> = ({
  application,
  show,
  handleClose,
}) => {
    const [removeIconImg, removeIconImgResult] = useRemoveApplicationIconImgMutation();
    const { isLoading } = removeIconImgResult;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="confirm">
          {translate('confirmRemoveApplicationImage')}
        </Modal.Body>
        <Modal.Footer className="border-0 py-3 justify-content-start">
          <Button
            className="me-1"
            disabled={isLoading}
            variant="secondary"
            onClick={handleClose}
          >
            {translate('cancel')}
          </Button>
          <Button
            disabled={isLoading}
            variant="danger"
            onClick={async () => {
              await removeIconImg(application.id);
              handleClose();
            }}
          >
            {translate('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

export default ConfirmRemoveModal;

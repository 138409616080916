import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Container, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useGetApplicationPermissionQuery, useLazyGetApplicationPermissionRolesQuery } from 'api/applicationPermissionSlice';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import PermissionRolesTable from 'App/AppRoutes/Applications/Application/Permissions/Detail/PermissionRolesTable';

const Detail = () => {
  const { applicationId, permissionId } = useParams();
  const { data: applicationPermission, isFetching: getPermissionFetching, error: getPermissionError } = useGetApplicationPermissionQuery(permissionId);
  const [
    getApplicationPermissionRoles,
    { data: roles, isFetching: getRolesFetching, error: getRolesError },
  ] = useLazyGetApplicationPermissionRolesQuery();
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const queryParameters = {
    sort_by: sortBy,
    sort_direction: sortDirection,
  };

  useEffect(() => {
    getApplicationPermissionRoles({ queryParameters, permissionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationPermissionRoles, sortBy, sortDirection]);

  if (getPermissionError || getRolesError) return (<Error coverPage />);
  if (getPermissionFetching || getRolesFetching) return (<Spinner size="10x" coverViewport />);

  return (
    <Container>
      <Row className="mt-4">
        <Col md={4}>
          <h1>{applicationPermission.name}</h1>
          <p>{applicationPermission.description}</p>
        </Col>
        <Col className="text-end">
          <Button as={Link} to={`/applications/${applicationId}/permissions/${permissionId}/edit`}>{translate('edit')}</Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <h2>{translate('roles')}</h2>
        </Col>
      </Row>
      <Row className="d-flex flex-column space-between h-100">
        <Col>
          <Row className="mt-2">
            <Col>
              <PermissionRolesTable
                applicationId={applicationId}
                roles={roles || []}
                setSortBy={(updatedSortKey) => {
                  if (sortBy === updatedSortKey) {
                    setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                  } else {
                    setSortBy(updatedSortKey);
                  }
                }}
                sortBy={sortBy}
                setSortDirection={setSortDirection}
                sortDirection={sortDirection}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Detail;

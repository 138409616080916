import React from 'react';
import PropTypes from 'prop-types';
import PaginationControl from 'common/PaginationControl';
import PaginationPropTypes from 'propTypes/PaginationPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';
import ApplicationActivityTable from 'common/applicationActivity/ApplicationActivityTable';
import ApplicationActivityPropTypes from 'propTypes/application/ApplicationActivityPropTypes';

const ApplicationActivityPaginatedTable = ({ applicationActivities, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onUserSelected, actionColumn }) => (
  <>
    <ApplicationActivityTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      applicationActivities={applicationActivities}
      onUserSelected={onUserSelected}
      actionColumn={actionColumn}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

ApplicationActivityPaginatedTable.propTypes = {
  applicationActivities: PropTypes.arrayOf(ApplicationActivityPropTypes),
  onUserSelected: PropTypes.func,
  actionColumn: ActionColumnPropTypes,
  ...PaginationPropTypes,
};

ApplicationActivityPaginatedTable.defaultProps = {
  applicationActivities: [],
  onUserSelected: undefined,
  actionColumn: null,
};

export default ApplicationActivityPaginatedTable;

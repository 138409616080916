import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from 'common/FormFeedback';

const UserForm = ({ initialValues, disabled, header, onCancel, onSubmit, apiErrors, submitText }) => {
  const schema = yup.object({
    first_name: yup.string().required(translate('firstNameRequired')),
    last_name: yup.string().required(translate('lastNameRequired')),
    email: yup.string().email().required(translate('emailRequired')),
  });

  return (
    <>
      {header && <h2>{header}</h2>}
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit(formInput);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('firstName')}</Form.Label>
              <Form.Control
                required
                name="first_name"
                type="text"
                placeholder="First Name"
                value={values.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.first_name && !errors.first_name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="first_name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDomain">
              <Form.Label>{translate('lastName')}</Form.Label>
              <Form.Control
                required
                name="last_name"
                type="text"
                placeholder="Last Name"
                value={values.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.last_name && !errors.last_name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="last_name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>{translate('email')}</Form.Label>
              <Form.Control
                required
                name="email"
                type="text"
                placeholder="Email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.email && !errors.email}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="email"
              />
            </Form.Group>
            <Form.Group>
              {onCancel && (
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  onClick={onCancel}
                >
                  {translate('cancel')}
                </Button>
              )}
              {onSubmit && (
                <Button
                  type="submit"
                  variant="primary"
                  className="mx-2"
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              )}
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

UserForm.defaultProps = {
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
  },
  header: null,
  onCancel: null,
  onSubmit: null,
  apiErrors: null,
  submitText: translate('save'),
};

UserForm.propTypes = {
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }),
  apiErrors: PropTypes.shape({
    first_name: PropTypes.arrayOf(PropTypes.string),
    last_name: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool.isRequired,
  header: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
};

export default UserForm;

import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useLazyGetApplicationActivityQuery } from 'api/applicationSlice';

const useSearchApplicationActivity = ({ applicationId }) => {
  const to = DateTime.now().plus({ days: 1 }).toJSDate();
  const from = DateTime.now().minus({ days: 7 }).toJSDate();

  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState('updated_at');
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchName, setSearchName] = useState('');
  const [searchEnvironment, setSearchEnvironment] = useState('');
  const [toDate, setToDate] = useState(to.toISOString());
  const [fromDate, setFromDate] = useState(from.toISOString());
  const [getApplicationActivity, result] = useLazyGetApplicationActivityQuery();

  const queryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };

  if (searchName !== '') queryParameters.name = searchName;
  if (searchEnvironment !== '') queryParameters.environment = searchEnvironment;
  queryParameters.from_date = fromDate;
  queryParameters.to_date = toDate;

  useEffect(() => {
    if (searchName !== '') queryParameters.name = searchName;
    if (searchEnvironment !== '') queryParameters.environment = searchEnvironment;

    getApplicationActivity({ applicationId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationActivity, take, page, sortBy, sortDirection, toDate, fromDate, searchName]);

  useEffect(() => {
    setPage(1);
    getApplicationActivity({ applicationId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchName,
    setSearchName,
    searchEnvironment,
    setSearchEnvironment,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    page,
    setPage,
    result,
    refetch: () => getApplicationActivity({ applicationId, queryParameters }),
  };
};

export default useSearchApplicationActivity;

import { useMemo } from 'react';
import { useListUserPermissionsQuery } from 'api/authSlice';

const useCheckUserPermission = () => {
  const queryResult = useListUserPermissionsQuery();
  const { data } = queryResult;

  const isSuperUser = useMemo(() => {
    if (!data) return false;
    return data.find(({ name }) => name === 'apex_admin_super_user');
  }, [data]);

  const checkUserPermissionIn = (permissionNames = []) => {
    if (isSuperUser) return true;
    if (!data) return false;

    return data.find(({ name }) => {
      return permissionNames.find(permission => name === permission);
    });
  };

  const checkUserPermission = (permissionName = '') => {
    if (isSuperUser) return true;
    if (!data) return false;

    return data.find(({ name }) => name === permissionName);
  };

  return {
    ...queryResult,
    checkUserPermission,
    checkUserPermissionIn,
    isSuperUser,
  };
};

export default useCheckUserPermission;

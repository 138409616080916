
import { Button, Form, InputGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import useToast from 'hooks/useToast';
import { FC } from 'react';
import { useUpdateHealthcheckPathMutation } from 'api/uiMicroserviceSlice';

const HealthcheckPathForm: FC<{
  microserviceableId: string
  applicationId: string
  healthcheckPath?: string
}> = ({ microserviceableId, applicationId, healthcheckPath }) => {
  const schema = yup.object({
    healthcheck_path: yup.string()
      .trim()
      // Matches: api, api/v1
      // Doesn't match: /api, api/, api//v1, api/v1/ /api/v1, /api/v1/
      .matches(/^(?!.*[/]{2})[a-z][a-z0-9-/]*[a-z0-9]$/, translate('invalidFormat')),
  });
  const toast = useToast();
  const [updateHealthcheck, updateResult] = useUpdateHealthcheckPathMutation();

  const onSubmit = async (formData: { healthcheck_path?: string }) => {
    try {
      await updateHealthcheck({
        id: microserviceableId,
        application_id: applicationId,
        ...formData,
      });

      toast({
        bg: 'success',
        title: translate('updated'),
        message: translate('healthcheckPathUpdatedSuccessfully'),
        autohide: true,
      });
    } catch (e) {
      toast({
        bg: 'danger',
        title: translate('error'),
        message: translate('anUnexpectedErrorOccurred'),
        autohide: false,
      });
    }
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ healthcheck_path: healthcheckPath }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleReset,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <InputGroup className="w-50 mb-3">
              <InputGroup.Text>/</InputGroup.Text>
              <Form.Control
                required
                name="healthcheck_path"
                type="text"
                placeholder={translate('healthcheckPath')}
                value={values.healthcheck_path}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={updateResult.isLoading}
                isValid={touched.healthcheck_path && !errors.healthcheck_path}
              />
              <Form.Text>{translate('forMoreInformation')}<a href="https://documentation.app.apex-mp.com/applications/microservices/ui-microservices#healthcheck"> {translate('readTheDocs')}</a></Form.Text>
            </InputGroup>
          </Row>
          <Button
            variant="secondary"
            type="button"
            onClick={handleReset}
            disabled={updateResult.isLoading}
            className="me-2"
          >
            {translate('cancel')}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={updateResult.isLoading}
          >
            {
              updateResult.isLoading
                ? <Spinner size="1x" />
                : translate('save')
            }
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default HealthcheckPathForm;

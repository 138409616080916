import React from 'react';
import PaginationControl from 'common/PaginationControl';
import DeploymentTable from 'common/deployment/DeploymentTable';
import IDeployment from 'types/IDeployment';
import { SortDirection } from 'types/ListSearchTypes';

const DeploymentPaginatedTable: React.FC<{
  deployments: IDeployment[];
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  sortDirection: SortDirection;
  canPromote: boolean;
}> = ({
  deployments,
  pageCount,
  currentPage,
  onPageChange,
  sortBy,
  setSortBy,
  sortDirection,
  canPromote,
}) => (
  <>
    <DeploymentTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      deployments={deployments}
      isDbMicroservice={false}
      canPromote={canPromote}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

export default DeploymentPaginatedTable;

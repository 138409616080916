import React from 'react';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import IOrganization from 'types/organization/IOrganization';
import IApplication from 'types/application/IApplication';

const AddApplicationOrganizationTable: React.FC<{
  application: IApplication;
  organizations: IOrganization[];
  loading: boolean;
  addSelectedOrg: (organization: IOrganization) => void;
  stagedOrgHash: { [id: string]: IOrganization };
}> = ({ organizations, loading, application, addSelectedOrg, stagedOrgHash }) => {
  const columns: InteractiveTableColumn<IOrganization>[] = [
    {
      title: translate('name'),
      dataIndex: 'name',
    },
    {
      title: translate('description'),
      dataIndex: 'description',
    },
    {
      title: translate('domain'),
      dataIndex: 'domain',
    },
    {
      title: '',
      dataIndex: 'id',
      onTd: () => ({ className: 'text-center' }),
      render: (_: string, org: IOrganization) => {
        if (org.applications?.find(({ id }) => application.id === id)) return (<span className="text-success">{translate('added')}</span>);
        if (stagedOrgHash[org.id]) return (<span className="text-warning">{translate('queued')}</span>);

        return (
          <Button className="btn-xs" onClick={() => addSelectedOrg(org)} disabled={loading}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        );
      },
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={organizations}
      columns={columns}
    />
  );
};

export default AddApplicationOrganizationTable;

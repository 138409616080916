import PropTypes from 'prop-types';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';

export default {
  pageCount: PropTypes.number.isRequired,
  pagesToShow: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  ...SortPropTypes,
};

import React, { useState } from 'react';
import { useGetTeamQuery, useDeleteTeamMutation } from 'api/teamSlice';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Row, Tab } from 'react-bootstrap';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import { translate } from '@apex/react-toolkit/lib';
import GroupsTab from 'App/AppRoutes/Organizations/Detail/Teams/TeamDetail/GroupsTab';
import TeamMembersTab from 'App/AppRoutes/Organizations/Detail/Teams/TeamDetail/TeamMembersTab';
import useGetCurrentUserOrganizationRole from 'hooks/useGetCurrentUserOrganizationRole';
import DeleteTeamModal from 'App/AppRoutes/Organizations/Detail/Teams/TeamDetail/DeleteTeamModal';
import ITeam from 'types/team/ITeam';
import useToast from 'hooks/useToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TeamDetail: React.FC = () => {
  const { teamId, orgId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { data: team, isLoading, error } = useGetTeamQuery(teamId as string);
  const [deleteTeam, { isLoading: deleteLoading }] = useDeleteTeamMutation();
  const { isAdmin } = useGetCurrentUserOrganizationRole({
    organizationId: orgId as string,
  });

  if (isLoading) return <Spinner size="10x" coverViewport />;
  if (error || !team) return <Error />;

  return (
    <Container>
      <DeleteTeamModal
        disabled={deleteLoading || isLoading}
        show={showDeleteModal}
        team={team}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={async (team: ITeam) => {
          const result: { data: null } | { error: { message: string } } = await deleteTeam(team);

          if ('error' in result) {
            toast({
              bg: 'danger',
              title: translate('error'),
              message: result.error.message,
              autohide: false,
            });
          } else {
            toast({
              bg: 'success',
              title: translate('success'),
              message: translate('deleteSuccessful'),
              autohide: true,
            });

            navigate(`/organizations/${orgId}#teams`);
          }

          setShowDeleteModal(false);
        }}
      />
      <Row className="mt-4">
        <Col>
          <h1>{team.name}</h1>
        </Col>
        <Col className="text-end">
          {
            isAdmin && (
              <>
                <Link to="edit" className="btn btn-primary">
                  <FontAwesomeIcon icon="pencil" className="me-1" />
                  {translate('edit')}
                </Link>
                <Button variant="danger" className="ms-2" onClick={() => setShowDeleteModal(true)}>
                  <FontAwesomeIcon icon="trash-alt" className="me-1" />
                  {translate('delete')}
                </Button>
              </>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {team.car_number} - {team.series?.name}
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TabsWithNavigationHash
            defaultActiveKey="users"
            transition={false}
            className="mb-3"
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey="users" title={translate("users")}>
              <TeamMembersTab team={team} />
            </Tab>
            <Tab eventKey="groups" title={translate("groups")}>
              <GroupsTab />
            </Tab>
          </TabsWithNavigationHash>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamDetail;

import { FC } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoleConfigPaginatedTable from 'common/infrastructure/roleConfigs/RoleConfigPaginatedTable';
import RoleConfigSearchFields from 'common/infrastructure/roleConfigs/RoleConfigSearchFields';
import useSearchRoleConfigs, { RoleConfigSortBy } from 'hooks/useSearchKubernetesRoleConfigs';

const SearchKubernetesRoleConfigs: FC = () => {
  const navigate = useNavigate();
  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchRoleConfigs();

  const { error, isLoading, data: paginatedData } = queryResult;

  if (!paginatedData) return (<Spinner animation='border' />);
  if (error) return (<Error coverPage />);

  const { data, meta } = paginatedData;

  return (
    <>
      <Row className="mt-2 w-auto">
        <Col md={6}>
          <h2>
            {translate('k8sRoleConfigs')}
            <Badge pill className="ms-3">
              {meta.total}
            </Badge>
          </h2>
        </Col>
        <Col md={{ span: 4, offset: 2 }} className="text-end">
          <Button
            onClick={() => navigate('/infrastructure/kubernetes-roles/new')}
            className="text-nowrap"
          >
            <FontAwesomeIcon icon="plus" /> {translate('createKubernetesRoleConfig')}
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <RoleConfigSearchFields
            disabled={isLoading}
            setSearchParameters={setSearchParameters}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <RoleConfigPaginatedTable
            roleConfigs={data}
            sortBy={sortBy}
            setSortBy={(updatedSortKey: RoleConfigSortBy) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortDirection={sortDirection}
            pageCount={meta.last_page}
            currentPage={meta.current_page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default SearchKubernetesRoleConfigs;

import React, { useContext } from 'react';
import { Accordion, AccordionContext, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useGetDatabaseMicroserviceQuery } from 'api/dbMicroserviceSlice';
import { Error, Spinner } from '@apex/react-toolkit/components';
import ApplicationMicroserviceDangerZone from 'common/applicationMicroservice/ApplicationMicroserviceDangerZone';
import RelationalDatabaseConnectionDetails from 'common/applicationMicroservice/relationalDatabase/RelationalDatabaseConnectionDetails';
import { IApplicationMicroserviceDatabase, IDatabaseMicroserviceMetadata } from 'types/application/microservice/IDatabaseMicroservice';
import Environment from 'types/Environment';

type InstanceKey = 'dev_instance' | 'staging_instance' | 'prod_instance';

const AccordionToggleLink: React.FC<{
  eventKey: string;
  activeText: string;
  inactiveText: string;
}> = ({ eventKey, activeText, inactiveText }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  const { activeEventKey } = useContext(AccordionContext);

  const isActive = activeEventKey && activeEventKey.includes(eventKey);

  return (
    <Button
      variant="link"
      onClick={decoratedOnClick}
    >
      {isActive ? activeText : inactiveText}
    </Button>
  );
};

const RelationalDatabaseMicroserviceConfiguration: React.FC<{
  microservice: IApplicationMicroserviceDatabase;
  isDeveloperAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized }) => {
  const { microserviceable: dbMicroservice } = microservice;
  const { data: db, isLoading, error } = useGetDatabaseMicroserviceQuery(dbMicroservice.id);


  if (isLoading) {
    return (<Spinner size="5x" />);
  }

  if (error || !db) {
    return (<Error error={error} />);
  }

  const dbInstancesExist = db.dev_instance || db.staging_instance || db.prod_instance;

  return (
    <Accordion alwaysOpen>
      {
        !dbInstancesExist && (
          <Card bg="dark" className="mt-3">
            <Card.Header>
              <Row>
                <Col className="text-start text-warning">
                  {translate('noDbInstancesExistYet')}
                </Col>
              </Row>
            </Card.Header>
          </Card>
        )
      }
      {
        dbInstancesExist && ['dev', 'staging', 'prod'].map((env: string) => db[`${env}_instance` as InstanceKey]
          && (
            <RelationalDatabaseConnectionDetails
              key={env}
              engine={db.engine}
              engineVersion={dbMicroservice.parameter_group_family as string}
              environment={env as Environment}
              instance={db[`${env}_instance` as InstanceKey] as IDatabaseMicroserviceMetadata}
              dbMicroservice={dbMicroservice}
            />
          ))
      }
      {
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start text-danger">
                    {translate('dangerZone')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="2"
                      inactiveText="Open"
                      activeText="Close"
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="2">
              <ApplicationMicroserviceDangerZone microservice={dbMicroservice} />
            </Accordion.Collapse>
          </>
        )
      }
    </Accordion>
  );
};

export default RelationalDatabaseMicroserviceConfiguration;

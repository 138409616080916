import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEditUserMutation } from 'api/userSlice';
import { selectCurrentUser } from 'localState/authSlice';
import IUser from 'types/IUser';
import { SortBy } from 'hooks/useSearchUsers';
import { SortDirection } from 'types/ListSearchTypes';

const UserTable: React.FC<{
  users: IUser[];
  sortBy: SortBy | 'approved';
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  setSortDirection?: (sortDirection: SortDirection) => void;
  actionColumn?: InteractiveTableColumn<IUser>;
  isFetching?: boolean;
  approvalColumn?: boolean;
  userUrl?: string;
}> = ({ users, sortBy, setSortBy, sortDirection, actionColumn, isFetching = false, approvalColumn = true, userUrl = '/users' }) => {
  const [updateUser, { isLoading }] = useEditUserMutation();
  const currentUser = useSelector(selectCurrentUser);

  const toggleApproved = (user: IUser) => updateUser({
    ...user,
    approved: !user.approved,
  });

  const columns: InteractiveTableColumn<IUser>[] = [
    {
      title: translate('firstName'),
      dataIndex: 'first_name',
      sortDirection: sortBy === 'first_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('first_name'),
        className: 'clickable',
      }),
      render: (first: string, { id }: IUser) => (<Link to={`${userUrl}/${id}`}>{first}</Link>),
    },
    {
      title: translate('lastName'),
      dataIndex: 'last_name',
      sortDirection: sortBy === 'last_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('last_name'),
        className: 'clickable',
      }),
    },
    {
      title: translate('email'),
      dataIndex: 'email',
      sortDirection: sortBy === 'email' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('email'),
        className: 'clickable',
      }),
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  if (approvalColumn) columns.push({
    title: translate('approved'),
    dataIndex: 'approved',
    sortDirection: sortBy === 'approved' && sortDirection,
    onTd: () => ({ className: 'text-center' }),
    render: (approved: boolean, user: IUser) => {
      return (
        <DropdownButton
          as={ButtonGroup}
          size="sm"
          variant={approved ? 'success' : 'danger'}
          disabled={isFetching || isLoading || user.id === currentUser.id}
          title={
            // eslint-disable-next-line no-nested-ternary
            (isFetching || isLoading) ? `${translate('loading')}...`
              : (approved ? translate('approved') : translate('unapproved'))
          }
        >
          <Dropdown.Item disabled={approved || isLoading} onClick={() => toggleApproved(user)}>
            {/** @ts-expect-error TypeScript doesn't handle icon correctly */}
            Approve {!approved && (<FontAwesomeIcon style={{ color: 'green' }} icon="circle-check" />)}
          </Dropdown.Item>
          <Dropdown.Item disabled={!approved || isLoading} onClick={() => toggleApproved(user)}>
            {/** @ts-expect-error TypeScript doesn't handle icon correctly */}
            Unapprove  {approved && (<FontAwesomeIcon style={{ color: 'red' }} icon="circle-xmark" />)}
          </Dropdown.Item>
        </DropdownButton>
      );
    },
  });

  return (
    <InteractiveTable
      dataId="id"
      data={users}
      columns={columns}
    />
  );
};

export default UserTable;

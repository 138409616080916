import { Card, Col, Container, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';

const Unauthorized = () => (
  <Container>
    <Row className="mt-4 justify-content-md-center">
      <Col md={{ span: 4 }}>
        <Card bg="secondary">
          <Card.Header>Unauthorized</Card.Header>
          <Card.Body>
            <Card.Text>
              {translate('youAreNotAuthorizedToViewThisPage')}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Unauthorized;

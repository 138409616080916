import { FC, useState } from 'react';
import { Badge, Button, Card, Col, Form, FormSelect, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import * as yup from 'yup';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import FormFeedback from 'common/FormFeedback';
import { useListNodeGroupsQuery } from 'api/nodeGroupSlice';
import INodeGroup from 'types/infrastructure/INodeGroup';
import useToast from 'hooks/useToast';
import { ComputeDetails, IComputeDetailsFormErrors } from 'types/application/microservice/scaling/IComputeDetails';
import { useEditComputeDetailsMutation } from 'api/computeDetailsSlice';

const ComputeDetailsForm: FC<{
  computeDetails: ComputeDetails
  applicationMicroserviceId: string
  isDeveloperAuthorized: boolean
}> = ({ computeDetails, applicationMicroserviceId, isDeveloperAuthorized }) => {
  const butteredToast = useToast();
  const { data: nodeGroupTypes, isLoading: nodeGroupTypesLoading, error: nodeGroupTypesError } = useListNodeGroupsQuery({});
  const [editComputeDetails, { isLoading: editLoading }] = useEditComputeDetailsMutation();
  const [apiErrors, setApiErrors] = useState<IComputeDetailsFormErrors | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (formData: any) => {
    try {
      const copy = JSON.parse(JSON.stringify(formData));
      if (copy.node_group_id === 'General Purpose') copy.node_group_id = null;

      const result: { data: ComputeDetails } | { error: IComputeDetailsFormErrors } = await editComputeDetails({
        id: computeDetails.id,
        application_microservice_id: applicationMicroserviceId,
        ...copy,
      });

      if ('error' in result) {
        setApiErrors(result.error);
        // trigger toast for failed attempt to update
        throw new Error();
      }

      butteredToast({
        bg: 'success',
        title: translate('success'),
        message: translate('computeDetailsUpdateSuccess'),
        autohide: true,
      });
    } catch (error: unknown) {
      butteredToast({
        bg: 'danger',
        title: translate('error'),
        message: translate('computeDetailsUpdateFailed'),
        autohide: true,
      });
    }
  };

  const isLoading = nodeGroupTypesLoading || editLoading;
  if (nodeGroupTypesError) return <Error coverPage />;
  if (!nodeGroupTypes || nodeGroupTypesLoading) return <Spinner />;

  const schema = yup.object({
    node_group_id: yup.string().nullable(),
    ephemeral_storage: yup.number()
      .min(1)
      .nullable(),
    cpu_request: yup.number().min(100).max(4000),
    memory_request: yup.number().min(64).max(8192),
  });

  return (
    <Card bg="dark">
      <Card.Body>
        <Formik
          validationSchema={schema}
          initialValues={{
            node_group_id: computeDetails.node_group_id ?? computeDetails.instance_type_name,
            ephemeral_storage: computeDetails.ephemeral_storage,
            cpu_request: computeDetails.cpu_request,
            memory_request: computeDetails.memory_request,
          }}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            handleReset,
            touched,
            values,
            errors,
            isValid,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-4" controlId="formInstanceType">
                <Form.Label column sm={3}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        {translate('instanceTypeExplanation')}
                      </Tooltip>
                    )}
                  >
                    <Badge pill bg="secondary" className="me-2">
                      <FontAwesomeIcon icon="info" />
                    </Badge>
                  </OverlayTrigger>
                  {translate('instanceType')}
                </Form.Label>
                <Col xl={3}>
                  <FormSelect
                    name="node_group_id"
                    value={values.node_group_id}
                    disabled={isLoading}
                    onChange={handleChange}
                  >
                    <option key="noSelection" value="General Purpose">
                      {translate('generalPurpose')}
                    </option>
                    {
                      nodeGroupTypes.data.map((nodeGroup: INodeGroup) => (
                        <option key={nodeGroup.id} value={nodeGroup.id}>
                          {nodeGroup.name}
                        </option>
                      ))
                    }
                  </FormSelect>
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="node_group_id"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4" controlId="formEphemeralStorage">
                <Form.Label column sm={3}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        {translate('ephemeralStorageExplanation')}
                      </Tooltip>
                    )}
                  >
                    <Badge pill bg="secondary" className="me-2">
                      <FontAwesomeIcon icon="info" />
                    </Badge>
                  </OverlayTrigger>
                  {translate('ephemeralStorage')}
                </Form.Label>
                <Col xl={3}>
                  <InputGroup>
                    <Form.Control
                      required
                      name="ephemeral_storage"
                      type="number"
                      placeholder={translate('ephemeralStorage')}
                      value={values.ephemeral_storage}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isLoading}
                      isValid
                    />
                    <InputGroup.Text>GiB</InputGroup.Text>
                  </InputGroup>
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="ephemeral_storage"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-4" controlId="formCpu">
                <Form.Label column sm={3}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        {translate('cpuExplanation')}
                      </Tooltip>
                    )}
                  >
                    <Badge pill bg="secondary" className="me-2">
                      <FontAwesomeIcon icon="info" />
                    </Badge>
                  </OverlayTrigger>
                  {translate('cpu')}
                </Form.Label>
                <Col xl={3}>
                  <InputGroup>
                    <Form.Control
                      required
                      name="cpu_request"
                      type="number"
                      placeholder={translate('cpu')}
                      value={values.cpu_request}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isLoading}
                      isValid
                    />
                    <InputGroup.Text>mCPU</InputGroup.Text>
                  </InputGroup>
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="cpu_request"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formMemory">
                <Form.Label column sm={3}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={(
                      <Tooltip>
                        {translate('memoryExplanation')}
                      </Tooltip>
                    )}
                  >
                    <Badge pill bg="secondary" className="me-2">
                      <FontAwesomeIcon icon="info" />
                    </Badge>
                  </OverlayTrigger>
                  {translate('memory')}
                </Form.Label>
                <Col xl={3}>
                  <InputGroup>
                    <Form.Control
                      required
                      name="memory_request"
                      type="number"
                      placeholder={translate('memory')}
                      value={values.memory_request}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isLoading}
                      isValid
                    />
                    <InputGroup.Text>MB</InputGroup.Text>
                  </InputGroup>
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="memory_request"
                  />
                </Col>
              </Form.Group>
              {
                isDeveloperAuthorized && (
                  <Row className="mt-4">
                    <Col md={4}>
                      <Button
                        variant="secondary"
                        disabled={isLoading || !isValid || !touched}
                        onClick={handleReset}
                      >
                        <FontAwesomeIcon icon="ban" />
                        <span className="ms-2">{translate('cancel')}</span>
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-2"
                        disabled={!isValid || !touched || isLoading}
                      >
                        {/* @ts-expect-error this is a valid icon */}
                        <FontAwesomeIcon icon="floppy-disk" />
                        <span className="ms-2">{translate('save')}</span>
                      </Button>
                    </Col>
                  </Row>
                )
              }
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default ComputeDetailsForm;


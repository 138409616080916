import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import UserForm from 'common/user/UserForm';
import { useAddUserMutation } from 'api/userSlice';


const New = () => {
  const navigate = useNavigate();
  const [addUser, { isLoading }] = useAddUserMutation();
  const [apiErrors, setApiErrors] = useState(null);

  const handleSubmit = async (formData) => {
    const { data, error } = await addUser(formData);
    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`../${data.id}`);
    }
  };


  const handleCancel = () => {
    navigate('../');
  };

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate('addUser')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          <UserForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            disabled={isLoading}
            apiErrors={apiErrors}
            submitText={translate('create')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default New;

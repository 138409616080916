import { useEffect, useState } from 'react';
import { useLazyListIamPoliciesQuery } from 'api/iamPolicySlice';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import PaginatedResult from 'types/PaginatedResult';
import RtkQueryResult from 'types/RtkQueryResult';
import IIamPolicy from 'types/infrastructure/IIamPolicy';

export type IamPolicySortBy = 'policy_name';
export type UseSearchIamPoliciesSearchParameters = {
  policy_name?: string
  any?: string
}

const useSearchIamPolicies:
  (args?: { initialSearchParameters: UseSearchIamPoliciesSearchParameters }) => {
    setTake: (take: number) => void
    take: number
    sortBy: IamPolicySortBy
    setSortBy: (sortBy: IamPolicySortBy) => void
    sortDirection: SortDirection
    setSortDirection: (sortDirection: SortDirection) => void
    setSearchParameters: (searchParameters: UseSearchIamPoliciesSearchParameters) => void
    searchParameters: UseSearchIamPoliciesSearchParameters
    page: number
    setPage: (page: number) => void
    result: RtkQueryResult<PaginatedResult<IIamPolicy>>
  } = (args) => {
    const initialSearchParameters = args?.initialSearchParameters || {};
    const [take, setTake] = useState<number>(10);
    const [sortBy, setSortBy] = useState<IamPolicySortBy>('policy_name');
    const [page, setPage] = useState<number>(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [searchParameters, setSearchParameters] = useState<UseSearchIamPoliciesSearchParameters>(initialSearchParameters);
    const [fetchIamPolicies, result] = useLazyListIamPoliciesQuery();

    const queryParameters: PagniateSortSearchQueryParameters<IamPolicySortBy, UseSearchIamPoliciesSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      fetchIamPolicies(queryParameters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchIamPolicies, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      fetchIamPolicies(queryParameters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchIamPolicies;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'react-bootstrap';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';

const AddedGroupList = ({ addedGroups, dismissGroup }) => {
  return (
    <Card style={{ minHeight: '100%' }}>
      <Card.Body variant="secondary">
        {
          addedGroups.map(group => (
            <Alert
              key={group.id}
              dismissible
              className="alert-fit-content ms-3"
              onClose={() => dismissGroup(group)}
            >
              {group.name}
            </Alert>
          ))
        }
      </Card.Body>
    </Card>

  );
};

AddedGroupList.propTypes = {
  addedGroups: PropTypes.arrayOf(GroupPropTypes).isRequired,
  dismissGroup: PropTypes.func.isRequired,
};

export default AddedGroupList;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import SearchFilter from 'common/SearchFilter';
import { translate } from '@apex/react-toolkit/lib';

const ApplicationRoleSearchFields = ({ disabled, setSearchObj, take, setTake }) => {
  return (
    <Row>
      <Col md={6} className="text-start">
        <Form.Label>{translate('searchBy')}</Form.Label>
        <SearchFilter
          disabled={disabled}
          onUpdate={setSearchObj}
          options={[
            {
              option: 'name',
              display: translate('name'),
            },
            {
              option: 'description',
              display: translate('description'),
            },
            {
              option: 'any',
              display: translate('any'),
            },
          ]}
        />
      </Col>
      <Col md={{ span: 2, offset: 4 }} className="text-end">
        <Form.Group>
          <Form.Label>{translate('resultsPerPage')}</Form.Label>
          <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

ApplicationRoleSearchFields.propTypes = {
  disabled: PropTypes.bool,
  setSearchObj: PropTypes.func.isRequired,
  take: PropTypes.number.isRequired,
  setTake: PropTypes.func.isRequired,
};

ApplicationRoleSearchFields.defaultProps = {
  disabled: false,
};

export default ApplicationRoleSearchFields;

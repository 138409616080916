import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';

const InfoPopover: React.FC<{
  infoText: string;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  placement?: Placement;
  bg?: string;
  bgClassName?: string;
  icon?: IconProp;
}> = ({
  trigger,
  infoText,
  placement,
  bg = 'secondary',
  bgClassName = 'ms-2 h-50 my-auto text-center',
  icon = 'info'
}) => {
    return (
      <OverlayTrigger
        trigger={trigger}
        placement={placement}
        rootClose
        overlay={(
          <Popover>
            <Popover.Body>
              {infoText}
            </Popover.Body>
          </Popover>
        )}
      >
        <Badge pill bg={bg} className={bgClassName}>
          <FontAwesomeIcon icon={icon} />
        </Badge>
      </OverlayTrigger>
    );
  };

export default InfoPopover;

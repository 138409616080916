import React from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translate } from "@apex/react-toolkit/lib";
import { Spinner, Error } from "@apex/react-toolkit/components";
import TeamSearchFields from "common/teams/TeamSearchFields";
import { useNavigate, useParams } from "react-router-dom";
import useSearchOrganizationTeams, { SortBy } from "hooks/useSearchTeams";
import TeamPaginatedTable from "common/teams/TeamPaginatedTable";

const TeamsTab: React.FC<{
  isAdmin?: boolean;
  isSuperUser?: boolean;
}> = ({ isAdmin, isSuperUser }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  if (!orgId) return <Error message={translate('organizationIdRouteError')} />;

  const {
    result: queryResult,
    setSearchParameters,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useSearchOrganizationTeams({ organizationId: orgId });

  const { error, isLoading, data: paginatedData } = queryResult;

  if (isLoading || !paginatedData) return <Spinner animation="border" />;
  if (error) return <Error coverPage />;

  const { data, meta } = paginatedData;

  return (
    <>
      <Row>
        <Col>
          <h2>
            {translate("teams")} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {(isAdmin || isSuperUser) && (
            <Button
              onClick={() => navigate(`/organizations/${orgId}/teams/new`)}
            >
              <FontAwesomeIcon icon="plus" /> {translate('createTeam')}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <TeamSearchFields
            disabled={isLoading}
            setSearchParameters={setSearchParameters}
            take={take}
            setTake={setTake}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <TeamPaginatedTable
            sortBy={sortBy}
            setSortBy={(updatedSortKey: SortBy) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortDirection={sortDirection}
            teams={data}
            pageCount={meta.last_page}
            currentPage={meta.current_page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default TeamsTab;

import React from 'react';
import InteractiveTable from 'common/InteractiveTable';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import IOrganization from 'types/organization/IOrganization';
import { IUserWithOrg } from 'types/IUser';
import { InteractiveTableColumn } from 'common/InteractiveTable';

const AddOrganizationMemberTable: React.FC<{
  organization: IOrganization;
  users: IUserWithOrg[];
  loading: boolean;
  addSelectedUser: (user: IUserWithOrg) => void;
  stagedMemberHash: {[id: string]: IUserWithOrg};
}> = ({ users, loading, organization, addSelectedUser, stagedMemberHash }) => {
    const columns: InteractiveTableColumn<IUserWithOrg>[] = [
      {
        title: translate('firstName'),
        dataIndex: 'first_name',
      },
      {
        title: translate('lastName'),
        dataIndex: 'last_name',
      },
      {
        title: translate('email'),
        dataIndex: 'email',
      },
      {
        title: translate('organization'),
        dataIndex: 'organization',
        render: (organization: IOrganization | null) => {
          return (<span>{organization?.name || ''}</span>);
        }
      },
      {
        title: '',
        dataIndex: 'id',
        onTd: () => ({ className: 'text-center' }),
        render: (_: string, user: IUserWithOrg) => {
          if (user.organizations?.find((org: IOrganization) => org.id === organization.id)) return (<span className="text-success">{translate('member')}</span>);
          if (stagedMemberHash[user.id]) return (<span className="text-warning">{translate('queued')}</span>);
          return (
            <Button className="btn-xs" onClick={() => addSelectedUser(user)} disabled={loading}>
              <FontAwesomeIcon icon="plus" />
            </Button>
          );
        },
      },
    ];

    return (
      <InteractiveTable
        dataId="id"
        data={users}
        columns={columns}
      />
    );
  };

export default AddOrganizationMemberTable;

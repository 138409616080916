import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useRemoveApplicationRoleGroupMutation } from 'api/applicationRoleSlice';
import GroupPropTypes from 'propTypes/group/GroupPropTypes';
import ApplicationRolePropTypes from 'propTypes/application/ApplicationRolePropTypes';

const RemoveGroupModal = ({ show, handleClose, group, applicationRole }) => {
  const [removeApplicationRoleGroup, { isLoading }] = useRemoveApplicationRoleGroupMutation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="border-0">
        <h2>{translate('removeGroup')}</h2>
      </Modal.Header>
      <Modal.Body className="confirm">
        <p>
          {translate('confirmRemoveRoleFromGroup', { groupName: group.name, roleName: applicationRole.name })}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-3 justify-content-start">
        <Button
          className="me-1"
          disabled={isLoading}
          variant="secondary"
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="danger"
          onClick={async () => {
            await removeApplicationRoleGroup({ roleId: applicationRole.id, groupId: group.id });
            handleClose();
          }}
        >
          {translate('remove')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveGroupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  group: GroupPropTypes.isRequired,
  handleClose: PropTypes.func.isRequired,
  applicationRole: ApplicationRolePropTypes.isRequired,
};

export default RemoveGroupModal;

import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import DeleteWorkerMicroserviceModal from './DeleteWorkerMicroserviceModal';
import { translate } from '@apex/react-toolkit/lib';
import { IApplicationMicroserviceWorker } from 'types/application/microservice/IWorkerMicroservice';

const DangerZone: React.FC<{
  microservice: IApplicationMicroserviceWorker
}> = ({ microservice }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      <DeleteWorkerMicroserviceModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        microservice={microservice}
      />
      <Card bg="dark">
        <Card.Body>
          <Button
            variant="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            {translate('deleteApplicationMicroservice')}
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default DangerZone;

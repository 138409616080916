import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { SortDirection } from 'types/ListSearchTypes';
import { SortBy } from 'hooks/useSearchTeams';
import ITeam from 'types/team/ITeam';

const TeamTable: React.FC<{
  teams: ITeam[]
  sortBy?: string
  setSortBy?: (sortBy: SortBy) => void
  sortDirection?: SortDirection
}> = ({ teams, sortBy, setSortBy, sortDirection }) => {
  const columns = [
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy && setSortBy('name'),
        className: 'clickable',
      }),
      render: (name: string, { id, organization_id }: ITeam) => (<Link to={`/organizations/${organization_id}/teams/${id}`}>{name}</Link>),
    },
    {
      title: translate('carNumber'),
      dataIndex: 'car_number',
      sortDirection: sortBy === 'car_number' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy && setSortBy('car_number'),
        className: 'clickable',
      }),
    },
    {
      title: translate('series'),
      dataIndex: 'series.name',
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={teams}
      columns={columns}
    />
  );
};

export default TeamTable;

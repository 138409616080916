import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IApplication from 'types/application/IApplication';
import { ImageTile, Spinner } from '@apex/react-toolkit/components';
import ImageUploaderModal from './ImageUploaderModal';
import ConfirmRemoveModal from './ConfirmRemoveModal';
import { useGetApplicationQuery } from 'api/applicationSlice';

const AppImageUploader: React.FC<{ application: IApplication }> = (
  { application }
) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  // we duplicate the get application query so we can force ImageTile to reload
  // with the fetching property
  const { isFetching } = useGetApplicationQuery(application.id);

  return (
    <Row>
      <ImageUploaderModal
        application={application}
        show={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
      />
      <ConfirmRemoveModal
        show={showRemoveModal}
        application={application}
        handleClose={() => setShowRemoveModal(false)}
      />
      <Col>
        <Row>
          <Col>
            <Row>
              <Col className="d-flex justify-content-center">
                <h3>{translate('applicationImage')}</h3>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                {
                  isFetching ? (
                    <Spinner />
                  ) : (
                    <ImageTile
                      className="home-tile-container"
                      bodyClassName="home-tile-body"
                      title={application.name}
                      backgroundColor={application.background_color}
                      textColor={application.text_color}
                      src={`/api/applications/${application.id}/icon-img`}
                    />
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-center">
            <Button variant="primary" onClick={() => setShowUploadModal(true)}>
              {translate('uploadPhoto')}
            </Button>
            <Button variant="danger" className="ms-1" onClick={() => setShowRemoveModal(true)}>
              {translate('removePhoto')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AppImageUploader;

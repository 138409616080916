import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Error } from "@apex/react-toolkit/components";
import { useAddTeamMutation } from "api/teamSlice";
import ITeam, { ITeamApiErrors } from "types/team/ITeam";
import TeamForm, { InitialValues } from "common/teams/TeamForm";
import { translate } from "@apex/react-toolkit/lib";
import Unauthorized from 'common/Unauthorized';
import useGetCurrentUserOrganizationRole from 'hooks/useGetCurrentUserOrganizationRole';

const NewTeam = () => {
  const { orgId } = useParams();
  const { isAdmin } = useGetCurrentUserOrganizationRole({
    organizationId: orgId as string,
  });
  const [addTeam, { isLoading, error }] = useAddTeamMutation();
  const navigate = useNavigate();

  if (orgId === undefined) return <Error coverPage />;
  if (!isAdmin) return (<Unauthorized />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate("team")}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          {
            <TeamForm
              organizationId={orgId}
              onSubmit={async (values: InitialValues) => {
                const result: { data: ITeam } | { error: ITeamApiErrors } =
                  await addTeam({ ...values, organization_id: orgId });

                if (!error && "data" in result) {
                  navigate(`/organizations/${orgId}/teams/${result.data.id}`);
                }
              }}
              disabled={isLoading}
              apiErrors={error?.errors}
              onCancel={() => navigate(`/organizations/${orgId}`)}
            />
          }
        </Col>
      </Row>
    </Container>
  );
};

export default NewTeam;

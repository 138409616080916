import React, { ReactNode } from 'react';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from './InteractiveTable.module.css';
import { SortDirection } from 'types/ListSearchTypes';

export interface InteractiveTableColumn<T> {
  title: string;
  dataIndex?: string;
  sortDirection?: boolean | SortDirection;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onTd?: (prop: any, item: T, idx: number) => object;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  render?: (prop: any, item: T, idx: number) => JSX.Element | string | null | false;
  onHeader?: (column: InteractiveTableColumn<T>) => ({
    onClick: () => void;
    className?: string;
  }) | null;
}

export interface IProps<T> {
  columns: InteractiveTableColumn<T>[];
  data?: T[];
  dataId: string;
  onRow?: (item: T, idx: number) => object | null;
}

const InteractiveTable = <T extends object>({
  columns,
  data = [],
  dataId,
  onRow = () => null,
}: IProps<T>) => {
  const getSortIcon = (sortDirection: SortDirection | boolean | undefined) => {
    if (sortDirection === 'asc') return (<FontAwesomeIcon icon={faArrowUp} />);
    if (sortDirection === 'desc') return (<FontAwesomeIcon icon={faArrowDown} />);
    return null;
  };

  return (
    <Table className={styles.table} striped bordered hover variant="dark" size="sm">
      <thead>
        <tr>
          {
            columns.map((column: InteractiveTableColumn<T>) => {
              const onHeader = column.onHeader || (() => null);
              return (
                <th
                  {...onHeader(column)}
                  key={`interative-table-${column.title}`}
                >
                  <span className={column.sortDirection ? 'text-primary' : ''}>
                    {column.title} {getSortIcon(column.sortDirection)}
                  </span>
                </th>
              );
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          data.map((item: T, idx: number) => (
            <tr key={`interactive-table-${item[dataId as keyof T]}`} {...onRow(item, idx)} data-testid={`row-${item[dataId as keyof T]}`} className="align-middle">
              {
                columns.map(({ dataIndex, render, title, onTd }) => {
                  const datum = get(item, dataIndex as keyof T);
                  const tdProps = onTd || (() => null);
                  return (
                    <td {...tdProps(datum, item, idx)} title={typeof datum === 'string' ? datum : ''} key={`row-${item[dataId as keyof T]}-${title}`}>
                      {render ? render(datum, item, idx) as unknown as ReactNode : datum as unknown as ReactNode}
                    </td>
                  );
                })
              }
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

// Left in for demonstration purposes
//
// InteractiveTable.defaultProps = {
//   // Set Props for a row
//   // onRow will conflict with columns.render(<Link to="" />"). don't use both at once
//   onRow: (record, rowIndex) => {
//     return {
//       // Example properties:
//       //
//       // onClick: event => noop(), // click row
//       // onDoubleClick: event => noop(), // double click row
//       // onContextMenu: event => noop(), // right button click row
//       // onMouseEnter: event => noop(), // mouse enter row
//       // onMouseLeave: event => noop(), // mouse leave row
//     };
//   },
//   onHeader: (column) => {
//     return {
//       // Example properties:
//       //
//       // onClick: event => noop(), // click row
//       // onDoubleClick: event => noop(), // double click row
//     };
//   },
// };

export default InteractiveTable;

import { Col, Container, Row } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';
import IamPolicyForm from 'common/infrastructure/iamPolicies/IamPolicyForm';
import { IIamPolicyApiErrors } from 'types/infrastructure/IIamPolicy';
import { useCreateIamPolicyMutation } from 'api/iamPolicySlice';
import useToast from 'hooks/useToast';

const New = () => {
  const navigate = useNavigate();
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();
  const [createIamPolicy, { isLoading }] = useCreateIamPolicyMutation();
  const [apiErrors, setApiErrors] = useState<IIamPolicyApiErrors | null>(null);
  const toast = useToast();

  if (!isSuperUser) return <Unauthorized />;
  if (permissionLoading) return (<Spinner size="10x" coverViewport />);

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>{translate('createIamPolicy')}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <IamPolicyForm
            disabled={isLoading}
            onSubmit={async (values) => {
              const result = await createIamPolicy(values);

              if ('error' in result) {
                setApiErrors(result.error.errors);
                toast({
                  bg: 'danger',
                  title: translate('error'),
                  message: result.error.message,
                  autohide: false,
                });
              } else if ('data' in result) {
                toast({
                  bg: 'success',
                  title: translate('success'),
                  message: translate('created'),
                  autohide: true,
                });

                navigate('..#iamPolicies');
              }
            }}
            onCancel={() => navigate('..#iamPolicies')}
            apiErrors={apiErrors}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default New;

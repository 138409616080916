import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useEditKubernetesRoleConfigMutation, useGetKubernetesRoleConfigQuery } from 'api/roleConfigsSlice';
import RoleConfigForm, { RoleConfigFormValues } from 'common/infrastructure/roleConfigs/RoleConfigForm';
import Unauthorized from 'common/Unauthorized';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import IRoleConfig, { IRoleConfigApiErrors, IRoleConfigValidationErrors } from 'types/infrastructure/IRoleConfig';

const EditKubernetesRoleConfig: FC = () => {
  const navigate = useNavigate();
  const { kubernetesRoleId } = useParams();
  const [apiErrors, setApiErrors] = useState<IRoleConfigValidationErrors | null>(null);
  const { isSuperUser, isLoading: permissionLoading } = useCheckUserPermission();

  const { data: roleConfig, isFetching, error } = useGetKubernetesRoleConfigQuery(kubernetesRoleId as string);

  const [editRoleConfig, { isLoading }] = useEditKubernetesRoleConfigMutation();

  if (!isSuperUser) return <Unauthorized />;
  if (isFetching || permissionLoading) return (<Spinner size="10x" coverViewport />);
  if (error || !roleConfig) return (<Error coverPage />);

  const handleSubmit = async (formData: RoleConfigFormValues) => {
    const result: { data: IRoleConfig } | { error: IRoleConfigApiErrors } = await editRoleConfig({ ...formData, id: roleConfig.id });

    if ('error' in result) {
      setApiErrors(result.error.errors);
    } else {
      navigate(`/infrastructure/kubernetes-roles/${roleConfig.id}`);
    }
  };

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
          <h2>Edit {roleConfig.name}</h2>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>
        <RoleConfigForm
            initialValues={roleConfig}
            disabled={isLoading}
            onCancel={() => navigate('..')}
            onSubmit={handleSubmit}
            apiErrors={apiErrors}
            // @ts-expect-error this is a valid icon
            icon="floppy-disk"
            submitText={translate('save')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EditKubernetesRoleConfig;

import PropTypes from 'prop-types';

export const clientCrednetialsPropTypeProperties = {
  client_id: PropTypes.string.isRequired,
  client_secret: PropTypes.string.isRequired,
};

export default PropTypes.shape({
  ...clientCrednetialsPropTypeProperties,
});

import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { translate } from "@apex/react-toolkit/lib";
import FormFeedback from "common/FormFeedback";
import { IOrganizationApiErrors } from "types/organization/IOrganization";

export interface InitialValues {
  name: string;
  description?: string;
  domain: string;
}

const defaultInitialValues: InitialValues = {
  name: "",
  description: "",
  domain: "",
};

const OrganizationForm: React.FC<{
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => void;
  disabled?: boolean;
  onCancel: () => void;
  apiErrors: IOrganizationApiErrors;
  submitText?: string;
}> = ({
  initialValues = defaultInitialValues,
  disabled = false,
  onCancel,
  onSubmit,
  apiErrors,
  submitText = translate("create"),
}) => {
  const schema = yup.object({
    name: yup.string().required(translate("nameRequired")),
    description: yup.string().nullable(),
    domain: yup
      .string()
      .matches(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        translate("validDomainMessage")
      )
      .required(translate("domainRequired")),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        onSubmit(formInput);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate("name")}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder={translate("name")}
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate("description")}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                value={values.description || ""}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDomain">
              <Form.Label>{translate("domain")}</Form.Label>
              <Form.Control
                required
                name="domain"
                type="text"
                placeholder="your-website-domain.com"
                value={values.domain || ""}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.domain && !errors.domain}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="domain"
              />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Button
                variant="secondary"
                type="button"
                disabled={disabled}
                onClick={onCancel}
                className="me-2"
              >
                {translate("cancel")}
              </Button>
              <Button variant="primary" type="submit" disabled={disabled}>
                {submitText}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationForm;

import { FC, useState } from 'react';
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import INodeGroup, { INodeGroupApiErrors, TaintType, NodeGroupCapacityType, AmiType } from 'types/infrastructure/INodeGroup';
import { ErrorMessage, Field, FieldArray, FieldInputProps, Formik } from 'formik';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import FormFeedback from 'common/FormFeedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type NodeGroupFormValues = Omit<INodeGroup, 'id' | 'created_at' | 'updated_at'>;

const defaultValues: NodeGroupFormValues = {
  name: '',
  description: '',
  iam_role: '',
  generally_available: true,
  minimum_node_count: 0,
  maximum_node_count: 0,
  desired_node_count: 0,
  capacity_type: 'SPOT',
  tags: [],
  subnets: [],
  labels: [],
  instance_type: '',
  storage_size: 50,
  ami_type: 'AL2_x86_64',
};

const AMI_TYPES: AmiType[] = [
  'AL2_x86_64',
  'AL2_x86_64_GPU',
  'AL2_ARM_64',
  'AL2023_x86_64_STANDARD',
];

const NodeGroupForm: FC<{
  initialValues?: NodeGroupFormValues
  disabled: boolean
  onCancel: () => void
  onSubmit: (formData: NodeGroupFormValues) => void
  apiErrors: Pick<INodeGroupApiErrors, 'errors'> | null
  submitText: string
  icon?: IconProp
}> = ({ initialValues = defaultValues, disabled, onCancel, onSubmit, apiErrors, submitText, icon = 'plus' }) => {
  const [selectedCapcity, setSelectedCapacity] = useState<NodeGroupCapacityType>(initialValues.capacity_type || defaultValues.capacity_type);
  const [selectedTaint, setSelectedTaint] = useState<TaintType | undefined>(initialValues.taint || undefined);
  const [selectedAmi, setSelectedAmi] = useState<AmiType>(initialValues.ami_type || defaultValues.ami_type);

  const schema = yup.object({
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().nullable(),
    iam_role: yup.string().nullable(),
    generally_available: yup.bool().default(true),
    minimum_node_count: yup.number().required(),
    maximum_node_count: yup.number().required()
      .moreThan(yup.ref('minimum_node_count'), translate('maxNodeCountMustBeLargerThanMinNodeCount')),
    desired_node_count: yup.number().required(),
    capacity_type: yup.string().oneOf(['ON_DEMAND', 'SPOT']).required(),
    tags: yup.array().required(),
    subnets: yup.array().required(),
    labels: yup.array().required(),
    instance_type: yup.string().required(),
    storage_size: yup.number().positive().min(50).max(300),
    ami_type: yup.string().oneOf(AMI_TYPES),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        const generallyAvailable = !!formInput.generally_available;
        onSubmit({
          ...formInput,
          capacity_type: selectedCapcity,
          generally_available: generallyAvailable,
          taint: selectedTaint,
          ami_type: selectedAmi,
        });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{translate('name')}</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder={translate('name')}
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.name && !errors.name}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="name"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{translate('description')}</Form.Label>
            <Form.Control
              required
              name="description"
              type="text"
              placeholder={translate('description')}
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.description && !errors.description}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="description"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formAmiType">
            <Form.Label>{translate('amiType')}</Form.Label>
            <Form.Select
              value={selectedAmi}
              onChange={(e) => {
                  setSelectedAmi(e.target.value as AmiType);
              }}
            >
              {
                AMI_TYPES.map((type) => (
                  <option value={type}>{type}</option>
                ))
              }
            </Form.Select>
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="ami_type"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formInstanceType">
            <Form.Label>{translate('instanceType')}</Form.Label>
            <Form.Control
              required
              name="instance_type"
              type="text"
              placeholder={translate('instanceType')}
              value={values.instance_type}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.instance_type && !errors.instance_type}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="instance_type"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formStorageSize">
            <Form.Label>{translate('storageSize')}</Form.Label>
            <Form.Control
              required
              name="storage_size"
              type="number"
              placeholder={translate('storage_size')}
              value={values.storage_size}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.storage_size && !errors.storage_size}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="storage_size"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formIamRole">
            <Form.Label>{translate('iamRole')}</Form.Label>
            <Form.Control
              name="iam_role"
              type="text"
              placeholder={translate('iamRole')}
              value={values.iam_role}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.iam_role && !errors.iam_role}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="iam_role"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGenerallyAvailable">
            <Form.Check
              name="generally_available"
              checked={values.generally_available}
              label={translate('generallyAvailable')}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.generally_available && !errors.generally_available}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="generally_available"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formMinNodeCount">
            <Form.Label>{translate('minimumNodeCount')}</Form.Label>
            <Form.Control
              required
              name="minimum_node_count"
              type="number"
              placeholder={translate('minimumNodeCount')}
              value={values.minimum_node_count}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.minimum_node_count && !errors.minimum_node_count}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="minimum_node_count"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formMaxNodeCount">
            <Form.Label>{translate('maximumNodeCount')}</Form.Label>
            <Form.Control
              required
              name="maximum_node_count"
              type="number"
              placeholder={translate('maximumNodeCount')}
              value={values.maximum_node_count}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.maximum_node_count && !errors.maximum_node_count}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="maximum_node_count"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDesiredNodeCount">
            <Form.Label>{translate('desiredNodeCount')}</Form.Label>
            <Form.Control
              required
              name="desired_node_count"
              type="number"
              placeholder={translate('desiredNodeCount')}
              value={values.desired_node_count}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.minimum_node_count && !errors.desired_node_count}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="desired_node_count"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCapacityType">
            <Form.Label>{translate('capacityType')}</Form.Label>
            <Form.Select
              value={selectedCapcity}
              onChange={e => setSelectedCapacity(e.target.value as NodeGroupCapacityType)}
            >
              <option value="ON_DEMAND">{translate('onDemandInstance')}</option>
              <option value="SPOT">{translate('spotInstance')}</option>
            </Form.Select>
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="description"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formTaintEffect">
            <Form.Label>{translate('taint')}</Form.Label>
            <Form.Text className="ms-2">
              {translate('readMoreAboutKubernetesTaints')}
              <a
                className="ms-1"
                href="https://kubernetes.io/docs/concepts/scheduling-eviction/taint-and-toleration/#concepts"
                target="_blank"
                rel="noreferrer noopener"
              >
                  {translate('here')}
              </a>
            </Form.Text>
            <Form.Select
              value={selectedTaint}
              onChange={e => setSelectedTaint(e.target.value as TaintType)}
            >
              <option selected={selectedTaint === undefined} disabled>{translate('selectTaintEffect')}</option>
              <option value="NoExecute">{translate('noExecute')}</option>
              <option value="NoSchedule">{translate('noSchedule')}</option>
              <option value="PreferNoShedule">{translate('preferNoSchedule')}</option>
            </Form.Select>
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="description"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formTags">
            <Form.Label>{translate('awsTags')}</Form.Label>
            <Card className="bg-dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col xl={4}><h6>{translate('key')}</h6></Col>
                  <Col xl={4}><h6>{translate('value')}</h6></Col>
                  <Col xl={4}><h6>{translate('delete')}</h6></Col>
                </Row>
                <FieldArray name="tags">
                  {({ push, remove }) => (
                    <div>
                      {values.tags.length > 0
                        && values.tags.map((tag, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Row key={index}>
                            <Col xl={4}>
                              <Field name={`tags.${index}.key`}>
                                {({ field }: { field: FieldInputProps<string> }) => (
                                  <Form.Group className="mb-3">
                                    <InputGroup>
                                      <Form.Control
                                        {...field}
                                        onKeyUp={field.onBlur}
                                        disabled={disabled}
                                      />
                                      <Form.Control.Feedback type="invalid" className="d-block">
                                        <ErrorMessage name={`tags.${index}.name`} />
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>
                                )}
                              </Field>
                            </Col>

                            <Col xl={4}>
                              <Field name={`tags.${index}.value`}>
                                {({ field }: { field: FieldInputProps<string> }) => (
                                  <Form.Group className="mb-3">
                                    <InputGroup>
                                      <Form.Control
                                        {...field}
                                        onKeyUp={field.onBlur}
                                        type="text"
                                        disabled={disabled}
                                      />
                                      <Form.Control.Feedback type="invalid" className="d-block">
                                        <ErrorMessage name={`tags.${index}.value`} />
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>
                                )}
                              </Field>
                            </Col>
                            <Col xs={1}>
                              <Button
                                disabled={disabled}
                                variant="danger"
                                onClick={() => remove(index)}
                              >
                                <FontAwesomeIcon icon="times" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      <Button
                        variant="primary"
                        onClick={() => push({ key: '', value: '' })}
                      >
                        <FontAwesomeIcon icon="plus" className="me-1" />
                        {translate('addTag')}
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </Card.Body>
            </Card>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLabels">
            <Form.Label>{translate('k8sLabels')}</Form.Label>
            <Card className="bg-dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col xl={4}><h6>{translate('key')}</h6></Col>
                  <Col xl={4}><h6>{translate('value')}</h6></Col>
                  <Col xl={4}><h6>{translate('delete')}</h6></Col>
                </Row>
                <FieldArray name="labels">
                  {({ push, remove }) => (
                    <div>
                      {values.labels.length > 0
                        && values.labels.map((label, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Row key={index}>
                            <Col xl={4}>
                              <Field name={`labels.${index}.key`}>
                                {({ field }: { field: FieldInputProps<string> }) => (
                                  <Form.Group className="mb-3">
                                    <InputGroup>
                                      <Form.Control
                                        {...field}
                                        onKeyUp={field.onBlur}
                                        disabled={disabled}
                                      />
                                      <Form.Control.Feedback type="invalid" className="d-block">
                                        <ErrorMessage name={`labels.${index}.name`} />
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>
                                )}
                              </Field>
                            </Col>

                            <Col xl={4}>
                              <Field name={`labels.${index}.value`}>
                                {({ field }: { field: FieldInputProps<string> }) => (
                                  <Form.Group className="mb-3">
                                    <InputGroup>
                                      <Form.Control
                                        {...field}
                                        onKeyUp={field.onBlur}
                                        type="text"
                                        disabled={disabled}
                                      />
                                      <Form.Control.Feedback type="invalid" className="d-block">
                                        <ErrorMessage name={`labels.${index}.value`} />
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>
                                )}
                              </Field>
                            </Col>
                            <Col xs={1}>
                              <Button
                                disabled={disabled}
                                variant="danger"
                                onClick={() => remove(index)}
                              >
                                <FontAwesomeIcon icon="times" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      <Button
                        variant="primary"
                        onClick={() => push({ key: '', value: '' })}
                      >
                        <FontAwesomeIcon icon="plus" className="me-1" />
                        {translate('addLabel')}
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </Card.Body>
            </Card>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSubnets">
            <Form.Label>{translate('subnets')}</Form.Label>
            <Card className="bg-dark">
              <Card.Body>
                <Row className="mb-1">
                  <Col xl={6}><h6>{translate('value')}</h6></Col>
                  <Col xl={6}><h6>{translate('delete')}</h6></Col>
                </Row>
                <FieldArray name="subnets">
                  {({ push, remove }) => (
                    <div>
                      {values.subnets.length > 0
                        && values.subnets.map((subnet, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Row key={index}>
                            <Col xl={6}>
                              <Field name={`subnets.${index}`}>
                                {({ field }: { field: FieldInputProps<string> }) => (
                                  <Form.Group className="mb-3">
                                    <InputGroup>
                                      <Form.Control
                                        {...field}
                                        onKeyUp={field.onBlur}
                                        disabled={disabled}
                                      />
                                      <Form.Control.Feedback type="invalid" className="d-block">
                                        <ErrorMessage name={`subnets.${index}`} />
                                      </Form.Control.Feedback>
                                    </InputGroup>
                                  </Form.Group>
                                )}
                              </Field>
                            </Col>
                            <Col xs={1}>
                              <Button
                                disabled={disabled}
                                variant="danger"
                                onClick={() => remove(index)}
                              >
                                <FontAwesomeIcon icon="times" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      <Button
                        variant="primary"
                        onClick={() => push('')}
                      >
                        <FontAwesomeIcon icon="plus" className="me-1" />
                        {translate('addSubnet')}
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </Card.Body>
            </Card>
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <Button
                variant="secondary"
                type="button"
                disabled={disabled}
                onClick={onCancel}
                className="me-2"
              >
                <FontAwesomeIcon icon="ban" className="me-1" />
                {translate("cancel")}
              </Button>
              <Button variant="primary" type="submit" disabled={disabled}>
                {
                  disabled ? (
                    <FontAwesomeIcon icon="spinner" className="me-1" spin />
                  ) : (
                    <FontAwesomeIcon icon={icon} className="me-1" />
                  )
                }
                {submitText}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default NodeGroupForm;

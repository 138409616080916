import { useState, useEffect } from 'react';
import { useLazyGetApplicationOrganizationsQuery } from 'api/applicationSlice';
import { PagniateSortSearchQueryParameters, SortDirection } from 'types/ListSearchTypes';
import IOrganization from 'types/organization/IOrganization';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';
import { SortBy } from 'hooks/useSearchOrganizations';

export type UseSearchApplicationOrganizationsSearchParameters = {
  name?: string
  description?: string
  any?: string
};

const useSearchApplicationOrganizations:
  (args: {
    applicationId: string;
    intitialTake?: number;
  }) => {
    setTake: (take: number) => void;
    take: number;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    sortDirection: SortDirection;
    setSortDirection: (sortDirection: SortDirection) => void;
    setSearchParameters: (searchParameters: UseSearchApplicationOrganizationsSearchParameters) => void;
    searchParameters: UseSearchApplicationOrganizationsSearchParameters;
    page: number
    setPage: (page: number) => void;
    result: RtkQueryResult<PaginatedResult<IOrganization>>
  }
  = ({ applicationId, intitialTake = 10 }) => {
    const [take, setTake] = useState<number>(intitialTake);
    const [sortBy, setSortBy] = useState<SortBy>('name');
    const [page, setPage] = useState(1);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [searchParameters, setSearchParameters] = useState<UseSearchApplicationOrganizationsSearchParameters>({});
    const [getAppOrganizations, result] = useLazyGetApplicationOrganizationsQuery();

    const queryParameters: PagniateSortSearchQueryParameters<SortBy, UseSearchApplicationOrganizationsSearchParameters> = {
      limit: take,
      page,
      sort_by: sortBy,
      sort_direction: sortDirection,
      ...searchParameters,
    };

    useEffect(() => {
      getAppOrganizations({ applicationId, queryParameters });
    }, [getAppOrganizations, take, page, sortBy, sortDirection]);

    useEffect(() => {
      setPage(1);
      getAppOrganizations({ applicationId, queryParameters });
    }, [searchParameters, take]);

    return {
      setTake,
      take,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      setSearchParameters,
      searchParameters,
      page,
      setPage,
      result,
    };
  };

export default useSearchApplicationOrganizations;

import { useState, useEffect } from 'react';
import { useLazyGetApplicationGroupsQuery } from 'api/applicationSlice';

const useSearchApplicationGroups = ({ applicationId }) => {
  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState('name');
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchObj, setSearchObj] = useState(null);
  const [getApplicationGroups, result] = useLazyGetApplicationGroupsQuery();

  const queryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };
  const searchBy = searchObj?.option;
  const searchVal = searchObj?.value;

  if (searchBy && searchVal) queryParameters[searchBy] = searchVal;

  useEffect(() => {
    if (searchBy && searchVal) queryParameters[searchBy] = searchVal;

    getApplicationGroups({ applicationId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationGroups, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getApplicationGroups({ applicationId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObj, take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    setSearchObj,
    page,
    setPage,
    result,
  };
};

export default useSearchApplicationGroups;

import React, { Suspense } from 'react';

import {
  Route,
  Routes,
} from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import Detail from 'App/AppRoutes/Groups/Detail';
import Search from 'App/AppRoutes/Groups/Search';
import New from 'App/AppRoutes/Groups/New';
import Edit from 'App/AppRoutes/Groups/Edit';

const Groups = () => {
  return (
    <Suspense fallback={<h1>{translate('loading')} . . .</h1>}>
      <Routes>
        <Route exact path="/" element={<Search />} />
        <Route path="new" element={<New />} />
        <Route path=":groupId" element={<Detail />} />
        <Route path=":groupId/edit" element={<Edit />} />
      </Routes>
    </Suspense>
  );
};

export default Groups;

import React, { Suspense } from 'react';
import {
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import PageNotFound from 'common/PageNotFound';
import Detail from 'App/AppRoutes/Applications/Application/Detail';
import Edit from 'App/AppRoutes/Applications/Application/Edit';
import Groups from 'App/AppRoutes/Applications/Application/Groups';
import Organizations from 'App/AppRoutes/Applications/Application/Organizations';
import Permissions from 'App/AppRoutes/Applications/Application/Permissions';
import NewPermission from 'App/AppRoutes/Applications/Application/Permissions/New';
import PermissionDetail from 'App/AppRoutes/Applications/Application/Permissions/Detail';
import EditPermission from 'App/AppRoutes/Applications/Application/Permissions/Edit';
import Roles from 'App/AppRoutes/Applications/Application/Roles';
import NewRole from 'App/AppRoutes/Applications/Application/Roles/New';
import RoleDetail from 'App/AppRoutes/Applications/Application/Roles/Detail';
import EditRole from 'App/AppRoutes/Applications/Application/Roles/Edit';
import Maintainers from 'App/AppRoutes/Applications/Application/Maintainers';
import Microservices from 'App/AppRoutes/Applications/Application/Microservices';
import MicroserviceDetail from 'App/AppRoutes/Applications/Application/Microservices/Detail';
import NewMicroservice from 'App/AppRoutes/Applications/Application/Microservices/New';

import Unauthorized from 'common/Unauthorized';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';

const Application = () => {
  const { applicationId } = useParams();
  const { error, isAppMaintainer, isFetching } = useIsApplicationMaintainer(applicationId);

  if (isFetching) return (<Spinner />);
  if (error) return (<Error />);

  if (!isAppMaintainer) {
    return (
      <Unauthorized />
    );
  }

  return (
    <Suspense fallback={<h1>{translate('loading')} . . .</h1>}>
      <Routes>
        <Route path="/" element={<Detail />} />
        <Route path="edit" element={<Edit />} />
        <Route path="groups" element={<Groups />} />
        <Route path="organizations" element={<Organizations />} />
        <Route path="permissions" element={<Permissions />} />
        <Route path="permissions/new" element={<NewPermission />} />
        <Route path="permissions/:permissionId" element={<PermissionDetail />} />
        <Route path="permissions/:permissionId/edit" element={<EditPermission />} />
        <Route path="roles" element={<Roles />} />
        <Route path="roles/new" element={<NewRole />} />
        <Route path="roles/:roleId/edit" element={<EditRole />} />
        <Route path="roles/:roleId/*" element={<RoleDetail />} />
        <Route path="maintainers" element={<Maintainers />} />
        <Route path="microservices" element={<Microservices />} />
        <Route path="microservices/new" element={<NewMicroservice />} />
        <Route path="microservices/:microserviceId/*" element={<MicroserviceDetail />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Application;

import { Card, Col, Row, Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IGroup from "types/group/IGroup";

const OrganizationRoleGroupsCard: React.FC<{
  groups: IGroup[]
}> = ({
  groups
}) => {
    return (
      <Card bg="dark" className="mt-2">
        <Card.Header className="ps-4 pe-0">
          <Row className="w-100">
            <Col>
              <h4>{translate('groups')}</h4>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table variant="dark" striped>
            <tbody>
              {
                groups?.map((group) => (
                  <tr className="align-middle" key={group.id}>
                    <td>
                      {group.name}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

export default OrganizationRoleGroupsCard;

import React, { useState, useCallback } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner, Error } from '@apex/react-toolkit/components';
import { IApplicationMicroserviceDatabase } from 'types/application/microservice/IDatabaseMicroservice';
import { useGetDatabaseReplicationConfigQuery, useGetDatabaseReplicationQuery } from 'api/dbMicroserviceSlice';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';
import { JsonView, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ReplicatonActionDropdown from './ReplicationActionDropdown';
import { formatDateTime } from 'helpers/datetime';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

const DatabaseReplicationTab: React.FC<{ microservice: IApplicationMicroserviceDatabase }> = ({ microservice }) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const { isSuperUser } = useCheckUserPermission();
  const { data: configData, error: configError, isLoading: configIsLoading, isFetching, refetch: configRefetch } =
    useGetDatabaseReplicationConfigQuery(microservice.application_microserviceable_id, {
      pollingInterval: isPolling ? 7000 : undefined,
    });
  const { data: replicationData, error: replicationError, isLoading: replicationIsLoading, refetch: replicationRefetch } =
    useGetDatabaseReplicationQuery(microservice.application_microserviceable_id, {
      pollingInterval: isPolling ? 7000 : undefined,
    });

  // if you don't cache these for shouldExpandNode on the Json viewer, the viewer collapses on each render
  const collapseReplicationJsonFn = useCallback((level: number) => (level !== 0), []);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const collapseConfigJsonFn = useCallback((level: number, value: any, field?: string) => (field !== 'ReplicationSettings' && level !== 0), []);

  if (configIsLoading || replicationIsLoading) return (<Spinner />);
  if (configError || replicationError) return (<Error />);

  if (!isSuperUser) {
    return (<Unauthorized />);
  }

  return (
    <Container>
      <Row>
        <Col>
          <Card bg='dark'>
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>
                    <h3>{translate('dmsReplication')}</h3>
                  </Col>
                  <Col className="text-end">
                    <Button
                      variant="primary"
                      onClick={() => { configRefetch(); replicationRefetch(); }}
                      disabled={isFetching || isPolling}
                    >
                      <FontAwesomeIcon icon={isFetching ? 'spinner' : 'arrows-rotate' as IconProp} /> {translate('refresh')}
                    </Button>
                    <ReplicatonActionDropdown
                      databaseMicroservice={microservice}
                      replicationCofig={configData || null}
                      additionalDropdownItems={[
                        <DropdownItem onClick={() => setIsPolling(!isPolling)}>
                          <FontAwesomeIcon icon="clock" /> {isPolling ? 'Stop Polling' : 'Poll For Updates'}
                        </DropdownItem>
                      ]}
                    />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text>
                {(configData && replicationData) ? (
                  <>
                    <Row>
                      <Col><strong>{translate('status')}:</strong> <span className="text-muted">{replicationData.status}</span></Col>
                    </Row>
                    <Row>
                      <Col><strong>{translate('replicationType')}:</strong> <span className="text-muted">{replicationData.replication_type}</span></Col>
                    </Row>
                    <Row>
                      <Col><strong>{translate('replicationCreateTime')}:</strong> <span className="text-muted">{formatDateTime(replicationData.replication_create_time)}</span></Col>
                    </Row>
                    <Row>
                      <Col><strong>{translate('replicationUpdateTime')}:</strong> <span className="text-muted">{formatDateTime(replicationData.replication_update_time)}</span></Col>
                    </Row>
                    <Row>
                      <Col><strong>{translate('startReplicationType')}:</strong> <span className="text-muted">{replicationData.start_replication_type || translate('n/a')}</span></Col>
                    </Row>
                    {
                      replicationData.failure_messages.length > 0 && (
                        <Row>
                          <Col>
                            <strong className="text-danger">{translate('failureMessages')}: </strong>{replicationData.failure_messages}
                          </Col>
                        </Row>
                      )
                    }
                  </>
                ) : (
                  <>
                    <Alert variant="danger">
                      {translate('replicationConfigDoesNotExistYet')}
                    </Alert>
                  </>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card bg='dark'>
            <Card.Body>
              <Card.Title>
                Replication JSON
              </Card.Title>
              {replicationData ? (
                <JsonView data={{
                  ...replicationData?.raw_replication,
                }}
                  shouldExpandNode={collapseReplicationJsonFn}
                  style={darkStyles}
                />
              ) : (
                <Alert variant="danger">
                  {translate('replicationDataIsNotAvailable')}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card bg='dark'>
            <Card.Body>
              <Card.Title>
                {translate('replicationConfigJson')}
              </Card.Title>
              {configData ? (
                <JsonView data={{
                  ...configData.raw_config,
                  ReplicationSettings: JSON.parse(configData.raw_config.ReplicationSettings),
                  TableMappings: JSON.parse(configData.raw_config.TableMappings),
                }}
                  shouldExpandNode={collapseConfigJsonFn}
                  style={darkStyles}
                />
              ) : (
                <Alert variant="danger">
                  {translate('replicationConfigDoesNotExistYet')}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default DatabaseReplicationTab;

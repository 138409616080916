import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InteractiveTable from 'common/InteractiveTable';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserPropTypes from 'propTypes/user/UserPropTypes';
import { translate } from '@apex/react-toolkit/lib';

const AddGroupMemberTable = ({ users, addSelectedUser, currentMembers, loading, stagedMemberHash }) => {
  const currentMemberHash = useMemo(() => {
    const memberHash = {};
    currentMembers.forEach(member => {
      memberHash[member.id] = member;
    });
    return memberHash;
  }, [currentMembers]);

  const columns = [
    {
      title: translate('firstName'),
      dataIndex: 'first_name',
    },
    {
      title: translate('lastName'),
      dataIndex: 'last_name',
    },
    {
      title: translate('email'),
      dataIndex: 'email',
    },
    {
      title: '',
      dataIndex: 'id',
      onTd: () => ({ className: 'text-center' }),
      render: (_, user) => {
        if (currentMemberHash[user.id]) return (<span className="text-success">{translate('member')}</span>);
        if (stagedMemberHash[user.id]) return (<span className="text-warning">{translate('queued')}</span>);
        return (
          <Button className="btn-xs" onClick={() => addSelectedUser(user)} disabled={loading}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        );
      },
    },
  ];

  return (
    <InteractiveTable
      dataId="id"
      data={users}
      columns={columns}
    />
  );
};

AddGroupMemberTable.propTypes = {
  users: PropTypes.arrayOf(UserPropTypes).isRequired,
  addSelectedUser: PropTypes.func.isRequired,
  currentMembers: PropTypes.arrayOf(UserPropTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  stagedMemberHash: PropTypes.object.isRequired,
};

export default AddGroupMemberTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormFeedback = ({ touched, errors, apiErrors, fieldName }) => {
  const hasErrors = touched[fieldName] && (
    errors[fieldName] ||
    apiErrors && apiErrors[fieldName] ||
    apiErrors?.errors && apiErrors?.errors?.[fieldName]
  );

  if (!hasErrors) return null;

  return (
    <Form.Control.Feedback type="invalid" className="d-block">
      {
        errors[fieldName] ||
        (apiErrors?.[fieldName]?.join(',')) ||
        (apiErrors?.errors?.[fieldName]?.join(','))
      }
    </Form.Control.Feedback>
  );
};

FormFeedback.propTypes = {
  touched: PropTypes.object,
  errors: PropTypes.object,
  apiErrors: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
};

export default FormFeedback;

import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { SortDirection } from 'types/ListSearchTypes';
import IDeployment from 'types/IDeployment';
import RtkQueryResult from 'types/RtkQueryResult';
import PaginatedResult from 'types/PaginatedResult';
import IQueryParameters from 'types/IQueryParameters';
import Environment from 'types/Environment';
import { useLazyGetNodeGroupDeploymentsQuery } from 'api/nodeGroupSlice';

export type SortBy = 'created_at' | 'updated' | 'engine_version' | 'image_tag'
  | 'target_environment' | 'status';
export type UseSearchApplicationPermissionsSearchParameters = {
  name?: string
  description?: string
  any?: string
};

interface IResult extends RtkQueryResult<PaginatedResult<IDeployment>> { }

const useSearchNodeGroupDeployments: (args: { nodeGroupId: string }) => {
  setTake: (take: number) => void;
  take: number;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
  setSearchStatus: (searchStatus: string) => void;
  searchStatus: string;
  setSearchTargetEnvironment: (environment: Environment | '') => void;
  searchTargetEnvironment: Environment | '';
  toDate: string;
  setToDate: (searchStatus: string) => void;
  fromDate: string;
  setFromDate: (searchStatus: string) => void;
  page: number
  setPage: (page: number) => void;
  result: IResult;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  refetch: () => any;
} = ({ nodeGroupId }) => {
  const to = DateTime.now().plus({ days: 1 }).toJSDate();
  const from = DateTime.now().minus({ days: 7 }).toJSDate();

  const [take, setTake] = useState<number>(10);
  const [sortBy, setSortBy] = useState<SortBy>('created_at');
  const [page, setPage] = useState<number>(1);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [searchStatus, setSearchStatus] = useState<string>('');
  const [searchTargetEnvironment, setSearchTargetEnvironment] = useState<Environment | ''>('');
  const [toDate, setToDate] = useState<string>(to.toISOString());
  const [fromDate, setFromDate] = useState<string>(from.toISOString());
  const [getApplicationMicroserviceDeployments, result] = useLazyGetNodeGroupDeploymentsQuery();

  const queryParameters: IQueryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };

  if (searchStatus !== '') queryParameters.status = searchStatus;
  if (searchTargetEnvironment !== '') queryParameters.target_environment = searchTargetEnvironment;
  queryParameters.from_date = fromDate;
  queryParameters.to_date = toDate;

  useEffect(() => {
    if (searchStatus !== '') queryParameters.status = searchStatus;
    if (searchTargetEnvironment !== '') queryParameters.target_environment = searchTargetEnvironment;

    getApplicationMicroserviceDeployments({ nodeGroupId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationMicroserviceDeployments, take, page, sortBy, sortDirection, toDate, fromDate, searchStatus]);

  useEffect(() => {
    setPage(1);
    getApplicationMicroserviceDeployments({ nodeGroupId, queryParameters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take]);

  return {
    setTake,
    take,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStatus,
    setSearchStatus,
    searchTargetEnvironment,
    setSearchTargetEnvironment,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    page,
    setPage,
    result,
    refetch: () => getApplicationMicroserviceDeployments({ nodeGroupId, queryParameters }),
  };
};

export default useSearchNodeGroupDeployments;

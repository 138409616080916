import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';

export default ({ devUrl, stagingUrl, prodUrl }) => {
  // Makes URLS work during local development
  if (process.env.NODE_ENV !== 'production') {
    devUrl = devUrl.replace('https', 'http');
    stagingUrl = stagingUrl.replace('https', 'http');
    prodUrl = prodUrl.replace('https', 'http');
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="secondary">
        {translate('openApp')}
      </Dropdown.Toggle>

      <Dropdown.Menu variant="dark">
        <Dropdown.Item href={devUrl} target="_blank">
          {translate('dev')}
        </Dropdown.Item>
        <Dropdown.Item href={stagingUrl} target="_blank">
          {translate('staging')}
        </Dropdown.Item>
        <Dropdown.Item href={prodUrl} target="_blank">
          {translate('prod')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

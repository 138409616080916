import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import Card from 'common/Card';
import IApplication from 'types/application/IApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileStorageCard: FC<{ application: IApplication }> = ({ application }) => {
  const { result } = useSearchApplicationMicroservices({
    applicationId: application.id,
    initialSearchObj: {
      include_type: ['s3', 'filesystem'],
    }
  });

  const { data, error, isLoading } = result;

  return (
    <Card
      title={translate('storage')}
      body={(
        <Table variant="dark" striped>
          <tbody>
            {
              isLoading && (
                <Spinner />
              )
            }
            {
              (!isLoading && (error || !data || !data.data)) && (
                <p className="my-2 text-center text-danger">
                  <FontAwesomeIcon icon="engine-warning" size="sm" />
                  {translate('errorFetchingStorageServices')}
                </p>
              )
            }
            {
              !isLoading && data?.data?.length && data.data.length > 0 && data.data.map(microservice => (
                <tr key={microservice.id}>
                  <td>
                    <Link to={`/applications/${application.id}/microservices/${microservice.id}`}>{microservice.name}</Link>
                  </td>
                  <td className="text-muted">
                    {microservice.application_microserviceable_type === 's3' ? 'object store' : 'file system'}
                  </td>
                  <td className="overflow-hidden">
                    {microservice.description}
                  </td>
                </tr>
              ))
            }
            {
              !isLoading && data && data.data && data.data.length === 0 && (
                <p className="my-2 text-center text-warning">{translate('noStorageServices')}</p>
              )
            }
          </tbody>
        </Table>
      )}
    />
  );
};

export default FileStorageCard;

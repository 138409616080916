import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationPropTypes from 'propTypes/application/ApplicationPropTypes';
import useSearchApplicationMicroservices from 'hooks/useSearchApplicationMicroservices';
import Card from 'common/Card';

const ScheduledJobsCard = ({ application }) => {
  const { result } = useSearchApplicationMicroservices({
    applicationId: application.id,
    initialSearchObj: {
      include_type: 'scheduled_job',
    }
  });

  return (
    <Card
      title={translate('scheduledJobs')}
      body={(
        <Table variant="dark" striped>
          <tbody>
            {
              result?.data?.data.length > 0 ? result?.data?.data.map(microservice => (
                <tr key={microservice.id}>
                  <td>
                    <Link to={`/applications/${application.id}/microservices/${microservice.id}`}>{microservice.name}</Link>
                  </td>
                  <td className="overflow-hidden">
                    {microservice.description}
                  </td>
                </tr>
              )) : (
                <p className="my-2 text-center text-warning">{translate('noScheduledJobs')}</p>
              )
            }
          </tbody>
        </Table>
      )}
    />
  );
};

ScheduledJobsCard.propTypes = {
  application: ApplicationPropTypes,
};

export default ScheduledJobsCard;

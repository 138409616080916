import { useState } from "react";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import * as yup from 'yup';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from "common/FormFeedback";
import IPermission from "types/application/IPermission";
import IGroup from "types/group/IGroup";
import useSearchOrganizationGroups from "hooks/useSearchOrganizationGroups";
import { Option } from "react-bootstrap-typeahead/types/types";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import useSearchOrganizationMembers from "hooks/useSearchOrganizationMembers";
import useSearchApplicationPermissions from "hooks/useSearchApplicationPermissions";
import IApplicationRole, { IApplicationRoleFormFields, IApplicationRoleValidationErrors } from "types/application/IApplicationRole";
import IUser from "types/IUser";
import MenuItem from "common/typeahead/MenuItem";

const OrganizationApplicationRoleForm: React.FC<{
  onSubmit: (formData: IApplicationRoleFormFields) => void
  onCancel: () => void
  apiErrors: IApplicationRoleValidationErrors | null
  disabled: boolean
  initialValues?: IApplicationRole
  organizationId: string
  applicationId: string
  currentPermissions?: IPermission[]
  currentGroups?: IGroup[]
  currentUsers?: IUser[]
}> = ({
  onSubmit,
  onCancel,
  apiErrors,
  disabled,
  initialValues = {
    name: '',
    description: '',
  },
  organizationId,
  applicationId,
  // these will be for editing a role
  currentPermissions = [],
  currentGroups = [],
  currentUsers = [],
}) => {
    const schema = yup.object({
      name: yup.string(),
      description: yup.string(),
    });

    const [selectedPermissions, setSelectedPermissions] = useState<Option[] | never[]>(currentPermissions);
    const [selectedGroups, setSelectedGroups] = useState<Option[] | never[]>(currentGroups);
    const [selectedUsers, setSelectedUsers] = useState<Option[] | never[]>(currentUsers);

    const {
      setSearchParameters: setSearchGroupsParameters,
      result: groupsResult,
    } = useSearchOrganizationGroups({ organizationId });

    const {
      setSearchParameters: setSearchMembersParameters,
      result: membersResult,
    } = useSearchOrganizationMembers({ organizationId });

    const {
      setSearchParameters: setSearchPermissionsParameters,
      result: permissionsResult,
    } = useSearchApplicationPermissions({ applicationId });

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={(formInput) => {
          onSubmit({
            ...formInput,
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            groups: selectedGroups.map(group => group.id),
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            users: selectedUsers.map(user => user.id),
            // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
            permissions: selectedPermissions.map(permission => permission.id)
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{translate('name')}</Form.Label>
              <Form.Control
                required
                name="name"
                type="text"
                placeholder="Name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.name && !errors.name}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{translate('description')}</Form.Label>
              <Form.Control
                required
                name="description"
                type="text"
                placeholder="Description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                isValid={touched.description && !errors.description}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="description"
              />
            </Form.Group>

            <Form.Group controlId="formPermissions" className="mb-3">
              <Form.Label>{translate('permissions')}</Form.Label>
              <AsyncTypeahead
                multiple
                paginate={false}
                placeholder={translate('searchPermissions')}
                id="async-permissions-typeahead"
                delay={300}
                isLoading={permissionsResult.isLoading || permissionsResult.isFetching}
                labelKey="name"
                onSearch={(query: string) => setSearchPermissionsParameters({ any: query })}
                options={permissionsResult.data?.data || []}
                selected={selectedPermissions}
                onChange={setSelectedPermissions}
                renderMenuItemChildren={(option, { text }) => (
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  <MenuItem label={option.name} smallText={option.description} searchText={text} />
                )}
                filterBy={(option) => {
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  return !selectedPermissions.find(p => p.id === option.id)
                }}
              />
            </Form.Group>

            <Form.Group controlId="formGroups" className="mb-3">
              <Form.Label>{translate('groups')}</Form.Label>
              <AsyncTypeahead
                multiple
                paginate={false}
                placeholder={translate('searchGroups')}
                id="async-groups-typeahead"
                delay={300}
                isLoading={groupsResult.isLoading || groupsResult.isFetching}
                labelKey="name"
                onSearch={(query: string) => setSearchGroupsParameters({ any: query })}
                options={groupsResult.data?.data || []}
                selected={selectedGroups}
                onChange={setSelectedGroups}
                renderMenuItemChildren={(option, { text }) => (
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  <MenuItem label={option.name} smallText={option.description} searchText={text} />
                )}
                filterBy={(option) => {
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  return !selectedGroups.find(g => g.id === option.id)
                }}
              />
            </Form.Group>

            <Form.Group controlId="formUsers" className="mb-3">
              <Form.Label>{translate('users')}</Form.Label>
              <AsyncTypeahead
                multiple
                paginate={false}
                placeholder={translate('searchUsers')}
                id="async-users-typeahead"
                delay={300}
                isLoading={membersResult.isLoading || membersResult.isFetching}
                onSearch={(query: string) => setSearchMembersParameters({ any: query })}
                options={membersResult.data?.data || []}
                selected={selectedUsers}
                onChange={setSelectedUsers}
                renderMenuItemChildren={(option, { text }) => (
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  <MenuItem label={`${option.first_name} ${option.last_name}`} smallText={option.email} searchText={text} />
                )}
                // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                labelKey={option => `${option.first_name} ${option.last_name}`}
                filterBy={(option) => {
                  // @ts-expect-error Typeahead's Option type isn't generic enough for our interfaces
                  return !selectedUsers.find(u => u.id === option.id)
                }}
              />
            </Form.Group>

            <Form.Group className="mt-4">
              {onCancel && (
                <Button
                  type="button"
                  variant="secondary"
                  disabled={disabled}
                  onClick={onCancel}
                >
                  {translate('cancel')}
                </Button>
              )}
              <Button
                type="submit"
                variant="primary"
                className="mx-2"
                disabled={disabled}
              >
                {translate('submit')}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    )
  };

export default OrganizationApplicationRoleForm;

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import ScheduledJobExecutionPropTypes from 'propTypes/application/microservice/ScheduledJobExecutionPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';
import { formatDateTime } from 'helpers/datetime';

const ScheduledExecutionTable = ({ scheduledExecutions, actionColumn }) => {
  const columns = [
    {
      title: translate('executeDate'),
      dataIndex: 'execute_at',
      sortDirection:'execute_at',

      render: createdAt => formatDateTime(createdAt),
    },
    {
      title: translate('status'),
      dataIndex: 'status',
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={scheduledExecutions}
      columns={columns}
    />
  );
};

ScheduledExecutionTable.propTypes = {
  actionColumn: ActionColumnPropTypes,
  scheduledExecutions: PropTypes.arrayOf(ScheduledJobExecutionPropTypes).isRequired,
};

export default ScheduledExecutionTable;

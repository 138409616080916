import { useEffect, useState } from 'react';
import useInterval from 'hooks/useInterval';
import { REFETCH_DELAY_IN_SECONDS } from 'config';

const useHealthcheck = (microserviceId, environment, enableInterval = true) => {
  const [isHealthy, setIsHealthy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchHealthcheckStatus = async () => {
    setIsLoading(true);
    try {
      const url = `/api/application-microservices/${microserviceId}/healthcheck?environment=${environment}`;
      const response = await fetch(url);
      if (response.status === 200) setIsHealthy(true);
      else setIsHealthy(false);
    } catch (error) {
      setIsHealthy(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Run on mount
  useEffect(() => {
    fetchHealthcheckStatus();
  }, []);

  useInterval(() => {
    if (enableInterval) fetchHealthcheckStatus();
  }, REFETCH_DELAY_IN_SECONDS * 1000);

  return {isHealthy, isLoading};
};

export default useHealthcheck;

import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import ScheduledJobVerisonSelecter from './ScheduledJobVerisonSelecter';
import { translate } from '@apex/react-toolkit/lib';
import ConfirmExecuteModal from './ConfirmExecuteModal';
import ScheduledExecutionsCard from './ScheduledExecutionsCard';
import CronCard from './CronCard';
import MicroserviceChartVersionSelectorCard from 'common/applicationMicroservice/MicroserviceChartVersionSelectorCard';
import ApexHelmChartVersionExplainTooltip from 'common/applicationMicroservice/ApexHelmChartVersionExplainTooltip';
import AccordionToggleLink from 'common/AccordionToggleLink';
import Environment from 'types/Environment';
import { IApplicationMicroserviceScheduledJob } from 'types/application/microservice/IScheduledJobMicroservice';

const ScheduledJobConfiguration: React.FC<{
  env: Environment;
  microservice: IApplicationMicroserviceScheduledJob;
  isDeveloperAuthorized: boolean;
}> = ({ env, microservice, isDeveloperAuthorized }) => {
  const { microserviceable: schedJobMs } = microservice;
  const [showConfirmExecuteModal, setShowConfirmExecuteModal] = useState(false);

  return (
    // Not sure why the global bg-dark-secondary class doesn't work here...
    <Card style={{ backgroundColor: 'rgba(108, 117, 125, 0.4)' }}>
      <Card.Body>
        <Row className="mb-4">
          <Col>
            <ScheduledJobVerisonSelecter env={env} microservice={microservice} />
          </Col>
          <Col className="text-end">
            <Button
              disabled={!schedJobMs[`${env}_tag`] || !isDeveloperAuthorized}
              variant="success"
              onClick={() => setShowConfirmExecuteModal(true)}
            >{translate('executeNow')}</Button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark" className="mt-3">
                <Card.Header>
                  <Row>
                    <Col className="text-start">
                      {translate('apexShcheduledJobVersions')}  <ApexHelmChartVersionExplainTooltip />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <span className="text-muted">
                            {translate(env)} {microservice.dev_chart_version.version}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="text-end">
                      <AccordionToggleLink
                        eventKey="apex-versions"
                        inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                        activeText={translate('close')}
                      />
                    </Col>
                  </Row>
                </Card.Header>
              </Card>
              <Accordion.Collapse eventKey="apex-versions">
                <MicroserviceChartVersionSelectorCard
                  microservice={microservice}
                  isDeveloperAuthorized={isDeveloperAuthorized}
                  environments={[env]}
                />
              </Accordion.Collapse>
            </Accordion>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark">
                <Card.Header>
                  {translate('cron')}
                </Card.Header>
              </Card>
              <CronCard
                canActivate={schedJobMs[`${env}_tag`] !== null}
                cron={schedJobMs[`${env}_cron`]}
              />
            </Accordion>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark">
                <Card.Header>
                  {translate('scheduledExecutions')}
                </Card.Header>
              </Card>
              <ScheduledExecutionsCard
                microservice={microservice}
                env={env}
              />
            </Accordion>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Accordion alwaysOpen>
              <Card bg="dark">
                <Card.Header>
                  {translate('environmentVariables')}
                </Card.Header>
              </Card>
              <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} lockedEnv={env} />
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
      <ConfirmExecuteModal
        show={showConfirmExecuteModal}
        env={env}
        handleClose={() => setShowConfirmExecuteModal(false)}
        microservice={microservice}
      />
    </Card >
  )
};

export default ScheduledJobConfiguration;

import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const applicationPermissionProperties = {
  ...BaseModelPropTypes,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default PropTypes.shape({
  ...applicationPermissionProperties,
});

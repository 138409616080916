import { useEffect } from 'react';
import { useLazyGetApplicationMicroserviceEnvironmentVariablesQuery } from 'api/applicationMicroserviceSlice';

const useSearchApplicationMicroserviceEnvironmentVariables = ({ microserviceId }) => {
  const [getApplicationMicroserviceEnvironmentVariables, result] = useLazyGetApplicationMicroserviceEnvironmentVariablesQuery();

  useEffect(() => {
    getApplicationMicroserviceEnvironmentVariables({ microserviceId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationMicroserviceEnvironmentVariables]);

  return {
    result,
  };
};

export default useSearchApplicationMicroserviceEnvironmentVariables;

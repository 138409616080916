import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { useAddApplicationMutation } from 'api/applicationSlice';
import ApplicationFormWithPreview from 'common/application/ApplicationFormWithPreview';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import Unauthorized from 'common/Unauthorized';

const New = () => {
  const navigate = useNavigate();
  const [addApplication, { isLoading }] = useAddApplicationMutation();
  const [apiErrors, setApiErrors] = useState(null);
  const { isSuperUser } = useCheckUserPermission();

  const handleSubmit = async (formData) => {
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await addApplication(formData);
    if (error) {
      if (error) {
        setApiErrors(error.errors);
      } else {
        navigate(`../${data.id}`);
      }
    } else {
      navigate(`../${data.id}`);
    }
  };

  const handleCancel = () => {
    navigate('../');
  };

  if (!isSuperUser) return <Unauthorized />;

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate('addApplication')}</h2>
        </Col>
      </Row>
      <ApplicationFormWithPreview
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        apiErrors={apiErrors}
        primaryAction={translate('create')}
      />
    </Container>
  );
};

export default New;

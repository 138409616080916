import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Col, Row, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import { Spinner } from '@apex/react-toolkit/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEditApplicationMaintainerRoleMutation, useGetApplicationMaintainersQuery, useLazyGetApplicationMaintainersQuery } from 'api/applicationSlice';
import AddMaintainersModal from 'App/AppRoutes/Applications/Application/Maintainers/MaintainersBody/AddMaintainersModal';
import RemoveMaintainerModal from 'App/AppRoutes/Applications/Application/Maintainers/MaintainersBody/RemoveMaintainerModal';
import MaintainerTable from 'common/maintainer/MaintainerTable';
import PaginationControl from 'common/PaginationControl';
import SearchFilter from 'common/SearchFilter';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

const MaintainersBody = ({ applicationName }) => {
  const { applicationId } = useParams();
  const [take, setTake] = useState(10);
  const [sortBy, setSortBy] = useState('last_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchObj, setSearchObj] = useState(null);
  const [page, setPage] = useState(1);
  const [showAddMaintainersModal, setShowAddMaintainersModal] = useState(false);
  const [canAddMaintainers, setCanAddMaintainers] = useState(false);
  const [getApplicationUsers, { data: paginatedData, isLoading, isFetching }] = useLazyGetApplicationMaintainersQuery();
  const [editApplicationMaintainerRole] = useEditApplicationMaintainerRoleMutation();
  const [maintainerQueuedForRemoval, setMaintainerQueuedForRemoval] = useState(null);
  const { isDeveloperAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);
  const { data: allMaintainers, isFetching: allMaintainersIsFetching, error: allMaintainersError } = useGetApplicationMaintainersQuery({ applicationId });

  const queryParameters = {
    limit: take,
    page,
    sort_by: sortBy,
    sort_direction: sortDirection,
  };
  const searchBy = searchObj?.option;
  const searchVal = searchObj?.value;

  if (searchBy && searchVal) queryParameters[searchBy] = searchVal;

  useEffect(() => {
    setCanAddMaintainers(allMaintainers && !allMaintainersError && !allMaintainersIsFetching);
  }, [allMaintainers, allMaintainersError, allMaintainersIsFetching]);

  useEffect(() => {
    getApplicationUsers({ queryParameters, applicationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationUsers, take, page, sortBy, sortDirection]);

  useEffect(() => {
    setPage(1);
    getApplicationUsers({
      applicationId,
      queryParameters: {
        ...queryParameters,
        page: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObj, take]);

  if (allMaintainersError) return (<Error coverPage />);
  if (isLoading || appAuthIsFetching || !paginatedData) return (<Spinner />);

  const { data: maintainers, meta } = paginatedData;

  return (
    <>
      {
        isDeveloperAuthorized && canAddMaintainers && (
          <AddMaintainersModal
            show={showAddMaintainersModal}
            applicationId={applicationId}
            applicationName={applicationName}
            handleClose={() => setShowAddMaintainersModal(false)}
            currentMaintainers={allMaintainers.data}
          />
        )
      }
      {
        maintainerQueuedForRemoval && (
          <RemoveMaintainerModal
            show={maintainerQueuedForRemoval !== null}
            handleClose={() => setMaintainerQueuedForRemoval(null)}
            applicationId={applicationId}
            applicationName={applicationName}
            maintainer={maintainerQueuedForRemoval}
          />
        )
      }
      <Row>
        <Col>
          <h2>
            {translate('maintainers')} <Badge pill>{meta.total}</Badge>
          </h2>
        </Col>
        <Col className="text-end">
          {
            isDeveloperAuthorized && (
              <Button
                onClick={() => setShowAddMaintainersModal(true)}
                disabled={!canAddMaintainers}
              >
                <FontAwesomeIcon icon="plus" />
                {translate('addMaintainers')}
              </Button>
            )
          }
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Form.Label>{translate('searchBy')}</Form.Label>
          <SearchFilter
            disabled={isFetching}
            onUpdate={setSearchObj}
            options={[
              {
                option: 'first_name',
                display: translate('firstName'),
              },
              {
                option: 'last_name',
                display: translate('lastName'),
              },
              {
                option: 'email',
                display: translate('email'),
              },
              {
                option: 'any',
                display: translate('any'),
              },
            ]}
          />
        </Col>
        <Col md={{ span: 2, offset: 6 }}>
          <Form.Group>
            <Form.Label>{translate('resultsPerPage')}</Form.Label>
            <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <MaintainerTable
            applicationId={applicationId}
            maintainers={maintainers}
            canMutateMaintainers={isDeveloperAuthorized}
            setSortBy={(updatedSortKey) => {
              if (sortBy === updatedSortKey) {
                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
              } else {
                setSortBy(updatedSortKey);
              }
            }}
            sortBy={sortBy}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            actionColumn={{
              dataIndex: 'id',
              render: (_, maintainer) => {
                return isDeveloperAuthorized && (
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-nowrap"
                    onClick={() => setMaintainerQueuedForRemoval(maintainer)}
                  >
                    <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
                  </Button>
                );
              }
            }}
            onSelectMaintainerRole={(maintainerId, role) => {
              editApplicationMaintainerRole({ applicationId, maintainerId, data: { maintainer_role: role }});
            }}
          />
          <PaginationControl
            pagesToShow={3}
            pageCount={meta.last_page}
            currentPage={page}
            onPageChange={setPage}
          />
        </Col>
      </Row>
    </>
  );
};

MaintainersBody.propTypes = {
  applicationName: PropTypes.string.isRequired,
};

export default MaintainersBody;

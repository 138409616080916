import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import GroupForm from 'common/group/GroupForm';
import { useAddGroupMutation } from 'api/groupSlice';
import { translate } from '@apex/react-toolkit/lib';


const New = () => {
  const navigate = useNavigate();
  const [addGroup, { isLoading }] = useAddGroupMutation();
  const [apiErrors, setApiErrors] = useState(null);

  const handleSubmit = async (formData) => {
    // eslint-disable-next-line no-unused-vars
    const { data, error } = await addGroup(formData);
    if (error) {
      setApiErrors(error.errors);
    } else {
      navigate(`/groups/${data.id}`);
    }
  };

  const handleCancel = () => {
    navigate('/groups/');
  };

  return (
    <Container>
      <Row className="mt-4 justify-content-md-center">
        <Col md={4}>
          <h2>{translate('addGroup')}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={4}>
          <GroupForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            disabled={isLoading}
            apiErrors={apiErrors}
            submitText={translate('create')}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default New;

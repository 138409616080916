import React from 'react';

import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import TrustedSourceForm from 'common/trustedSources/TrustedSourceForm';
import { useAddDatabaseTrustedSourceMutation, useGetDatabaseTrustedSourcesQuery, useDeleteDatabaseTrustedSourceMutation } from 'api/dbMicroserviceSlice';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import { useParams } from 'react-router-dom';
import Environment from 'types/Environment';
import IDatabaseMicroservice from 'types/application/microservice/IDatabaseMicroservice';
import ITrustedSource from 'types/application/ITrustedSource';
import useToast from 'hooks/useToast';

const TrustedSources: React.FC<{
  dbMicroservice: IDatabaseMicroservice;
  environment: Environment;
}> = ({
  dbMicroservice,
  environment,
}) => {
    const { applicationId } = useParams();
    const toast = useToast();
    const [addDatabaseTrustedSource, { isLoading: addTrustedSourceLoading, error }] = useAddDatabaseTrustedSourceMutation();
    const { data, isLoading: getTrustedSourcesLoading } = useGetDatabaseTrustedSourcesQuery(dbMicroservice.id);
    const { isDeveloperAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);
    const [deleteTrustedSource, { isLoading: deleteLoading }] = useDeleteDatabaseTrustedSourceMutation();

    const disabled = deleteLoading || appAuthIsFetching || addTrustedSourceLoading || getTrustedSourcesLoading || !isDeveloperAuthorized;
    let trustedSourcesForEnv: ITrustedSource[] = [];
    if (data && data.length > 0) trustedSourcesForEnv = data.filter((ts: ITrustedSource) => ts.environment === environment);

    return (
      <Container>
        <Row>
          <Col>
            <h3>{translate('trustedSources')}</h3>
          </Col>
        </Row>
        {
          (trustedSourcesForEnv.length > 0) && (
            <>
              <Table variant="dark" bordered striped>
                <thead>
                  <tr>
                    <th>{translate('description')}</th>
                    <th>{translate('trustedIps')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    trustedSourcesForEnv.map((trustedSource: ITrustedSource) => (
                      <tr key={trustedSource.id}>
                        <td>{trustedSource.description}</td>
                        <td>{trustedSource.trusted_ips}</td>
                        {isDeveloperAuthorized && (
                          <td>
                            <Button
                              variant="danger"
                              disabled={disabled}
                              onClick={async () => {
                                try {
                                  await deleteTrustedSource({ dbId: dbMicroservice.id, trustedSourceId: trustedSource.id });
                                } catch (e) {
                                  toast({
                                    bg: 'danger',
                                    title: translate('error'),
                                    message: translate('anUnexpectedErrorOccurred'),
                                    autohide: false,
                                  });
                                }
                              }}>
                              {translate('delete')}
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <hr />
            </>
          )
        }
        <Row>
          <Col>
            <TrustedSourceForm
              disabled={disabled}
              onSubmit={(values) => addDatabaseTrustedSource({
                dbId: dbMicroservice.id,
                data: {
                  ...values,
                  environment,
                },
              })}
              apiErrors={error?.errors}
            />
          </Col>
        </Row>
      </Container>

    );
  };

export default TrustedSources;

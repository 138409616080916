import React from 'react';
import { groupBy, sortBy } from 'lodash';
import { Accordion, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import IApplication from 'types/application/IApplication';
import IApplicationRole from 'types/application/IApplicationRole';
import { translate } from '@apex/react-toolkit/lib';

import './styles.css';

const GroupApplicationsAndRolesAccordion: React.FC<{
  applications: IApplication[];
  groupRoles: IApplicationRole[];
}> = ({ applications, groupRoles }) => {
  const rolesByApplicationIdHash = groupBy(groupRoles, 'application_id');

  if (applications.length === 0) {
    return (<h4>This group has no applications</h4>)
  }

  return (
    <Accordion defaultActiveKey={applications[0].id}>
      {
        sortBy(applications, 'name').map((app: IApplication) => (
          <Accordion.Item key={app.id} eventKey={app.id}>
            <Accordion.Header >
              <strong>{app.name}</strong>
            </Accordion.Header>
            <Accordion.Body style={{ padding: 0 }}>
              <ListGroup variant="flush">
                {
                  rolesByApplicationIdHash[app.id] ?
                    rolesByApplicationIdHash[app.id].map(
                      (role: IApplicationRole) => (
                        <ListGroupItem key={role.id} variant="dark">
                          <Row>
                            <Col md={{ offset: 1 }}>
                              {role.name}
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )
                    )
                    : (
                      <ListGroupItem variant="dark">
                        <strong>
                          {translate('noRolesHaveBeenDefined')}
                        </strong>
                      </ListGroupItem>
                    )
                }
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  );
}

export default GroupApplicationsAndRolesAccordion;

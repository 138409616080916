import { FC } from 'react';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable, { InteractiveTableColumn } from 'common/InteractiveTable';
import { SortDirection } from 'types/ListSearchTypes';
import IApexHelmChartVersion from 'types/infrastructure/IApexHelmChartVersion';
import { ApexHelmChartVersionsSortBy } from 'hooks/useSearchApexHelmChartVersions';
import { Button } from 'react-bootstrap';
import RunningOutOfTimeSpan from 'common/datetime/RunningOutOfTimeSpan';

const ApexHelmChartVersionTable: FC<{
  apexHelmChartVersions: IApexHelmChartVersion[];
  sortBy: ApexHelmChartVersionsSortBy;
  setSortBy: (value: ApexHelmChartVersionsSortBy) => void;
  sortDirection: SortDirection;
  onChartNameClicked?: (apexHelmChartVersion: IApexHelmChartVersion) => void;
  actionColumn?: InteractiveTableColumn<IApexHelmChartVersion>;
}> = ({ apexHelmChartVersions, sortBy, setSortBy, sortDirection, onChartNameClicked, actionColumn }) => {
  const columns: InteractiveTableColumn<IApexHelmChartVersion>[] = [
    {
      title: translate('chartName'),
      dataIndex: 'chart_name',
      sortDirection: sortBy === 'chart_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('chart_name'),
        className: 'clickable',
      }),
      render: (chartName: string, apexHelmChartVersion: IApexHelmChartVersion) => {
        return (
          <Button variant="link" onClick={onChartNameClicked ? () => onChartNameClicked(apexHelmChartVersion) : undefined}>
            {chartName}
          </Button >
        );
      },
    },
    {
      title: translate('version'),
      dataIndex: 'version',
      sortDirection: sortBy === 'version' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('version'),
        className: 'clickable',
      }),
    },
    {
      title: translate('deprecationDate'),
      dataIndex: 'deprecation_date',
      sortDirection: sortBy === 'deprecation_date' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('deprecation_date'),
        className: 'clickable',
      }),
      render: (deprecationDate?: string) => {
        if (!deprecationDate) return '';

        return (
          <RunningOutOfTimeSpan
            dateString={deprecationDate}
            warnDaysBefore={30}
          />
        );
      },
    },
    {
      title: translate('eolDate'),
      dataIndex: 'eol_date',
      sortDirection: sortBy === 'eol_date' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('eol_date'),
        className: 'clickable',
      }),
      render: (eolDate?: string) => {
        if (!eolDate) return '';

        return (
          <RunningOutOfTimeSpan
            dateString={eolDate}
            warnDaysBefore={30}
          />
        );
      },
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  return (
    <InteractiveTable
      dataId="id"
      data={apexHelmChartVersions}
      columns={columns}
    />
  );
};

export default ApexHelmChartVersionTable;

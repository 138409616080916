import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import Card from 'common/Card';
import useSearchApplicationActivity from 'hooks/useSearchApplicationActivity';
import { formatDateTime } from 'helpers/datetime';
import ApplicationActivityStatus from 'common/applicationActivity/ApplicationActivityStatus';

export default () => {
  const { applicationId } = useParams();

  const {
    setTake,
    result: queryResult,
  } = useSearchApplicationActivity({ applicationId });

  // Run on mount
  useEffect(() => {
    setTake(10);
  }, []);

  if (queryResult.isLoading || !queryResult.data) return (<Spinner />);

  return (
    <>
      <Card
        title={(
          <>
            {translate('recentActivity')}

            {/* APEX-762: Modify <TabsWithNavigationHash /> to support changing tabs from outside the tab group. */}
            <Link to="#activity" style={{ fontSize: 14, color: 'grey', pointerEvents: 'none' }}>
              {translate('allActivity')}
              <FontAwesomeIcon icon="arrow-right" className="ms-1" />
            </Link>
          </>
        )}
        body={(
          <Table variant="dark" striped>
            <tbody>
              {queryResult?.data?.data.map(activity => (
                <tr key={activity.id}>
                  <td className="text-center">
                    <ApplicationActivityStatus status={activity.status} />
                  </td>
                  <td>
                    {activity.name}
                    <br />
                    <small className="text-muted">{formatDateTime(activity.updated_at)}</small>
                  </td>
                  <td>
                    {translate(activity.action)} <code>{activity.code_identifier}</code>
                    <br />
                    <small className="text-muted">{activity.environment && translate(activity.environment)}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </>
  );
};
